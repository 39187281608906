import React, { Component } from 'react';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';
import { ComboBox, DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons'
import ExcelDownload from './components/ExcelDownload';
import {
  TabStrip,
  TabStripTab
} from '@progress/kendo-react-layout';
import ReactQuill from 'react-quill';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Grid, GridColumn as Column, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import '../Spark.css';
import moment from 'moment';
import { getConsolidatedCMSDataIntegrated } from '../integration/ConsolidatedCMSService';
import { getProjectPendingTasks } from './../projectManagement/workflow/PendingTaskService';
import {
  formatNumber,
  getFormattedDate,
  getFormattedDateAndTime,
  isWeekend,
  roundNumber,
  reStructureDate
} from '../integration/CommonUtils';
import {
  getResourceRequestResourceMixData,
  getActiveProjectResources,
  allocateResources,
  deleteRequest,
  saveResourceRequests,
  getRatesForAllocation,
  updateStatusToConfirm,
  deleteRequestSubLine,
  saveResourceAllocation, getPreSalesResourceMixData,
  getLineStatus, saveResourceLineHistory, getLineHistory,
  getAllProjectResources,
  getAllRateTypes,
  addMainLineHistoryToSubLine,
  getAllocationPercentageHistory,
  updateAllocationPercentageHistory,
  allocationChangeNotification,
  getLeavesIntegrated,
  getPreviousCMSResourceMixData,
  getActualOtherExpenses,
  saveActualOtherExpenseDates,
  saveNewActualOtherExpense,
  updateActualOtherExpense,
  deleteActualOtherExpenseCost,
  getActualOtherExpensesPreviousCMS
} from './ResourceService';
import {
  getAllDesignationsIntegrated,
  getHolidaysIntegrated,
  getResourceRegionsIntegrated,
  getRateTypesIntegrated,
  getCostIntegrated,
  getRegionCurrencyIntegrated
} from '../integration/MasterDataService';
import { getExpenseTypes, getPaymentMethods } from '../masterData/common/CommonService';
import ProjectORCrCell from './resourceRequest/convertActive/ProjectORCrCell';
import { filterBy } from '@progress/kendo-data-query';
import * as loggerService from '../integration/LoggerService';
import { LOG_TYPES } from '../integration/IntegrationEnums';
import {
  getPhasesAndSubPhases,
  savePhasesOfResourceRequests,
  getCMSheet, calculateDuration, calculateCostDate, retrieveConversionRate,
  getCMSheetOtherExpenses
} from '../projectManagement/cmSheet/CMSheetService';
import PhaseRow from './components/PhaseRow';

import { DebounceInput } from 'react-debounce-input';
import SuccessImg from '../ui/static/images/checked.svg';
import ErrorImg from '../ui/static/images/cancel.svg';
import { Redirect } from 'react-router';
import { updateProject, getProjectStatistics } from '../projectManagement/project/ProjectService';
import ResourceRequestStatusCell from './components/ResourceRequestStatusCell';
import ResourceRequestLineStartDateCell from './components/ResourceRequestLineStartDateCell';
import GridSubLineCell from './components/GridSubLineCell';
import Loader from '../integration/components/Loader';
import { retrieveConversionRateIntegrated } from '../integration/ProjectManagementService';
import {
  submitForResourceAllocation,
  submitForResourceSoftBook,
  submitForResourceSoftBookSubLine,
  approveResourceRequest,
  rejectResourceRequest,
  getAllApproversForResource, 
  submitForResourceDeallocate, 
  submitForResourceDeallocateSubLine, 
  deallocationEmail, 
  deallocateResourceRequestItems
} from './resourceRequest/ResourceRequestActivationService';
import { saveAuditTrail } from '../integration/auditTrail/AuditTrailService';
import {
  selectResourceRequestWorkflow, setWorkFlow
} from '../projectManagement/workflow/WorkflowService';

import ResourceRequestValueThreeCell from './components/ResourceRequestValueThreeCell';
import ResourceRequestValueFourCell from './components/ResourceRequestValueFourCell';
import ResourceRequestSubLineActionCell from './EditSubLineActionCell';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import ResourceRequestStatusListCell from './components/ResourceRequestStatusListCell';
import MoreDetailsCell from './components/MoreDetailsCell';
import { Link } from "react-router-dom";
import ProjectCell from "../projectManagement/search/ProjectCell";
import UpdatedDateCell from "../projectManagement/search/UpdatedDateCell";
import CRIdLinkCell from "./components/CRIdLinkCell";
import ResourceRequestLineEndDateCell from "./components/ResourceRequestLineEndDateCell";
import {getAllTaskTypes} from '../masterData/taskType/TaskTypeService';
import {
  getCost,
  getCostDetails,
  getCostofAllocatedResource, getCRDetails,
  getMultiYearCost,
  getProjectDetails
} from '../masterData/costCard/CostCardService';
import {getMultiYearInfoByYearandCostCardId} from "../masterData/multiyearIncrement/MultiyearIncrementService";
import {saveProjectTask,saveResource,getAllProjectTasks,getResource} from '../Timesheets/projectTaskManagement/ProjectTaskManagementService';
import ResourceRequestEditStatusCell from './components/ResourceRequestEditStatusCell';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import ResourceRequestStatusENUMS from "./components/ResourceRequestStatusEnums";
import LineHistoryDateCell from './components/LineHistoryDateCell';
import { saveFrontEndLogs } from '../integration/FrontEndLogService';
import AllocationPercentageCell from './components/AllocationPercentageCell';
import CustomDatePickerPopUp from './components/CustomDatePickerPopUp';
import OtherExpensesActionsCell from './components/OtherExpensesActionsCell';
import OtherExpenseActualCostActionCell from './components/OtherExpenseActualCostActionCell';


const CUSTOM_RATE = 'CUSTOM_RATE';
const ResourceRequestStatus = require('../resourceManagement/components/ResourceRequestStatusEnums');

class ProjectResourceRequest extends Component {

  isMount = false;
  allTheNames = [];
  designationsAll = [];
  usersAll = [];
  usersForDesignation = [];
  TmpDesignationsAll = [];
  TmpDesignation = null;
  TmpSubPhasesAll = [];
  forRemoveResource = [];
  regionsAll = [];
  paymentMethodsAll = [];
  rateTypesAll = [];
  onSiteStatusAll = [{ id: 1, Name: 'Onsite' }, { id: 2, Name: 'Offshore' }];
  ticked = [];
  allocationId = [];
  sublineTicked = [];
  usersAllActiveAndInactive = [];
  billableStatusValues = [
    { id: 1, Text: 'Billable', value: true },
    { id: 2, Text: 'Non-Billable', value: false }
  ];


  state = {
    sort: [
      { dir: 'asc' }
    ]
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
      phaseSubPhaseId: null,
      editableItem: null,
      phasesSubPhases: [],
      project: this.props.location.project,
      isSaveDisable: false,
      calculations: [],
      resourceMix: [],
      allocationSummaryResources: [],
      showSuccessImage: false,
      showErrorImage: false,
      popupVisible: false,
      isSubPhaseEdit: false,
      allocateResourcesDialog: false,
      newStartDateMin: null,
      RolesAll: [],
      RegionsAll: [],
      tmpDesignationsAll: [],
      RateTypes: [],
      allRateTypes: [],
      isEditButton: false,
      isAddDone: false,
      ExpenseTypesAll: [],
      namesAll: [],
      usersAll: [],
      onSiteStatusAll: (this.onSiteStatusAll),
      newResPhase: null,
      newResSubPhase: null,
      hasUnSavedData: false,
      newResProjectOrCRName: null,
      newResProjectOrCRNames: null,
      newResRole: null,
      newResDesignation: null,
      newResRegion: null,
      newOnSiteStatus: null,
      newStartDate: null,
      newEndDate: null,
      newEffortDays: 0,
      newAllocation: 100,
      newPaymentMethod: null,
      newPaymentMethodAll: [],
      newProjectCurrency: null,
      newCost: null,
      newRate: null,
      newRatesAll: [],
      isStartDateVisible: false,
      showEditDialog: false,
      showAddDialog: false,
      isSaveButtonVisibleEdit: false,

      addResourceGridData: [],
      selectedType: null,
      projectPhaseVisible: false,
      projectSubPhaseVisible: false,
      startDate: null,
      startDateMin: null,
      startDateIsaPastDate: false,
      phasesTemp: [],
      isfullSelected: false,

      phaseStartDate: null,
      selectedProjecOrCrForPhase: null,
      phaseName: '',
      subPhaseName: '',
      selectedPhaseIndex: -1,
      minPhaseDate: null,
      minSubPhaseDate: null,
      selectedProjectOrCrPhases: [],
      subPhaseStartDate: null,

      subPhaseRemovePhaseIndex: null,
      subPhaseRemoveSubPhaseIndex: null,

      showConfirmDialog: false,
      showDeallocConfirmDialog: false,
      confirmDialogMessage: '',
      confirmDialogAction: null,

      selectedCMSId: null,

      showMessageDialog: false,
      dialogMessage: '',
      dialogTitle: '',
      isCanRemoveRequest: false,
      isCanDeallocateRequest: false,

      goBack: false,
      showCancelConfirmation: false,
      isEditResource: false,

      rejectReason: '',
      showRejectConfirmation: false,

      visible: true,
      notEditableResource: false,

      // sub line related changes
      selctedResourceSubLineStartDate: null,
      selctedResourceSubLineEndDate: null,
      selectedProjecOrCrSublineResources: [],
      subLineStartDateMin: null,
      subLineEndDateMax: null,
      mainLineofsublineObj: null,
      hasSubLines: false,
      sublineCost: null,

      editableResourceSelectedPhase: null,
      editableResourceSelectedSubPhase: null,

      allProjectCrPhases: [],
      subPhasesOfSelectedPhase: [],

      disableSelectedResourceStartEndDate: false,

      subLineDefaultAllocationPercentage: 0,
      subLineEffortDays: 0,
      subLineDefaultRateType: null,
      subLineDefaultRateValue: 0,
      selectedResourcesSubLine: [],
      isCheckedSubline: false,
      //CostValues
      yearslotsWithinStartEndProjectDates: [],
      editableResourceSubLines: [],
      showEditSubLineDialog: false,

      costDate: new Date(),

      subLineUpdatedDialog: false,

      showOutsourcedCostDialog: false,
      allocatedDesignation: null,
      activeCRs: [],
      allocatedProjectManager: false,
      isCancelAddPopUp: false,

      requestAllocation: false,
      requestAllocationSub: false,
      confirmStatus: false,
      confirmStatusSub: false,

      calculatedValues: null,
      approvedCalculatedValues: null,
      approveAddSubLine: false,
      //for soft booking
      isCanSoftBooking: false,
      softBookingDialog: false,
      IsSoftBookingResorce: false,
      goBackCancel: false,
      requestAllocationSoft: false,
      requestAllocationSoftResubmit: false,
      requestAllocationSubSoft: false,
      requestAllocationSubSoftResubmit: false,
      requestAllocationSoftDate: false,
      requestAllocationSoftSubDate: false,

      userDefinedEndDate: null,
      previousEndDate: null,

      //for line filter
      SelectedLineStatuses: [],
      LineStatus: [],
      LineStatusAll: [],
      selectedProjectOrCRName: [],
      filteredTotalCost: null,
      filteredTotalRevenue: null,
      filteredCostToRevenueRatio: null,
      forFilterResourceLine: [],
      filteredResourceMix: [],
      excelDataObsolete: false,

      isLoading: true,
      matchingWorkflowList: [],
      selectedWorkFlowId: null,
      visibleLineStatus: false,
      managerComment: '',
      lineStatusHistoryList: [],

      calculationsfactory: [],
      numSelectedLines: null,
      //for deallocation
      isResurceRequestDeallocate: false,
      isDeallocateDialog: false,
      mainmoredetails: false,
      resDeallocationStartDate: null,
      resDeallocationEndDate: null,
      deallocationStartDate: null,
      disableConfirmButton: true,
      deallocationDatesErrorMessage: false,
      // for actual calculations
      cmsCalculations: null,
      calculatedActValues: null,
      showRateValidationDialog: false,
      showRateErrorImage: false,
      addedSubLinesToGrid: false,
      paymentMethodDisabled: false,
      addResourceSaveBtnDisabled: false,
      managerCommentCMS: '',
      isWaitingConfirmResource: false,
      addButtonDisabled: false,
      validProjAndCrs: [],
      mainLineAsSubLine: null,
      isNotCurrencyUSD: false,
      selectedResources: [],
      selectedResourcesSubLine: [],
      allocateSoftBookedResourcesDialog: false,
      softBookingStartDate: null,
      softBookingEndDate: null,
      isSoftBookingDatesChanged: false,
      softBookingStartDateMin: null,
      softBookingDatesError: false,
      isProjectRevenueExceed: false,
      validPMsProjAndCrs: [],
      showChangeAllocationDialog: false,
      visibleAllocationHistory: false,
      lineAllocationHistoryList: [],
      allocationHistoryMinDate: null,
      allocationHistoryMaxDate: null,
      allocationPercentageStartDate: null,
      allocationPercentageEndDate: null,
      changedllocationPercentage: null,
      allocationHistoryDataItem: null,
      allocationPercentageEffortDays: null,
      disableAddWorkflowBtn: false,
      otherExpenses: [],
      addOtherExpensesPopup: false,
      otherExpenseCostValue: null,
      otherExpenseDate: null,
      actualOtherExpenses: [],
      addNewOtherExpensePopup: false,
      newOtherExpenseProjectOrCR: null,
      newExpenseType: null,
      otherExpenseBillableStatus: null,
      expenseTypes: [],
      disableOtherExpenseSaveBtn: false,
      addActualOtherExpensesHistoryPopup: false,
      isEditOtherExpense: false,
      deleteOtherExpensePopup: false,
      deleteOtherExpenseActualCostPopup: false,
      actualCostDataItem: null,
      actualExpenseMinDate: null,
      isInitLoad: false,
      outsourcedCost: null
    }


    this.toggleDialog = this.toggleDialog.bind(this);

    this.expandChange = this.expandChange.bind(this);

    this.toggleLineStatusDialog = this.toggleLineStatusDialog.bind(this);

    this.toggleMoreDialog = this.toggleMoreDialog.bind(this);
  }

  expandChange = (event) => {
    event.dataItem.expanded = !event.dataItem.expanded;

    this.setState({
      selectedMainLineIndex: event.dataItem.index
    });
    this.forceUpdate();
  };

  onClickMore = async (dataItem) => {
    this.toggleMoreDialog(dataItem);

  }

  async toggleMoreDialog(dataItem) {
    this.setState({
      mainmoredetails: !this.state.mainmoredetails,
      dataItem: dataItem
    });
  }

  onClickLineStatus = async (dataItem) => {
    if (dataItem.ResourceRequestId) {
      await this.getLineHistory(dataItem.ResourceRequestId, dataItem.id);
    } else {
      await this.getLineHistory(dataItem.id, null);
    }
    this.toggleLineStatusDialog(dataItem);
  };

  toggleLineStatusDialog(dataItem) {
    this.setState({
      visibleLineStatus: !this.state.visibleLineStatus,
      dataItem: dataItem
    });
  }

  // toggleDialog() {
  //   this.setState({
  //     visible: !this.state.visible
  //   });
  // }

  async componentDidMount() {
    if (this.props.location.hasOwnProperty('project')) {
      this.isMount = true;
      this.setHeaderTitle();
      await this.populateData();
      await this.getInitialData();
      this.populateCalculationsGrid();
    }
    this.getAllRateTypes()
  }

  getAllRateTypes= async ()=>{
    await getAllRateTypes().then(res=>{
      this.setState({
        allRateTypes:res.data
      })
    })
  }

  populateCalculationsGrid = () => {
    const calculationsfactory = [
      {
        Field: 'Cost of Dedicated Time',
        Value1: 'Baseline',
        Value2: 'Ongoing',
        Value3: 'Variance',
        isHeader: true
      },
      {
        Field: 'Deployable Resources',
        Value1: '163,075',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'Non-Deployable Resources',
        Value1: '7,825',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'Non-Billable OPE',
        Value1: '0',
        Value2: '',
        Value3: ''

      },
      {
        Field: 'JIRA Licenses',
        Value1: '1,440',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'Shift Allowances - Offshore',
        Value1: '86,400',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'Travel Allowances - Offshore',
        Value1: '16,457',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'In-region other costs',
        Value1: '42900',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'COE other costs',
        Value1: '38,500',
        Value2: '',
        Value3: ''
      },
      {
        Field: 'Total Cost Excluding Sales Commission',
        Value1: '356,597',
        Value2: '',
        Value3: '',
        isHeader: true
      },
      {
        Field: 'Available Capacity at Target Billable Utilization (Days)',
        Value1: '2,375',
        Value2: '',
        Value3: '',
        isHeader: true
      },
      {
        Field: 'Net Profit/Loss',
        Value1: '',
        Value2: '',
        Value3: '',
        isHeader: true
      },
      {
        Field: 'Net Profit Margin',
        Value1: '',
        Value2: '',
        Value3: '',
        isHeader: true
      }
    ];

    const calculationTemp = [];
    for (var item of calculationsfactory) {
      if (item.isHidden == undefined) {
        calculationTemp.push(item);
      } else if (!item.isHidden) {
        calculationTemp.push(item);
      }
    }
    this.setState(
      {
        calculationsfactory: calculationTemp
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  setHeaderTitle = async () => {
    if (this.props.location.action === 'RPApprove') {
      this.props.onHeaderTitleChange('Resource Allocation');
    } else if (this.props.location.action === 'RPApproveWF') {
      this.props.onHeaderTitleChange('Resource Request Approval');
    } else {
      this.props.onHeaderTitleChange('Resource Request');
    }
  };

  populateLineStatus = async () => {
    let defaultStatus = [];
    await getLineStatus()
      .then(res => {
        if (this.isMount) {
          this.setState({
            LineStatus: res.data,
            LineStatusAll: res.data,
          });
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    //for default status filter
    if (this.state.LineStatus) {
      for (const status of this.state.LineStatus) {
        if (status.code === 'NEW_FROM_CMS' || status.code === 'NEW_FROM_RM' || status.code === 'CONFIRMED' || status.code === 'EDIT') {
          defaultStatus.push(status);
        }
      }


      this.setState({
        SelectedLineStatuses: defaultStatus,
      });


    }




  };

  getInitialData = async () => {
    await this.setState({
      isLoading: true,
      isInitLoad: true
    });
    const idList = this.getIDListArray();
    const param = idList.join(',');
    await this.populateConsolidatedResourceMix();
    await this.handleCalculations();
    if (this.props.location.action === 'RPApproveWF') {
      await this.handleCalculations(true); // approved Calculations
    }
    await this.handleActualCalculations();
    // await this.filterCalculations();
    await this.submitSearch();
    await this.setState({
      isLoading: false,
      isInitLoad: false
    });
  };

  componentWillUnmount() {
    this.isMount = false;
  }

  handleInputChange = event => {
    const value = event.target.value;
    const field = event.target.name;
    this.setState({
      [field]: value
    });
  };

  populateData = async () => {
    await this.setAllNames();
    this.setEndDate();
    this.populateLineStatus();
    const resDesg = await getAllDesignationsIntegrated();
    const resReg = await getResourceRegionsIntegrated();
    const resUsers = await getActiveProjectResources();
    const expsenseTypes = await getExpenseTypes();
    const resUsersAll = await getAllProjectResources();

    await getPaymentMethods().then(response => {
      this.paymentMethodsAll = response.data;
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    await getRateTypesIntegrated().then(resRT => {
      let rateTypes = [];
      if (resRT.data && resRT.data.length > 0) {
        for (const rateType of resRT.data) {
          if (rateType.Code !== CUSTOM_RATE) {
            rateType.Rate = 0;
            rateType.name = rateType.Name + ' - 0.00';
          } else {
            rateType.name = rateType.Name;
          }
          rateTypes.push(rateType);
        }
      }
      this.state.newRatesAll = rateTypes;
      this.rateTypesAll = rateTypes;
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    this.designationsAll = resDesg.data;
    const activeRegions = resReg.data.filter(value => value.IsActive === true);
    this.regionsAll = activeRegions;
    this.usersAll = resUsers.data;
    this.usersAllActiveAndInactive = resUsersAll.data;

    this.getPhasesAndSubPhases(this.state.project.id);

    //set min date for add resource request
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    //getActiveCRs
    if (this.state.project.AllChangeRequests){
      this.state.project.ChangeRequests = this.state.project.AllChangeRequests;
    }
    const allCRs = this.state.project.ChangeRequests;
    let filteredCRs = [];
    filteredCRs = allCRs.filter(eachCR => eachCR.StatusConvertedToActive === true);
    for (let cr of filteredCRs) {
      cr.cmsVersion = cr.CMSheets[0].MajorVersion + '.' + cr.CMSheets[0].Version;
    }

    let addButtonDisabled = false
    if (this.props.location.project.ProjectStatus && this.props.location.project.ProjectStatus.StatusName === 'Waiting Confirmation') {
      addButtonDisabled = true;
    }
    if (!this.props.location.project.StatusConvertedToActive && this.props.location.project.ChangeRequests && this.props.location.project.ChangeRequests.length > 0) {
      for (const cr of this.props.location.project.ChangeRequests) {
        if (!cr.StatusConvertedToActive && cr.Status && cr.Status.StatusName === 'Waiting Confirmation') {
          addButtonDisabled = true
        } else {
          addButtonDisabled = false;
          break;
        }
      }
    }

    if (this.isMount) {
      await this.setState(
        {
          RolesAll: resDesg.data,
          RegionsAll: activeRegions,
          ExpenseTypesAll: expsenseTypes.data ? expsenseTypes.data.filter(obj => obj.IsPoolProject === false): [],
          addResourceMinDate: oneWeekAgo,
          activeCRs: filteredCRs,
          addButtonDisabled: addButtonDisabled,
          expenseTypes: expsenseTypes.data ? expsenseTypes.data.filter(obj => obj.IsPoolProject === false): []
        });
    }

    await this.populateOtherExpenses();

  };

  populateOtherExpenses = async () => {
    let otherExpenses = [];
    const validProjectAndCrs = this.props.location.action === 'create' ? this.state.validPMsProjAndCrs : this.state.validProjAndCrs;
    for (const record of validProjectAndCrs) {
      const cmsId = record.CMSheetId;
      const cmsVersion = record.CMSheetVersion;
      let cmsOtherExpenses = [];
      let actualOtherExpenses = [];
      let actualOtherExpensesPreviousCMS = [];

      if (record.CMSheetStatus === "APPROVED") {
        await getCMSheetOtherExpenses(cmsId, cmsVersion).then(res => {
          cmsOtherExpenses = res.data;
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
        
        await getActualOtherExpenses(cmsId, cmsVersion).then(res => {
          actualOtherExpenses = res.data;
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      }

      await getActualOtherExpensesPreviousCMS(cmsId, cmsVersion).then(res => {
        actualOtherExpensesPreviousCMS = res.data;
        if (actualOtherExpensesPreviousCMS && actualOtherExpensesPreviousCMS.length > 0) {
          actualOtherExpenses = actualOtherExpenses.concat(actualOtherExpensesPreviousCMS);
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

      let projcurrencyId = 0;
      await getRegionCurrencyIntegrated(record.billingRegion).then(response => {
        projcurrencyId = response.data.CurrencyId;
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      const proStartDate = new Date(record.StartDate);
      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
        projcurrencyId,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );

      if (cmsOtherExpenses && cmsOtherExpenses.length > 0) {
        for (const cmsExpense of cmsOtherExpenses) {
          const actualExpense = actualOtherExpenses.filter(val => val.ExpenseTypeId === cmsExpense.ExpenseTypeId && val.CMSOtherExpenseId === cmsExpense.id);
          const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === cmsExpense.ExpenseTypeId);
          
          const otherExpenseObj = {
            CMSheetId: cmsId,
            CMSOtherExpenseId: cmsExpense.id,
            CMSheetVersion: cmsVersion,
            ProjectId: actualExpense.length > 0 ? actualExpense[0].ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
            CrId: actualExpense.length > 0 ? actualExpense[0].CrId : record.type == 'ChangeRequest' ? record.id : null,
            ProjectName: record.name,
            ProjectStartDate: record.StartDate,
            ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
            ExpenseTypeId: cmsExpense.ExpenseTypeId,
            BudgetedOccurrences: cmsExpense.BudgetedOccurrences,
            BudgetedRate: Number(cmsExpense.BudgetedRate / projrateRecordCurrencyRate).toFixed(2),
            BillableToClient: cmsExpense.BillableToClient ? "Billable" : "Non-Billable",
            CMSCost: Number(cmsExpense.Cost / projrateRecordCurrencyRate).toFixed(2),
            TotalActualCost: actualExpense.length > 0 ? Number(actualExpense[0].TotalActualCost).toFixed(2) : 0,
            IsResourceRequest: actualExpense.length > 0 ? actualExpense[0].IsResourceRequest : false,
            ActualExpense: actualExpense.length > 0 ? actualExpense[0] : null,
            ActualExpenseId: actualExpense.length > 0 ? actualExpense[0].id : null,
            allowEditDelete: false
          }
          otherExpenses.push(otherExpenseObj);
        }
      }

      const expensesFromResourceRequest = actualOtherExpenses.filter(val => val.IsResourceRequest);
      if (expensesFromResourceRequest && expensesFromResourceRequest.length > 0) {
        for (const expense of expensesFromResourceRequest) {
          const allowEditDelete = expense.ActualOtherExpensesDate && expense.ActualOtherExpensesDate.length > 0 ? false : true;
          const cmsExpense = cmsOtherExpenses.filter(val => val.ExpenseTypeId === expense.ExpenseTypeId && val.id === expense.CMSOtherExpenseId);
          if (cmsExpense && cmsExpense.length <= 0) {
            const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === expense.ExpenseTypeId);
            const otherExpenseObj = {
              CMSheetId: cmsId,
              CMSOtherExpenseId: expense.CMSOtherExpenseId,
              CMSheetVersion: cmsVersion,
              ProjectId: expense.ProjectId ? expense.ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
              CrId: expense.CrId > 0 ? expense.CrId : record.type == 'ChangeRequest' ? record.id : null,  
              ProjectName: record.name,
              ProjectStartDate: record.StartDate,
              ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
              ExpenseTypeId: expense.ExpenseTypeId,
              BudgetedOccurrences: 0,
              BudgetedRate: 0,
              BillableToClient: expense.BillableToClient ? "Billable" : "Non-Billable",
              CMSCost: 0,
              TotalActualCost: expense.TotalActualCost && expense.TotalActualCost > 0 ? Number(expense.TotalActualCost).toFixed(2) : 0,
              IsResourceRequest: expense.IsResourceRequest,
              ActualExpense: expense,
              ActualExpenseId: expense.id,
              allowEditDelete: allowEditDelete
            }
            otherExpenses.push(otherExpenseObj);
          }
        }
      }

      if (actualOtherExpensesPreviousCMS && actualOtherExpensesPreviousCMS.length > 0) {
        for (const expense of actualOtherExpensesPreviousCMS) {
          const allowEditDelete = expense.ActualOtherExpensesDate && expense.ActualOtherExpensesDate.length > 0 ? false : true;
          const expenseData = otherExpenses.filter(val => val.ExpenseTypeId === expense.ExpenseTypeId && val.CMSheetId === expense.CMSheetId);
          if (expenseData && expenseData.length <= 0) {
            const expenseType = this.state.ExpenseTypesAll.filter(obj => obj.id === expense.ExpenseTypeId);
            const otherExpenseObj = {
              CMSheetId: cmsId,
              CMSOtherExpenseId: expense.CMSOtherExpenseId,
              CMSheetVersion: cmsVersion,
              ProjectId: expense.ProjectId ? expense.ProjectId : record.type == 'Project' ? record.id : record.ProjectId,
              CrId: expense.CrId > 0 ? expense.CrId : record.type == 'ChangeRequest' ? record.id : null,  
              ProjectName: record.name,
              ProjectStartDate: record.StartDate,
              ExpenseType: expenseType.length > 0 ? expenseType[0].Name : null,
              ExpenseTypeId: expense.ExpenseTypeId,
              BudgetedOccurrences: 0,
              BudgetedRate: 0,
              BillableToClient: expense.BillableToClient ? "Billable" : "Non-Billable",
              CMSCost: 0,
              TotalActualCost: expense.TotalActualCost && expense.TotalActualCost > 0 ? Number(expense.TotalActualCost).toFixed(2) : 0,
              IsResourceRequest: expense.IsResourceRequest,
              ActualExpense: expense,
              ActualExpenseId: expense.id,
              allowEditDelete: allowEditDelete
            }
            otherExpenses.push(otherExpenseObj);
          }
        }
      }
    }
    await this.setState({
      otherExpenses: otherExpenses
    });
  }

  onClickAddOtherExpense = async (dataItem) => {
    const currentDate= moment(new Date()).format('YYYY-MM-DD');;
    const previousMonthDate = new Date().setDate(0);
    const validLastDate = moment(previousMonthDate).add(5, 'days').format('YYYY-MM-DD');
    const monthFirstDate = moment(previousMonthDate).add(1, 'days').format('YYYY-MM-DD');
    const setFirstDatePrevMonth = new Date(previousMonthDate).setDate(1);
    const firstDatePrevMonth = moment(setFirstDatePrevMonth).format('YYYY-MM-DD');

    let minDate = new Date(dataItem.ProjectStartDate);

    if (new Date (currentDate) <= new Date(validLastDate)) {
      if (new Date(dataItem.ProjectStartDate) < new Date(firstDatePrevMonth)) {
        minDate = new Date(firstDatePrevMonth);
      }
    } else {
      if (new Date(dataItem.ProjectStartDate) < new Date(monthFirstDate)) {
        minDate = new Date(monthFirstDate);
      }
    }

    if(!this.state.userDefinedEndDate || new Date(this.state.userDefinedEndDate) < new Date(minDate)) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please update the End Date to proceed.', 'Error');
      return;
    }

    if (dataItem.ActualExpense && dataItem.ActualExpense.ActualOtherExpensesDate) {
      for (const record of dataItem.ActualExpense.ActualOtherExpensesDate) {
        const expenseDate = moment(record.Date).format('YYYY-MM-DD');
        if (new Date (currentDate) <= new Date(validLastDate)) {
          if (new Date(expenseDate) >= new Date(firstDatePrevMonth)){
            record.allowEditDelete = true;
          } else {
            record.allowEditDelete = false;
          }
        } else {
          if (new Date(expenseDate) >= new Date(monthFirstDate)){
            record.allowEditDelete = true;
          } else {
            record.allowEditDelete = false;
          }
        }
      }
    }
    let actualOtherExpenses = dataItem.ActualExpense ? dataItem.ActualExpense.ActualOtherExpensesDate : [];
    if (actualOtherExpenses && actualOtherExpenses.length > 0) {
      actualOtherExpenses.sort(function(a,b){return new Date(b.updatedAt) - new Date(a.updatedAt)})
    }
    await this.setState({
      dataItem: dataItem,
      actualOtherExpenses: dataItem.ActualExpense ? dataItem.ActualExpense.ActualOtherExpensesDate : [],
      otherExpenseCostValue: null,
      otherExpenseDate: null,
      addOtherExpensesPopup: !this.state.addOtherExpensesPopup,
      actualExpenseMinDate: minDate,
      disableOtherExpenseSaveBtn: true
    });
  }

  otherExpenseDateChange = async event => {
    let date = event.target.value;
    if (date) {
      await this.setState({
        otherExpenseDate: new Date(date)
      });  
    } else {
      await this.setState({
        otherExpenseDate: null
      }); 
    }
  };

  handleOtherExpenseCostChange = async event => {
    let value = event.target.value;

    if (value && value < 0) {
      value = null;
    } else if (value && value.length > 8) {
      value = value.slice(0, 8);
    }
  
    await this.setState({
      otherExpenseCostValue: value ? Number((Number(value)).toFixed(2)) : null
    });
  };

  addOtherExpenseToGrid = async() => {
    const otherExpense = this.state.dataItem;
    let actualOtherExpenses = this.state.actualOtherExpenses;

    if (this.state.actualCostDataItem && this.state.actualCostDataItem.IsFromEdit) {
      this.state.actualCostDataItem.Date = moment(this.state.otherExpenseDate).format('YYYY-MM-DD');
      this.state.actualCostDataItem.Cost = Number(this.state.otherExpenseCostValue).toFixed(2);
    } else {
      const Obj = {
        Date: moment(this.state.otherExpenseDate).format('YYYY-MM-DD'),
        Cost: Number(this.state.otherExpenseCostValue).toFixed(2),
        IsActive: true,
        allowEditDelete: true
      }
      actualOtherExpenses.unshift(Obj);
    }
    await this.setState({
      actualOtherExpenses: actualOtherExpenses,
      otherExpenseCostValue: '',
      otherExpenseDate: null,
      actualCostDataItem: null,
      disableOtherExpenseSaveBtn: false
    });
  }

  saveActualOtherExpenseDates = async() => {
    await this.setState({
      disableOtherExpenseSaveBtn: true,
      isLoading: true
    });
    const actualExpenseObj = this.state.dataItem;
    actualExpenseObj.ActualOtherExpensesDates = this.state.actualOtherExpenses;

    await saveActualOtherExpenseDates(actualExpenseObj).then(async res => {
      await this.populateOtherExpenses();
      this.handleActualCalculations();
      await this.setState({
        addOtherExpensesPopup: false,
        showSuccessImage: true,
        showErrorImage: false
      });
      this.toggleDialog(
        `Expense updated successfully`,
        'Success'
      );
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
    await this.setState({
      disableOtherExpenseSaveBtn: false,
      isLoading: false
    });
  }

  addNewOtherExpense = async() => {
    const currentDate= moment(new Date()).format('YYYY-MM-DD');;
    const previousMonthDate = new Date().setDate(0);
    const validLastDate = moment(previousMonthDate).add(5, 'days').format('YYYY-MM-DD');
    const monthFirstDate = moment(previousMonthDate).add(1, 'days').format('YYYY-MM-DD');
    const firstDatePrevMonth = new Date(previousMonthDate).setDate(1);
    let minDate = new Date();

    if (new Date (currentDate) <= new Date(validLastDate)) {
      minDate = new Date(firstDatePrevMonth);
    } else {
      minDate = new Date(monthFirstDate);
    }

    if(!this.state.userDefinedEndDate || new Date(this.state.userDefinedEndDate) < new Date(minDate)) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please update the End Date to proceed.', 'Error');
      return;
    }

    await this.setState({
      addNewOtherExpensePopup: !this.state.addNewOtherExpensePopup,
      otherExpenseBillableStatus: null,
      newExpenseType: null,
      newOtherExpenseProjectOrCR: null,
      isEditOtherExpense: false,
      expenseTypes: this.state.ExpenseTypesAll
    });
  }

  handleOtherExpenseProjectOrCr = async event => {
    let value = event.target.value;
    if (value) {
      let validExpenseTypes = this.state.ExpenseTypesAll;
      // if (this.state.otherExpenses && this.state.otherExpenses.length > 0) {
      //   let ongoingExpenseTypes = [];
      //   if(value.type ==="Project") {
      //     ongoingExpenseTypes = this.state.otherExpenses.map(val => {
      //       return val.ProjectId === value.id && val.CrId == null ? val.ExpenseTypeId : null;
      //     });
      //   } else {
      //     ongoingExpenseTypes = this.state.otherExpenses.map(val => {
      //       return val.ProjectId === value.ProjectId && val.CrId == value.id ? val.ExpenseTypeId : null;
      //     });
      //   }
      //   validExpenseTypes = this.state.ExpenseTypesAll.filter(val => !ongoingExpenseTypes.includes(val.id));
      // }
      await this.setState({
        newOtherExpenseProjectOrCR: value,
        expenseTypes: validExpenseTypes,
        newExpenseType: null
      });  
    } else {
      await this.setState({
        newOtherExpenseProjectOrCR: value,
        expenseTypes: this.state.ExpenseTypesAll,
        newExpenseType: null
      });
    }
  };

  handleOtherExpenseBillableStatusChange = async event => {
    let value = event.target.value;
    await this.setState({
      otherExpenseBillableStatus: value
    });
  };

  handleOtherExpenseTypeChange = async event => {
    let value = event.target.value;
    await this.setState({
      newExpenseType: value
    });
  };

  saveNewActualOtherExpense = async () => {
    await this.setState({
      disableOtherExpenseSaveBtn: true,
      isLoading: true
    });
    if (this.state.isEditOtherExpense) {
      const actualExpenseId = this.state.dataItem.ActualExpenseId;

      const actualExpenseObj = {
        ProjectId: this.state.newOtherExpenseProjectOrCR.type === "Project" ? this.state.newOtherExpenseProjectOrCR.id : this.state.newOtherExpenseProjectOrCR.ProjectId,
        CrId: this.state.newOtherExpenseProjectOrCR.type === "Project" ? null : this.state.newOtherExpenseProjectOrCR.id,
        CMSheetId: this.state.newOtherExpenseProjectOrCR.CMSheetId,
        CMSheetVersion: this.state.newOtherExpenseProjectOrCR.CMSheetVersion,
        ExpenseTypeId: this.state.newExpenseType.id,
        IsResourceRequest: true,
        BillableToClient: this.state.otherExpenseBillableStatus.Text === "Billable" ? true : false,
        IsActive: true,
      }
  
      await updateActualOtherExpense(actualExpenseObj, actualExpenseId).then(async res => {
        await this.populateOtherExpenses();
        await this.setState({
          addNewOtherExpensePopup: false,
          showSuccessImage: true,
          showErrorImage: false,
          isEditOtherExpense: false
        });
        this.toggleDialog(
          `Expense updated successfully`,
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    } else {
      const actualExpenseObj = {
        ProjectId: this.state.newOtherExpenseProjectOrCR.type === "Project" ? this.state.newOtherExpenseProjectOrCR.id : this.state.newOtherExpenseProjectOrCR.ProjectId,
        CrId: this.state.newOtherExpenseProjectOrCR.type === "Project" ? null : this.state.newOtherExpenseProjectOrCR.id,
        CMSheetId: this.state.newOtherExpenseProjectOrCR.CMSheetId,
        CMSheetVersion: this.state.newOtherExpenseProjectOrCR.CMSheetVersion,
        ExpenseTypeId: this.state.newExpenseType.id,
        TotalActualCost: 0,
        IsResourceRequest: true,
        BillableToClient: this.state.otherExpenseBillableStatus.Text === "Billable" ? true : false,
        IsActive: true,
      }
  
      await saveNewActualOtherExpense(actualExpenseObj).then(async res => {
        await this.populateOtherExpenses();
        await this.setState({
          addNewOtherExpensePopup: false,
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(
          `Expense added successfully`,
          'Success'
        );
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    
    await this.setState({
      disableOtherExpenseSaveBtn: false,
      isLoading: false
    });
  };

  onClickOtherExpenseHistory = async (dataItem) => {
    await this.setState({
      dataItem: dataItem,
      actualOtherExpenses: dataItem.ActualExpense ? dataItem.ActualExpense.ActualOtherExpensesDate : [],
    });
    this.toggleActualOtherExpensesHistory();
  }

  toggleActualOtherExpensesHistory = async() => {
    await this.setState({
      addActualOtherExpensesHistoryPopup: !this.state.addActualOtherExpensesHistoryPopup,
    });
  }

  onClickEditOtherExpense = async(dataItem) => {
    let projectOrCR = null;

    if (dataItem.CrId) {
      projectOrCR = this.state.validPMsProjAndCrs.filter(val => val.id == dataItem.CrId && val.ProjectId == dataItem.ProjectId);
    } else {
      projectOrCR = this.state.validPMsProjAndCrs.filter(val => val.id == dataItem.ProjectId && val.CrId == null);
    }

    let expenseType = this.state.ExpenseTypesAll.filter(val => val.id == dataItem.ExpenseTypeId);
    let billableStatus = this.billableStatusValues.filter(val => val.Text == dataItem.BillableToClient);

    let validExpenseTypes = this.state.ExpenseTypesAll;
    // if (this.state.otherExpenses && this.state.otherExpenses.length > 0) {
    //   let ongoingExpenseTypes = [];
    //   if(dataItem.CrId == null) {
    //     ongoingExpenseTypes = this.state.otherExpenses.map(val => {
    //       return val.ProjectId === dataItem.ProjectId && val.CrId == null && val.ExpenseTypeId !== dataItem.ExpenseTypeId ? val.ExpenseTypeId : null;
    //     });
    //   } else {
    //     ongoingExpenseTypes = this.state.otherExpenses.map(val => {
    //       return val.ProjectId === dataItem.ProjectId && val.CrId == dataItem.CrId && val.ExpenseTypeId !== dataItem.ExpenseTypeId ? val.ExpenseTypeId : null;
    //     });
    //   }
    //   validExpenseTypes = this.state.ExpenseTypesAll.filter(val => !ongoingExpenseTypes.includes(val.id));
    // }

    await this.setState({
      dataItem: dataItem,
      isEditOtherExpense: true,
      addNewOtherExpensePopup: !this.state.addNewOtherExpensePopup,
      otherExpenseBillableStatus: billableStatus && billableStatus.length > 0 ? billableStatus[0] : null,
      newExpenseType: expenseType && expenseType.length > 0 ? expenseType[0] : null,
      newOtherExpenseProjectOrCR: projectOrCR && projectOrCR.length > 0 ? projectOrCR[0] : null,
      expenseTypes: validExpenseTypes
    });
  }

  onClickDeleteOtherExpense = async(dataItem) => {
    await this.setState({
      dataItem: dataItem,
    });
    this.deleteConfirmationClose();
  }

  deleteConfirmationClose = async () => {
    await this.setState({
      deleteOtherExpensePopup: !this.state.deleteOtherExpensePopup,
    });
  }

  deleteOtherExpense = async() => {
    await this.setState({
      isLoading: true
    });
    const actualExpenseId = this.state.dataItem.ActualExpenseId;
    const actualExpenseObj = {
      IsActive: false,
    }
    await updateActualOtherExpense(actualExpenseObj, actualExpenseId).then(async res => {
      await this.populateOtherExpenses();
      await this.deleteConfirmationClose();
      await this.setState({
        showSuccessImage: true,
        showErrorImage: false
      });
      this.toggleDialog(
        `Expense deleted successfully`,
        'Success'
      );
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
    await this.setState({
      isLoading: false
    });
  }

  onClickEditActualExpenseCost = async(dataItem) => {
    dataItem.IsFromEdit = true;
    await this.setState({
      actualCostDataItem: dataItem,
      otherExpenseCostValue: dataItem.Cost,
      otherExpenseDate: new Date(dataItem.Date),

    });
  }

  onClickDeleteActualExpenseCost = async(dataItem) => {
    await this.setState({
      actualCostDataItem: dataItem,
    });
    this.deleteActualCostConfirmationClose();
  }

  deleteActualCostConfirmationClose = async () => {
    await this.setState({
      deleteOtherExpenseActualCostPopup: !this.state.deleteOtherExpenseActualCostPopup,
    });
  }

  deleteOtherExpenseActualCost = async() => {
    await this.setState({
      isLoading: true,
    });
    let dataItem = this.state.actualCostDataItem;
    let actualOtherExpenses = this.state.actualOtherExpenses;
    if (!dataItem.hasOwnProperty('id')) {
      actualOtherExpenses = actualOtherExpenses.filter(val => val !== dataItem)
    } else {
      const actualExpenseId = dataItem.id;
      const actualExpenseObj = {
        IsActive: false,
        ActualOtherExpenseId: dataItem.ActualOtherExpenseId
      }
      await deleteActualOtherExpenseCost(actualExpenseObj, actualExpenseId).then(async res => {
        actualOtherExpenses = actualOtherExpenses.filter(val => val !== dataItem)
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
    }
    await this.setState({
      actualOtherExpenses: actualOtherExpenses,
      isLoading: false
    });
    this.deleteActualCostConfirmationClose();
  }


  calculateActualNonBillableOtherExpenses = async(cmsId, cmsVersion, projectStatus) => {
    let otherExpensesTotal = 0;
    const closedStatusList = ['Closed', 'Discontinue', 'Close without bidding', 'Closed-Opportunity Loss', 'Complete'];
    const otherExpenses = this.state.otherExpenses;
    const projectOrCrOtherExpenses = otherExpenses.filter(val => val.CMSheetId == cmsId && val.BillableToClient === "Non-Billable");
    if (!closedStatusList.includes(projectStatus)) {
      for (const expense of projectOrCrOtherExpenses) {
        if (Number(expense.CMSCost) >= Number(expense.TotalActualCost)) {
          otherExpensesTotal += Number(expense.CMSCost);
        } else {
          otherExpensesTotal += Number(expense.TotalActualCost);
        }
      }
    } else {
      for (const expense of projectOrCrOtherExpenses) {
        if (expense.TotalActualCost !== 0) {
          otherExpensesTotal += Number(expense.TotalActualCost);
        } else {
          otherExpensesTotal += Number(expense.CMSCost);
        }
      }
    }
    return otherExpensesTotal;
  }

  rowRender(trElement, props) {
    const isHeader = props.dataItem.isHeader;
    const IsMajorField = props.dataItem.IsMajorField;
    const className = 'summery-header-style';
    const trProps = { class: isHeader === true ? className : IsMajorField ? 'summary-grid-bold-style' : null };
    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  rowRenderSB(sbElement, props) {
    const isSoftBooking = props.dataItem.IsSoftBookingResource ? true : false
    const className = 'soft-booking-style';
    const isPreviousCmsResource = props.dataItem.IsPreviousCmsResource ? true : false;
    const prevCMSClassName = 'prev-cms-style';
    const sbProps = { id: isPreviousCmsResource ? prevCMSClassName : isSoftBooking ? className : null };
    return React.cloneElement(sbElement, { ...sbProps }, sbElement.props.children);
  }

  getStartDate = () => {
    let dates = [];
    if (this.state.project.StartDate) {
      dates.push(reStructureDate(this.state.project.StartDate));
    }
    for (let cr of this.state.project.ChangeRequests) {
      if (cr.StartDate) {
        dates.push(reStructureDate(cr.StartDate));
      }
    }
    const earliest = reStructureDate(Math.min.apply(null, dates));
    let minDate = moment(earliest).format('MM-DD-YYYY');
    return minDate;
  };

  getEndDate = () => {
    let dates = [];
    if (this.state.project.EndDate) {
      dates.push(reStructureDate(this.state.project.EndDate));
    }
    for (let cr of this.state.project.ChangeRequests) {
      if (cr.EndDate) {
        dates.push(reStructureDate(cr.EndDate));
      }
    }
    if (dates.length > 0) {
      const latest = reStructureDate(Math.max.apply(null, dates));
      let maxDate = moment(latest).format('MM-DD-YYYY');
      return maxDate;
    } else {
      return '';
    }
  };

  handleProjEndDateChange = async(event) => {
    if (this.state.project.UserDefinedEndDate === null) {
      await this.setState({
        userDefinedEndDate: event.value
      }
      )
    } else {
      await this.setState({
        previousEndDate: this.state.project.UserDefinedEndDate
      },
        async() => {
          await this.setState({
            userDefinedEndDate: event.value
          })
        }
      )
    }
    if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
      //update project
      this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
      updateProject(this.state.project, this.state.project.id)
        .then(res => {
          this.onUserDefinedEndDateChange();
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  getMaxEndDate = () => {
    var projectStartDate = this.getStartDate();
    var resourceLineEndDate = new Date();
    for (let eachLine of this.state.resourceMix) {
      if (new Date(eachLine.EndDate) > new Date(resourceLineEndDate)) {
        resourceLineEndDate = eachLine.EndDate;
      }
    }
    if ((new Date(projectStartDate) > new Date()) && ((new Date(projectStartDate) > new Date(resourceLineEndDate)))) {
      return projectStartDate;
    } else if ((new Date(resourceLineEndDate) > new Date()) && (new Date(resourceLineEndDate) > new Date(projectStartDate))) {
      return resourceLineEndDate;
    } else {
      var currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      return currentDate;
    }
  };

  getProjCMS = () => {
    let projCMS;
    if (this.props.location.project && this.props.location.project.CMSheets && this.props.location.project.CMSheets.length > 0) {
      projCMS = this.props.location.project.CMSheets[0].MajorVersion + '.' + this.props.location.project.CMSheets[0].Version;
    } else {
      projCMS = null;
    }
    return projCMS;
  };

  /* getCRNames = () => {
        const crNames = []
        for (let cr of this.state.project.ChangeRequests) {
            crNames.push(cr.CRName)
        }
        return crNames.join(', ')
    }

    getVersions = () => {
        let versions = ["1." + this.props.location.project.CMSheets[0].Version]
        for (let cr of this.props.location.project.ChangeRequests) {
            versions.push("1." + cr.CMSheets[0].Version)
        }
        return versions.join(", ")
    }*/

  async getPhasesAndSubPhases(ProjectId) {
    getPhasesAndSubPhases(ProjectId)
      .then(res => {
        let existingPhasesArray = [];
        const projectAndCr = res.data;
        let tempProjAndCrDataArray = [];
        let phasesSubPhasesObj = [];
        if (projectAndCr && projectAndCr.length > 0) {
          projectAndCr.forEach(function (data) {
            if (data.CMSheets[0].Phases.length > 0) {
              phasesSubPhasesObj.push(data);
            }
            if (data.ProjectName) {
              const projObj = {
                Type: 'Project',
                Name: data.ProjectName,
                id: data.id,
                Project: data
              };
              tempProjAndCrDataArray.push(projObj);
            } else {
              const crObj = {
                Type: 'ChangeRequest',
                Name: data.CRName,
                id: data.id,
                ChangeRequest: data
              };
              tempProjAndCrDataArray.push(crObj);
            }

            if (data.CMSheets && data.CMSheets.length > 0) {
              const existingPhases = data.CMSheets[0].Phases;

              if (existingPhases && existingPhases.length > 0) {
                for (const phase of existingPhases) {
                  existingPhasesArray.push(phase);
                }
              }
            }


          });
        }

        this.setState({
          phasesTemp: existingPhasesArray,
          phasesTempAll: existingPhasesArray,
          phasesSubPhases: phasesSubPhasesObj,
          projectAndCrData: tempProjAndCrDataArray
        });

      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  /*getCRIDs = () => {
        const crIDs = []
        for (let cr of this.state.project.ChangeRequests) {
            crIDs.push(cr.UniqueId)
        }
        return crIDs.join(',')
    }*/

  getIDListArray = () => {
    let idList = [];
    const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].MajorVersion + '.' + this.state.project.CMSheets[0].Version : null;
    const projectID = '\'' + (this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null) + projectVersion + '\'';
    idList.push(projectID);

    for (const cr of this.state.project.ChangeRequests) {
      if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        const crVersion = cr.CMSheets[0].MajorVersion + '.' + cr.CMSheets[0].Version;
        const crID = '\'' + cr.CMSheets[0].id + crVersion + '\'';
        idList.push(crID);
      }
    }
    return idList;
  };

  setAllNames = async () => {

    let theList = [];
    let allObjs = [];
    let validProjAndCrs = [];
    let validPMsProjAndCrs = [];

    const projectObj = {
      id: this.state.project.id,
      name: this.state.project.ProjectName,
      type: 'Project',
      CMSheetId: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null,
      CMSheetVersion: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null,
      StartDate: this.state.project.StartDate,
      paymentMethod: this.state.project.PaymentMethod ? this.state.project.PaymentMethod : this.state.project.PaymentMethodRecord ? this.state.project.PaymentMethodRecord.id : null,
      billingRegion: this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region ? this.state.project.Region.id : null,
      customerId: this.state.project.CustomerId ? this.state.project.CustomerId : this.state.project.Customer ? this.state.project.Customer.id : null,
      countryId: this.state.project.CountryId,
      CMSheetStatus: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Status : null,
    };

    theList.push(projectObj);
    allObjs.push(projectObj);


    for (const cr of this.state.project.ChangeRequests) {
      if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        const crObj = {
          id: cr.id,
          name: cr.CRName,
          type: 'ChangeRequest',
          CMSheetId: cr.CMSheets[0].id,
          CMSheetVersion: cr.CMSheets[0].Version,
          StartDate: cr.StartDate,
          paymentMethod: cr.PaymentMethodId,
          billingRegion: cr.BillingRegion,
          customerId: cr.CustomerId,
          countryId: cr.CountryId,
          ProjectId: cr.ProjectId,
          CMSheetStatus: cr.CMSheets[0] ? cr.CMSheets[0].Status : null,
        };
        theList.push(crObj);
      } else if (cr.Status && cr.Status.StatusName === 'Waiting Confirmation') {
        const crObj = {
          id: cr.id,
          name: cr.CRName,
          type: 'ChangeRequest',
          CMSheetId: cr.CMSheets[0].id,
          CMSheetVersion: cr.CMSheets[0].Version,
          StartDate: cr.StartDate,
          paymentMethod: cr.PaymentMethodId,
          billingRegion: cr.BillingRegion,
          customerId: cr.CustomerId,
          countryId: cr.CountryId,
          ProjectId: cr.ProjectId,
          CMSheetStatus: cr.CMSheets[0] ? cr.CMSheets[0].Status : null,
        };
        validProjAndCrs.push(crObj);
      }
      const anyCRObj = {
        id: cr.id,
        name: cr.CRName,
        type: 'ChangeRequest',
        CMSheetId: cr.CMSheets.length > 0 ? cr.CMSheets[0].id : null,
        CMSheetVersion: cr.CMSheets.length > 0 ? cr.CMSheets[0].Version : null,
        StartDate: cr.StartDate,
        paymentMethod: cr.PaymentMethodId,
        billingRegion: cr.BillingRegion,
        customerId: cr.CustomerId,
        ProjectId: cr.ProjectId
      };
      allObjs.push(anyCRObj);
    }

    validProjAndCrs = validProjAndCrs.concat(theList);

    this.allTheNames = theList;
    await this.setState({
      namesAll: theList,
      proObj: projectObj,
      allCrsAndProjs: allObjs,
      validProjAndCrs: validProjAndCrs
    });

    //check allocated PM is in project or CR
    let crAll = [];
    let theCRList = [];
    if (this.state.project.ResourceRequestStatus === 'PM_ALLOCATED' || (this.state.project.ProjectStatus && this.state.project.ProjectStatus.StatusName === 'Waiting Confirmation')) {
      if (this.state.project.AllocatedResourceRequestPM && this.state.project.AllocatedResourceRequestPM.length > 0) {
        // for (const pm of this.state.project.AllocatedResourceRequestPM) {
        //   if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
        //     this.state.allocatedProjectManager = true
        //   }
        // }
        let projectProjectManagers = this.state.project.AllocatedResourceRequestPM;
        let projValidManagers = [];
        projValidManagers = projectProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())) );
        if (projValidManagers.length == 0 && projectProjectManagers.length > 0){

          projectProjectManagers.sort(function(a,b){return new Date(b.EndDate) - new Date(a.EndDate)})
          const lastAssignedManager = projectProjectManagers[0];
          projValidManagers = projectProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate ));

        }
        for (const value of projValidManagers){
          if(window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase()){
            this.state.allocatedProjectManager = true;
            break;
          }
        }
        let allowRREdit = true;
        const reviseCMSrequestsProjects = this.state.project.ReviseCMSRequest;
        if (reviseCMSrequestsProjects && reviseCMSrequestsProjects.length > 0) {
            const sortedArray = reviseCMSrequestsProjects.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
                (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
            ) {
              allowRREdit = false;
            }
        }
        if (this.state.allocatedProjectManager && allowRREdit) {
          const projectObj = {
            id: this.state.project.id,
            name: this.state.project.ProjectName,
            type: 'Project',
            CMSheetId: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null,
            CMSheetVersion: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null,
            StartDate: this.state.project.StartDate,
            paymentMethod: this.state.project.PaymentMethod,
            billingRegion: this.state.project.BillingRegion,
            customerId: this.state.project.CustomerId,
            countryId: this.state.project.CountryId,
            CMSheetStatus: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Status : null,
          };
          validPMsProjAndCrs.push(projectObj);
        }
      }
    }

    if (this.state.project.ChangeRequests) {
      for (const cr of this.state.project.ChangeRequests) {
        if (cr.ResourceRequestStatus === 'PM_ALLOCATED' || (cr.Status && cr.Status.StatusName === 'Waiting Confirmation')) {
          if (cr.AllocatedPM && cr.AllocatedPM.length > 0) {
            // for (const pm of cr.AllocatedPM) {
            //   if (pm.PMResource.Email.toLowerCase() === window.LOGGED_USER.userId.toLowerCase()) {
                // if (!crAll.includes(cr)) {
                //   crAll.push(cr)
                // }
            //   }
            // }
            const crProjectManagers = cr.AllocatedPM;

            //find valid pms from end date
            let validManagers = [];
            validManagers = crProjectManagers.filter(manager => (getFormattedDate(new Date(new Date(manager.EndDate).setDate(new Date(manager.EndDate).getDate() + 3))) >= getFormattedDate(new Date()) && getFormattedDate(new Date(manager.StartDate)) <= getFormattedDate(new Date())));

            if (validManagers.length === 0 && crProjectManagers.length > 0) {
              crProjectManagers.sort(function (a, b) {
                return new Date(b.EndDate) - new Date(a.EndDate)
              })
              const lastAssignedManager = crProjectManagers[0];
              validManagers = crProjectManagers.filter(manager => (manager.EndDate == lastAssignedManager.EndDate));
            }

            let allowRREdit = true;
            const reviseCMSrequests = cr.ReviseCMSRequest;// need to changeeeeees
            if (reviseCMSrequests && reviseCMSrequests.length > 0) {
              const sortedArray = reviseCMSrequests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
              if(sortedArray[0].ApprovalStatus == "PENDING_APPROVALS" || 
                (sortedArray[0].ApprovalStatus == "APPROVED" && !sortedArray[0].IsRevisedCmsApproved)
              ) {
                allowRREdit = false;
              }
            }

            for (const value of validManagers){
              if (window.LOGGED_USER.userId.toUpperCase() === value.PMResource.Email.toUpperCase() && allowRREdit) {
                const crObj = {
                  id: cr.id,
                  name: cr.CRName,
                  type: "ChangeRequest",
                  CMSheetId: cr.CMSheets[0].id,
                  CMSheetVersion: cr.CMSheets[0].Version,
                  StartDate: cr.StartDate,
                  paymentMethod: cr.PaymentMethodId,
                  billingRegion: cr.BillingRegion,
                  customerId: cr.CustomerId,
                  ProjectId: cr.ProjectId,
                  countryId: cr.CountryId,
                  CMSheetStatus: cr.CMSheets[0] ? cr.CMSheets[0].Status : null,
                };
                theCRList.push(crObj);
                validPMsProjAndCrs.push(crObj);
                break;
              }
            }
          }
        }
      }
    }

    // for (const cr of crAll) {
    //   if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
    //     const crObj = {
    //       id: cr.id,
    //       name: cr.CRName,
    //       type: "ChangeRequest",
    //       CMSheetId: cr.CMSheets[0].id,
    //       CMSheetVersion: cr.CMSheets[0].Version,
    //       StartDate: cr.StartDate,
    //       paymentMethod: cr.PaymentMethodId,
    //       billingRegion: cr.BillingRegion,
    //       customerId: cr.CustomerId,
    //       ProjectId: cr.ProjectId
    //     };
    //     theCRList.push(crObj)
    //   }
    // }
    await this.setState({
      crObj: theCRList,
      validPMsProjAndCrs: validPMsProjAndCrs
    });
  }

  setEndDate = async () => {
    //resource Mix max end date
    var resourceLineEndDate = new Date(this.getStartDate());
    for (let eachLine of this.state.resourceMix) {
      if (new Date(eachLine.EndDate) > new Date(resourceLineEndDate)) {
        resourceLineEndDate = eachLine.EndDate;
      }
    }
    if (this.state.project.UserDefinedEndDate === null) {
      var project = this.state.project;
      var userDefinedEndDate = null;
      var userDefinedEndDateandTime = [];
      if (project.ConvertedToActiveAt !== null) { //only during activation this date gets saved
        if (!project.EstimatedEndDate && project.StartDate !== project.EstimatedStartDate) {
          var totalEffortDays = await this.calculateTotalEffort(project.EstimatedStartDate, project.StartDate, project.CountryId);
          if (totalEffortDays > 0) {
            let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, project.EstimatedEndDate, project.CountryId);
            project.EstimatedEndDate = endDate;
          }
          project.EstimatedStartDate = project.StartDate;
        }
        userDefinedEndDateandTime = [project.EstimatedEndDate, project.ConvertedToActiveAt];
      }

      for (const cr of project.ChangeRequests) {
        if (cr.ConvertedToActiveAt && cr.ConvertedToActiveAt !== null) {
          if (!cr.EstimatedEndDate && cr.StartDate !== cr.EstimatedStartDate) {
            var totalEffortDays = await this.calculateTotalEffort(cr.EstimatedStartDate, cr.StartDate, cr.CountryId);
            if (totalEffortDays > 0) {
              let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, cr.EstimatedEndDate, cr.CountryId);
              cr.EstimatedEndDate = endDate;
            }
            cr.EstimatedStartDate = cr.StartDate;
          }
          if (userDefinedEndDateandTime.length === 0) {
            userDefinedEndDateandTime = [cr.EstimatedEndDate, cr.ConvertedToActiveAt];
          } else if (userDefinedEndDateandTime.length > 0 && cr.ConvertedToActiveAt < userDefinedEndDateandTime[1]) {
            userDefinedEndDateandTime = [cr.EstimatedEndDate, cr.ConvertedToActiveAt];
          }
        }
      }

      if (userDefinedEndDateandTime[0] && (new Date(resourceLineEndDate) <= new Date(userDefinedEndDateandTime[0])) && (new Date(userDefinedEndDateandTime[0]) >= new Date(this.getStartDate()))) {
        userDefinedEndDate = reStructureDate(userDefinedEndDateandTime[0]);
      }

      this.setState({
        project: project,
        userDefinedEndDate: userDefinedEndDate
      })
    } else {
      this.setState({
        previousEndDate: reStructureDate(this.state.project.UserDefinedEndDate)
      })
      // if ((new Date(this.state.project.UserDefinedEndDate) > new Date()) && (new Date(resourceLineEndDate) < new Date(this.state.project.UserDefinedEndDate))) {
        this.setState({
          userDefinedEndDate: reStructureDate(this.state.project.UserDefinedEndDate)
        })
      // }
    }
  };

  onUserDefinedEndDateChange = () => {
    const userDetails = window.LOGGED_USER;
    const auditTrail = {
      Action: this.state.previousEndDate === null ? ('End date revised to ' + moment(this.state.userDefinedEndDate).format('MM-DD-YYYY')) : ('End date revised to ' + moment(this.state.userDefinedEndDate).format('MM-DD-YYYY') + ' from ' + moment(this.state.previousEndDate).format('MM-DD-YYYY')),
      RefType: 'Resource Request',
      RefId: this.state.project.id,
      RefName: this.state.project.OPID + ' - ' +this.state.project.ProjectName
    };
    saveAuditTrail(auditTrail);
  };

  calculateTotalEffort = async (startDateIn, endDateIn, projOrCrCountryId) => {

    if (startDateIn && endDateIn) {
      let startDate = new Date(startDateIn);
      let endDate = new Date(endDateIn);

      let defaultCountry = projOrCrCountryId;

      let currentYear = startDate.getFullYear();
      let startDateTimeZone = new Date(startDate);
      startDateTimeZone.setHours(0, 0, 0, 0);
      let end_date = moment(new Date(endDate));
      let start_date = moment(new Date(startDateTimeZone));

      let loop = new Date(start_date);
      let totalWorkingDays = 0;
      let isHoliday = false;

      while (currentYear <= endDate.getFullYear()) {
        // Holidays
        let holidays = await getHolidaysIntegrated(defaultCountry, currentYear).then(res => {
          if (res && res.data) { return res.data; }
          return [];
        }).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          return [];
        });

        if (holidays && holidays.length === 0) {
          console.log('Holidays not found for ' + startDate.getFullYear());
        } else if (!holidays) {
          holidays = [];
        }
        while (loop <= end_date && loop <= new Date(currentYear, 11, 31)) {
          isHoliday = false;
          const dateStr = getFormattedDate(loop);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (!isHoliday && !isWeekend(loop)) {
            totalWorkingDays++;
          }

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        currentYear = currentYear + 1;

      }
      return totalWorkingDays;
    }

  };

  calculateNewEstimatedEndDate = async (workingDays, startDate, countryId) => {

    let startDateObj = reStructureDate(startDate);
    let endDate;

    // estimate end date
    const noOfWorkingDays = workingDays;

    let totalDays = 0;
    let workingDaysCount = 0;

    let holidays = await getHolidaysIntegrated(countryId, startDateObj.getFullYear()).then(
      res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      }
    )
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    if (holidays && holidays.length === 0) {
      console.log('Holidays not found for ' + startDateObj.getFullYear());
    } else if (!holidays) {
      holidays = [];
    }

    // set start date when start date is a weekday or holiday
    let formattedStartDate = reStructureDate(startDate);
    while((isWeekend(formattedStartDate)) || (holidays.indexOf(getFormattedDate(formattedStartDate)) > -1)){
      formattedStartDate.setDate(formattedStartDate.getDate() + 1);
    }

    endDate = reStructureDate(formattedStartDate);
    let currentYear = startDateObj.getFullYear();
    let isHoliday = false;

    while (workingDaysCount < noOfWorkingDays - 1 || isWeekend(endDate) || isHoliday) {
      if (currentYear !== endDate.getFullYear()) {
        holidays = await getHolidaysIntegrated(countryId, endDate.getFullYear())
          .then(res => {
            if (res && res.data) {
              return res.data;
            }
            return [];
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            return [];
          });

        currentYear = endDate.getFullYear();
      }

      totalDays++;
      endDate.setDate(endDate.getDate() + 1);

      isHoliday = false;
      const dateStr = getFormattedDate(endDate);
      if (holidays.indexOf(dateStr) > -1) {
        isHoliday = true;
      }

      if (!isWeekend(endDate) && !isHoliday) {
        workingDaysCount++;
      }
    }

    endDate.setDate(endDate.getDate());
    return { endDate, noOfWorkingDays, totalDays };
  };

  getCMSheetVersionAndIdList = () => {
    let theList = [];
    // check if the project is activated or is in Waiting Confirmation status
    if ((this.state.project.StatusConvertedToActive && this.state.project.StatusConvertedToActive === true) || this.state.project.ProjectStatus && this.state.project.ProjectStatus.StatusName === 'Waiting Confirmation') {
      const projectVersionObj = {
        id: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null,
        Version: this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null
      };
      theList.push(projectVersionObj);
    }
    for (const cr of this.state.project.ChangeRequests) {
      if ((cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) || cr.Status && cr.Status.StatusName === 'Waiting Confirmation') {
        if (cr.CMSheets && cr.CMSheets.length > 0) {
          const crVersionObj = {
            id: cr.CMSheets[0].id,
            Version: cr.CMSheets[0].Version
          };
          theList.push(crVersionObj);
        }   
      }
    }
    return theList;
  };


  populateConsolidatedResourceMix = async () => {
    const IdList = this.getCMSheetVersionAndIdList();
    if (IdList && IdList.length > 0) {
      if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPApproveWF') {
        await getResourceRequestResourceMixData(IdList).then(async response => {
          const res = response.data;
          const allocationSummaryResources = [];
          for (let resource of res) {
            for (let element of resource) {
              allocationSummaryResources.push(element);
            }
          }
          this.setState({
            allocationSummaryResources: allocationSummaryResources
          });
        });
      }
      let pendingLinesForApprove = [];
      const tempPendingTaskRes = await getProjectPendingTasks(this.state.project.id);
      if (tempPendingTaskRes && tempPendingTaskRes.data && tempPendingTaskRes.data.length > 0) {
        pendingLinesForApprove = tempPendingTaskRes.data;
      }
      await getResourceRequestResourceMixData(IdList).then(async response => {
        let res = response.data;
        this.setState({
          forRemoveResource: res,
          forSoftBook: res
        });
        await getPreviousCMSResourceMixData(IdList).then(async response => {
          const previousCmsData = response.data;
          if (previousCmsData && previousCmsData.length > 0) {
            res = res.concat(previousCmsData);
          }          
        }).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

        const resources = [];
        let index = 0;
        let allProjectCRphases = [];
        for (let resource of res) {
          for (let element of resource) {
            element.selected = false;
            element.index = index;
            element.name = this.getProjectOrCRName(element);

            if (element.AllocatedResource) {
              element.AllocatedResource.displayName = element.AllocatedResource.Name;
            }

            if (this.props.location.action === 'RPApprove') {
              if (element.ResourceRequestSubLine.length > 0) {
                var pendingSubline = false
                var allocateSublines = [];
                for (const subline of element.ResourceRequestSubLine) {
                  if (subline.Status === 'RES_ALLOC_PENDING') {

                    let filteredAllocatedName = {};
                    let filteredAllocatedDesig = {};

                    if (subline.ResourceId) {
                      // Name
                      filteredAllocatedName = this.usersAllActiveAndInactive.filter(function (itm) {
                        return subline.ResourceId === itm.id;
                      });
                      subline.AllocatedResource = filteredAllocatedName[0];

                      // Designation
                      if (filteredAllocatedName[0] && subline.AllocatedDesignationId) {
                        filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                            return (subline.AllocatedDesignationId === itm.id);
                        });
                      } else {
                          filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                              return (filteredAllocatedName[0].DesignationId === itm.id);
                          });
                      }
                      subline.AllocatedDesignation = filteredAllocatedDesig[0] ? filteredAllocatedDesig[0].Name : "";
                    }

                    pendingSubline = true;
                    allocateSublines.push(subline);
                  }
                }
                if (pendingSubline === true) {
                  element.ResourceRequestSubLine = allocateSublines;
                  resources.push(element);
                  index++;
                }
              }
              else if (element.Status === 'RES_ALLOC_PENDING') {
                resources.push(element);
                index++;
              }
            } else if (this.props.location.action === 'RPApproveWF') {
              if (element.ResourceRequestSubLine.length > 0) {
                var pendingSubline = false;
                var requestedSublines = [];
                for (const subline of element.ResourceRequestSubLine) {
                  if (subline.Status === 'RQST_APP_PENDING') {
                    const approvalSubLine = pendingLinesForApprove.filter(a => a.ResourceRequestId == subline.ResourceRequestId && a.SublineResourceRequestId == subline.id);
                    if (approvalSubLine && approvalSubLine.length > 0) {
                      pendingSubline = true;
                      let matchingAllocationPercentageSubline = null;
                      let allocationPercentageHistory = element.AllocationPercentageHistory.filter(res => res.ResourceRequestId === element.id && res.ResourceRequestSubLineId === subline.id);
                      if (allocationPercentageHistory && allocationPercentageHistory.length > 0) {
                        let validHistory = Object.values(allocationPercentageHistory).find(c => new Date(moment(new Date()).format('YYYY-MM-DD')) >= new Date(moment(c.StartDate).format('YYYY-MM-DD')) && new Date(moment(new Date()).format('YYYY-MM-DD')) <= new Date(moment(c.EndDate).format('YYYY-MM-DD')));
                        matchingAllocationPercentageSubline = validHistory ? validHistory.AllocationPercentage : subline.AllocationPercentage;
                      } else {
                        matchingAllocationPercentageSubline = subline.AllocationPercentage;
                      }
                      subline.CurrentAllocationPercentage = matchingAllocationPercentageSubline
                      requestedSublines.push(subline);
                    }
                  }
                }
                if (pendingSubline === true) {
                  element.ResourceRequestSubLine = requestedSublines;
                  resources.push(element);
                  index++;
                }
              }
              else if (element.Status === 'RQST_APP_PENDING') {
                const approvalLine = pendingLinesForApprove.filter(a => a.ResourceRequestId == element.id && a.SublineResourceRequestId == null);
                if (approvalLine && approvalLine.length > 0) {
                  const allocationPercentageHistoryDetails = element.AllocationPercentageHistory.filter(res => res.ResourceRequestId === element.id && res.ResourceRequestSubLineId === null);
                  let matchingAllocationPercentage = null;
                  if (allocationPercentageHistoryDetails && allocationPercentageHistoryDetails.length > 0) {
                    let validHistory  = Object.values(allocationPercentageHistoryDetails).find(c => new Date(moment(new Date()).format('YYYY-MM-DD')) >= new Date(moment(c.StartDate).format('YYYY-MM-DD')) && new Date(moment(new Date()).format('YYYY-MM-DD')) <= new Date(moment(c.EndDate).format('YYYY-MM-DD')));
                    matchingAllocationPercentage = validHistory ? validHistory.AllocationPercentage : element.AllocationPercentage;
                  } else {
                    matchingAllocationPercentage = element.AllocationPercentage;
                  }
                  element.CurrentAllocationPercentage = matchingAllocationPercentage
                  resources.push(element);
                  index++;
                }
              }
            } else {

              const allocationPercentageHistoryDetails = element.AllocationPercentageHistory.filter(res => res.ResourceRequestId === element.id && res.ResourceRequestSubLineId === null);
              let matchingAllocationPercentage = null;
              if (allocationPercentageHistoryDetails && allocationPercentageHistoryDetails.length > 0) {
                let validHistory  = Object.values(allocationPercentageHistoryDetails).find(c => new Date(moment(new Date()).format('YYYY-MM-DD')) >= new Date(moment(c.StartDate).format('YYYY-MM-DD')) && new Date(moment(new Date()).format('YYYY-MM-DD')) <= new Date(moment(c.EndDate).format('YYYY-MM-DD')));
                matchingAllocationPercentage = validHistory ? validHistory.AllocationPercentage : element.AllocationPercentage;
              } else {
                matchingAllocationPercentage = element.AllocationPercentage;
              }
              element.CurrentAllocationPercentage = matchingAllocationPercentage

              var allocateSublines = [];
              for (const subline of element.ResourceRequestSubLine) {
                let matchingAllocationPercentageSubline = null;
                let allocationPercentageHistory = element.AllocationPercentageHistory.filter(res => res.ResourceRequestId === element.id && res.ResourceRequestSubLineId === subline.id);
                if (allocationPercentageHistory && allocationPercentageHistory.length > 0) {
                  let validHistory = Object.values(allocationPercentageHistory).find(c => new Date(moment(new Date()).format('YYYY-MM-DD')) >= new Date(moment(c.StartDate).format('YYYY-MM-DD')) && new Date(moment(new Date()).format('YYYY-MM-DD')) <= new Date(moment(c.EndDate).format('YYYY-MM-DD')));
                  matchingAllocationPercentageSubline = validHistory ? validHistory.AllocationPercentage : subline.AllocationPercentage;
                } else {
                  matchingAllocationPercentageSubline = subline.AllocationPercentage;
                }
                subline.CurrentAllocationPercentage = matchingAllocationPercentageSubline

                const sublineObj = JSON.parse(JSON.stringify(subline));

                // Set desination name
                if (subline.AllocatedResource !== null) {
                  let filteredAllocatedDesig = {};
                  if (subline.AllocatedDesignationId) {
                    filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                        return (subline.AllocatedDesignationId === itm.id);
                    });
                  } else {
                      filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                          return (subline.AllocatedResource.Resource.DesignationId === itm.id);
                      });
                  }

                  sublineObj.AllocatedDesignation = filteredAllocatedDesig[0] ? filteredAllocatedDesig[0].Name : "";
                  // Allocated region
                  const AllocatedRegion = this.regionsAll.filter(function (itm) {
                    return subline.AllocatedResource.Resource.Region === itm.id;
                  });
                  sublineObj.AllocatedRegion = AllocatedRegion[0];
                }

                allocateSublines.push(sublineObj);
              }

              // Set sublines
              element.ResourceRequestSubLine = allocateSublines;

              resources.push(element);
              index++;
            }

            let allPhases = [];
            if (this.state.phasesTempAll && this.state.phasesTempAll.length > 0) {
              allPhases = this.state.phasesTempAll.filter(res => res.CMSheetId === element.CMSheetId);
            }
            element.Phases = allPhases;

            if (element.PhaseId && allPhases.length > 0) {
              let linePhase = allPhases.filter(res => res.id === element.PhaseId);
              element.Phase = linePhase[0];
              if (element.SubPhaseId) {
                let lineSubPhase = null;
                if (linePhase[0] && linePhase[0].SubPhases && linePhase[0].SubPhases.length > 0) {
                  let subPhases = linePhase[0].SubPhases;
                  lineSubPhase = subPhases.filter(res => res.id === element.SubPhaseId);
                  element.SubPhase = lineSubPhase[0];               
                }
              }
            } else {
              element.Phase = null;
              element.SubPhase = null; 
            }

            // if (element.Type === 'Project') {
            //   if (element.Project && element.Project.CMSheets && element.Project.CMSheets.length > 0) {
            //     const cmsheet = element.Project.CMSheets[0];
            //     if (cmsheet.Phases && cmsheet.Phases.length > 0) {
            //       for (const phase of cmsheet.Phases) {
            //         allProjectCRphases.push(phase);
            //       }
            //     }
            //   }
            // } else {
            //   if (element.ChangeRequest && element.ChangeRequest.CMSheets && element.ChangeRequest.CMSheets.length > 0) {
            //     const cmsheet = element.ChangeRequest.CMSheets[0];
            //     if (cmsheet.Phases && cmsheet.Phases.length > 0) {
            //       for (const phase of cmsheet.Phases) {
            //         allProjectCRphases.push(phase);
            //       }
            //     }
            //   }
            // }



            if (element.ResourceRequestSubLine && element.ResourceRequestSubLine.length > 0) {
              let index = 0;
              let minStartDate = element.ResourceRequestSubLine[0].StartDate;
              let maxEndDate = element.ResourceRequestSubLine[0].EndDate;
              let cost = 0;
              let revenue = 0.00;
              let EffortDays = 0;

              for (const subLine of element.ResourceRequestSubLine) {

                if (subLine.PhaseId && allPhases.length > 0) {
                  let linePhase = allPhases.filter(res => res.id === subLine.PhaseId);
                  subLine.Phase = linePhase[0];
                  if (subLine.SubPhaseId) {
                    let lineSubPhase = null;
                    if (linePhase[0] && linePhase[0].SubPhases && linePhase[0].SubPhases.length > 0) {
                      let subPhases = linePhase[0].SubPhases;
                      lineSubPhase = subPhases.filter(res => res.id === subLine.SubPhaseId);
                      subLine.SubPhase = lineSubPhase[0];               
                    }
                  }
                } else {
                  subLine.Phase = null;
                  subLine.SubPhase = null; 
                } 

                if (subLine.EffortDays) {
                  EffortDays += subLine.EffortDays;
                }

                if (minStartDate > subLine.StartDate) {
                  minStartDate = subLine.StartDate;
                }

                if (maxEndDate < subLine.EndDate) {
                  maxEndDate = subLine.EndDate;
                }

                if (subLine.Cost) {
                  let tmpCost = Number(subLine.Cost);
                  cost = cost + tmpCost;
                }

                if (subLine.Revenue) {
                  revenue += Number(subLine.Revenue)
                }
                subLine.CostToRevenueRatio = Number(subLine.Revenue) === 0 ? 0.00 : (subLine.Cost / subLine.Revenue).toFixed(2);
                const costToRevenueRatio = Number(revenue) === 0 ? 0.00 : cost / revenue;

                element.TotalCost = cost.toFixed(2);
                element.TotalRevenue = revenue.toFixed(2);
                element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);

                if (!subLine.PaymentMethodRecord) {
                  let paymentMethod = element.Type === "Project" ? element.Project.PaymentMethodRecord : element.ChangeRequest.PaymentMethod;
                  subLine.PaymentMethodRecord = paymentMethod;
                }

                subLine.index = index;
                index++;
              }
              element.StartDate = minStartDate;
              element.EndDate = maxEndDate;
              element.EffortDays = EffortDays.toFixed(2);
              element.Status = 'EDIT';
            }
            const costToRevenueRatio = Number(element.TotalRevenue) === 0 ? 0.00 : element.TotalCost / element.TotalRevenue;
            element.CostToRevenueRatio = costToRevenueRatio.toFixed(2);
            element.EffortDays = Number(Number(element.EffortDays).toFixed(2));
          }
        }

        const uniquePhases = [];
        // allProjectCRphases.map(x => uniquePhases.filter(a => a.id == x.id).length > 0 ? null : uniquePhases.push(x));

        resources.map(dataItem => Object.assign({ selected: false, expanded: false }, dataItem));

        let resourcesTemp = [];
        let filteredArray = [];
        for (const resource of resources) {
          const resourceObj = JSON.parse(JSON.stringify(resource));
          // resourceObj.Phases = uniquePhases;

          const filteredArray = this.designationsAll.filter(function (itm) {
            return resourceObj.dataValueProjectRole.CategoryId === itm.CategoryId;
          });

          resourceObj.Designations = filteredArray;
          let filteredAllocatedName = {};
          let filteredAllocatedDesig = {};
          if (resource.AllocatedResourceID != null) {
            filteredAllocatedName = this.usersAllActiveAndInactive.filter(function (itm) {
              return resourceObj.AllocatedResourceID === itm.id;
            });

            if(filteredAllocatedName && filteredAllocatedName.length > 0){
              resourceObj.AllocatedResource.Name = filteredAllocatedName[0].Name;
              if (filteredAllocatedName[0] && resourceObj.AllocatedDesignationId) {
                filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                    return (resourceObj.AllocatedDesignationId === itm.id);
                });
              } else {
                  filteredAllocatedDesig = this.designationsAll.filter(function (itm) {
                      if(filteredAllocatedName.length > 0){
                          return (filteredAllocatedName[0].DesignationId === itm.id);
                      }
                  });
              }
              if(filteredAllocatedDesig && filteredAllocatedDesig.length > 0 ){
                resourceObj.AllocatedDesignation = filteredAllocatedDesig[0].Name;
              }

            }
          }
          resourcesTemp.push(resourceObj);
        }

        resourcesTemp.forEach(function (item) {
          if (item.ResourceRequestSubLine.length > 0) {
            item.expanded = true;
          } else {

          }
        });


        for (const resource of resourcesTemp) {
          let countryId;

          if (resource.EndDate === null && resource.StartDate !== null) {
            let totalEffortDays = 0;
            totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));

            if (resource.OnsiteStatus === 'Onsite') {
              countryId = resource.Type === "Project" ? resource.Project.CountryId : resource.ChangeRequest.CountryId;
            } else {
              countryId = resource.dataValueResourceRegion.DefaultCountryId;
            }

            if(resource.Type === 'Project'){
              if(resource.StartDate < resource.Project.StartDate){
                let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.Project.StartDate, countryId);
                resource.EndDate = moment(endDate).format('YYYY-MM-DD');
              }
              else{
                let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.StartDate, countryId);
                resource.EndDate = moment(endDate).format('YYYY-MM-DD');
              }
            }
            else if(resource.Type === 'ChangeRequest'){
              if(resource.StartDate < resource.ChangeRequest.StartDate){
                let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.ChangeRequest.StartDate, countryId);
                resource.EndDate = moment(endDate).format('YYYY-MM-DD');
              }
              else{
                let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.StartDate, countryId);
                resource.EndDate = moment(endDate).format('YYYY-MM-DD');
              }
            }
          }


          else if((resource.Type === 'Project') && (resource.StartDate < resource.Project.StartDate)){
            let totalEffortDays = 0;
            totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));

            if (resource.OnsiteStatus === 'Onsite') {
              countryId = resource.Project.CountryId;
            } else {
              countryId = resource.dataValueResourceRegion.DefaultCountryId;
            }
            
            let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.Project.StartDate, countryId);
            resource.EndDate = moment(endDate).format('YYYY-MM-DD');
          }

          else if((resource.Type === 'ChangeRequest') && (resource.StartDate < resource.ChangeRequest.StartDate)){
            let totalEffortDays = 0;
            totalEffortDays = (resource.EffortDays / (resource.AllocationPercentage / 100));

            if (resource.OnsiteStatus === 'Onsite') {
              countryId = resource.ChangeRequest.CountryId;
            } else {
              countryId = resource.dataValueResourceRegion.DefaultCountryId;
            }
            
            let { endDate, noOfWorkingDays, totalDays } = await this.calculateNewEstimatedEndDate(totalEffortDays, resource.ChangeRequest.StartDate, countryId);
            resource.EndDate = moment(endDate).format('YYYY-MM-DD');
          }

        }

        let locationAction = this.props.location.action;
        for (var item of resourcesTemp) {
          if (item && item.ResourceRequestSubLine.length > 0) {
            for (var subline of item.ResourceRequestSubLine) {
              subline.locationActionAttribute = locationAction;
            }
          }
        }

        this.setState({
          resourceMix: resourcesTemp,
          allProjectCrPhases: uniquePhases,
          forFilterResourceLine: resourcesTemp,
        });
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    } else {
      this.setState({
        resourceMix: []
      });
    }
    if (this.props.location.action === 'RPApprove') {
      await this.populatePresalesResourceMix();
    }

    await this.sortResourceMixByLastModifiedDate();

  };

  sortResourceMixByLastModifiedDate = async () => {
    await this.state.resourceMix.sort(function (a, b) {
      var keyA = new Date(a.LastUpdatedByUserAt),
        keyB = new Date(b.LastUpdatedByUserAt);
      // first compare is request from previous CMS or Not
      if (b.IsPreviousCmsResource - a.IsPreviousCmsResource !== 0) {
        return a.IsPreviousCmsResource - b.IsPreviousCmsResource;
      } else {
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
      }   
      return 0;
    });

    await this.state.resourceMix.forEach(function (resourceItem) {
      if (resourceItem.ResourceRequestSubLine && resourceItem.ResourceRequestSubLine.length > 0) {
        resourceItem.ResourceRequestSubLine.sort(function (a, b) {
          var keyA = new Date(a.LastUpdatedByUserAt),
            keyB = new Date(b.LastUpdatedByUserAt);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
      }
    });

    var indexRes = 0;
    for (const eachLine of this.state.resourceMix) {
      eachLine.index = indexRes;
      indexRes++;
      var indexSub = 0;
      if (eachLine.ResourceRequestSubLine && eachLine.ResourceRequestSubLine.length > 0) {
        for (const eachSub of eachLine.ResourceRequestSubLine) {
          eachSub.index = indexSub;
          indexSub++;
        }
      }
    }
  };

  populatePresalesResourceMix = async () => {
    await getPreSalesResourceMixData(this.state.allCrsAndProjs)
      .then(response => {
        var tempResourceMix = [];
        var index = 0;
        if (this.state.resourceMix.length > 0) {
          tempResourceMix = this.state.resourceMix;
          index = tempResourceMix.length;
        }
        for (let resource of response.data) {
          for (let element of resource) {
            if (element.Status === 'RES_ALLOC_PENDING') {
              var duplicates = tempResourceMix.filter(beforeItem => beforeItem.id == element.id);
              if (duplicates.length === 0) {
                element.index = index;
                tempResourceMix.push(element);
                index++;
              }
            }
          }
        }
        this.setState({
          resourceMix: tempResourceMix,
          forFilterResourceLine: tempResourceMix
        })
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
  };

  populateInitialCMSToGrid = () => {
    if (this.state.calculatedValues && this.state.calculatedActValues) {
      const calculatedValues = this.state.calculatedValues;
      const approvedCalculatedValues = this.state.approvedCalculatedValues;
      const calculatedActValues = this.state.calculatedActValues;

      const calculations = [
        // {
        //   Field: '',
        //   Value1: 'Approved CMS Value',
        //   Value2: '',
        //   Value3: 'Allocation Based Value',
        //   Value4: '',
        //   Value5: '',
        //   Value6: '',
        //   Value7: 'Actual + Forecast Based Value',
        //   Value8: '',
        //   Value9: '',
        //   Value10: '',
        //   isMainHeader: true
        // },
        {
          Field: 'Project Revenue',
          Value1: '',
          Value2: '',
          Value3: '',
          Value4: '',
          Value5: 'Variance %',
          Value6: 'Variance Value',
          Value7: '',
          Value8: '',
          Value9: 'Variance %',
          Value10: 'Variance Value',
          Value11: '',
          Value12: '',
          Value13: 'Variance %',
          Value14: 'Variance Value',
          isHeader: true
        },
        {
          Field: 'Total Resource Revenue',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalResourceRevenueCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalResourceRevenueOngoing)),
          Value5: (calculatedValues.TotalResourceRevenueCMS !== 0 ? ((((((Number(calculatedValues.TotalResourceRevenueOngoing)) - (Number(calculatedValues.TotalResourceRevenueCMS))) / (Number(calculatedValues.TotalResourceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalResourceRevenueOngoing)) - (Number(calculatedValues.TotalResourceRevenueCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalResourceRevenue)),
          Value9: (calculatedValues.TotalResourceRevenueCMS !== 0 ? ((((((Number(calculatedActValues.TotalResourceRevenue)) - (Number(calculatedValues.TotalResourceRevenueCMS))) / (Number(calculatedValues.TotalResourceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalResourceRevenue)) - (Number(calculatedValues.TotalResourceRevenueCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalResourceRevenue)),
          Value13: (calculatedValues.TotalResourceRevenueCMS !== 0 ? ((((((Number(calculatedActValues.TotalResourceRevenue)) - (Number(calculatedValues.TotalResourceRevenueCMS))) / (Number(calculatedValues.TotalResourceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalResourceRevenue)) - (Number(calculatedValues.TotalResourceRevenueCMS))).toFixed(2))),
        },
        {
          Field: 'Product/Licence Revenue',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalServiceRevenueCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalServiceRevenueCMS)),
          Value5: (calculatedValues.TotalServiceRevenueCMS !== 0 ? ((((((Number(calculatedValues.TotalServiceRevenueCMS)) - (Number(calculatedValues.TotalServiceRevenueCMS))) / (Number(calculatedValues.TotalServiceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalServiceRevenueCMS)) - (Number(calculatedValues.TotalServiceRevenueCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalServiceRevenue)),
          Value9: (calculatedValues.TotalServiceRevenueCMS !== 0 ? ((((((Number(calculatedActValues.TotalServiceRevenue)) - (Number(calculatedValues.TotalServiceRevenueCMS))) / (Number(calculatedValues.TotalServiceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalServiceRevenue)) - (Number(calculatedValues.TotalServiceRevenueCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalServiceRevenue)),
          Value13: (calculatedValues.TotalServiceRevenueCMS !== 0 ? ((((((Number(calculatedActValues.TotalServiceRevenue)) - (Number(calculatedValues.TotalServiceRevenueCMS))) / (Number(calculatedValues.TotalServiceRevenueCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalServiceRevenue)) - (Number(calculatedValues.TotalServiceRevenueCMS))).toFixed(2)))
        },
        {
          Field: 'Contingency Value',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalContingencyCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalContingencyOngoing)),
          Value5: (calculatedValues.TotalContingencyCMS !== 0 ? ((((((Number(calculatedValues.TotalContingencyOngoing)) - (Number(calculatedValues.TotalContingencyCMS))) / (Number(calculatedValues.TotalContingencyCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalContingencyOngoing)) - (Number(calculatedValues.TotalContingencyCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalContingency)),
          Value9: (calculatedValues.TotalContingencyCMS !== 0 ? ((((((Number(calculatedActValues.TotalContingency)) - (Number(calculatedValues.TotalContingencyCMS))) / (Number(calculatedValues.TotalContingencyCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalContingency)) - (Number(calculatedValues.TotalContingencyCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalContingency)),
          Value13: (calculatedValues.TotalContingencyCMS !== 0 ? ((((((Number(calculatedActValues.TotalContingency)) - (Number(calculatedValues.TotalContingencyCMS))) / (Number(calculatedValues.TotalContingencyCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalContingency)) - (Number(calculatedValues.TotalContingencyCMS))).toFixed(2)))
        },
        {
          Field: 'Discount Value',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalDiscountCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalDiscountOngoing)),
          Value5: (calculatedValues.TotalDiscountCMS !== 0 ? ((((((Number(calculatedValues.TotalDiscountOngoing)) - (Number(calculatedValues.TotalDiscountCMS))) / (Number(calculatedValues.TotalDiscountCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalDiscountOngoing)) - (Number(calculatedValues.TotalDiscountCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalDiscount)),
          Value9: (calculatedValues.TotalDiscountCMS !== 0 ? ((((((Number(calculatedActValues.TotalDiscount)) - (Number(calculatedValues.TotalDiscountCMS))) / (Number(calculatedValues.TotalDiscountCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalDiscount)) - (Number(calculatedValues.TotalDiscountCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalDiscount)),
          Value13: (calculatedValues.TotalDiscountCMS !== 0 ? ((((((Number(calculatedActValues.TotalDiscount)) - (Number(calculatedValues.TotalDiscountCMS))) / (Number(calculatedValues.TotalDiscountCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalDiscount)) - (Number(calculatedValues.TotalDiscountCMS))).toFixed(2)))

        },
        {
          Field: 'Revenue to Fortude after Discount',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.discountedRevenueToFortudeCMS)),
          Value3: '',
          Value4: this.props.location.action !== 'RPApproveWF' ?
            formatNumber(Number(calculatedValues.discountedRevenueToFortude))
            : `${formatNumber(Number(calculatedValues.discountedRevenueToFortude))} | ${formatNumber(Number(approvedCalculatedValues.discountedRevenueToFortude))}`,
          Value5: (calculatedValues.discountedRevenueToFortudeCMS !== 0 ? ((((((Number(calculatedValues.discountedRevenueToFortude)) - (Number(calculatedValues.discountedRevenueToFortudeCMS))) / (Number(calculatedValues.discountedRevenueToFortudeCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.discountedRevenueToFortude)) - (Number(calculatedValues.discountedRevenueToFortudeCMS))).toFixed(2))),

          Value7: '',
          Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Revenue)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVariance)) : 0.00,
          Value11: '',
          Value12: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Revenue)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVarPercentage)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.RevVariance)) : 0.00,
          IsMajorField: true
        },
        {
          Field: 'Average Rate per Day',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)),
          Value5: ((calculatedValues.totalEffortRM !== 0 && calculatedValues.totalEffortDaysCMS !== 0 && calculatedValues.discountedRevenueToFortudeCMS !== 0) ? ((((((Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)) - (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS))) / (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: (calculatedValues.totalEffortRM !== 0 && calculatedValues.totalEffortDaysCMS) ? (((Number(calculatedValues.discountedRevenueToFortude / calculatedValues.totalEffortRM)) - (Number(calculatedValues.discountedRevenueToFortudeCMS / calculatedValues.totalEffortDaysCMS))).toFixed(2)) : 0,

          Value7: '',
          Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRatePerDay)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVariance)) : 0.00,
          Value11: '',
          Value12: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRatePerDay)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVarPercentage)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.AvgRateVariance)) : 0.00

        },

        //------------------------------------
        {
          Field: 'Project Costs',
          Value1: '',
          Value2: '',
          Value3: '',
          isHeader: true
        },
        {
          Field: 'Total Resource Cost (Direct + Indirect)',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalResourceCostCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalResourceCostOngoing)),
          Value5: (calculatedValues.TotalResourceCostCMS !== 0 ? ((((((Number(calculatedValues.TotalResourceCostOngoing)) - (Number(calculatedValues.TotalResourceCostCMS))) / (Number(calculatedValues.TotalResourceCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalResourceCostOngoing)) - (Number(calculatedValues.TotalResourceCostCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalResourceCost)),
          Value9: (calculatedValues.TotalResourceCostCMS !== 0 ? ((((((Number(calculatedActValues.TotalResourceCost)) - (Number(calculatedValues.TotalResourceCostCMS))) / (Number(calculatedValues.TotalResourceCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalResourceCost)) - (Number(calculatedValues.TotalResourceCostCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalResourceCostTE)),
          Value13: (calculatedValues.TotalResourceCostCMS !== 0 ? ((((((Number(calculatedActValues.TotalResourceCostTE)) - (Number(calculatedValues.TotalResourceCostCMS))) / (Number(calculatedValues.TotalResourceCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalResourceCostTE)) - (Number(calculatedValues.TotalResourceCostCMS))).toFixed(2)))

        },
        {
          Field: 'Sales Commission',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalSalesCommissionCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalSalesCommissionOngoing)),
          Value5: (calculatedValues.TotalSalesCommissionCMS !== 0 ? ((((((Number(calculatedValues.TotalSalesCommissionOngoing)) - (Number(calculatedValues.TotalSalesCommissionCMS))) / (Number(calculatedValues.TotalSalesCommissionCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalSalesCommissionOngoing)) - (Number(calculatedValues.TotalSalesCommissionCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalSalesCommission)),
          Value9: (calculatedValues.TotalSalesCommissionCMS !== 0 ? ((((((Number(calculatedActValues.TotalSalesCommission)) - (Number(calculatedValues.TotalSalesCommissionCMS))) / (Number(calculatedValues.TotalSalesCommissionCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalSalesCommission)) - (Number(calculatedValues.TotalSalesCommissionCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalSalesCommission)),
          Value13: (calculatedValues.TotalSalesCommissionCMS !== 0 ? ((((((Number(calculatedActValues.TotalSalesCommission)) - (Number(calculatedValues.TotalSalesCommissionCMS))) / (Number(calculatedValues.TotalSalesCommissionCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalSalesCommission)) - (Number(calculatedValues.TotalSalesCommissionCMS))).toFixed(2)))

        },
        {
          Field: 'Total Non-Billable Expenses',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalNonBillableExpensesCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalNonBillableExpensesCMS)),
          Value5: (calculatedValues.TotalNonBillableExpensesCMS !== 0 ? ((((((Number(calculatedValues.TotalNonBillableExpensesCMS)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))) / (Number(calculatedValues.TotalNonBillableExpensesCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalNonBillableExpensesCMS)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalNonBillableExpenses)),
          Value9: (calculatedValues.TotalNonBillableExpensesCMS !== 0 ? ((((((Number(calculatedActValues.TotalNonBillableExpenses)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))) / (Number(calculatedValues.TotalNonBillableExpensesCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalNonBillableExpenses)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalNonBillableExpenses)),
          Value13: (calculatedValues.TotalNonBillableExpensesCMS !== 0 ? ((((((Number(calculatedActValues.TotalNonBillableExpenses)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))) / (Number(calculatedValues.TotalNonBillableExpensesCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalNonBillableExpenses)) - (Number(calculatedValues.TotalNonBillableExpensesCMS))).toFixed(2)))
        },
        {
          Field: 'Total Cost',
          Value1: '',
          Value2: formatNumber(Number(calculatedValues.TotalCostCMS)),
          Value3: '',
          Value4: formatNumber(Number(calculatedValues.TotalCostOngoing)),
          Value5: (calculatedValues.TotalCostCMS !== 0 ? ((((((Number(calculatedValues.TotalCostOngoing)) - (Number(calculatedValues.TotalCostCMS))) / (Number(calculatedValues.TotalCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value6: formatNumber(Number(((Number(calculatedValues.TotalCostOngoing)) - (Number(calculatedValues.TotalCostCMS))).toFixed(2))),
          Value7: '',
          Value8: formatNumber(Number(calculatedActValues.TotalCost)),
          Value9: (calculatedValues.TotalCostCMS !== 0 ? ((((((Number(calculatedActValues.TotalCost)) - (Number(calculatedValues.TotalCostCMS))) / (Number(calculatedValues.TotalCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value10: formatNumber(Number(((Number(calculatedActValues.TotalCost)) - (Number(calculatedValues.TotalCostCMS))).toFixed(2))),
          Value11: '',
          Value12: formatNumber(Number(calculatedActValues.TotalCostTE)),
          Value13: (calculatedValues.TotalCostCMS !== 0 ? ((((((Number(calculatedActValues.TotalCostTE)) - (Number(calculatedValues.TotalCostCMS))) / (Number(calculatedValues.TotalCostCMS)))) * 100).toFixed(2)) : 0) + '%',
          Value14: formatNumber(Number(((Number(calculatedActValues.TotalCostTE)) - (Number(calculatedValues.TotalCostCMS))).toFixed(2))),
          IsMajorField: true
        },

        //--------------------------------------------------------------------
        {
          Field: 'Profitability',
          Value1: '',
          Value2: '',
          Value3: '',
          isHeader: true
        },
        /*{
          Field: 'Gross margin % | Gross profit from resource deployment',
          Value1: initialCMS.projectrevenueafterdiscount ? formatNumber(Number(parseFloat(((initialCMS.grossprofitfromresourcedeployment / initialCMS.projectrevenueafterdiscount) * 100)))) + '%' : '0.00%',
          Value2: formatNumber(Number(initialCMS.grossprofitfromresourcedeployment)),
          Value3: ''
        },*/
        {
          Field: 'Gross Margin % | Gross Profit From Resource Deployment',
          Value1: calculatedValues.GrossProfitMargin,
          Value2: formatNumber(Number(calculatedValues.TotalGrossProfit.toFixed(2))),
          Value3: this.props.location.action !== 'RPApproveWF' ?
            (calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.TotalOngoingGrossProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')
            : `${(calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.TotalOngoingGrossProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')} | ${(approvedCalculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((approvedCalculatedValues.TotalOngoingGrossProfit / approvedCalculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')}`,
          Value4: this.props.location.action !== 'RPApproveWF' ?
            (calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')
            : `${(calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')} | ${(calculatedValues.TotalOngoingGrossProfit ? formatNumber(Number(calculatedValues.TotalOngoingGrossProfit)) : '0.00')}`,
          Value5: formatNumber(Number(((calculatedValues.GrossVeriance / (calculatedValues.TotalGrossProfit ? Number(calculatedValues.TotalGrossProfit) : 0.00)) * 100).toFixed(2))) + '%',
          Value6: formatNumber(Number(calculatedValues.GrossVeriance)),

          Value7: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossPercentage) * 100) : 0.00) + '%',
          Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMargin)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVariance)) : 0.00,
          Value11: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossPercentageTE) * 100) : 0.00) + '%',
          Value12: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginTE)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVarPercentageTE)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.GrossMarginVarianceTE)) : 0.00
        },
        {
          Field: 'Net Margin % | Net Profit / (Loss)',
          Value1: calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((calculatedValues.netProfitLossCMS / calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%',
          Value2: calculatedValues.netProfitLossCMS ? formatNumber(Number(calculatedValues.netProfitLossCMS)) : '0.00',
          Value3: this.props.location.action !== 'RPApproveWF' ?
            (calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.netProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')
            : `${(calculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((calculatedValues.netProfit / calculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')} | ${(approvedCalculatedValues.discountedRevenueToFortude ? formatNumber(Number(parseFloat(((approvedCalculatedValues.netProfit / approvedCalculatedValues.discountedRevenueToFortude) * 100)))) + '%' : '0.00%')}`,
          Value4: this.props.location.action !== 'RPApproveWF' ?
            (calculatedValues.netProfit ? formatNumber(Number(calculatedValues.netProfit)) : '0.00')
            : `${(calculatedValues.netProfit ? formatNumber(Number(calculatedValues.netProfit)) : '0.00')} | ${(approvedCalculatedValues.netProfit ? formatNumber(Number(approvedCalculatedValues.netProfit)) : '0.00')}`,
          Value5: (calculatedValues.netProfitLossCMS !== 0) ? formatNumber(Number(((((Number(calculatedValues.netProfit) - (Number(calculatedValues.netProfitLossCMS))) / Number(calculatedValues.netProfitLossCMS)) * 100).toFixed(2)))) + '%' : '0.00%',
          Value6: formatNumber(Number(((calculatedValues.netProfit ? (Number(calculatedValues.netProfit)) : 0.00) - (calculatedValues.netProfitLossCMS ? (Number(calculatedValues.netProfitLossCMS)) : 0.00)).toFixed(2))),

          Value7: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetPercentage) * 100) : 0.00) + '%',
          Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMargin)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVariance)) : 0.00,
          Value11: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetPercentageTE) * 100) : 0.00) + '%',
          Value12: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginTE)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVarPercentageTE)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NetMarginVarianceTE)) : 0.00,
          IsMajorField: true
        },
        {
          Field: 'Duration | Effort',
          Value1: '',
          Value2: '',
          Value3: '',
          isHeader: true
        },
        {
          Field: 'Est. Project Duration (Calendar Days)',
          Value1: calculatedValues.estDuration + ' days',
          Value2: '',
          Value3: calculatedValues.estDuration + ' days',
          Value4: '',
          Value5: '',
          Value6: '',
          Value7: (calculatedActValues !== null ? calculatedActValues.EstDuration : 0) + ' days',
          Value8: '',
          Value9: '',
          Value10: '',
          Value11: (calculatedActValues !== null ? calculatedActValues.EstDuration : 0) + ' days'
        },
        {
          Field: 'Total Contracted Effort Days | Hours',
          Value1: formatNumber(Number(calculatedValues.totalEffortDaysCMS)),
          Value2: formatNumber(Number(calculatedValues.totalEffortDaysCMS * 8)),
          Value3: this.props.location.action !== 'RPApproveWF' ?
            formatNumber(Number(calculatedValues.totalEffortRM))
            : ` ${formatNumber(Number(calculatedValues.totalEffortRM))} |  ${formatNumber(Number(approvedCalculatedValues.totalEffortRM))} `,
          Value4: this.props.location.action !== 'RPApproveWF' ?
            formatNumber(Number(calculatedValues.totalEffortRM * 8))
            : ` ${formatNumber(Number(calculatedValues.totalEffortRM * 8))} | ${formatNumber(Number(approvedCalculatedValues.totalEffortRM * 8))} `,
          Value5: (calculatedValues.totalEffortDaysCMS !== 0 ? (Number(formatNumber((((formatNumber(Number(calculatedValues.totalEffortRM)) - formatNumber(Number(calculatedValues.totalEffortDaysCMS))) / formatNumber(Number(calculatedValues.totalEffortDaysCMS))) * 100))).toFixed(2)) : 0) + '%',
          Value6: Number(formatNumber((formatNumber(Number(calculatedValues.totalEffortRM)) - Number(calculatedValues.totalEffortDaysCMS)))).toFixed(2),
          
          Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffortDays)) : 0.00,
          Value8: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffort)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVariance)) : 0.00,
          Value11: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffortDays)) : 0.00,
          Value12: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ContractedEffort)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVarPercentage)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.ConEffVariance)) : 0.00
        },
        // {
        //   Field: 'Fixed Price Effort',
        //   Value1: formatNumber(Number(calculatedValues.fixedPriceEffortApproved)),
        //   Value2: '',
        //   Value3: this.props.location.action !== 'RPApproveWF' ?
        //     formatNumber(Number(calculatedValues.fixedPriceEffortOngoing))
        //     : `${formatNumber(Number(calculatedValues.fixedPriceEffortOngoing))} | ${formatNumber(Number(approvedCalculatedValues.fixedPriceEffortOngoing))}`,
        //   Value4: '',
        //   Value5: (calculatedValues.fixedPriceEffortApproved !== 0.00 ? ((((((Number(calculatedValues.fixedPriceEffortOngoing)) - (Number(calculatedValues.fixedPriceEffortApproved))) / (Number(calculatedValues.fixedPriceEffortApproved)))) * 100).toFixed(2)) : 0.00) + '%',
        //   Value6: formatNumber(Number(((Number(calculatedValues.fixedPriceEffortOngoing)) - (Number(calculatedValues.fixedPriceEffortApproved))).toFixed(2))),

        //   Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffort)) : 0.00,
        //   Value8: '',
        //   Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffVarPercentage)) : 0.00) + '%',
        //   Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.FixedEffVariance)) : 0.00
        // },
        // {
        //   Field: 'T & M Effort',
        //   Value1: formatNumber(Number(calculatedValues.TMEffortOngoingApproved)),
        //   Value2: '',
        //   Value3: this.props.location.action !== 'RPApproveWF' ?
        //     formatNumber(Number(calculatedValues.TMEffortOngoing))
        //     : `${formatNumber(Number(calculatedValues.TMEffortOngoing))} | ${formatNumber(Number(approvedCalculatedValues.TMEffortOngoing))}`,
        //   Value4: '',
        //   Value5: (calculatedValues.TMEffortOngoingApproved !== 0.00 ? ((((((Number(calculatedValues.TMEffortOngoing)) - (Number(calculatedValues.TMEffortOngoingApproved))) / (Number(calculatedValues.TMEffortOngoingApproved)))) * 100).toFixed(2)) : 0.00) + '%',
        //   Value6: (formatNumber(Number((Number(calculatedValues.TMEffortOngoing) - Number(calculatedValues.TMEffortOngoingApproved)).toFixed(2)))),

        //   Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TMEffort)) : 0.00,
        //   Value8: '',
        //   Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TmEffVarPercentage)) : 0.00) + '%',
        //   Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.TmEffVariance)) : 0.00
        // },
        //-------------------------------------------------------------
        {
          Field: 'Billable Days | Hours',
          Value1: calculatedActValues !== null ? formatNumber(Number(calculatedValues.billableEffortDays)) : 0.00,
          Value2: calculatedValues.billableEffortDays ? formatNumber(Number(calculatedValues.billableEffortDays * 8)) : 0.00,
          Value3: calculatedValues !== null ? formatNumber(Number(calculatedValues.BillableEffortOngoing)) : 0.00,
          Value4: calculatedValues.BillableEffortOngoing ? formatNumber(Number(calculatedValues.BillableEffortOngoing * 8)) : 0.00,
          Value5: (calculatedValues.billableEffortDays !== 0 ? formatNumber(((Number(calculatedValues.BillableEffortOngoing) - Number(calculatedValues.billableEffortDays))/Number(calculatedValues.billableEffortDays)) * 100) : 0.00) + '%',
          Value6: Number(formatNumber(Number(calculatedValues.BillableEffortOngoing) - Number(calculatedValues.billableEffortDays))),

          Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Billable)) : 0.00,
          Value8: calculatedActValues.Billable ? formatNumber(Number(calculatedActValues.Billable * 8)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVariance)) : 0.00,
          Value11: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.Billable)) : 0.00,
          Value12: calculatedActValues.Billable ? formatNumber(Number(calculatedActValues.Billable * 8)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVarPercentage)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.BillVariance)) : 0.00
        },
        {
          Field: 'Non-Billable Days | Hours',
          Value1: calculatedActValues !== null ? formatNumber(Number(calculatedValues.nonBillableEffortDays)) : 0.00,
          Value2: calculatedValues.nonBillableEffortDays ? formatNumber(Number(calculatedValues.nonBillableEffortDays * 8)) : 0.00,
          Value3: calculatedValues !== null ? formatNumber(Number(calculatedValues.NonBillableEffortOngoing)) : 0.00,
          Value4: calculatedValues.NonBillableEffortOngoing ? formatNumber(Number(calculatedValues.NonBillableEffortOngoing * 8)) : 0.00,
          Value5: (calculatedValues.nonBillableEffortDays !== 0 ? formatNumber(((Number(calculatedValues.NonBillableEffortOngoing) - Number(calculatedValues.nonBillableEffortDays))/Number(calculatedValues.nonBillableEffortDays)) * 100) : 0.00) + '%',
          Value6: Number(formatNumber((formatNumber(Number(calculatedValues.NonBillableEffortOngoing)) - Number(calculatedValues.nonBillableEffortDays)))).toFixed(2),

          Value7: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NonBillable)) : 0.00,
          Value8: calculatedActValues.NonBillable ? formatNumber(Number(calculatedActValues.NonBillable * 8)) : 0.00,
          Value9: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVarPercentage)) : 0.00) + '%',
          Value10: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVariance)) : 0.00,
          Value11: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NonBillable)) : 0.00,
          Value12: calculatedActValues.NonBillable ? formatNumber(Number(calculatedActValues.NonBillable * 8)) : 0.00,
          Value13: (calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVarPercentage)) : 0.00) + '%',
          Value14: calculatedActValues !== null ? formatNumber(Number(calculatedActValues.NBVariance)) : 0.00

        }
      ];
      if (this.isMount) {
        this.setState({
          calculations: calculations
        },
          () => {
            this.forceUpdate();
          }
        );
      }
    }

  };

  toggleProjectPhase = async () => {
    await this.setState({
      projectPhaseVisible: !this.state.projectPhaseVisible,
      isEditButton: false
    });
  };

  toggleProjectSubPhase = async () => {
    await this.setState({
      projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
      selectedProjecOrCrForPhase: null
    });
  };

  onStartDateChange = async event => {
    let startDate = null;
    if (event.value === null) {
      startDate = null;
    } else {
      startDate = new Date(event.target.value);
    }

    await this.setState(prevState => {
      return {
        startDate: startDate
      };
    });

    if ((startDate && (new Date(startDate) >= new Date(this.state.startDateMin))) || startDate === null) {
      this.setState({
        startDateIsaPastDate: false
      });
    } else {
      this.setState({ startDateIsaPastDate: true });
    }
  };

  toggleMessageDialog = (message, title) => {
    this.setState({
      showMessageDialog: !this.state.showMessageDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleRateValidatioDialog = (message, title) => {
    this.setState({
      showRateValidationDialog: !this.state.showRateValidationDialog,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleDialogAllocationPercentage = async (dataItem) => {

    await this.setState({
      allocationHistoryDataItem: dataItem,
      allocationPercentageStartDate: new Date(moment(new Date(dataItem.StartDate)).format('YYYY-MM-DD')) > new Date(moment(new Date()).format('YYYY-MM-DD')) ? new Date(dataItem.StartDate) : new Date(),
      allocationHistoryMaxDate: new Date(dataItem.EndDate),
      allocationHistoryMinDate: new Date(moment(new Date(dataItem.StartDate)).format('YYYY-MM-DD')) > new Date(moment(new Date()).format('YYYY-MM-DD')) ? new Date(dataItem.StartDate) : new Date(),
      disableConfirmButton: true,
      showChangeAllocationDialog: !this.state.showChangeAllocationDialog,
    });

    if (this.state.showChangeAllocationDialog == false) {
      await this.setState({
        allocationPercentageStartDate: null,
        allocationPercentageEndDate: null,
        changedllocationPercentage: null,
        allocationPercentageEffortDays: null,
        disableConfirmButton: true
      });
    }
  };

  onClickAllocationHistory = async (dataItem) => {
    let lineAllocationHistoryList = [];
    const resourceDetails = {
      resourceRequestId: dataItem.ResourceRequestId ? dataItem.ResourceRequestId : dataItem.id,
      resourceRequestIdSub: dataItem.ResourceRequestId ? dataItem.id : null,
    };
    await getAllocationPercentageHistory(resourceDetails)
      .then(res => {
        lineAllocationHistoryList = res.data.sort(function(a,b){return new Date(a.StartDate) - new Date(b.StartDate)})
        for (let history of lineAllocationHistoryList) {
          history.EffortDays = Number(history.EffortDays).toFixed(2);
        }
        this.setState({
          lineAllocationHistoryList: lineAllocationHistoryList
        });
      }).catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });
    this.toggleAllocationHistoryDialog(dataItem);
  }

  toggleAllocationHistoryDialog = (dataItem) => {
    this.setState({
      visibleAllocationHistory: !this.state.visibleAllocationHistory
    });
  }

  updateAllocationPercentageHistory = async () => {
    await this.setState({
      disableConfirmButton: true
    });
    if (new Date(moment(this.state.allocationPercentageEndDate).format('YYYY-MM-DD')) < new Date(moment(this.state.allocationPercentageStartDate).format('YYYY-MM-DD'))) {
      this.setState({
        showSuccessImage: false,
        showRateErrorImage: true,
        disableConfirmButton: false
      });
      const message = 'The End Date cannot be earlier than the Start Date';
      const title = 'Error';
      this.toggleRateValidatioDialog(message, title);
      return;
    }
    if (new Date(moment(this.state.allocationHistoryMinDate).format('YYYY-MM-DD')) > new Date(moment(this.state.allocationPercentageStartDate).format('YYYY-MM-DD'))) {
      this.setState({
        showSuccessImage: false,
        showRateErrorImage: true,
        disableConfirmButton: false
      });
      const message = 'The Start Date cannot be earlier than the Current Date';
      const title = 'Error';
      this.toggleRateValidatioDialog(message, title);
      return;
    }
    await this.setState({
      loading: true
    });

    let lineAllocationHistoryList = [];
    let newlyAddedLines = [];
    let selectedRequest = this.state.allocationHistoryDataItem;
    const resourceRequestId = this.state.allocationHistoryDataItem && this.state.allocationHistoryDataItem.ResourceRequestId ? 
                              this.state.allocationHistoryDataItem.ResourceRequestId : this.state.allocationHistoryDataItem.id;
    const resourceRequestIdSub = this.state.allocationHistoryDataItem && this.state.allocationHistoryDataItem.ResourceRequestId ? 
                              this.state.allocationHistoryDataItem.id : null;
    const newStartDate = moment(this.state.allocationPercentageStartDate).format('YYYY-MM-DD');
    const newEndDate = moment(this.state.allocationPercentageEndDate).format('YYYY-MM-DD');
    const resourceId = this.state.allocationHistoryDataItem && this.state.allocationHistoryDataItem.ResourceId ? this.state.allocationHistoryDataItem.ResourceId : null;
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub,
    };
    await getAllocationPercentageHistory(resourceDetails)
    .then(res => {
      lineAllocationHistoryList = res.data;
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

    const obj = {
      ResourceRequestId: resourceRequestId,
      ResourceRequestSubLineId: resourceRequestIdSub,
      ResourceId: resourceId,
      StartDate: newStartDate,
      EndDate: newEndDate,
      AllocationPercentage: this.state.changedllocationPercentage
    }
    newlyAddedLines.push(obj);

    const validAlocationHistoryList = lineAllocationHistoryList.filter(record => {
      const newAllocStartDate = moment(newStartDate);
      const newAllocEndDate = moment(newEndDate);
      const recordStartDate = moment(record.StartDate);
      const recordEndDate = moment(record.EndDate);
      return newAllocStartDate.isBetween(recordStartDate, recordEndDate) || newAllocEndDate.isBetween(recordStartDate, recordEndDate) ||
      recordStartDate.isBetween(newAllocStartDate, newAllocEndDate) || newAllocStartDate.isSame(recordStartDate) || 
      newAllocEndDate.isSame(recordStartDate) || newAllocStartDate.isSame(recordEndDate)
    });

    for (const record of validAlocationHistoryList) {
      if (record.StartDate < newStartDate && record.EndDate > newEndDate) {
        const recordEndDate = record.EndDate;
        record.EndDate = moment(newStartDate).subtract(1, 'days').format('YYYY-MM-DD');
        const newObj = {
          ResourceRequestId: record.ResourceRequestId,
          ResourceRequestSubLineId: record.ResourceRequestSubLineId,
          ResourceId: resourceId,
          StartDate: moment(newEndDate).add(1, 'days').format('YYYY-MM-DD'),
          EndDate: recordEndDate,
          AllocationPercentage: record.AllocationPercentage
        }
        newlyAddedLines.push(newObj);
      } else if (record.StartDate >= newStartDate && record.EndDate <= newEndDate) {
        record.IsActive = false;
      } else if (record.StartDate <= newStartDate && record.EndDate <= newEndDate) {
        record.EndDate = moment(newStartDate).subtract(1, 'days').format('YYYY-MM-DD');
      } else if (record.StartDate >= newStartDate && record.EndDate > newEndDate) {
        record.StartDate = moment(newEndDate).add(1, 'days').format('YYYY-MM-DD');
      }
    }

    const allHistories = validAlocationHistoryList.concat(newlyAddedLines);
    if (selectedRequest.ResourceRequestId) {
      let mainLine = this.state.resourceMix.filter(
        item => item.id === selectedRequest.ResourceRequestId
      );
      selectedRequest.Type = mainLine[0].Type;
      selectedRequest.Project = mainLine[0].Project;
      selectedRequest.ChangeRequest = mainLine[0].ChangeRequest;
    }

    for (const item of allHistories) {
      await this.calculateAllocationHistoryLineEffort(item, selectedRequest);
    }

    await updateAllocationPercentageHistory(allHistories)
    .then(async res => {
      let saveRequest = [];
      await allocationChangeNotification(selectedRequest)
      .then(async res => {
      }).catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });

      await this.getCostForDesignation(selectedRequest);
      if (selectedRequest.ResourceRequestId) {
        let mainLine = this.state.resourceMix.filter(
          item => item.id === selectedRequest.ResourceRequestId
        );
        mainLine[0].ResourceRequestSubLine[selectedRequest.index] = selectedRequest;
        saveRequest = [mainLine[0]];
      } else {
        saveRequest = [selectedRequest];
      }
      await this.handleResourceSave(saveRequest);
      await this.setState({
        showChangeAllocationDialog: false,
        allocationPercentageStartDate: null,
        allocationPercentageEndDate: null,
        changedllocationPercentage: null,
        allocationPercentageEffortDays: null,
        disableConfirmButton: true
      });
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });
    await this.setState({
      loading: false
    });
  }


  deallocateAllocationPercentageHistory = async (dataItem) => {
    await this.setState({
      loading: true
    });

    let lineAllocationHistoryList = [];
    const resourceRequestId = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.ResourceRequestId : dataItem.id;
    const resourceRequestIdSub = dataItem && dataItem.ResourceRequestId ? 
                              dataItem.id : null;
    const newEndDate = moment(dataItem.ResDeallocationStartDate).format('YYYY-MM-DD');
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub,
    };
    await getAllocationPercentageHistory(resourceDetails)
    .then(res => {
      lineAllocationHistoryList = res.data;
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });

    const validAlocationHistoryList = lineAllocationHistoryList.filter(record => new Date(record.StartDate) >= new Date(newEndDate) || new Date(record.EndDate) >= new Date(newEndDate));

    for (const record of validAlocationHistoryList) {
      if (record.StartDate > newEndDate && record.EndDate > newEndDate) {
        record.IsActive = false;
      } else if (record.StartDate <= newEndDate && record.EndDate >= newEndDate) {
        record.EndDate = moment(newEndDate).format('YYYY-MM-DD')
      }
    }
    for (const item of validAlocationHistoryList) {
      await this.calculateAllocationHistoryLineEffort(item, dataItem);
    }

    await updateAllocationPercentageHistory(validAlocationHistoryList)
    .then(async res => {
      
    }).catch(err => {
      loggerService.writeLog(err, LOG_TYPES.ERROR);
    });
    await this.setState({
      loading: false
    });
  }

  calculateAllocationHistoryLineEffort = async (historyLine, request) => {

    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    let isLeave = false;
    let isHalfDay = false;

    if (request && historyLine.StartDate && historyLine.AllocationPercentage > 0) {

      let startDate = new Date(historyLine.StartDate);
      let endDate = new Date(historyLine.EndDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let effort = 0;
      let resourceCountryId = null;
      if (request.OnsiteStatus === "Offshore") {
        resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;
      } else if (request.OnsiteStatus === "Onsite") {
        resourceCountryId = request.Type === 'Project' ? request.Project.CountryId : request.ChangeRequest.CountryId;
      }
      let holidays = []; 
      holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (!holidays) {
        holidays = [];
      }
      const leaveObj = {
        ResourceId: request.ResourceId, 
        StartDate: moment(historyLine.StartDate).format('YYYY-MM-DD'), 
        EndDate: moment(historyLine.EndDate).format('YYYY-MM-DD')
      }
      let resourceLeaves = await getLeavesIntegrated(leaveObj).then(res => {
        if (res && res.data) {
            return res.data;
        }
        return [];
      })
      .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
      });
      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        isLeave = false;
        isHalfDay = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (resourceLeaves && resourceLeaves.length > 0) {
          let leaveDate = resourceLeaves.filter(
              leave => leave.LeaveDate === dateStr
          );
          if (leaveDate && leaveDate.length > 0) {
              for (const leave of leaveDate) {
                  if (leave.Duration === '8') {
                      isLeave = true;
                  } else {
                      isHalfDay = true;
                  }
              }
            }
        }

        if (!isHoliday && !isLeave && !isWeekend(loop)) {
          if (!isHalfDay) {
            totalWorkingDays = totalWorkingDays + 1;
          } else {
            totalWorkingDays = totalWorkingDays + 0.5;
          }
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }

      effort = totalWorkingDays * (historyLine.AllocationPercentage / 100);
      historyLine.EffortDays = Number((effort).toFixed(2));
    }
  };

  handlePhasesAdd = async (phaseName, startDate, projectOrCr) => {
    const cmsheetId = projectOrCr.Type === 'Project' ? projectOrCr.Project.CMSheets[0].id : projectOrCr.ChangeRequest.CMSheets[0].id;
    const phasesTemp = this.state.phasesTemp;

    if (phaseName) {
      phaseName = phaseName.trim();
      const exists = phasesTemp.filter(obj => {
        let exist = obj.Text;
        if (exist) {
          const existLC = exist.toLowerCase();
          const phaseNameLC = phaseName.toLowerCase();

          if (phaseNameLC === existLC) {
            return true;
          }
          return false;
        }
        return false;
      });
      if (exists.length > 0) {
        const message = 'Phase name already exists. Please use a unique name';
        const title = 'Name Already Exists';
        this.toggleMessageDialog(message, title);
      } else {
        phasesTemp.push({
          Text: phaseName,
          StartDate: startDate,
          SubPhases: [],
          CMSheetId: cmsheetId
        });

        this.setState({
          phasesTemp: phasesTemp,
          selectedCMSId: cmsheetId
        });
      }
    }
  };

  handlePhasesEdit = async (id, phaseName, startDate, projectOrCr) => {
    const cmsheetId = projectOrCr[0].Type === 'Project' ? projectOrCr[0].Project.CMSheets[0].id : projectOrCr[0].ChangeRequest.CMSheets[0].id;
    const phasesTemp = this.state.phasesTemp;
    if (phaseName) {
      phaseName = phaseName.trim();
      phasesTemp.filter(obj => {
        const startDateFormatted = getFormattedDate(startDate);
        if (obj.id === id) {
          obj.Text = phaseName;
          obj.StartDate = startDateFormatted;
        }
      });
      this.setState({
        phasesTemp: phasesTemp,
        selectedCMSId: cmsheetId
      });
    }
  };

  handleSubPhasesEdit = async (id, phaseName, subPhaseName, startDate, projectOrCr) => {
    const cmsheetId = projectOrCr[0].Type === 'Project' ? projectOrCr[0].Project.CMSheets[0].id : projectOrCr[0].ChangeRequest.CMSheets[0].id;
    const phasesTemp = this.state.phasesTemp;
    if (subPhaseName) {
      const startDateFormatted = getFormattedDate(startDate);
      this.state.phasesTemp[this.state.phaseEditIndex].SubPhases[this.state.subPhaseEditIndex].Text = subPhaseName;
      this.state.phasesTemp[this.state.phaseEditIndex].SubPhases[this.state.subPhaseEditIndex].StartDate = startDateFormatted;

      this.setState({
        phasesTemp: phasesTemp,
        selectedCMSId: cmsheetId
      });
    }
  };

  handlePhasesSave = async () => {
    if (this.state.phasesTemp) {
      const phasesTemp = this.state.phasesTemp;
      //call save phases API
      await savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
        .then(res => {

          this.toggleProjectPhase();
          const message = 'Successfully saved.';
          const title = 'Success';
          this.toggleMessageDialog(message, title);
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }

    await this.getPhasesAndSubPhases(this.state.project.id);
  };

  getAllocationRates = async (role, type, rateCardParamOnEdit) => {
    if (role === null) { // when use state value change to this.state.newResRole
      this.state.newRatesAll = this.rateTypesAll;
    } else {
      let customerRate, regionRate,  projectRate,projectCrRate,lastCustomRate, lastApprovedProject_Rate = 0;
      let lastCustomRateProjectName = '';
      let rateCardParams = null;
      let roleName = '';
      if (type === 'edit') {
        roleName = this.state.editableItem.dataValueProjectRole ? this.state.editableItem.dataValueProjectRole.Name : '';
        rateCardParams = {
          customerId: rateCardParamOnEdit.CustomerId,
          regionId: rateCardParamOnEdit.RegionId,
          projectId: rateCardParamOnEdit.ProjectId,
          role: role,
          projectDate: rateCardParamOnEdit.CostDate,
          dataType: rateCardParamOnEdit.Type,
          resourceId: this.state.editableItem.id
        };

      } else if (type === 'add') {
        roleName = role.Name;
        rateCardParams = {
          customerId: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.customerId : null,
          regionId: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.billingRegion : null,
          role: role.id,
          projectDate: new Date(),
          dataType: (this.state.newResProjectOrCRName ? (this.state.newResProjectOrCRName.type === 'project' ? 'projectCMS' : "crCMS") : null),
          projectId: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.type === "ChangeRequest" ? this.state.newResProjectOrCRName.ProjectId : this.state.newResProjectOrCRName.id : null
        };
      }

      await getRatesForAllocation(rateCardParams)
        .then(async result => {
          const defaultRate = result.data.defaultRate;
          const customersRate = result.data.customerRate;
          const lastCustomerRate = result.data.lastCustomerRate;
          const lastApprovedProjRate = result.data.lastApprovedrate;
          const customRate = result.data.customRate;
          const projectSpecificRate = result.data.projectSpecificRate;
          const projectSpecificCrRate = result.data.projectSpecificCrRate;
          
          //For standard rate
          if (defaultRate && defaultRate.Rate > 0) {
            regionRate = defaultRate.Rate > 0 ? defaultRate.Rate : 0;
          }
          //For pre-agreed rate
          if (customersRate && customersRate.Rate > 0) {
            customerRate = customersRate.Rate > 0 ? customersRate.Rate : 0;
          }
          //For last overridden rate
          if (lastCustomerRate && lastCustomerRate.Rate > 0) {
            lastCustomRate = lastCustomerRate.Rate;
            lastCustomRateProjectName = lastCustomerRate.Project.Name;
          }

          //For project specific rate
          if (projectSpecificRate && projectSpecificRate.Rate > 0) {
            projectRate = projectSpecificRate.Rate > 0 ? projectSpecificRate.Rate : 0;
          }
          //For project specific CR rate
          if (projectSpecificCrRate && projectSpecificCrRate.Rate > 0) {
            projectCrRate = projectSpecificCrRate.Rate > 0 ? projectSpecificCrRate.Rate : 0;
          }

          //For overridden rate
          if (lastApprovedProjRate && lastApprovedProjRate.Rate > 0) {
            lastApprovedProject_Rate = lastApprovedProjRate.Rate;
          }
         
          if (!customersRate || (customersRate.Rate && customersRate.Rate <= 0)) {
            if ((!regionRate || (regionRate.Rate && regionRate.Rate <= 0))) {
              this.setState({
                showSuccessImage: false,
                showRateErrorImage: true
              });
              const message = 'Standard rate not found for ' + roleName;
              const title = 'Error';
              this.toggleRateValidatioDialog(message, title);
            }
          }
          let tmpRates = this.rateTypesAll;
          for (let rateType of tmpRates) {
            if (rateType.Code === 'CUSTOMER_RATE') {
              rateType.Rate = customerRate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === 'REGION_RATE') {
              rateType.Rate = regionRate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === 'LAST_CUS_RATE') {
              rateType.Rate = lastCustomRate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
              rateType.LastCustomerRateProject = lastCustomRateProjectName;
            }
            if (rateType.Code === 'APPROVED_PROJ_RATE') {
              rateType.Rate = lastApprovedProject_Rate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === 'PROJECT_APPROVED_RATE') {
              rateType.Rate = projectRate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === 'PROJECT_APPROVED_CR_RATE') {
              rateType.Rate = projectCrRate;
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
            if (rateType.Code === 'CUSTOM_RATE') {
              rateType.Rate = Number(customRate);
              rateType.name = rateType.Name + ' - ' + formatNumber(rateType.Rate);
            }
          }
          //let tmpRates = this.rateTypesAll;
          if (type === 'add') {
            this.state.newRatesAll = tmpRates;
          } else if (type === 'edit') {
            const defaultRateType = tmpRates.filter(rateType => rateType.id === this.state.editableItem.dataValueRateType.id);
            const availableRateTypes = tmpRates.filter(rateType => (rateType.Rate && rateType.Rate !== 0) || rateType.Code === "ADMIN_RATE");
            defaultRateType[0].Rate = Number(this.state.editableItem.RateValue);
            defaultRateType[0].name = defaultRateType[0].Name + ' - ' + formatNumber(defaultRateType[0].Rate);
            let projcurrencyId = 0;
            let selectedProjOrCR = this.state.editableItem.Type === "Project" ? this.state.editableItem.Project : this.state.editableItem.ChangeRequest;
            let billingRegion = this.state.editableItem.Type === "Project" ? this.state.editableItem.Project.BillingRegion : this.state.editableItem.ChangeRequest.BillingRegion;
            await getRegionCurrencyIntegrated(billingRegion).then(response => {
              projcurrencyId = response.data.CurrencyId;
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
            //when the currency is != USD
            if (projcurrencyId !== 1) {
              const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                projcurrencyId,
                new Date(selectedProjOrCR.StartDate).getMonth() + 1,
                new Date(selectedProjOrCR.StartDate).getFullYear()
              );
              const rateInProjCurrency = Number(this.state.editableItem.RateValue) * Number(projrateRecordCurrencyRate);
              defaultRateType[0].Rate  = Number((rateInProjCurrency).toFixed(2));
              defaultRateType[0].name = defaultRateType[0].Name + ' - ' + formatNumber(rateInProjCurrency);
              saveFrontEndLogs("Warn","Resource Request SubLine Revenue Conversion Dropdown value",`ResourceRequestId : ${this.state.editableItem.id}, projrateRecordCurrencyRate : ${projrateRecordCurrencyRate}, Rate Before : ${this.state.editableItem.RateValue}, Rate After : ${rateInProjCurrency}`);
            }
            await this.setState({
              RateTypes: availableRateTypes,
              subLineDefaultRateType: defaultRateType[0],
              subLineDefaultRateValue: defaultRateType[0].Rate ? defaultRateType[0].Rate : 0
            });
          }
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  // For actual, forecast breakdown
  formatValueEightCell = props => {
    if (props.dataItem.Field == 'Est. Project Duration (Calendar Days)') {
      return (
        <td>
          <td id="EstProjDurationToolTip" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value8}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="EstProjDurationToolTip"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {this.state.calculatedActValues.EstDurationAct  + ' days'}</span>
              <br />
              <span>Forecast: {this.state.calculatedActValues.EstDurationFt + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if ((props.dataItem.Field == 'Revenue to Fortude after Discount') && this.state.project.PaymentMethodRecord && this.state.project.PaymentMethodRecord.id == 2) {
      return (
        <td>
          <td id="RevenuetoFortudeToolTip" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value8}
          </td>

          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="RevenuetoFortudeToolTip"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {this.state.calculatedActValues.RevenueAct}</span>
              <br />
              <span>Forecast: {this.state.calculatedActValues.RevenueFt}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    else {
      return (
        <td className= {"summary-grid-text-align"}>
          {props.dataItem.Value8}
        </td>
      );
    }
  };

    // For actual, forecast breakdown
    formatValueTwelveCell = props => {
      if ((props.dataItem.Field == 'Revenue to Fortude after Discount') && this.state.project.PaymentMethodRecord && this.state.project.PaymentMethodRecord.id == 2) {
        return (
          <td>
            <td id="RevenuetoFortudeToolTipV4" className="summary-grid-text-align-tooltip">
              {props.dataItem.Value12}
            </td>
  
            <UncontrolledTooltip
              modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              target="RevenuetoFortudeToolTipV4"
              placement="bottom"
              style={{ maxWidth: '300px' }}
            >
              <div className="text-left p-1">
                <span>Actual: {this.state.calculatedActValues.RevenueAct}</span>
                <br />
                <span>Forecast: {this.state.calculatedActValues.RevenueFt}</span>
              </div>
            </UncontrolledTooltip>
          </td>
        );
      }
      if ((props.dataItem.Field == 'Total Resource Cost (Direct + Indirect)')) {
        return (
          <td>
            <td id="TotalResourceCostToolTipV4" className="summary-grid-text-align-tooltip">
              {props.dataItem.Value12}
            </td>
  
            <UncontrolledTooltip
              modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
              target="TotalResourceCostToolTipV4"
              placement="bottom"
              style={{ maxWidth: '300px' }}
            >
              <div className="text-left p-1">
                <span>Actual: {this.state.calculatedActValues.TotalCostTEAct}</span>
                <br />
                <span>Forecast: {this.state.calculatedActValues.TotalCostTEFt}</span>
              </div>
            </UncontrolledTooltip>
          </td>
        );
      }
      else {
        return (
          <td className= {"summary-grid-text-align"}>
            {props.dataItem.Value12}
          </td>
        );
      }
    };
  

  formatValueSevenCell = props => {
    if (props.dataItem.Field == 'Total Contracted Effort Days | Hours') {
      return (
        <td>
          <td id="ContractedEffort" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value7}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="ContractedEffort"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.ContractedEffortAct).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.ContractedEffortFt).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'Fixed Price Effort') {
      return (
        <td>
          <td id="FixedEffort">
            {props.dataItem.Value7}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="FixedEffort"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.FixedEffortAct).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.FixedEffortFt).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'T & M Effort') {
      return (
        <td>
          <td id="TMEffort">
            {props.dataItem.Value7}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="TMEffort"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.TMEffortAct).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.TMEffortFt).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'Billable Days | Hours') {
      return (
        <td>
          <td id="Billable" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value7}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="Billable"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.BillApproveTimeSum).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.BillLineFutureAllocSum).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'Non-Billable Days | Hours') {
      return (
        <td>
          <td id="NonBillable" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value7}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="NonBillable"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.NBApproveTimeSum).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.NBLineFutureAllocSum).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    else {
      return (
        <td className= {"summary-grid-text-align"}>
          {props.dataItem.Value7}
        </td>
      );
    }
  };

  formatValueElevenCell = props => {
    if (props.dataItem.Field == 'Total Contracted Effort Days | Hours') {
      return (
        <td>
          <td id="ContractedEffortV4" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value11}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="ContractedEffortV4"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.ContractedEffortAct).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.ContractedEffortFt).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'Billable Days | Hours') {
      return (
        <td>
          <td id="BillableV4" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value11}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="BillableV4"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.BillApproveTimeSum).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.BillLineFutureAllocSum).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    if (props.dataItem.Field == 'Non-Billable Days | Hours') {
      return (
        <td>
          <td id="NonBillableV4" className="summary-grid-text-align-tooltip">
            {props.dataItem.Value11}
          </td>
        
          <UncontrolledTooltip
            modifiers={{ preventOverflow: { boundariesElement: 'window' } }}
            target="NonBillableV4"
            placement="bottom"
            style={{ maxWidth: '300px' }}
          >
            <div className="text-left p-1">
              <span>Actual: {Number(this.state.calculatedActValues.NBApproveTimeSum).toFixed(2)  + ' days'}</span>
              <br />
              <span>Forecast: {Number(this.state.calculatedActValues.NBLineFutureAllocSum).toFixed(2) + ' days'}</span>
            </div>
          </UncontrolledTooltip>
        </td>
      );
    }
    else {
      return (
        <td className= {"summary-grid-text-align"}>
          {props.dataItem.Value11}
        </td>
      );
    }
  };

  addPhase = async () => {
    this.setState({
      isAddDone: true
    });
    if (!this.state.isEditButton) {
      let startDateStr = null;
      let selectedProjectOrCr = null;
      if (this.state.phaseStartDate) {
        startDateStr = getFormattedDate(this.state.phaseStartDate);
      }

      if (this.state.selectedProjecOrCrForPhase) {
        selectedProjectOrCr = this.state.selectedProjecOrCrForPhase;
      }
      this.handlePhasesAdd(this.state.phaseName, startDateStr, selectedProjectOrCr);

      this.state.cancelDataPhaseName = this.state.phaseName;
      this.state.cancelDataProjectId = this.state.selectedProjecOrCrForPhase;

      this.setState({
        phaseName: '',
        startDate: '',
        selectedProjecOrCrForPhase: null,
        phaseStartDate: null,
        startDateIsaPastDate: false
      });
    } else {
      this.handlePhasesEdit(this.state.phaseSubPhaseId, this.state.phaseName, this.state.phaseStartDate, this.state.projectAndCrData);

      await this.setState({
        isSaveButtonVisibleEdit: true,
        phaseName: '',
        startDate: '',
        selectedProjecOrCrForPhase: null,
        phaseStartDate: null,
        startDateIsaPastDate: false,
        isEditButton: false
      });
    }
  };

  handlePhasesRemove = async (index, value) => {
    if (index > -1) {
      this.setState(
        {
          phaseRemoveIndex: index,
          selectedCMSId: value.CMSheetId
        },
        () => {
          const message =
            'By removing the phase, any sub phases linked to this will be removed as well as any relationships made with the resource and expenses will be removed. Do you want to continue?';
          this.toggleConfirmDialog(message, this.removePhase);
        }
      );
    }
  };

  handleResourceRemove = async () => {
    if (this.state.userDefinedEndDate === null) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please insert an End date to proceed', 'Error');
    } else {
      if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
        //update project
        this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
        updateProject(this.state.project, this.state.project.id)
          .then(res => {
            this.onUserDefinedEndDateChange();
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }


      this.setState(
        {
          isResurceRequestRemove: true
        },
        () => {
          const message =
            'Are you sure you want to remove the selected line(s) from resource request?';
          this.toggleConfirmDialog(message, this.removeResourceRequest);
        }
      );

    }

    this.submitSearch();
  };

  handleResourceDeallocate = async () => {
    let selectedSubLine = [];

    if (this.state.userDefinedEndDate === null) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please insert an End date to proceed', 'Error');
    } else {
      let selectedMainLines = this.state.selectedResources;
      let selectedSubLines = this.state.selectedResourcesSubLine;
      let allSelectedLines = [];

      if(selectedMainLines && selectedMainLines.length > 0){
        for(let el of selectedMainLines){
          allSelectedLines.push(el);
        }
      }
      if(selectedSubLines && selectedSubLines.length > 0){
        for(let el of selectedSubLines){
          allSelectedLines.push(el);
        }
      }

      // Set date range - Date picker
      if(allSelectedLines && allSelectedLines.length > 0){
        if(allSelectedLines.length === 1){
          // Selected one resource to deallocate
          let startDate = moment(new Date()).format('YYYY-MM-DD');
          let endDate = (allSelectedLines[0].EndDate && allSelectedLines[0].EndDate !== null) ? (moment(allSelectedLines[0].EndDate).format('YYYY-MM-DD')) : null;

          // Set start date
          this.state.resDeallocationStartDate = new Date(startDate);

          // Set end date
          this.state.resDeallocationEndDate = new Date(endDate);
        }
        else if(allSelectedLines.length > 1){
          // Selected more than one resource to deallocate
          let startDate = moment(new Date()).format('YYYY-MM-DD');
          let endDate = (allSelectedLines[0].EndDate && allSelectedLines[0].EndDate !== null) ? (moment(allSelectedLines[0].EndDate).format('YYYY-MM-DD')) : null;

          // Set start date
          this.state.resDeallocationStartDate = new Date(startDate);

          // Get minimum resource end date
          for(let res of allSelectedLines){
            if(moment(res.EndDate).format('YYYY-MM-DD') < endDate){
              endDate = moment(res.EndDate).format('YYYY-MM-DD');
            }
          }

          // Set end date
          this.state.resDeallocationEndDate = new Date(endDate);
        }
      }

      if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
        //update project
        this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
        updateProject(this.state.project, this.state.project.id)
          .then(res => {
            this.onUserDefinedEndDateChange();
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
      }

      this.setState(
        {
          isResurceRequestDeallocate: true,
        },
        () => {
          const message = 'By deallocation, you will be removing the allocated resource from the project. You will no longer be able to use this line for further work. Are you sure you want to remove this?';
          this.toggleDeallocationConfirmDialog(message, this.deallocateResourceRequest);
        }
      );

    }

    this.submitSearch();
  };

  handleResourcesSave = async () => {
    if (this.props.location.action !== 'RPApprove') {
      if (this.state.userDefinedEndDate === null) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please insert an End date to proceed', 'Error');
      } else {
        if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
          //update project
          this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
          updateProject(this.state.project, this.state.project.id)
            .then(res => {
              this.onUserDefinedEndDateChange();
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
        await this.setState(
          {
            isClickSaveButton: true
          },
        );
        await this.handleResourceSave(this.state.resourceMix);
        if (this.state.resourcesSaved === true) {
          this.setState({
            showSuccessImage: true,
            showErrorImage: false
          });
          this.toggleDialog(
            ` Successfully saved`,
            'Success'
          );
        }
      }
    } else {
      await this.setState(
        {
          isClickSaveButton: true
        },
      );
      await this.handleResourceSave(this.state.resourceMix);
    }

    this.submitSearch();
  };

  handleResourceSave = async (resourcesToSave) => {

    if (this.state.isClickSaveButton === true) {
      if (this.props.location.action === 'RPApprove') {
        let objsToSend = [];
        const selectedResources = resourcesToSave;
        if (selectedResources && selectedResources.length > 0) {
          for (const each of selectedResources) {
            each.Status = 7;
            objsToSend.push(each);
          }

          await saveResourceAllocation(resourcesToSave)
            .then(res => {
              this.setState({
                showSuccessImage: true,
                showErrorImage: false
              });

              this.toggleDialog(
                ` Successfully saved`,
                'Success'
              );
              this.getInitialData()
              this.forceUpdate()
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.toggleDialog(error.response.data, 'Error');
            });
        }
      } else {
        this.setState({
          resourcesSaved: false
        });
        console.log("main line's status change",resourcesToSave);
        await saveResourceRequests(resourcesToSave)
          .then(res => {
            this.setState({
              resourcesSaved: true
            });

            this.getInitialData();
            this.forceUpdate();
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            this.toggleDialog(error.response.data, 'Error');
          });

        this.getInitialData()
        this.forceUpdate()

      }
      this.ticked = [];
      this.allocationId = [];
      this.sublineTicked = [];
      this.setState({
        hasSubLines: false,
        confirmStatus: false,
        confirmStatusSub: false
      })
    }
    else {
      if (this.props.location.action === 'RPApprove') {
        let objsToSend = [];
        const selectedResources = resourcesToSave;
        if (selectedResources && selectedResources.length > 0) {
          for (const each of selectedResources) {
            each.Status = 7;
            objsToSend.push(each);
          }

          await saveResourceAllocation(resourcesToSave)
            .then(res => {
              this.getInitialData()
              this.forceUpdate()
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
              this.toggleDialog(error.response.data, 'Error');
            });
        }
      } else {

        console.log("main line's status change",resourcesToSave);
        await saveResourceRequests(resourcesToSave)
          .then(async res => {
            if (this.state.mainLineAsSubLine) {
              await addMainLineHistoryToSubLine(this.state.mainLineAsSubLine);
              await this.setState({
                mainLineAsSubLine: null
              })
            }
            this.getInitialData()
            this.forceUpdate()
          })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
            this.toggleDialog(error.response.data, 'Error');
          });
      }
      this.ticked = [];
      this.allocationId = [];
      this.sublineTicked = [];
      this.setState({
        hasSubLines: false,
        confirmStatus: false,
        confirmStatusSub: false
      })
    }
    this.setState({
      managerCommentCMS: '',
      addedSubLinesToGrid: false,
      mainLineAsSubLine: null,
      outsourcedCost: null
    })
  };

  confirmSelectedResources = async () => {
    if (this.props.location.action !== 'RPApprove') {
      if (this.state.userDefinedEndDate === null) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please insert an End date to proceed', 'Error');
      } else {
        if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
          //update project
          this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
          updateProject(this.state.project, this.state.project.id)
            .then(res => {
              this.onUserDefinedEndDateChange();
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
        this.resourceConfirm();
      }
    } else {
      this.resourceConfirm();
    }

    this.submitSearch();
  };

  resourceConfirm = async () => {

    let currentRequests = this.state.selectedResources;
    let currentSubLines = this.state.selectedResourcesSubLine;

    let containsNew = false;
    let currentRequestsIds = [];
    let currentSubLinesIds = [];

    if (currentRequests && currentRequests.length > 0) {
      for (let resource of currentRequests) {
        if (!resource.hasOwnProperty('id')) {
          containsNew = true;
          break;
        } else {
          currentRequestsIds.push(resource.id);
        }

      }
    }

    if (currentSubLines && currentSubLines.length > 0) {
      for (let resource of currentSubLines) {
        if (!resource.hasOwnProperty('id')) {
          containsNew = true;
          break;
        } else {
          currentSubLinesIds.push(resource.id);
        }
      }
    }

    if (containsNew) {
      const message = 'You have unsaved requests. Please save before confirm'; //otherwise row will not have ID
      const title = 'Unsaved data';
      this.toggleMessageDialog(message, title);
    } else {
      const confirmObj = {
        mainLines: currentRequestsIds,
        subLines: currentSubLinesIds
      };

      await updateStatusToConfirm(confirmObj).then(res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false
        });
        this.toggleDialog(
          ` Successfully confirmed`,
          'Success'
        );
        this.getInitialData();
      })
        .catch(error => {
          console.log(error);
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

    }
    this.ticked = [];
    this.sublineTicked = [];
    this.allocationId = [];
    this.setState({
      hasSubLines: false,
      confirmStatus: false,
      confirmStatusSub: false,
      requestAllocationSoftDate: false,
      requestAllocationSoftSubDate: false,
      requestAllocationSub: false,
      requestAllocation: false,
      requestAllocationSoft: false,
      requestAllocationSubSoft: false,
      requestAllocationSubSoftResubmit: false,
      requestAllocationSoftResubmit: false,
      selectedResources: [],
      selectedResourcesSubLine: []
    })
  };

  EditPhase = async (index, value) => {
    this.setState(
      {
        isEditButton: true
      }
    );
    const projectAndCrData = this.state.projectAndCrData;
    projectAndCrData.filter(obj => {
      if (obj.Type === 'Project') {
        if (obj.Project.CMSheets[0].id === value.CMSheetId) {
          this.setState(
            {
              minPhaseDate: obj.Project.StartDate ? new Date(obj.Project.StartDate) : new Date(obj.Project.currentDate),
              selectedProjecOrCrForPhase: obj
            }
          );
        }
      } else if (obj.Type === 'ChangeRequest') {
        if (obj.ChangeRequest.CMSheets[0].id === value.CMSheetId) {
          this.setState(
            {
              minPhaseDate: obj.ChangeRequest.StartDate ? new Date(obj.ChangeRequest.StartDate) : new Date(obj.ChangeRequest.currentDate),
              selectedProjecOrCrForPhase: obj
            }
          );
        }
      }
    });

    if (index > -1) {
      const phasesTemp = this.state.phasesTemp;
      await this.setState(
        {
          phaseEditIndex: index,
          selectedCMSId: value.CMSheetId,
          phaseSubPhaseId: value.id,
          phaseName: phasesTemp[index].Text,
          phaseStartDate: phasesTemp[index].StartDate ? new Date(phasesTemp[index].StartDate) : null
        }
      );
    }
  };

  EditSubPhase = async (phaseIndex, subPhaseIndex, value) => {
    const phasesTemp = this.state.selectedProjectOrCrPhases;
    this.setState(
      {
        isEditButton: true,
        isSubPhaseEdit: true
      }
    );
    const projectAndCrData = this.state.projectAndCrData;
    projectAndCrData.filter(obj => {
      if (obj.Type === 'Project') {
        if (obj.Project.CMSheets[0].id === value.CMSheetId) {
          this.setState(
            {
              minSubPhaseDate: obj.Project.StartDate ? new Date(obj.Project.StartDate) : new Date(obj.Project.currentDate),
              selectedProjecOrCrForPhase: obj.Name
            }
          );
        }
      } else if (obj.Type === 'ChangeRequest') {
        if (obj.ChangeRequest.CMSheets[0].id === value.CMSheetId) {
          this.setState(
            {
              minSubPhaseDate: obj.ChangeRequest.StartDate ? new Date(obj.ChangeRequest.StartDate) : new Date(obj.ChangeRequest.currentDate),
              selectedProjecOrCrForPhase: obj.Name
            }
          );
        }
      }
    });

    if (phaseIndex > -1 && subPhaseIndex > -1 && phasesTemp[phaseIndex] && phasesTemp[phaseIndex].SubPhases[subPhaseIndex]) {
      await this.setState(
        {
          phaseEditIndex: phaseIndex,
          subPhaseEditIndex: subPhaseIndex,
          selectedCMSId: value.CMSheetId,
          phaseSubPhaseId: value.id,
          subPhaseName: phasesTemp[phaseIndex].SubPhases[subPhaseIndex].Text,
          subPhaseStartDate: phasesTemp[phaseIndex].SubPhases[subPhaseIndex].StartDate ? new Date(phasesTemp[phaseIndex].SubPhases[subPhaseIndex].StartDate) : null
        }
      );
      this.state.selectedProjectOrCrPhases[this.state.selectedPhaseIndex] = phasesTemp[phaseIndex].Text;
    }
  };

  handleSubPhasesRemove = async (phaseIndex, subPhaseIndex) => {
    const phasesTemp = this.state.selectedProjectOrCrPhases;

    if (phaseIndex > -1 && subPhaseIndex > -1 && phasesTemp[phaseIndex] && phasesTemp[phaseIndex].SubPhases[subPhaseIndex]) {
      this.setState(
        {
          subPhaseRemovePhaseIndex: phaseIndex,
          subPhaseRemoveSubPhaseIndex: subPhaseIndex
        },
        () => {
          const message =
            'By removing the sub phase, any relationships made with the resource and expenses will be removed. Do you want to continue?';
          this.toggleConfirmDialog(message, this.removeSubPhase);
        }
      );
    }
  };

  removePhase = async () => {
    const phasesTemp = this.state.phasesTemp;
    phasesTemp.splice(this.state.phaseRemoveIndex, 1);

    this.setState(
      {
        phasesTemp: phasesTemp
      },
      () => {
        this.toggleConfirmDialog('', null);
      }
    );

    if (this.state.phasesTemp) {
      const phasesTemp = this.state.phasesTemp;
      //call save phases API
      savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
        .then(res => {

          const message = 'Successfully saved.';
          const title = 'Success';
          this.toggleMessageDialog(message, title);
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  removeResourceRequest = async () => {
    const IdList = this.getCMSheetVersionAndIdList();
    await getResourceRequestResourceMixData(IdList).then(response => {
      const res = response.data;

      this.setState({
        forRemoveResource: res,
        DeleteResourceRequest: true,
      });

    });


    let currentRequests = this.state.selectedResources;
    let Requests = this.state.forRemoveResource[0];
    let deletedIds = [];
    let deletedIndex = [];
    let Type = null;
    let id = null;
    let reasourceAll = this.state.forRemoveResource[0];
    let currentSubLine = this.state.selectedResourcesSubLine;
    let deletedSubIds = [];
    let deletedSubIndex = [];

    this.forceUpdate();

    for (let i = 1; i < this.state.forRemoveResource.length; i++) {
      reasourceAll = reasourceAll.concat(this.state.forRemoveResource[i])
    }

    if (currentRequests) {
      await currentRequests.map(function (obj) {
        if (obj.selected) {
          Type = obj.Type;
          id = obj.Project ? obj.Project.id : obj.ChangeRequest.id;
          deletedIds.push(obj.id);
          deletedIndex.push(obj.index);
        }
      });
    }


    await this.setState({
      resourceMix: reasourceAll
    });

    for (let i = deletedIndex.length - 1; i >= 0; i--) {
      this.state.resourceMix.splice(deletedIndex[i], 1);
    }


    if (this.state.isCheckedSubline) {
      if (currentSubLine && currentSubLine.length > 0) {
        await currentSubLine.map(function (obj) {
          if (obj.selected) {
            deletedSubIds.push(obj.id);
            deletedSubIndex.push(obj.index);
          }
        });
      



      for (let i = deletedSubIndex.length - 1; i >= 0; i--) {
        for (const resourcemix of this.state.resourceMix) {
          for (const sub of resourcemix.ResourceRequestSubLine) {
            if (sub.index === deletedSubIndex[i]) {
              resourcemix.ResourceRequestSubLine.splice(deletedSubIndex[i], 1);
            }
          }
        }
      }


      await deleteRequestSubLine(deletedSubIds)
        .then(res => {
          this.getInitialData();
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleMessageDialog(error.response.data, 'Error');
        });
      }
    }

    await deleteRequest(deletedIds, Type, id)
      .then(res => {
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleMessageDialog(error.response.data, 'Error');
      });


    this.toggleConfirmDialog('', null);
    this.toggleMessageDialog('Successfully Deleted', 'Success');

    this.submitSearch();

    this.setState({
      DeleteResourceRequest: false,
      selectedResources: [],
      selectedResourcesSubLine: []
    });
  };

  calculateMainLineEffort = async (request) => {

    await this.setState({
      isLoading: true
    });
    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    let totalHolidays = 0;

    if (request && request.StartDate && request.AllocationPercentage > 0) {

      let startDate = new Date(request.StartDate);
      let endDate = this.state.deallocationStartDate ? new Date(this.state.deallocationStartDate) : new Date(request.EndDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      var effort = 0;
      var totalDays = 0;
      var effortHours = 0;

      if (request.OnsiteStatus === "Offshore") {
        const resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;
        let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

        if (holidays && holidays.length === 0) {
        } else if (!holidays) {
          holidays = [];
        }

        let loop = new Date(startDate);
        while (loop <= endDate) {
          isHoliday = false;
          const dateStr = getFormattedDate(loop);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (!isHoliday && !isWeekend(loop)) {
            totalWorkingDays++;
          }
          else if(isHoliday || isWeekend(loop)){
            totalHolidays++;
          }

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        effort = totalWorkingDays * (request.AllocationPercentage / 100);
        totalDays = totalWorkingDays + totalHolidays;
        effortHours = effort * 8;

      } else if (request.OnsiteStatus === "Onsite") {
        const resourceCountryId = request.Project ? request.Project.CountryId : request.ChangeRequest.CountryId;
        let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

        if (holidays && holidays.length === 0) {
        } else if (!holidays) {
          holidays = [];
        }

        let loop = new Date(startDate);
        while (loop <= endDate) {
          isHoliday = false;
          const dateStr = getFormattedDate(loop);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (!isHoliday && !isWeekend(loop)) {
            totalWorkingDays++;
          }
          else if(isHoliday || isWeekend(loop)){
            totalHolidays++;
          }

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        effort = totalWorkingDays * (request.AllocationPercentage / 100);
        totalDays = totalWorkingDays + totalHolidays;
        effortHours = effort * 8;
      }

      request.EffortDays = effort;
      request.TotalEffortDays = effort;
      request.EffortHours = effortHours;
      request.WorkDays = effort;
      request.TotalDays = totalDays;

      await this.setState({
        isLoading: false,

      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  };

  calculateSubLineEffort = async (request) => {

    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    if (request) {
      let startDate = new Date(request.StartDate);
      let endDate = new Date(this.state.deallocationStartDate);

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const resourceCountryId = request.dataValueResourceRegion ? request.dataValueResourceRegion.DefaultCountryId : null;

      let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (holidays && holidays.length === 0) {
      } else if (!holidays) {
        holidays = [];
      }

      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (!isHoliday && !isWeekend(loop)) {
          totalWorkingDays++;
        } else {
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      const effort = totalWorkingDays * (request.AllocationPercentage / 100);

      request.EffortDays = effort;
    }
  };


  deallocateResourceRequest = async () => {
    await this.setState({
      isResurceRequestDeallocate: true
    });

    let currentRequests = this.state.selectedResources;
    let currentSubLines = this.state.selectedResourcesSubLine;
    let emailsForMainLines = [];
    let mainLinesToBeUpdated = [];
    let mainLinesToBeDeleted = [];

    let emailsForSubLines = [];
    let subLinesToBeUpdated = [];
    let subLinesToBeDeleted = [];

    if (currentRequests) {
      for (const request of currentRequests) {
        request.ResDeallocationStartDate = moment(this.state.deallocationStartDate).format('YYYY-MM-DD');
        emailsForMainLines.push(request);

        if(request.ResDeallocationStartDate >= request.StartDate){
          //update data
          await this.deallocateAllocationPercentageHistory(request);
          await this.calculateMainLineEffort(request);
          await this.getCostForDesignation(request);
          mainLinesToBeUpdated.push(request);
        }
        else{
          const IdList = this.getCMSheetVersionAndIdList();

          await getResourceRequestResourceMixData(IdList).then(response => {
            const res = response.data;
            this.setState({
              forRemoveResource: res,
              DeleteResourceRequest: true
            });
          });

          let currentRequests = request;
          let deletedIds = [];
          let deletedIndex = [];
          let Type = null;
          let id = null;
          let reasourceAll = this.state.forRemoveResource[0];
          let currentSubLine = this.state.selectedResourcesSubLine;

          this.forceUpdate();

          for (let i = 1; i < this.state.forRemoveResource.length; i++) {
            reasourceAll = reasourceAll.concat(this.state.forRemoveResource[i])
          }

          if (currentRequests) {
            if (currentRequests.selected) {
              Type = currentRequests.Type;
              id = currentRequests.Project ? currentRequests.Project.id : currentRequests.ChangeRequest.id;
              deletedIds.push(currentRequests.id);
              deletedIndex.push(currentRequests.index);
            }
          }

          await this.setState({
            resourceMix: reasourceAll
          });

          for (let i = deletedIndex.length - 1; i >= 0; i--) {
            this.state.resourceMix.splice(deletedIndex[i], 1);
          }

          if (this.state.isCheckedSubline) {
            if (currentSubLine) {
              await currentSubLine.map(function (obj) {
                if (obj.selected) {
                  deletedIds.push(obj.id);
                  deletedIndex.push(obj.index);
                }
              });
            }

            for (let i = deletedIndex.length - 1; i >= 0; i--) {
              for (const resourcemix of this.state.resourceMix) {
                for (const sub of resourcemix.ResourceRequestSubLine) {
                  if (sub.index === deletedIndex[i]) {
                    resourcemix.ResourceRequestSubLine.splice(deletedIndex[i], 1);
                  }
                }
              }
            }
          }

          let mainLineItem = {
            deletedIds: deletedIds,
            type: Type,
            id: id
          };

          mainLinesToBeDeleted.push(mainLineItem);
        }
      }
    }
    this.forceUpdate();

    if (this.state.isCheckedSubline) {
      if (currentSubLines) {
        for (const request of currentSubLines) {
          request.ResDeallocationStartDate = moment(this.state.deallocationStartDate).format('YYYY-MM-DD');

          for (const resource of this.state.resourceMix) {
            if (request.ResourceRequestId == resource.id) {
              let sublines = resource.ResourceRequestSubLine;

              for(let subline of sublines){
                if(subline.id == request.id){
                  subline.SelectedDeallocationLine = true;
                  subline.ResDeallocationStartDate = moment(this.state.deallocationStartDate).format('YYYY-MM-DD');
                }
                else{
                  subline.SelectedDeallocationLine = false;
                }
              }
              emailsForSubLines.push(resource);
            }
          }

          if(request.ResDeallocationStartDate >= request.StartDate){
            //update data
            await this.deallocateAllocationPercentageHistory(request);
            await  this.calculateSubLineEffort(request);
            await this.getCostForDesignation(request);
            subLinesToBeUpdated.push(request);
          }
          else{
            const IdList = this.getCMSheetVersionAndIdList();

            await getResourceRequestResourceMixData(IdList).then(response => {
              const res = response.data;
              this.setState({
                forRemoveResource: res,
                DeleteResourceRequest: true
              });
            });

            let currentRequests = this.state.selectedResources;
            let deletedIds = [];
            let deletedIndex = [];
            let Type = null;
            let id = null;
            let reasourceAll = this.state.forRemoveResource[0];
            let currentSubLine = request;

            this.forceUpdate();

            for (let i = 1; i < this.state.forRemoveResource.length; i++) {
              reasourceAll = reasourceAll.concat(this.state.forRemoveResource[i])
            }

            if (currentRequests) {
              await currentRequests.map(function (obj) {
                if (obj.selected) {
                  Type = obj.Type;
                  id = obj.Project ? obj.Project.id : obj.ChangeRequest.id;
                  deletedIds.push(obj.id);
                  deletedIndex.push(obj.index);
                }
              });
            }

            await this.setState({
              resourceMix: reasourceAll
            });

            for (let i = deletedIndex.length - 1; i >= 0; i--) {
              this.state.resourceMix.splice(deletedIndex[i], 1);
            }

            if (this.state.isCheckedSubline) {
              if (currentSubLine) {
                if (currentSubLine.selected) {
                  deletedIds.push(currentSubLine.id);
                  deletedIndex.push(currentSubLine.index);
                }
              }

              for (let i = deletedIndex.length - 1; i >= 0; i--) {
                for (const resourcemix of this.state.resourceMix) {
                  for (const sub of resourcemix.ResourceRequestSubLine) {
                    if (sub.index === deletedIndex[i]) {
                      resourcemix.ResourceRequestSubLine.splice(deletedIndex[i], 1);
                    }
                  }
                }
              }

              subLinesToBeDeleted.push(deletedIds);
            }
          }
        }
        this.forceUpdate();
      }
    }

    let items = {
      emailsForMainLines: emailsForMainLines,
      mainLinesToBeUpdated: mainLinesToBeUpdated,
      mainLinesToBeDeleted: mainLinesToBeDeleted,
      emailsForSubLines: emailsForSubLines,
      subLinesToBeUpdated: subLinesToBeUpdated,
      subLinesToBeDeleted: subLinesToBeDeleted
    };

    deallocateResourceRequestItems(items)
    .then(res => {
      this.getInitialData();
    })
    .catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });
    this.forceUpdate();


    this.toggleDeallocationConfirmDialog('', null);
    this.toggleMessageDialog('Successfully Deallocated', 'Success');
    this.submitSearch();

    this.setState({
      DeleteResourceRequest: false,
      selectedResources: [],
      selectedResourcesSubLine: []
    });
  };


  submitResourcesForSoftBooking = async event => {

    let selectedLines = [];
    let selectedSubLines = [];
    let Type = null;
    let id = null;

    this.submitSoftBookingToggle();

    await this.setState({
      IsSoftBookingResorce: true,
    });

    let currentRequests = this.state.selectedResources;
    let currentSubLines = this.state.selectedResourcesSubLine;

    if (currentRequests) {
      await currentRequests.map(function (obj) {
        if (obj.selected) {
          obj.ToStatus = 7;
          selectedLines.push(obj);
        }
      });
    }
    this.forceUpdate();

    if (this.state.isCheckedSubline) { // Sublines
      if (currentSubLines) {
        await currentSubLines.map(function (obj) {
          if (obj.selected) {
            obj.ToStatus = 7;
            selectedSubLines.push(obj);
          }
        });
      }
      this.forceUpdate();
      this.updateLineHistory(selectedLines, selectedSubLines);
      await submitForResourceSoftBookSubLine(selectedSubLines)
        .then(res => {
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleMessageDialog(error.response.data, 'Error');
        });
    }

    if(selectedLines.length > 0){ // Main lines
      this.updateLineHistory(selectedLines, selectedSubLines);
      submitForResourceSoftBook(selectedLines)
      .then(response => {

        this.getInitialData();

      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
      });
    }

    let message = 'Selected resources was sent for soft booking.';
    const title = 'Success';
    this.setState({
      showSuccessImage: true,
      showErrorImage: false,
      isSubmittedSoftBook: true,
      selectedResources: [],
      selectedResourcesSubLine: [],
      managerComment: ''
    });
    this.toggleDialog(message, title);

    this.submitSearch();
  };


  toggleOutsourcedCostDialog = () => {
    this.setState({
      showOutsourcedCostDialog: !this.state.showOutsourcedCostDialog
    });
  };

  toggleOutsourcedCostCloseDialog = async() => {
    const message = 'Please enter a outsource cost value to continue.';
    const title = 'Error';
    await this.setState({
      showSuccessImage: false,
      showRateErrorImage: true
    });

    this.toggleRateValidatioDialog(message, title);
  };

  handleOutsourcedCostInputChange = async (event) => {
    const value = event.target.value;
    await this.setState({
      outsourcedCost: value
    });
  };

  confirmOutsourcedCost = async () => {
    this.setState({
      showSuccessImage: true,
      showErrorImage: false
    });
    if (this.state.editableItem) {
      this.state.editableItem.CostPerResource = this.state.outsourcedCost;
      this.state.editableItem.CostPerOutSourcedResource = this.state.outsourcedCost;
    }
    this.toggleDialog(
      ` Successfully saved`,
      'Success'
    );

    this.toggleOutsourcedCostDialog();
  };

  handleSelect = (e) => {
    this.setState({ selected: e.selected });
  };

  handleCalculations = async (isFromApproverView) => {

    //ongoing cms values
    let totalCostRM = Number(0.00);
    let totalGrossCostProject = Number(0.00);
    let totalGrossCostCr = Number(0.00);
    let totalRevenueRM = Number(0.00);
    let totalEffortRM = Number(0.00);
    let calculatedValues = {};
    let discountedRevenueToFortude = Number(0.00);
    let netProfit = Number(0.00);
    let fixedPriceEffortOngoing = Number(0.00);
    let TMEffortOngoing = Number(0.00);

    //approved cms values
    let totalEffortDaysCMS = 0.00;
    let discountedRevenueToFortudeCMS = Number(0.00);
    let netProfitLossCMS = Number(0.00);
    let fixedPriceEffortApproved = Number(0.00);
    let TMEffortApproved = Number(0.00);
    let totalCostCMS = Number(0.00);
    let billableEffortDays = Number(0.00);
    let nonBillableEffortDays = Number(0.00);
    let TotalGrossProfit = Number(0.00);
    let TotalOngoingGrossProfit = Number(0.00);
    let ServiceRevenue = Number(0.00);
    let GrossProfitMargin;

    let currencyIdCR = 0;

    let totalResourceRevenueCMS = 0;
    let totalServiceRevenueCMS = 0;
    let totalDiscountCMS = 0;
    let totalResourceCostCMS = 0;
    let totalSalesCommissionCMS = 0;
    let totalContingencyCMS = 0;
    let totalNonBillableExpensesCMS = 0;
    let totalProjectResRevenueOngoing = 0;
    let totalCrResRevenueOngoing = 0;
    let totalDiscountOngoing = 0;
    let totalContingencyOngoing = 0;
    let billableEffortOngoing = 0;
    let nonBillableEffortOngoing = 0;
    let totalSalesCommissionOngoing = 0;
    let projOrCrOngoingRevenues = [];

    let calculationResourceMix = this.state.resourceMix;

    const invalidStatusList = ['NEW_FROM_CMS', 'NEW_FROM_RM', 'REMOVED', 'RQST_APP_REJECTED', 'EDIT','RES_ALLOC_CANCELLED', 'RES_INVALIDATED'];
    const approvedStatusList = ['CONFIRMED', 'RES_ALLOC_PENDING', 'ALLOC_APP_PENDING', 'COMPLETE', 'CLOSE'];

    //For Resource Allocation Summary Calculations
    if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPApproveWF') {
      calculationResourceMix = this.state.allocationSummaryResources;
    }

    for (const mainLine of calculationResourceMix) {
      if (mainLine.IsPreSales === false) {
        if (mainLine.Project && mainLine.Project.StatusConvertedToActive === true && mainLine.Type === "Project") {
          if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
            for (const subLine of mainLine.ResourceRequestSubLine) {
              if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                totalCostRM += Number(subLine.Cost);
                totalRevenueRM += Number(subLine.Revenue);
                totalEffortRM += Number(subLine.EffortDays);
                totalGrossCostProject += Number(subLine.DirectCost);

                if (subLine.RateType && subLine.RateType === 5) {
                  nonBillableEffortOngoing += Number(subLine.EffortDays);
                } else {
                  billableEffortOngoing += Number(subLine.EffortDays);
                }

                if (mainLine.Project.PaymentMethod === 1) {
                  fixedPriceEffortOngoing += Number(subLine.EffortDays);
                }
                else if (mainLine.Project.PaymentMethod === 2) {
                  TMEffortOngoing += Number(subLine.EffortDays);
                }
                else if (mainLine.Project.PaymentMethod === 3) {
                  //for calculate fixed price effort
                  if (subLine.PaymentMethod === 1) {
                    fixedPriceEffortOngoing += Number(subLine.EffortDays)
                  }
                  else {
                    //for calculate T & M effort
                    TMEffortOngoing += Number(subLine.EffortDays)
                  }
                }

              }
            }
          } else {
            if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
              totalCostRM += Number(mainLine.TotalCost);
              totalRevenueRM += Number(mainLine.TotalRevenue);
              totalEffortRM += Number(mainLine.EffortDays);
              totalGrossCostProject += Number(mainLine.DirectCost);

              if (mainLine.RateType && mainLine.RateType === 5) {
                nonBillableEffortOngoing += Number(mainLine.EffortDays);
              } else {
                billableEffortOngoing += Number(mainLine.EffortDays);
              }

              if (mainLine.Project.PaymentMethod === 1) {
                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
              }
              else if (mainLine.Project.PaymentMethod === 2) {
                TMEffortOngoing += Number(mainLine.EffortDays);
              }
              else if (mainLine.Project.PaymentMethod === 3) {

                //for calculate fixed price effort
                if (mainLine.PaymentMethod === 1) {
                  fixedPriceEffortOngoing += Number(mainLine.EffortDays)

                } else if (mainLine.PaymentMethod === 2) {
                  //for calculate T & M effort
                  TMEffortOngoing += Number(mainLine.EffortDays)

                }
                else if (mainLine.PaymentMethod === 3) {

                  fixedPriceEffortOngoing += Number(mainLine.EffortDays)
                }
              }

            }
          }
        } else if (mainLine.ChangeRequest && mainLine.ChangeRequest.StatusConvertedToActive === true) {
          if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
            for (const subLine of mainLine.ResourceRequestSubLine) {
              if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                totalCostRM += Number(subLine.Cost);
                totalRevenueRM += Number(subLine.Revenue);
                totalEffortRM += Number(subLine.EffortDays);
                totalGrossCostCr += Number(subLine.DirectCost);

                if (subLine.RateType && subLine.RateType === 5) {
                  nonBillableEffortOngoing += Number(subLine.EffortDays);
                } else {
                  billableEffortOngoing += Number(subLine.EffortDays);
                }

                if (mainLine.ChangeRequest.PaymentMethodId === 1) {
                  fixedPriceEffortOngoing += Number(subLine.EffortDays);
                }
                else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                  TMEffortOngoing += Number(subLine.EffortDays);
                }
                else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                  //for calculate fixed price effort
                  if (subLine.PaymentMethod === 1) {
                    fixedPriceEffortOngoing += Number(subLine.EffortDays)

                  }
                  else {
                    //for calculate T & M effort
                    TMEffortOngoing += Number(subLine.EffortDays)

                  }
                }

              }
            }
          } else {
            if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
              totalCostRM += Number(mainLine.TotalCost);
              totalRevenueRM += Number(mainLine.TotalRevenue);
              totalEffortRM += Number(mainLine.EffortDays);
              totalGrossCostCr += Number(mainLine.DirectCost) ;

              if (mainLine.RateType && mainLine.RateType === 5) {
                nonBillableEffortOngoing += Number(mainLine.EffortDays);
              } else {
                billableEffortOngoing += Number(mainLine.EffortDays);
              }

              if (mainLine.ChangeRequest.PaymentMethodId === 1) {

                fixedPriceEffortOngoing += Number(mainLine.EffortDays);
              } else if (mainLine.ChangeRequest.PaymentMethodId === 2) {
                TMEffortOngoing += Number(mainLine.EffortDays);
              } else if (mainLine.ChangeRequest.PaymentMethodId === 3) {
                //for calculate fixed price effort
                if (mainLine.PaymentMethod === 1) {
                  fixedPriceEffortOngoing += mainLine.EffortDays

                } else {
                  //for calculate T & M effort
                  TMEffortOngoing += mainLine.EffortDays

                }
              }

            }
          }
        }
      }
    }

    if (this.state.project.StatusConvertedToActive === true) {

      const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
      const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;
      let projcurrencyId = 0;
      let cmsRevenueAfterDiscountFixed = Number(0.00);
      const projObj = {
        CmsVersion: projectVersion,
        CmsID: projectID
      }

      let billingRegion = this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region.id;
      await getRegionCurrencyIntegrated(billingRegion).then(response => { //currencyId
        projcurrencyId = response.data.CurrencyId;
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      const proStartDate = new Date(this.state.project.StartDate);
      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated( //conversionRate
        projcurrencyId,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );

      //approved CMS values
      if (projectID !== null && projectVersion !== null) {
        await getCMSheet(projectID, projectVersion).then(res => {

          if (res.data.Project.PaymentMethod === 1) {
            fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
          }
          else if (res.data.Project.PaymentMethod === 2) {
            TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
          }
          else if (res.data.Project.PaymentMethod === 3) {
            for (let alloc of res.data.ResourceAllocations) {

              if (alloc.PaymentMethod === 1) {
                fixedPriceEffortApproved += alloc.TotalEffortDays
              }
              else {
                TMEffortApproved += alloc.TotalEffortDays
              }
            }
          }
          totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

          // Total gross profit
          TotalGrossProfit += projcurrencyId !== 1 ? Number(res.data.GrossProfitFromResourceDeployment) / Number(projrateRecordCurrencyRate) : Number(res.data.GrossProfitFromResourceDeployment);

          // Service revenue
          ServiceRevenue += Number(res.data.ServiceRevenueValue);

          if(res.data.ResourceAllocations.length > 0){
            res.data.ResourceAllocations.map(v => {
              v.Billability === "Billable" ? billableEffortDays += v.EffortDays : nonBillableEffortDays += v.EffortDays;
            })
          }
        })
      }

      if (projectID !== null && projectVersion !== null) {
        await getCMSheet(projectID, projectVersion).then(res => {
          //Revenue to Fortude after discount - CMS
          const discountVal = (   // Value discount / (premium)
            (Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate)) *
            (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
          const x = Math.pow(10, Number(2) + 1);
          const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
          cmsRevenueAfterDiscountFixed =
            Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate); // Revenue to company after discount
          discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountFixed;

          totalResourceRevenueCMS += Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate);
          totalServiceRevenueCMS += Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate);
          totalDiscountCMS += Number(valueDiscount);
          totalContingencyCMS += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
          totalSalesCommissionCMS += Number(cmsRevenueAfterDiscountFixed * (res.data.SalesCommissionPercentage / 100));
          totalNonBillableExpensesCMS+= Number(res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate);
          totalResourceCostCMS += Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate);
          
          if (this.state.project.PaymentMethodRecord.id === 1) { 
            totalProjectResRevenueOngoing += Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate);
            totalDiscountOngoing += Number(valueDiscount);
            totalContingencyOngoing += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
            projObj.RevenueAfterDiscount = cmsRevenueAfterDiscountFixed;
            projOrCrOngoingRevenues.push(projObj);
          }

          //Net Profit - cms
          const contingencyVal =
            Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2);
          const netProfitLossCMSproj = cmsRevenueAfterDiscountFixed -
            res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate -
            cmsRevenueAfterDiscountFixed * (res.data.SalesCommissionPercentage / 100) -
            res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate -
            contingencyVal;
          netProfitLossCMS += netProfitLossCMSproj;
          totalCostCMS += Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate);
          totalCostCMS += Number(contingencyVal);
        })
      }

      //on-Going CMS values - based on project type
      //revenue to fortude after discount - ongoing
      if (this.state.project.PaymentMethodRecord.id === 1) {    //fixed project
        discountedRevenueToFortude += cmsRevenueAfterDiscountFixed;
      } else if (this.state.project.PaymentMethodRecord.id === 2) { //T&M
        //Sum of revenue from billable lines + sum of (Service Revenue - Discount + Contingency) from CMS
        for (const mainLine of calculationResourceMix) {
          if (mainLine.CMSheetId === projectID) { //Sum of revenue from billable lines of project
            if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
              for (const subLine of mainLine.ResourceRequestSubLine) {
                if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                  discountedRevenueToFortude += Number(subLine.Revenue);
                  totalProjectResRevenueOngoing += Number(subLine.Revenue);
                }
              }
            } else {
              if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                totalProjectResRevenueOngoing += Number(mainLine.TotalRevenue);
              }
            }
          }
        }
        if (projectID !== null && projectVersion !== null) {
          await getCMSheet(projectID, projectVersion).then(res => {
            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate);
            // discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / projrateRecordCurrencyRate);
            // discountedRevenueToFortude += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
            let discountPercentage = res.data.DiscountPercentage && res.data.DiscountPercentage !== null ? (Number(res.data.DiscountPercentage) / 100) : 0;
            let discountValue = Number((Number(totalProjectResRevenueOngoing) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate)) * discountPercentage).toFixed(2);
            totalDiscountOngoing += Number(discountValue);
            discountedRevenueToFortude = discountedRevenueToFortude - Number(discountValue);

            const projRevenueOngoing = Number(totalProjectResRevenueOngoing) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(discountValue);
            projObj.RevenueAfterDiscount = projRevenueOngoing;
            projOrCrOngoingRevenues.push(projObj);
          })
        }
      } else if (this.state.project.PaymentMethodRecord.id === 3) { //hybrid
        //T&M and fixed
        for (const mainLine of calculationResourceMix) {
          if (mainLine.CMSheetId === projectID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) { //Sum of revenue from billable lines of project
            if (mainLine.ResourceRequestSubLine.length > 0) {
              for (const subLine of mainLine.ResourceRequestSubLine) {
                if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                  discountedRevenueToFortude += Number(subLine.Revenue);
                  totalProjectResRevenueOngoing += Number(subLine.Revenue);
                }
              }
            } else {
              if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                totalProjectResRevenueOngoing += Number(mainLine.TotalRevenue);
              }
            }
          }
        }
        if (projectID !== null && projectVersion !== null) {
          await getCMSheet(projectID, projectVersion).then(res => {
            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate);
            // discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / projrateRecordCurrencyRate);
            // discountedRevenueToFortude += Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
            let discountPercentage = res.data.DiscountPercentage && res.data.DiscountPercentage !== null ? (Number(res.data.DiscountPercentage) / 100) : 0;
            let discountValue = Number((Number(totalProjectResRevenueOngoing) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate)) * discountPercentage).toFixed(2);
            totalDiscountOngoing += Number(discountValue);
            discountedRevenueToFortude = discountedRevenueToFortude - Number(discountValue);

            const projRevenueOngoing = Number(totalProjectResRevenueOngoing) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(discountValue);
            projObj.RevenueAfterDiscount = projRevenueOngoing;
            projOrCrOngoingRevenues.push(projObj);
          })
        }
      }
    }

    //revenue to fortude after discount calculations of CR
    for (const cr of this.state.project.ChangeRequests) {
      if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        const crVersion = cr.CMSheets[0].Version;
        const crID = cr.CMSheets[0].id;
        let cmsRevenueAfterDiscountCR = Number(0.00);
        let crRevenueOngoing = Number(0.00);
        const crObj = {
          CmsVersion: crVersion,
          CmsID: crID
        }

        await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {  //curencyID
          currencyIdCR = response.data.CurrencyId;
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        const crStartDate = new Date(cr.StartDate);
        const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(  //conversion rate
          currencyIdCR,
          crStartDate.getMonth() + 1,
          crStartDate.getFullYear()
        );

        //approved CMS values
        if (crID !== null && crVersion !== null) {
          await getCMSheet(crID, crVersion).then(res => {

            if (res.data.ChangeRequest.PaymentMethodId === 1) {
              fixedPriceEffortApproved += res.data.TotalEffortDaysResourceAllocation
            }
            else if (res.data.ChangeRequest.PaymentMethodId === 2) {
              TMEffortApproved += res.data.TotalEffortDaysResourceAllocation
            }
            else if (res.data.ChangeRequest.PaymentMethodId === 3) {
              for (let alloc of res.data.ResourceAllocations) {

                if (alloc.PaymentMethod === 1) {
                  fixedPriceEffortApproved += alloc.TotalEffortDays
                }
                else {
                  TMEffortApproved += alloc.TotalEffortDays
                }
              }
            }
            totalEffortDaysCMS += Number(res.data.TotalEffortDaysResourceAllocation);

            // Total gross profit
            TotalGrossProfit += currencyIdCR !== 1 ? Number(res.data.GrossProfitFromResourceDeployment) / Number(crRateRecordCurrencyRate) : Number(res.data.GrossProfitFromResourceDeployment);

            // Service revenue
            ServiceRevenue += Number(res.data.ServiceRevenueValue);
          })
        }

        // Revenue to Fortude after discount - cms calculations
        if (crID !== null && crVersion !== null) {
          await getCMSheet(crID, crVersion).then(res => {
            const discountVal = (   // Value discount / (premium)
              (Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate)) *
              (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
            const x = Math.pow(10, Number(2) + 1);
            const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
            cmsRevenueAfterDiscountCR =
              Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate); // Revenue to company after discount
            discountedRevenueToFortudeCMS += cmsRevenueAfterDiscountCR;

            totalResourceRevenueCMS += Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate);
            totalServiceRevenueCMS += Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate);
            totalDiscountCMS += Number(valueDiscount);
            totalContingencyCMS += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
            totalSalesCommissionCMS += Number(cmsRevenueAfterDiscountCR * (res.data.SalesCommissionPercentage / 100));
            totalNonBillableExpensesCMS+= Number(res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate);
            totalResourceCostCMS += Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate);

            if (cr.PaymentMethodId === 1) { 
              crRevenueOngoing += Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate);
              totalDiscountOngoing += Number(valueDiscount);
              totalContingencyOngoing += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
              crObj.RevenueAfterDiscount = cmsRevenueAfterDiscountCR;
              projOrCrOngoingRevenues.push(crObj);
            }

            //Net Profit - cms
            const contingencyVal =
              Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2);
            const netProfitLossCMScr = cmsRevenueAfterDiscountCR -
              res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate -
              cmsRevenueAfterDiscountCR * (res.data.SalesCommissionPercentage / 100) -
              res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate -
              contingencyVal;
            netProfitLossCMS += netProfitLossCMScr;
            totalCostCMS += Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate);
            totalCostCMS += Number(contingencyVal);

            if(res.data.ResourceAllocations.length > 0){
              res.data.ResourceAllocations.map(v => {
                v.Billability === "Billable" ? billableEffortDays += v.EffortDays : nonBillableEffortDays += v.EffortDays;
              })
            }
          })
        }

        //on-Going CMS values - based on CR type
        // Revenue to Fortude after discount - ongoing
        if (cr.PaymentMethodId === 1) { //fixed CR
          discountedRevenueToFortude += cmsRevenueAfterDiscountCR;
        }
        else if (cr.PaymentMethodId === 2) { //T&M crs
          for (const mainLine of calculationResourceMix) {
            if (mainLine.CMSheetId === crID) {  //Sum of revenue from billable lines of CR
              if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                for (const subLine of mainLine.ResourceRequestSubLine) {
                  if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                    discountedRevenueToFortude += Number(subLine.Revenue);
                    crRevenueOngoing += Number(subLine.Revenue);
                  }
                }
              } else {
                if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                  discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                  crRevenueOngoing += Number(mainLine.TotalRevenue);
                }
              }
            }
          }
          await getCMSheet(crID, crVersion).then(res => {
            discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate);
            // discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / crRateRecordCurrencyRate);
            // discountedRevenueToFortude += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
            let discountPercentage = res.data.DiscountPercentage && res.data.DiscountPercentage !== null ? (Number(res.data.DiscountPercentage) / 100) : 0;
            let discountValue = Number((Number(crRevenueOngoing) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate)) * discountPercentage).toFixed(2);
            totalDiscountOngoing += Number(discountValue);
            discountedRevenueToFortude = discountedRevenueToFortude - Number(discountValue);

            const projRevenueOngoing = Number(crRevenueOngoing) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(discountValue);
            crObj.RevenueAfterDiscount = projRevenueOngoing;
            projOrCrOngoingRevenues.push(crObj);
          })
        } else if (cr.PaymentMethodId === 3) { //hybrid
          //T&M and fixed
          for (const mainLine of calculationResourceMix) {
            if (mainLine.CMSheetId === crID && (mainLine.PaymentMethod === 2 || mainLine.PaymentMethod === 1)) {  //Sum of revenue from billable lines of CR
              if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
                for (const subLine of mainLine.ResourceRequestSubLine) {
                  if ((!isFromApproverView && !invalidStatusList.includes(subLine.Status)) || (isFromApproverView && approvedStatusList.includes(subLine.Status))) {
                    discountedRevenueToFortude += Number(subLine.Revenue);
                    crRevenueOngoing += Number(subLine.Revenue);
                  }
                }
              } else {
                if ((!isFromApproverView && !invalidStatusList.includes(mainLine.Status)) || (isFromApproverView && approvedStatusList.includes(mainLine.Status))) {
                  discountedRevenueToFortude += Number(mainLine.TotalRevenue);
                  crRevenueOngoing += Number(mainLine.TotalRevenue);
                }
              }
            }
          }
          if (crID !== null && crVersion !== null) {
            await getCMSheet(crID, crVersion).then(res => {
              discountedRevenueToFortude += Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate);
              // discountedRevenueToFortude = discountedRevenueToFortude - Number(res.data.DiscountValue / crRateRecordCurrencyRate);
              // discountedRevenueToFortude += Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
              let discountPercentage = res.data.DiscountPercentage && res.data.DiscountPercentage !== null ? (Number(res.data.DiscountPercentage) / 100) : 0;
              let discountValue = Number((Number(crRevenueOngoing) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate)) * discountPercentage).toFixed(2);
              totalDiscountOngoing += Number(discountValue);
              discountedRevenueToFortude = discountedRevenueToFortude - Number(discountValue);  

              const projRevenueOngoing = Number(crRevenueOngoing) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(discountValue);
              crObj.RevenueAfterDiscount = projRevenueOngoing;
              projOrCrOngoingRevenues.push(crObj);
            })
          }
        }
        totalCrResRevenueOngoing += Number(crRevenueOngoing);
      }
    }

    //Net profit calculations
    netProfit += discountedRevenueToFortude; //Revenue to Fortude after discount
    netProfit = netProfit - totalCostRM; //Cost from Resource Deployment

    if (this.state.project.StatusConvertedToActive === true) {
      const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
      const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;
      let projcurrencyId = 0;
      let billingRegion = this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region.id;
      await getRegionCurrencyIntegrated(billingRegion).then(response => {
        projcurrencyId = response.data.CurrencyId;
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      const proStartDate = new Date(this.state.project.StartDate);
      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
        projcurrencyId,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );
      if (projectID !== null && projectVersion !== null) {
        await getCMSheet(projectID, projectVersion).then(res => {
          netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate); //Non-Billable Other Expenses from CMSs
          // netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
          //Sales Commission from CMSs
          // const discountVal = (   // Value discount / (premium)
          //   (Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate)) *
          //   (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
          // const x = Math.pow(10, Number(2) + 1);
          // const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
          // const cmsRevenueAfterDiscount =
          //   Number(res.data.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / projrateRecordCurrencyRate); // Revenue to company after discount
          // netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs

          const projRevenue = projOrCrOngoingRevenues.filter(res => res.CmsID === projectID && res.CmsVersion === projectVersion);
          const projectSalesCommission = projRevenue && projRevenue.length > 0 ? Number(projRevenue[0].RevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2) : 0;
          totalSalesCommissionOngoing += Number(projectSalesCommission);
          netProfit = netProfit - Number(projectSalesCommission); //Sales Commission from CMSs

        })
      }
    }
    for (const cr of this.state.project.ChangeRequests) {
      if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        const crVersion = cr.CMSheets[0].Version;
        const crID = cr.CMSheets[0].id;
        let currencyIdCR = 0;
        await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
          currencyIdCR = response.data.CurrencyId;
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        const crStartDate = new Date(cr.StartDate);
        const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
          currencyIdCR,
          crStartDate.getMonth() + 1,
          crStartDate.getFullYear()
        );
        await getCMSheet(crID, crVersion).then(res => {
          netProfit = netProfit - Number(res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate); //Non-Billable Other Expenses from CMSs
          // netProfit = netProfit - Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
          // - Sales Commission from CMSs
          // const discountVal = (     // Value discount / (premium)
          //   (Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate)) *
          //   (Number(res.data.DiscountPercentage) / 100)).toFixed(2);
          // const x = Math.pow(10, Number(2) + 1);
          // const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
          // const cmsRevenueAfterDiscount =
          //   Number(res.data.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate) - Number(valueDiscount) + Number(res.data.ContingencyValue / crRateRecordCurrencyRate); // Revenue to company after discount
          // netProfit = netProfit - Number((cmsRevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2)); //Sales Commission from CMSs

          const projRevenue = projOrCrOngoingRevenues.filter(res => res.CmsID === crID && res.CmsVersion === crVersion);
          const crSalesCommission = projRevenue && projRevenue.length > 0 ? Number(projRevenue[0].RevenueAfterDiscount * res.data.SalesCommissionPercentage / 100).toFixed(2) : 0;
          totalSalesCommissionOngoing += Number(crSalesCommission);
          netProfit = netProfit - Number(crSalesCommission); //Sales Commission from CMSs

        })
      }
    }

    // TotalOngoingGrossProfit calculation
    // TotalOngoingGrossProfit = discountedRevenueToFortude - totalGrossCostProject;
    // TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;

    TotalOngoingGrossProfit = discountedRevenueToFortude;

    if (this.state.project.StatusConvertedToActive === true) { // Project
      const projectVersion = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].Version : null;
      const projectID = this.state.project.CMSheets.length > 0 ? this.state.project.CMSheets[0].id : null;

      let projcurrencyId = 0;
      let billingRegion = this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region.id;
      await getRegionCurrencyIntegrated(billingRegion).then(response => {
        projcurrencyId = response.data.CurrencyId;
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      const proStartDate = new Date(this.state.project.StartDate);
      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
        projcurrencyId,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );

        // Calculate on going gross profit
        if (projectID !== null && projectVersion !== null) {
            await getCMSheet(projectID, projectVersion).then(res => {
                TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostProject;
                TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate);

                //TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ContingencyValue / projrateRecordCurrencyRate);
                let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / projrateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                // TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;

                TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue / projrateRecordCurrencyRate); 

            })
        }
    }

    TotalOngoingGrossProfit = TotalOngoingGrossProfit - totalGrossCostCr;

    for (const cr of this.state.project.ChangeRequests) {
      if (cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        const crVersion = cr.CMSheets[0].Version;
        const crID = cr.CMSheets[0].id;
        await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
          currencyIdCR = response.data.CurrencyId;
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
            const crStartDate = new Date(cr.StartDate);
            const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
                currencyIdCR,
                crStartDate.getMonth() + 1,
                crStartDate.getFullYear()
            );

            await getCMSheet(crID, crVersion).then(res => {
                TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate);

                //TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ContingencyValue / crRateRecordCurrencyRate);
                let costContingencyVal = Number(Number(res.data.TotalCostResourceAllocation / crRateRecordCurrencyRate) * res.data.ContingencyPercentage / 100).toFixed(2); //Cost Contingency from CMSs
                // TotalOngoingGrossProfit = TotalOngoingGrossProfit - costContingencyVal;

                TotalOngoingGrossProfit = TotalOngoingGrossProfit - Number(res.data.ServiceRevenueValue / crRateRecordCurrencyRate); 

            })
        }
    }

    //estimated project duration
    var estDuration = 0;
    var startDate = this.getStartDate();
    if (this.state.userDefinedEndDate) {
      const sd = reStructureDate(startDate);
      const ed = reStructureDate(this.state.userDefinedEndDate);
      // estDuration = calculateDuration(sd, ed);
      ed.setDate(ed.getDate() + 1);
      estDuration = moment(ed).diff(moment(sd), 'days');
    } else {
      estDuration = 0;
    }


    calculatedValues.totalEffortRM = totalEffortRM;
    calculatedValues.totalRevenueRM = totalRevenueRM;
    calculatedValues.totalCostRM = totalCostRM;
    calculatedValues.discountedRevenueToFortude = discountedRevenueToFortude ? discountedRevenueToFortude : 0;
    calculatedValues.netProfit = netProfit ? netProfit : 0.00;
    calculatedValues.totalEffortDaysCMS = totalEffortDaysCMS;
    calculatedValues.discountedRevenueToFortudeCMS = discountedRevenueToFortudeCMS ? discountedRevenueToFortudeCMS : 0;
    calculatedValues.fixedPriceEffortOngoing = fixedPriceEffortOngoing;
    calculatedValues.fixedPriceEffortApproved = fixedPriceEffortApproved;
    calculatedValues.TMEffortOngoing = TMEffortOngoing;
    calculatedValues.TMEffortOngoingApproved = TMEffortApproved;
    calculatedValues.netProfitLossCMS = netProfitLossCMS;
    calculatedValues.estDuration = estDuration;
    calculatedValues.totalCostCMS = totalCostCMS;
    calculatedValues.billableEffortDays = billableEffortDays;
    calculatedValues.nonBillableEffortDays = nonBillableEffortDays;

    calculatedValues.TotalResourceRevenueCMS = Number(totalResourceRevenueCMS);
    calculatedValues.TotalServiceRevenueCMS = Number(totalServiceRevenueCMS);
    calculatedValues.TotalDiscountCMS = Number(totalDiscountCMS);
    calculatedValues.TotalSalesCommissionCMS = Number(totalSalesCommissionCMS);
    calculatedValues.TotalContingencyCMS = Number(totalContingencyCMS);
    calculatedValues.TotalNonBillableExpensesCMS = Number(totalNonBillableExpensesCMS);
    calculatedValues.TotalResourceCostCMS = Number(totalResourceCostCMS);
    calculatedValues.TotalContingencyOngoing = Number(totalContingencyOngoing);
    calculatedValues.BillableEffortOngoing = Number(billableEffortOngoing);
    calculatedValues.NonBillableEffortOngoing = Number(nonBillableEffortOngoing);
    calculatedValues.TotalResourceRevenueOngoing = Number(totalProjectResRevenueOngoing) + Number(totalCrResRevenueOngoing);
    calculatedValues.TotalResourceCostOngoing = Number(totalCostRM);
    calculatedValues.TotalCostCMS = Number(totalResourceCostCMS) + Number(totalSalesCommissionCMS) + Number(totalNonBillableExpensesCMS);
    calculatedValues.TotalCostOngoing = Number(totalCostRM) + Number(totalSalesCommissionCMS) + Number(totalNonBillableExpensesCMS);
    calculatedValues.TotalDiscountOngoing = Number(totalDiscountOngoing);
    calculatedValues.TotalSalesCommissionOngoing = Number(totalSalesCommissionOngoing);

    // Gross Profit / Gross Profit Marging %
    calculatedValues.TotalGrossProfit = TotalGrossProfit;
    GrossProfitMargin = calculatedValues.discountedRevenueToFortudeCMS ? formatNumber(Number(parseFloat(((calculatedValues.TotalGrossProfit / calculatedValues.discountedRevenueToFortudeCMS) * 100)))) + '%' : '0.00%';
    calculatedValues.GrossProfitMargin = GrossProfitMargin;

    // Gross - Ongoing CMS Value / Ongoing CMS Value percentage
    calculatedValues.TotalOngoingGrossProfit = TotalOngoingGrossProfit;


    // Gross - Variance value / percentage
    let GrossVeriance = ((calculatedValues.TotalOngoingGrossProfit ? Number(Math.abs(calculatedValues.TotalOngoingGrossProfit)) : 0.00) - (calculatedValues.TotalGrossProfit ? Number(Math.abs(calculatedValues.TotalGrossProfit)) : 0.00)).toFixed(2);
    calculatedValues.GrossVeriance = GrossVeriance;

    if (!isFromApproverView) {
      await this.setState({
        calculatedValues: calculatedValues,
        cmsCalculations: calculatedValues
      });
    } else {
      await this.setState({
        approvedCalculatedValues: calculatedValues,
        cmsCalculations: calculatedValues
      });
    }

    if ((this.props.location.action !== 'RPApproveWF') || (this.props.location.action === 'RPApproveWF' && isFromApproverView)) {
      this.populateInitialCMSToGrid();
    }
  }

  // Actual Calculations
  handleActualCalculations = async () => {
    let fixedEffort = 0;
    let fixedLineApproveTimeSum = 0;
    let fixedLineFutureAllocSum = 0;
    let tmEffort = 0;
    let tmLineApproveTimeSum = 0;
    let tmLineFutureAllocSum = 0;
    let billEffort = 0;
    let billApproveTimeSum = 0;
    let billLineFutureAllocSum = 0;
    let nonBillEffort = 0;
    let nbApproveTimeSum = 0;
    let nbLineFutureAllocSum = 0;
    let contractedEffort = 0;
    let contractedEffAct = 0;
    let contractedEffFt = 0;
    let revenue = 0;
    let avgRatePerDay = 0;
    let totalCost = 0;
    let directCost = 0;
    let grossMargin = 0;
    let grossMarginPercentage = 0;
    let netMargin = 0;
    let netMarginPercentage = 0;
    let estDuration = 0;
    let estDurationFt = 0;
    let estDurationAct = 0;
    let firstAppDate = null;
    let latestAppDate = null;
    let earliestAllocDate = null;
    let furthestAllocDate = null;

    // For variance
    let conEffVariance = 0;
    let conEffVarPercentage = 0;
    let fixedEffVariance = 0;
    let fixedEffVarPercentage = 0;
    let tmEffVariance = 0;
    let tmEffVarPercentage = 0;
    let billVariance = 0;
    let billVarPercentage = 0;
    let nbVariance = 0;
    let nbVarPercentage = 0;
    let revVariance = 0;
    let revVarPercentage = 0;
    let avgRateVariance = 0;
    let avgRateVarPercentage = 0;
    let grossMarginVariance = 0;
    let grossMarginVarPercentage = 0;
    let netMarginVariance = 0;
    let netMarginVarPercentage = 0;
    let revenueAct = 0;
    let revenueFt = 0;

    let projStatistics = [];
    let calculatedActValues = {};

    let totalResourceRevenue = 0;
    let totalServiceRevenue = 0;
    let totalDiscount = 0;
    let totalResourceDirectCost = 0;
    let totalResourceInDirectCost = 0;
    let totalSalesCommission = 0;
    let totalNonBillableExpenses = 0;
    let totalContingency = 0;

    let totalCostTE = 0;
    let directCostTE = 0;
    let totalResourceCostTE = 0;
    let grossMarginTE = 0;
    let grossMarginPercentageTE = 0;
    let netMarginTE = 0;
    let netMarginPercentageTE = 0;
    let grossMarginVarianceTE = 0;
    let grossMarginVarPercentageTE = 0;
    let netMarginVarianceTE = 0;
    let netMarginVarPercentageTE = 0;
    let totalCostTEAct = 0;
    let totalCostTEFt = 0;

    let project = this.state.project;
    let crs = this.state.project.ChangeRequests;
    let cmsCalculations = this.state.cmsCalculations;
    let calculationResourceMix = this.state.resourceMix;
    let projectStatus = this.state.project.ProjectStatus ? this.state.project.ProjectStatus.StatusName : null;
    const closedStatusList = ['Closed', 'Discontinue', 'Close without bidding', 'Closed-Opportunity Loss', 'Complete'];
    //For Resource Allocation Summary Calculations
    if (this.props.location.action === 'RPApprove' || this.props.location.action === 'RPApproveWF') {
      calculationResourceMix = this.state.allocationSummaryResources;
    }

    // Get project and that project's cr(s) statistics
    await getProjectStatistics(project.id).then(response => {
      if (response.data && response.data.length > 0) {
        projStatistics = response.data;
      }
    }).catch(error => {
      loggerService.writeLog(error, LOG_TYPES.ERROR);
    });

    // Project
    if (project && project !== null && (project.StatusConvertedToActive && project.StatusConvertedToActive === true)) {
      let projCms = null;
      let tmBillAppTimeRev = 0;
      let tmFtAllocRev = 0;
      let fixedLineTotCost = 0;
      let tmLineTotCost = 0;
      let fixedLineDirCost = 0;
      let tmLineDirCost = 0;
      let teBasedTotalCost = 0;
      let teBasedDirectCost = 0;

      let projStat = projStatistics.length > 0 ? 
                     (projStatistics.filter(el => el.ProjectId == project.id && el.CrId == null).length > 0 ? 
                     projStatistics.filter(el => el.ProjectId == project.id && el.CrId == null)[0] : null) : null;

      const projectVersion = project.CMSheets.length > 0 ? project.CMSheets[0].Version : null;
      const projectID = project.CMSheets.length > 0 ? project.CMSheets[0].id : null;

      if (projectID !== null && projectVersion !== null) {
        await getCMSheet(projectID, projectVersion).then(res => {
          if (res.data && res.data !== null) {
            projCms = res.data;
          }
        }).catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      }

      if (projStat && projStat !== null) {
        // For Est Project Duration
        firstAppDate = (firstAppDate !== null && projStat.FirstApproveDate && projStat.FirstApproveDate !== null) ? 
                       ( (firstAppDate < projStat.FirstApproveDate) ? (firstAppDate) : (projStat.FirstApproveDate) ) : 
                       ( (firstAppDate !== null) ? (firstAppDate) : 
                       ( (projStat.FirstApproveDate && projStat.FirstApproveDate !== null) ? (projStat.FirstApproveDate) : (null) ) );

        latestAppDate = (latestAppDate !== null && projStat.LatestApproveDate && projStat.LatestApproveDate !== null) ? 
                        ( (latestAppDate > projStat.LatestApproveDate) ? (latestAppDate) : (projStat.LatestApproveDate) ) : 
                        ( (latestAppDate !== null) ? (latestAppDate) : 
                        ( (projStat.LatestApproveDate && projStat.LatestApproveDate !== null) ? (projStat.LatestApproveDate) : (null) ) );

        furthestAllocDate = (furthestAllocDate !== null && projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? 
                            ( (furthestAllocDate > projStat.FurthestAllocDate) ? (furthestAllocDate) : (projStat.FurthestAllocDate) ) : 
                            ( (furthestAllocDate !== null) ? (furthestAllocDate) : 
                            ( (projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? (projStat.FurthestAllocDate) : (null) ) );

        earliestAllocDate = (earliestAllocDate !== null && projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? 
                            ( (earliestAllocDate < projStat.EarliestAllocDate) ? (earliestAllocDate) : (projStat.EarliestAllocDate) ) : 
                            ( (earliestAllocDate !== null) ? (earliestAllocDate) : 
                            ( (projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? (projStat.EarliestAllocDate) : (null) ) );

        // For Fixed Price Effort
        let fxdLineAppTimeSum = (projStat.FixedLineApproveTimeSum && projStat.FixedLineApproveTimeSum !== null ? Number(projStat.FixedLineApproveTimeSum) : 0);
        let fxdLineFtAllocSum = (projStat.FixedLineFutureAllocSum && projStat.FixedLineFutureAllocSum !== null ? Number(projStat.FixedLineFutureAllocSum) : 0);
        fixedEffort += ((fxdLineAppTimeSum / 8) + (fxdLineFtAllocSum));
        fixedLineApproveTimeSum += (fxdLineAppTimeSum / 8);
        fixedLineFutureAllocSum += (fxdLineFtAllocSum);
        
        // For T&M Effort
        let tmLineAppTimeSum = (projStat.TMLineApproveTimeSum && projStat.TMLineApproveTimeSum !== null ? Number(projStat.TMLineApproveTimeSum) : 0);
        let tmLineFtAllocSum = (projStat.TMLineFutureAllocSum && projStat.TMLineFutureAllocSum !== null ? Number(projStat.TMLineFutureAllocSum) : 0);
        tmEffort += ((tmLineAppTimeSum / 8) + (tmLineFtAllocSum));
        tmLineApproveTimeSum += (tmLineAppTimeSum / 8);
        tmLineFutureAllocSum += (tmLineFtAllocSum);

        // For Billable Effort
        let billAppTimeSum = (projStat.ApprovedBillTimeSum && projStat.ApprovedBillTimeSum !== null ? Number(projStat.ApprovedBillTimeSum) : 0);
        let billLineFtAllocSum = (projStat.BillLineFutureAllocSum && projStat.BillLineFutureAllocSum !== null ? Number(projStat.BillLineFutureAllocSum) : 0);
        billEffort += ((billAppTimeSum / 8) + (billLineFtAllocSum));
        billApproveTimeSum += (billAppTimeSum / 8);
        billLineFutureAllocSum += (billLineFtAllocSum);

        // For NonBillable Effort
        let nbAppTimeSum = (projStat.ApprovedNonBillTimeSum && projStat.ApprovedNonBillTimeSum !== null ? Number(projStat.ApprovedNonBillTimeSum) : 0);
        let nbLineFtAllocSum = (projStat.NBLineFutureAllocSum && projStat.NBLineFutureAllocSum !== null ? Number(projStat.NBLineFutureAllocSum) : 0);
        nonBillEffort += ((nbAppTimeSum / 8) + (nbLineFtAllocSum));
        nbApproveTimeSum += (nbAppTimeSum / 8);
        nbLineFutureAllocSum += (nbLineFtAllocSum);

        // For Revenue
        tmBillAppTimeRev = projStat.TMLineActualRevenue && projStat.TMLineActualRevenue !== null ? 
                               Number(projStat.TMLineActualRevenue) : 0;
        tmFtAllocRev = projStat.TMLineBillFutureAllocDRSum && projStat.TMLineBillFutureAllocDRSum !== null ? 
                           Number(projStat.TMLineBillFutureAllocDRSum) : 0;

        // For Total Cost
        fixedLineTotCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                           (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) + */
                           (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0) + 
                           (projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0);

        tmLineTotCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                        (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0) + */
                        (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0) + 
                        (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0);

        // For Direct Cost
        fixedLineDirCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + */
                           (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0);

        tmLineDirCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + */
                        (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0);

        totalResourceDirectCost += Number(fixedLineDirCost + tmLineDirCost);
        totalResourceInDirectCost += ((projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0) + 
          (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0));

        teBasedTotalCost = (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                          (projStat.ActualOHSum && projStat.ActualOHSum !== null ? Number(projStat.ActualOHSum) : 0) +
                          (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0) + 
                          (projStat.ForecastOHSum && projStat.ForecastOHSum !== null ? Number(projStat.ForecastOHSum) : 0);

        teBasedDirectCost = (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                          (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0);

        totalResourceCostTE += Number(teBasedTotalCost);

        totalCostTEAct +=  (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                        (projStat.ActualOHSum && projStat.ActualOHSum !== null ? Number(projStat.ActualOHSum) : 0);
        totalCostTEFt += (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0) + 
                      (projStat.ForecastOHSum && projStat.ForecastOHSum !== null ? Number(projStat.ForecastOHSum) : 0);
      }

      let projcurrencyId = 0;
      let billingRegion = this.state.project.BillingRegion ? this.state.project.BillingRegion : this.state.project.Region.id;
      await getRegionCurrencyIntegrated(billingRegion).then(response => {
        projcurrencyId = response.data.CurrencyId;
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
      const proStartDate = new Date(project.StartDate);
      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
        projcurrencyId,
        proStartDate.getMonth() + 1,
        proStartDate.getFullYear()
      );

      if (projCms !== null) {
        let projRevenue = 0;
        let serviceRev = projCms.ServiceRevenueValue && projCms.ServiceRevenueValue !== null ? Number(projCms.ServiceRevenueValue / projrateRecordCurrencyRate) : 0;
        let discountPercentage = projCms.DiscountPercentage && projCms.DiscountPercentage !== null ? (Number(projCms.DiscountPercentage) / 100) : 0;
        // let nonBillExpenses = ((projCms.Status && projCms.Status == 'APPROVED') && (projCms.NonBillableTotalOtherExpenses && projCms.NonBillableTotalOtherExpenses !== null)) ?
        //                       (Number(projCms.NonBillableTotalOtherExpenses / projrateRecordCurrencyRate)) : 0;
        let otherExpensesTotal =   await this.calculateActualNonBillableOtherExpenses(projectID, projectVersion, projectStatus);

        totalNonBillableExpenses += Number(otherExpensesTotal);

        let salesComPercentage = ((projCms.Status && projCms.Status == 'APPROVED') && (projCms.SalesCommissionPercentage && projCms.SalesCommissionPercentage !== null)) ?
                                 (Number(projCms.SalesCommissionPercentage) / 100) : 0;

        // Revenue To Company
        if ((project.StatusConvertedToActive && project.StatusConvertedToActive === true) && (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 1)){ //Fixed
          const serviceRevenue = serviceRev.toFixed(2);
          // Contingency
          const contingencyValue = (Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue)) * (Number(projCms.ContingencyPercentage) / 100);
          // Value discount / (premium)
          const discountVal = ((Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
          const x = Math.pow(10, Number(2) + 1);
          const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
          // Revenue to company after discount
          const revenueAfterDiscount = Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue);

          projRevenue += revenueAfterDiscount;

          totalResourceRevenue += Number(projCms.TotalRevenueResourceAllocation / projrateRecordCurrencyRate);
          totalServiceRevenue += Number(serviceRevenue);
          totalDiscount += Number(valueDiscount);
          totalContingency += Number(contingencyValue);
        }
        if (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 2) { //T&M
          projRevenue += ((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * (1 - discountPercentage));
          revenueAct +=  (tmBillAppTimeRev + serviceRev) * (1 - discountPercentage);
          revenueFt += tmFtAllocRev * (1 - discountPercentage);

          totalResourceRevenue += Number(tmBillAppTimeRev + tmFtAllocRev);
          totalServiceRevenue += Number(serviceRev);
          const valDiscount = Number((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * discountPercentage).toFixed(2);
          totalDiscount += Number(valDiscount);
        }
        if (project.PaymentMethodRecord && project.PaymentMethodRecord.id == 3) { //Hybrid
          // Revenue for Fixed price lines
          let revForFixed = 0;
          if (projCms.Status && projCms.Status == 'APPROVED') {
            for (let mainLine of calculationResourceMix) {
              if (mainLine.PaymentMethod && mainLine.PaymentMethod == 1 && mainLine.Type && mainLine.Type == 'Project') {
                if (mainLine.ResourceRequestSubLine.length > 0) {
                  for (let subLine of mainLine.ResourceRequestSubLine) {
                    if (subLine.Status && (subLine.Status == 'COMPLETE' || subLine.Status == 'CLOSE')) {
                      revForFixed += (subLine.Revenue && subLine.Revenue !== null ?  Number(subLine.Revenue) : 0);
                    }
                  }
                }
                else {
                  if (mainLine.Status && (mainLine.Status == 'COMPLETE' || mainLine.Status == 'CLOSE')) {
                    revForFixed += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                  }
                }
              }
            }
          }

          // Revenue for T&M lines
          let revForTM = (tmBillAppTimeRev + tmFtAllocRev + serviceRev);
          projRevenue += ((revForFixed + revForTM) * (1 - discountPercentage));

          totalResourceRevenue += Number(revForFixed + tmBillAppTimeRev + tmFtAllocRev);
          totalServiceRevenue += Number(serviceRev);
          const valDiscount = Number((revForFixed + revForTM) * discountPercentage).toFixed(2);
          totalDiscount += Number(valDiscount);
        }
        revenue += projRevenue;

        // Total Cost
        totalCost += ((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (projRevenue * salesComPercentage));

        totalCostTE += (teBasedTotalCost + otherExpensesTotal + (projRevenue * salesComPercentage));
        directCostTE += (teBasedDirectCost + otherExpensesTotal);

        totalSalesCommission += Number(projRevenue * salesComPercentage);

        // Direct Cost
        directCost += ((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal));
        console.log("================================================================");
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} Revenue : `,projRevenue);
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} ResourceLinesCost : `,((fixedLineTotCost) + (tmLineTotCost)));
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} OtherExpensesTotal : `,(otherExpensesTotal));
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} SalesCommission : `,(projRevenue * salesComPercentage));
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} TotalCost : `,((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (projRevenue * salesComPercentage)));
        console.log(`Timesheet Actuals: PROJECT OPID ${project.OPID} DirectCost : `,((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal)));
        console.log("================================================================");

      }
    }

    // CR(s)
    if (crs && crs.length > 0) {
      for (let cr of crs) {
        if(cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) {
        let crCms = null;
        let tmBillAppTimeRev = 0;
        let tmFtAllocRev = 0;
        let fixedLineTotCost = 0;
        let tmLineTotCost = 0;
        let fixedLineDirCost = 0;
        let tmLineDirCost = 0;
        let teBasedTotalCost = 0;
        let teBasedDirectCost = 0;
        let crStatus = cr.Status ? cr.Status.StatusName : null;

        let projStat = projStatistics.length > 0 ? 
                       (projStatistics.filter(el => el.ProjectId == project.id && el.CrId == cr.id).length > 0 ? 
                       projStatistics.filter(el => el.ProjectId == project.id && el.CrId == cr.id)[0] : null) : null;

        const crVersion = cr.CMSheets.length > 0 ? cr.CMSheets[0].Version : null;
        const crID = cr.CMSheets.length > 0 ? cr.CMSheets[0].id : null;

        if (crID !== null && crVersion !== null) {
          await getCMSheet(crID, crVersion).then(res => {
            if (res.data && res.data !== null) {
              crCms = res.data;
            }
          }).catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        }

        if (projStat && projStat !== null) {
          // For Est Project Duration
          firstAppDate = (firstAppDate !== null && projStat.FirstApproveDate && projStat.FirstApproveDate !== null) ? 
                       ( (firstAppDate < projStat.FirstApproveDate) ? (firstAppDate) : (projStat.FirstApproveDate) ) : 
                       ( (firstAppDate !== null) ? (firstAppDate) : 
                       ( (projStat.FirstApproveDate && projStat.FirstApproveDate !== null) ? (projStat.FirstApproveDate) : (null) ) );

          latestAppDate = (latestAppDate !== null && projStat.LatestApproveDate && projStat.LatestApproveDate !== null) ? 
                          ( (latestAppDate > projStat.LatestApproveDate) ? (latestAppDate) : (projStat.LatestApproveDate) ) : 
                          ( (latestAppDate !== null) ? (latestAppDate) : 
                          ( (projStat.LatestApproveDate && projStat.LatestApproveDate !== null) ? (projStat.LatestApproveDate) : (null) ) );

          furthestAllocDate = (furthestAllocDate !== null && projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? 
                              ( (furthestAllocDate > projStat.FurthestAllocDate) ? (furthestAllocDate) : (projStat.FurthestAllocDate) ) : 
                              ( (furthestAllocDate !== null) ? (furthestAllocDate) : 
                              ( (projStat.FurthestAllocDate && projStat.FurthestAllocDate !== null) ? (projStat.FurthestAllocDate) : (null) ) );

          earliestAllocDate = (earliestAllocDate !== null && projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? 
                              ( (earliestAllocDate < projStat.EarliestAllocDate) ? (earliestAllocDate) : (projStat.EarliestAllocDate) ) : 
                              ( (earliestAllocDate !== null) ? (earliestAllocDate) : 
                              ( (projStat.EarliestAllocDate && projStat.EarliestAllocDate !== null) ? (projStat.EarliestAllocDate) : (null) ) );

          // For Fixed Price Effort
          let fxdLineAppTimeSum = (projStat.FixedLineApproveTimeSum && projStat.FixedLineApproveTimeSum !== null ? Number(projStat.FixedLineApproveTimeSum) : 0);
          let fxdLineFtAllocSum = (projStat.FixedLineFutureAllocSum && projStat.FixedLineFutureAllocSum !== null ? Number(projStat.FixedLineFutureAllocSum) : 0);
          fixedEffort += ((fxdLineAppTimeSum / 8) + (fxdLineFtAllocSum));
          fixedLineApproveTimeSum += (fxdLineAppTimeSum / 8);
          fixedLineFutureAllocSum += fxdLineFtAllocSum;
          
          // For T&M Effort
          let tmLineAppTimeSum = (projStat.TMLineApproveTimeSum && projStat.TMLineApproveTimeSum !== null ? Number(projStat.TMLineApproveTimeSum) : 0);
          let tmLineFtAllocSum = (projStat.TMLineFutureAllocSum && projStat.TMLineFutureAllocSum !== null ? Number(projStat.TMLineFutureAllocSum) : 0);
          tmEffort += ((tmLineAppTimeSum / 8) + (tmLineFtAllocSum));
          tmLineApproveTimeSum += (tmLineAppTimeSum / 8);
          tmLineFutureAllocSum += tmLineFtAllocSum;

          // For Billable Effort
          let billAppTimeSum = (projStat.ApprovedBillTimeSum && projStat.ApprovedBillTimeSum !== null ? Number(projStat.ApprovedBillTimeSum) : 0);
          let billLineFtAllocSum = (projStat.BillLineFutureAllocSum && projStat.BillLineFutureAllocSum !== null ? Number(projStat.BillLineFutureAllocSum) : 0);
          billEffort += ((billAppTimeSum / 8) + (billLineFtAllocSum));
          billApproveTimeSum += (billAppTimeSum / 8);
          billLineFutureAllocSum += (billLineFtAllocSum);

          // For NonBillable Effort
          let nbAppTimeSum = (projStat.ApprovedNonBillTimeSum && projStat.ApprovedNonBillTimeSum !== null ? Number(projStat.ApprovedNonBillTimeSum) : 0);
          let nbLineFtAllocSum = (projStat.NBLineFutureAllocSum && projStat.NBLineFutureAllocSum !== null ? Number(projStat.NBLineFutureAllocSum) : 0);
          nonBillEffort += ((nbAppTimeSum / 8) + (nbLineFtAllocSum));
          nbApproveTimeSum += (nbAppTimeSum / 8);
          nbLineFutureAllocSum += (nbLineFtAllocSum);

          // For Revenue
          tmBillAppTimeRev = projStat.TMLineActualRevenue && projStat.TMLineActualRevenue !== null ? 
                                 Number(projStat.TMLineActualRevenue) : 0;
          tmFtAllocRev = projStat.TMLineBillFutureAllocDRSum && projStat.TMLineBillFutureAllocDRSum !== null ? 
                             Number(projStat.TMLineBillFutureAllocDRSum) : 0;

          // For Total Cost
          fixedLineTotCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + 
                             (projStat.FixedLineCapValOHSum && projStat.FixedLineCapValOHSum !== null ? Number(projStat.FixedLineCapValOHSum) : 0) + */
                             (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0) + 
                             (projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0);

          tmLineTotCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + 
                          (projStat.TMLineCapValOHSum && projStat.TMLineCapValOHSum !== null ? Number(projStat.TMLineCapValOHSum) : 0) + */
                          (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0) + 
                          (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0);

          // For Direct Cost
          fixedLineDirCost = /*(projStat.FixedLineCapValDCSum && projStat.FixedLineCapValDCSum !== null ? Number(projStat.FixedLineCapValDCSum) : 0) + */
                             (projStat.FixedLineFutureAllocDCSum && projStat.FixedLineFutureAllocDCSum !== null ? Number(projStat.FixedLineFutureAllocDCSum) : 0);

          tmLineDirCost = /*(projStat.TMLineCapValDCSum && projStat.TMLineCapValDCSum !== null ? Number(projStat.TMLineCapValDCSum) : 0) + */
                          (projStat.TMLineFutureAllocDCSum && projStat.TMLineFutureAllocDCSum !== null ? Number(projStat.TMLineFutureAllocDCSum) : 0);

          
          totalResourceDirectCost += Number(fixedLineDirCost + tmLineDirCost);
          totalResourceInDirectCost += ((projStat.FixedLineFutureAllocOHSum && projStat.FixedLineFutureAllocOHSum !== null ? Number(projStat.FixedLineFutureAllocOHSum) : 0) + 
            (projStat.TMLineFutureAllocOHSum && projStat.TMLineFutureAllocOHSum !== null ? Number(projStat.TMLineFutureAllocOHSum) : 0));
        
          teBasedTotalCost = (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                            (projStat.ActualOHSum && projStat.ActualOHSum !== null ? Number(projStat.ActualOHSum) : 0) +
                            (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0) + 
                            (projStat.ForecastOHSum && projStat.ForecastOHSum !== null ? Number(projStat.ForecastOHSum) : 0);

          teBasedDirectCost = (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                              (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0);

          totalResourceCostTE += Number(teBasedTotalCost);

          totalCostTEAct +=  (projStat.ActualDCSum && projStat.ActualDCSum !== null ? Number(projStat.ActualDCSum) : 0) + 
                          (projStat.ActualOHSum && projStat.ActualOHSum !== null ? Number(projStat.ActualOHSum) : 0);
          totalCostTEFt += (projStat.ForecastDCSum && projStat.ForecastDCSum !== null ? Number(projStat.ForecastDCSum) : 0) + 
                         (projStat.ForecastOHSum && projStat.ForecastOHSum !== null ? Number(projStat.ForecastOHSum) : 0);

        }

        let currencyIdCR = 0;
        await getRegionCurrencyIntegrated(cr.BillingRegion).then(response => {
          currencyIdCR = response.data.CurrencyId;
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });
        const crStartDate = new Date(cr.StartDate);
        const crRateRecordCurrencyRate = await retrieveConversionRateIntegrated(
            currencyIdCR,
            crStartDate.getMonth() + 1,
            crStartDate.getFullYear()
        );

        if (crCms !== null) {
          let crRevenue = 0;
          let serviceRev = crCms.ServiceRevenueValue && crCms.ServiceRevenueValue !== null ? Number(crCms.ServiceRevenueValue / crRateRecordCurrencyRate) : 0;
          let discountPercentage = crCms.DiscountPercentage && crCms.DiscountPercentage !== null ? (Number(crCms.DiscountPercentage) / 100) : 0;
          // let nonBillExpenses = (crCms.Status && crCms.Status == 'APPROVED') && (crCms.NonBillableTotalOtherExpenses && crCms.NonBillableTotalOtherExpenses !== null) ?
          //                       Number(crCms.NonBillableTotalOtherExpenses / crRateRecordCurrencyRate) : 0;
          let otherExpensesTotal = await this.calculateActualNonBillableOtherExpenses(crID, crVersion, crStatus);

          totalNonBillableExpenses += Number(otherExpensesTotal);
                        
          let salesComPercentage = (crCms.Status && crCms.Status == 'APPROVED') && (crCms.SalesCommissionPercentage && crCms.SalesCommissionPercentage !== null) ?
                                   (Number(crCms.SalesCommissionPercentage) / 100) : 0;

          // Revenue To Company
          if ((cr.StatusConvertedToActive && cr.StatusConvertedToActive === true) && (cr.PaymentMethodId && cr.PaymentMethodId == 1)) { //Fixed
            const serviceRevenue = serviceRev.toFixed(2);
            // Contingency
            const contingencyValue = (Number(crCms.TotalRevenueResourceAllocation  / crRateRecordCurrencyRate) + Number(serviceRevenue)) * (Number(crCms.ContingencyPercentage) / 100);
            // Value discount / (premium)
            const discountVal = ((Number(crCms.TotalRevenueResourceAllocation  / crRateRecordCurrencyRate) + Number(serviceRevenue) + Number(contingencyValue)) * (discountPercentage)).toFixed(2);
            const x = Math.pow(10, Number(2) + 1);
            const valueDiscount = Number((Number(discountVal) + (1 / x)).toFixed(2));
            // Revenue to company after discount
            const revenueAfterDiscount = Number(crCms.TotalRevenueResourceAllocation / crRateRecordCurrencyRate) + Number(serviceRevenue) - Number(valueDiscount) + Number(contingencyValue);

            crRevenue += revenueAfterDiscount;

            totalResourceRevenue += Number(crCms.TotalRevenueResourceAllocation / crRateRecordCurrencyRate);
            totalServiceRevenue += Number(serviceRevenue);
            totalDiscount += Number(valueDiscount);
            totalContingency += Number(contingencyValue);
          }
          if (cr.PaymentMethodId && cr.PaymentMethodId == 2) { //T&M
            crRevenue += ((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * (1 - discountPercentage));
            revenueAct +=  (tmBillAppTimeRev + serviceRev) * (1 - discountPercentage);
            revenueFt += tmFtAllocRev * (1 - discountPercentage);

            totalResourceRevenue += Number(tmBillAppTimeRev + tmFtAllocRev);
            totalServiceRevenue += Number(serviceRev);
            const valDiscount = Number((tmBillAppTimeRev + tmFtAllocRev + serviceRev) * discountPercentage).toFixed(2);
            totalDiscount += Number(valDiscount);
          }
          if (cr.PaymentMethodId && cr.PaymentMethodId == 3) { //Hybrid
            // Revenue for Fixed lines
            let revForFixed = 0;
            if (crCms.Status && crCms.Status == 'APPROVED') {
              for (let mainLine of calculationResourceMix) {
                if (mainLine.PaymentMethod && mainLine.PaymentMethod === 1 && mainLine.Type && mainLine.Type == 'ChangeRequest' && mainLine.ProjectId === cr.id) {
                  if (mainLine.ResourceRequestSubLine.length > 0) {
                    for (let subLine of mainLine.ResourceRequestSubLine) {
                      if (subLine.Status && (subLine.Status == 'COMPLETE' || subLine.Status == 'CLOSE')) {
                        revForFixed += (subLine.Revenue && subLine.Revenue !== null ? Number(subLine.Revenue) : 0);
                      }
                    }
                  }
                  else {
                    if (mainLine.Status && (mainLine.Status == 'COMPLETE' || mainLine.Status == 'CLOSE')) {
                      revForFixed += (mainLine.TotalRevenue && mainLine.TotalRevenue !== null ? Number(mainLine.TotalRevenue) : 0);
                    }
                  }
                }
              }
            }
            
            // Revenue for T&M lines
            let revForTM = (tmBillAppTimeRev + tmFtAllocRev + serviceRev);
            crRevenue += ((revForFixed + revForTM) * (1 - discountPercentage));

            totalResourceRevenue += Number(revForFixed + tmBillAppTimeRev + tmFtAllocRev);
            totalServiceRevenue += Number(serviceRev);
            const valDiscount = Number((revForFixed + revForTM) * discountPercentage).toFixed(2);
            totalDiscount += Number(valDiscount);  
          }         
          revenue += crRevenue;

          // Total Cost
          totalCost += ((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (crRevenue * salesComPercentage));

          totalCostTE += (teBasedTotalCost + otherExpensesTotal + (crRevenue * salesComPercentage));
          directCostTE += (teBasedDirectCost + otherExpensesTotal);

          totalSalesCommission += Number(crRevenue * salesComPercentage);

          // Direct Cost
          directCost += ((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal));

          console.log("================================================================");
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} Revenue : `,crRevenue);
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} ResourceLinesCost : `,((fixedLineTotCost) + (tmLineTotCost)));
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} OtherExpensesTotal : `,(otherExpensesTotal));
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} SalesCommission : `,(crRevenue * salesComPercentage));
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} TotalCost : `,((fixedLineTotCost) + (tmLineTotCost) + (otherExpensesTotal) + (crRevenue * salesComPercentage)));
          console.log(`Timesheet Actuals: CR OPID ${cr.OPID} DirectCost : `,((fixedLineDirCost) + (tmLineDirCost) + (otherExpensesTotal)));
          console.log("================================================================");

        }
      }
      }
    }

    // Est Project Duration
    let startDateAct = firstAppDate !== null ? new Date(firstAppDate) : null;
    let endDateAct = latestAppDate !== null ? new Date(latestAppDate) : null;
    if (startDateAct !== null && endDateAct !== null) {
      endDateAct.setDate(endDateAct.getDate() + 1);
      estDurationAct = moment(endDateAct).diff(moment(startDateAct), 'days');
    } else {
      estDurationAct = 0;
    }

    let startDateFt = latestAppDate !== null ? new Date(latestAppDate) : new Date(earliestAllocDate);
    let endDateFt = furthestAllocDate !== null ? new Date(furthestAllocDate) : null;
    if (startDateFt !== null && endDateFt !== null) {
      if(latestAppDate !== null){
        startDateFt.setDate(startDateFt.getDate() + 1);
      }
      endDateFt.setDate(endDateFt.getDate() + 1);

      estDurationFt = moment(endDateFt).diff(moment(startDateFt), 'days');
    } else {
      estDurationFt = 0
    }
    estDuration = estDurationAct + estDurationFt;
    
    // Contracted Effort(Days), Avg Rate Per Day
    contractedEffort = Number(billEffort.toFixed(2)) + Number(nonBillEffort.toFixed(2));
    contractedEffAct = Number(billApproveTimeSum.toFixed(2)) + Number(nbApproveTimeSum.toFixed(2));
    contractedEffFt = Number(billLineFutureAllocSum.toFixed(2)) + Number(nbLineFutureAllocSum.toFixed(2));
    avgRatePerDay = contractedEffort !== 0 ? (Number(revenue.toFixed(2)) / contractedEffort) : 0;

    // Gross Margin, Gross Margin Percentage
    grossMargin = Number(revenue.toFixed(2)) - Number(directCost.toFixed(2));
    grossMarginPercentage = revenue !== 0 ? (Number(grossMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

    // Net Margin, Net Margin Percentage
    netMargin = Number(revenue.toFixed(2)) - Number(totalCost.toFixed(2));
    netMarginPercentage = revenue !== 0 ? (Number(netMargin.toFixed(2)) / Number(revenue.toFixed(2))) : 0;
    console.log("Timesheet Actuals Revenue : ",revenue)
    console.log("Timesheet Actuals TotalCost : ",totalCost)
    console.log("Timesheet Actuals DirectCost : ",directCost)

    netMarginTE = Number(revenue.toFixed(2)) - Number(totalCostTE.toFixed(2));
    netMarginPercentageTE = revenue !== 0 ? (Number(netMarginTE.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

    grossMarginTE = Number(revenue.toFixed(2)) - Number(directCostTE.toFixed(2));
    grossMarginPercentageTE = revenue !== 0 ? (Number(grossMarginTE.toFixed(2)) / Number(revenue.toFixed(2))) : 0;

    // CMS Values - For Variance Calculations
    let totEffDaysCms = (cmsCalculations !== null && cmsCalculations.totalEffortDaysCMS && cmsCalculations.totalEffortDaysCMS !== null) ? 
                        (Number(cmsCalculations.totalEffortDaysCMS).toFixed(2)) : 0;
    let fixedPriceEffCms = (cmsCalculations !== null && cmsCalculations.fixedPriceEffortApproved && cmsCalculations.fixedPriceEffortApproved !== null) ? 
                           (Number(cmsCalculations.fixedPriceEffortApproved).toFixed(2)) : 0;
    let tmEffCms = (cmsCalculations !== null && cmsCalculations.TMEffortOngoingApproved && cmsCalculations.TMEffortOngoingApproved !== null) ? 
                   (Number(cmsCalculations.TMEffortOngoingApproved).toFixed(2)) : 0;
    let revenueCms = (cmsCalculations !== null && cmsCalculations.discountedRevenueToFortudeCMS && cmsCalculations.discountedRevenueToFortudeCMS !== null) ? 
                     (Number(cmsCalculations.discountedRevenueToFortudeCMS).toFixed(2)) : 0;
    let grossProfitCms = (cmsCalculations !== null && cmsCalculations.TotalGrossProfit && cmsCalculations.TotalGrossProfit !== null) ? 
                         (Number(cmsCalculations.TotalGrossProfit).toFixed(2)) : 0;
    let netProfitCms = (cmsCalculations !== null && cmsCalculations.netProfitLossCMS && cmsCalculations.netProfitLossCMS !== null) ? 
                       (Number(cmsCalculations.netProfitLossCMS).toFixed(2)) : 0;
    let billableEffortDaysCms = (cmsCalculations !== null && cmsCalculations.billableEffortDays) ? 
                      (Number(cmsCalculations.billableEffortDays).toFixed(2)) : 0;
    let nonBillableEffortDaysCms = (cmsCalculations !== null && cmsCalculations.nonBillableEffortDays) ? 
                      (Number(cmsCalculations.nonBillableEffortDays).toFixed(2)) : 0;

    // Variance Calculations
    conEffVariance = Number(contractedEffort.toFixed(2)) - Number(totEffDaysCms);
    conEffVarPercentage = Number(totEffDaysCms) !== 0 ? ((Number(conEffVariance) / Number(totEffDaysCms)) * 100 ) : 0;
    fixedEffVariance = Number(fixedEffort.toFixed(2)) - Number(fixedPriceEffCms);
    fixedEffVarPercentage = Number(fixedPriceEffCms) !== 0 ? ((Number(fixedEffVariance) / Number(fixedPriceEffCms)) * 100 ) : 0;
    tmEffVariance = Number(tmEffort.toFixed(2)) - Number(tmEffCms);
    tmEffVarPercentage = Number(tmEffCms) !== 0 ? ((Number(tmEffVariance) / Number(tmEffCms)) * 100 ) : 0;
    billVariance = Number(billEffort.toFixed(2)) - Number(billableEffortDaysCms);
    billVarPercentage = Number(billableEffortDaysCms) !== 0 ? ((Number(billVariance) / Number(billableEffortDaysCms)) * 100 ) : 0;
    nbVariance = Number(nonBillEffort.toFixed(2)) - Number(nonBillableEffortDaysCms);
    nbVarPercentage = Number(nonBillableEffortDaysCms) !== 0 ? ((Number(nbVariance) / Number(nonBillableEffortDaysCms)) * 100 ) : 0;;
    revVariance = Number(revenue.toFixed(2)) - Number(revenueCms);
    revVarPercentage = Number(revenueCms) !== 0 ? ((Number(revVariance) / Number(revenueCms)) * 100 ) : 0;
    avgRateVariance = Number(avgRatePerDay.toFixed(2)) - (Number(totEffDaysCms) !== 0 ? Number((Number(revenueCms) / Number(totEffDaysCms)).toFixed(2)) : 0);
    avgRateVarPercentage = (Number(totEffDaysCms) !== 0 && Number(revenueCms) !== 0) ? (( Number(avgRateVariance) / Number((Number(revenueCms) / Number(totEffDaysCms)).toFixed(2)) ) * 100 ) : 0;
    grossMarginVariance = Number(grossMargin.toFixed(2)) - Number(grossProfitCms);
    grossMarginVarPercentage = Number(grossProfitCms) !== 0 ? ((Number(grossMarginVariance) / Number(grossProfitCms)) * 100 ) : 0;
    netMarginVariance = Number(netMargin.toFixed(2)) - Number(netProfitCms);
    netMarginVarPercentage = Number(netProfitCms) !== 0 ? ((Number(netMarginVariance) / Number(netProfitCms)) * 100 ) : 0;

    grossMarginVarianceTE = Number(grossMarginTE.toFixed(2)) - Number(grossProfitCms);
    grossMarginVarPercentageTE = Number(grossProfitCms) !== 0 ? ((Number(grossMarginVarianceTE) / Number(grossProfitCms)) * 100 ) : 0;
    netMarginVarianceTE = Number(netMarginTE.toFixed(2)) - Number(netProfitCms);
    netMarginVarPercentageTE = Number(netProfitCms) !== 0 ? ((Number(netMarginVarianceTE) / Number(netProfitCms)) * 100 ) : 0;

    calculatedActValues = {
      EstDuration: estDuration,
      EstDurationAct: estDurationAct,
      EstDurationFt: estDurationFt,
      FixedEffort: fixedEffort,
      FixedEffortAct: fixedLineApproveTimeSum,
      FixedEffortFt: fixedLineFutureAllocSum,
      TMEffort: tmEffort,
      TMEffortAct: tmLineApproveTimeSum,
      TMEffortFt: tmLineFutureAllocSum,
      ContractedEffort: contractedEffort * 8,
      ContractedEffortDays: contractedEffort,
      ContractedEffortAct: contractedEffAct,
      ContractedEffortFt: contractedEffFt,
      Billable: billEffort,
      BillApproveTimeSum: billApproveTimeSum,
      BillLineFutureAllocSum: billLineFutureAllocSum,
      NonBillable: nonBillEffort,
      NBApproveTimeSum: nbApproveTimeSum,
      NBLineFutureAllocSum: nbLineFutureAllocSum,
      Revenue: revenue,
      AvgRatePerDay: avgRatePerDay,
      GrossMargin: grossMargin,
      GrossPercentage: grossMarginPercentage,
      NetMargin: netMargin,
      NetPercentage: netMarginPercentage,

      // Revenue and Cost values
      TotalResourceRevenue: totalResourceRevenue,
      TotalServiceRevenue: totalServiceRevenue,
      TotalDiscount: totalDiscount,
      TotalResourceDirectCost: totalResourceDirectCost,
      TotalResourceInDirectCost: totalResourceInDirectCost,
      TotalResourceCost: Number(totalResourceDirectCost) + Number(totalResourceInDirectCost),
      TotalNonBillableExpenses: totalNonBillableExpenses,
      TotalSalesCommission: totalSalesCommission,
      TotalContingency: totalContingency,
      TotalCost: totalCost,

      GrossMarginTE: grossMarginTE,
      GrossPercentageTE: grossMarginPercentageTE,
      NetMarginTE: netMarginTE,
      NetPercentageTE: netMarginPercentageTE,
      TotalResourceCostTE: Number(totalResourceCostTE),
      TotalCostTE: totalCostTE,
      GrossMarginVarianceTE: grossMarginVarianceTE,
      GrossMarginVarPercentageTE: grossMarginVarPercentageTE,
      NetMarginVarianceTE: netMarginVarianceTE,
      NetMarginVarPercentageTE: netMarginVarPercentageTE,
      TotalCostTEAct: Number(totalCostTEAct).toFixed(2),
      TotalCostTEFt: Number(totalCostTEFt).toFixed(2),

      // Variance
      ConEffVariance: conEffVariance,
      ConEffVarPercentage: conEffVarPercentage,
      FixedEffVariance: fixedEffVariance,
      FixedEffVarPercentage: fixedEffVarPercentage,
      TmEffVariance: tmEffVariance,
      TmEffVarPercentage: tmEffVarPercentage,
      BillVariance: billVariance,
      BillVarPercentage: billVarPercentage,
      NBVariance: nbVariance,
      NBVarPercentage: nbVarPercentage,
      RevVariance: revVariance,
      RevVarPercentage: revVarPercentage,
      AvgRateVariance: avgRateVariance,
      AvgRateVarPercentage: avgRateVarPercentage,
      GrossMarginVariance: grossMarginVariance,
      GrossMarginVarPercentage: grossMarginVarPercentage,
      NetMarginVariance: netMarginVariance,
      NetMarginVarPercentage: netMarginVarPercentage,
      RevenueAct: Number(revenueAct).toFixed(2),
      RevenueFt: Number(revenueFt).toFixed(2)
    };

    this.setState({
      calculatedActValues: calculatedActValues
    });
    this.populateInitialCMSToGrid();
  };

  handleComboChange = async event => {
    this.setState({
      isMount: true
    });
    const value = event.target.value;
    const name = event.target.name;

    switch (name) {
      case 'newResProjectOrCRName':
        await this.getPaymentTypeToAdd(value);
        await this.checkProjectRevenue();
        await this.getCurrencyForRegion(value);
        await this.setState({
          newResProjectOrCRName: value,
          hasUnSavedData: true,
          newResPhase: null,
          newResSubPhase: null
        });
        await this.calculateEffortForAddResource();
        if (this.state.newResRole) {
          await this.getAllocationRates(this.state.newResRole, 'add', '')
        }
        if (this.state.newStartDate && this.state.newResProjectOrCRName) {
          if (new Date(this.state.newResProjectOrCRName.StartDate) > new Date(this.state.newStartDate) ) {
            await this.setState({
              newStartDate: null,
              newEndDate: null
            });
          }
        }
        if (value) {
          let phasesTemp = this.state.phasesTempAll.filter(res => res.CMSheetId === value.CMSheetId);
          this.setState({
            phasesTemp: phasesTemp
          });
        } else {
          this.setState({
            phasesTemp: this.state.phasesTempAll
          });
        }
        break;
      case 'newResPhase':
        let filteredArr = [];
        if (value) {
          //this.TmpSubPhasesAll = value.SubPhases;
          filteredArr = this.state.phasesTemp.filter(function (itm) {
            return value.Text === itm.Text;
          });
          this.TmpSubPhasesAll = filteredArr[0].SubPhases;
          const phasesTempAll = this.state.phasesTempAll
          this.setState({
            newStartDate: value.StartDate ? new Date(value.StartDate) : null,
            newStartDateMin: value.StartDate ? reStructureDate(value.StartDate) : null,
            newEffortDays: '',
            newEndDate: null,
            phasesTemp: phasesTempAll
            // newAllocation: ''
          });
        } else {
          this.TmpSubPhasesAll = [];
          this.setState({
            newStartDateMin: null
          });
        }

        let phasesTempAll = this.state.phasesTempAll;
        if (this.state.newResProjectOrCRName) {
          phasesTempAll = this.state.phasesTemp
        }
        this.setState({
          newResPhase: value,
          hasUnSavedData: true,
          tmpSubPhasesAll: this.TmpSubPhasesAll,
          newResSubPhase: null,
          phasesTemp: phasesTempAll
        });
        break;
      case 'newResSubPhase':
        if (value) {
          this.setState({
            newResSubPhase: value,
            hasUnSavedData: true,
            newStartDate: value.StartDate ? new Date(value.StartDate) : null,
            newStartDateMin: value.StartDate ? reStructureDate(value.StartDate) : null,
            newEffortDays: '',
            newEndDate: null
            // newAllocation: ''
          });
        } else {
          this.setState({
            newStartDateMin: reStructureDate(this.state.newResPhase.StartDate)
          });
        }
        this.setState({
          newResSubPhase: value,
          hasUnSavedData: true,
        });
        break;

      case 'newResRole':
        if (value) {
          await this.getAllocationRates(value, 'add', '');
          let Designation = null;
          let filteredArray = this.designationsAll.filter(function (itm) {
            return value.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
          });
          this.TmpDesignationsAll = filteredArray;

          let desig = this.designationsAll.filter(function (itm) {
            return value.id === itm.id;
          });
          this.TmpDesignation = desig[0];
        } else {
          this.TmpDesignationsAll = [];
          this.TmpDesignation = [];
        }

        this.setState({
          newResRole: value,
          hasUnSavedData: true,
          tmpDesignationsAll: this.TmpDesignationsAll,
          newResDesignation: this.TmpDesignation
        });
        break;
      case 'newResDesignation':
        if (value && value.Name === 'Outsourced') {
          this.toggleOutsourcedCostDialog();
        }

        this.setState({
          newResDesignation: value,
          hasUnSavedData: true
        });
        break;
      case 'newResRegion':
        await this.setState({
          newResRegion: value,
          hasUnSavedData: true
        });
        await this.validateCostforRegion();
        break;
      case 'newOnSiteStatus':
        this.setState({
          newOnSiteStatus: value,
          hasUnSavedData: true
        });
        this.calculateEffortForAddResource();
        break;

      case 'newPaymentMethod':
        this.setState({
          newPaymentMethod: value,
          hasUnSavedData: true,
          newRate: null
        });
        break;

      case 'newRate':
        this.setState({
          newRate: value,
          hasUnSavedData: true
        });
        await this.populateRateValue(value);
        break;

      default:
        break;
    }

  };

  handleNumericChange = async event => {
    const value = event.value;
    const name = event.field;
    const dataItem = event.dataItem;

    this.setState({
      isLoading: true
    });
    var decimal = /^[-+]?[0-9]+\.[0-9]+$/;
    switch (name) {
      case 'RateValue':
        dataItem[name] = value;
        if (!value || value === '0') {
          dataItem.RateValue = 0;
          this.setState({ isErrorRateValue: true });
        } else {
          this.setState({ isErrorRateValue: false });
        }
        break;
    }
  };

  populateRateValue = async dataItem => {

    if (dataItem) {
      dataItem.RateValueDisabled = true;
      dataItem.RateValue = dataItem.Rate ? dataItem.Rate : 0;
      dataItem.LastCustomerRateProject = '';

      switch (dataItem.Code) {
        /*case LAST_CUS_RATE: {
          // Last Customer Rates
          dataItem.LastCustomerRateProject = dataItem.dataValueRateType.LastCustomerRateProject;
          break;
        }*/
        case CUSTOM_RATE: {
          // Custom Rate
          dataItem.RateValueDisabled = false;
          dataItem.RateValue = 0;
          dataItem.Rate = 0;
          break;
        }
        default:{

        }
      }
      if (!dataItem.RateValue) {
        dataItem.RateValue = 0;
        dataItem.Rate = 0;
      }
    } /*else {
      dataItem.RateValue = 0;
    }*/
  };

  handleComboChangeEdit = async event => {
    const value = event.target.value;
    const name = event.target.name;
    switch (name) {
      case 'editRole':
        if (value) {
          let filteredArray = this.designationsAll.filter(function (itm) {
            return value.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
          });
          this.TmpDesignationsAll = filteredArray;
          this.state.editableItem.dataValueProjectRole = value;
          this.state.editableItem.ProjectRole = value.id;
          this.state.tmpDesignationsAll = filteredArray;
        } else {
          this.TmpDesignationsAll = [];
          this.state.tmpDesignationsAll = [];
          this.state.editableItem.dataValueProjectRole = null;
          this.state.editableItem.ProjectRole = null;
        }
        this.state.editableItem.dataValueCostDesignation = null;
        this.state.editableItem.Designation = null;
        this.forceUpdate();
        break;
      case 'editDesignation':
        if (value) {
          //const index = event.dataItem.index;
          const index = event.target.index;
          this.state.editableItem.dataValueCostDesignation = value;
          this.state.editableItem.Designation = value.id;

          if (value && value.Name === 'Outsourced') {
            await this.setState(
              {
                OutsourcedCostDataItemIndex: index
              },
              () => {
                this.toggleOutsourcedCostDialog();
              }
            );
          }
        } else {
          this.state.editableItem.dataValueCostDesignation = null;
          this.state.editableItem.Designation = null;
        }
        this.forceUpdate();
        break;
      case 'editRegion':
        if (value) {
          this.state.editableItem.dataValueResourceRegion = value;
          this.state.editableItem.ResourceRegion = value.id;
        } else {
          this.state.editableItem.dataValueResourceRegion = null;
          this.state.editableItem.ResourceRegion = null;
        }
        this.forceUpdate();
        break;
      case 'editOnSiteStatus':
        if (value) {
          this.state.editableItem.OnsiteStatus = value.Name;
        } else {
          this.state.editableItem.OnsiteStatus = null;
        }
        this.forceUpdate();
        break;
      case 'editAllocatedResource':
        if (value) {
          await this.setAllocatedDesignation(value);
          this.state.editableItem.AllocatedResource = value;
          this.state.editableItem.AllocatedResourceID = value.id;
          this.state.editableItem.ResourceId = value.id;
          this.state.editableItem.AllocatedDesignation = this.state.allocatedDesignation;
        } else {
          this.state.editableItem.AllocatedResource = null;
          this.state.editableItem.AllocatedResourceID = null;
          this.state.editableItem.ResourceId = null;
          this.state.editableItem.AllocatedDesignation = null;
        }
        this.forceUpdate();
        break;
      case 'editPhase':
        if (value) {
          this.state.editableItem.Phase = value;
          this.state.editableItem.PhaseId = value.id;

          let subPhasesOfSelectedPhase = [];
          if (value && value.SubPhases && value.SubPhases.length > 0) {
            subPhasesOfSelectedPhase = value.SubPhases;
          }

          this.setState({
            subPhasesOfSelectedPhase: subPhasesOfSelectedPhase
          });

        } else {
          this.state.editableItem.Phase = null;
          this.state.editableItem.PhaseId = null;

          this.state.editableItem.SubPhase = null;
          this.state.editableItem.SubPhaseId = null;

          this.setState({
            subPhasesOfSelectedPhase: []
          });

        }
        break;
      case 'editSubPhase':
        if (value) {
          this.state.editableItem.SubPhase = value;
          this.state.editableItem.SubPhaseId = value.id;
        } else {
          this.state.editableItem.SubPhase = null;
          this.state.editableItem.SubPhaseId = null;
        }
        this.forceUpdate();
        break;
      default:
        break;
    }
  };

  setAllocatedDesignation = async (value) => {
    let selectedDesignation = this.designationsAll.filter(
      resource => resource.id === value.DesignationId
    );
    await this.setState({
      allocatedDesignation: selectedDesignation[0].Name
    });
  };

  handleAddStartDateChange = event => {
    let startDate = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'editStartDate':
        this.state.editableItem.StartDate = moment((new Date(startDate))).format('MM/DD/YYYY');
        this.forceUpdate();
        break;
      case 'editEndDate':
        this.state.editableItem.StartDate = moment((new Date(startDate))).format('MM/DD/YYYY');
        this.forceUpdate();
        break;
      case 'addStartDate':
        if (startDate) {
          this.setState({
            newStartDate: (new Date(startDate)),
            hasUnSavedData: true
          });

          this.calculateEffortForAddResource();
        } else {
          this.setState({
            newStartDate: null
          });
        }

        break;
      default:
        break;
    }
  };

  handleAddEndDateChange = event => {
    let endDate = event.target.value;
    let name = event.target.name;
    switch (name) {
      case 'addEndDate':
        if (endDate) {
          this.setState({
            newEndDate: (new Date(endDate)),
            hasUnSavedData: true
          });

          this.calculateEffortForAddResource();
        } else {
          this.setState({
            newEndDate: null
          });
        }

        break;
      default:
        break;
    }
  };

  handleAddEffortDaysChange = event => {

    let value = event.target.value;
    if (value < 0) {
      value = 0;
    }
    switch (event.target.name) {
      case 'editEffortDays':
        this.state.editableItem.EffortDays = Number((Number(value)).toFixed(2));
        this.state.hasUnSavedData = true;
        this.forceUpdate();
        break;
      case 'newEffortDays':
        this.state.newEffortDays = formatNumber(this.state.newEffortDays);
        this.state.hasUnSavedData = true;
        this.forceUpdate();
        break;
      default:
        break;
    }
  };

  handleAddAllocationChange = event => {
    let value = event.target.value;
    let name = event.target.name;

    switch (name) {
      case 'newAllocation':
        if (value < 0) {
          value = 0;
        } else if (value > 100) {
          value = 100;
        } else if (!value) {
          value = 100;
        }
        this.setState({
          newAllocation: Number((Number(value)).toFixed(2)),
          hasUnSavedData: true
        });
        this.calculateEffortForAddResource();
        break;

      case 'newRateValue':
        if (value < 0) {
          value = 0;
        }
        let newRate = this.state.newRate;
        newRate.Rate = Number((Number(value)).toFixed(2));
        this.setState({
          newRate: newRate,
          hasUnSavedData: true
        });
        break;
      default:
        break;
    }
  };

  getPaymentTypeToAdd = async (value) => {
    let paymentTypes = []

    let projectOrCrPaymentType = value ? value.paymentMethod : null
    if (projectOrCrPaymentType === 1) {
      paymentTypes.push(this.paymentMethodsAll[0]);
    } else if (projectOrCrPaymentType === 2) {
      paymentTypes.push(this.paymentMethodsAll[1]);
    } else if (projectOrCrPaymentType === 3) {
      paymentTypes = [this.paymentMethodsAll[0], this.paymentMethodsAll[1]];
    }

    await this.setState({
      newPaymentMethod: projectOrCrPaymentType === 1 || projectOrCrPaymentType === 2 ? paymentTypes[0] : null,
      newPaymentMethodAll: paymentTypes,
      paymentMethodDisabled: projectOrCrPaymentType === 1 || projectOrCrPaymentType === 2 ? true : false
    })

    return paymentTypes;
  };

  checkProjectRevenue = async () => {

    const calculatedValues = this.state.calculatedValues;
    var totalRevenueProjAndCrsCMS = calculatedValues.discountedRevenueToFortudeCMS;
    var totalRevenueAllocated = 0.00;
    var remainingRevenue = 0.00;
    const approvedStatusList = ['CONFIRMED', 'RES_ALLOC_PENDING', 'ALLOC_APP_PENDING', 'COMPLETE', 'CLOSE', 'RQST_APP_PENDING'];

    //Already allocated lines revenue
    for (const mainLine of this.state.resourceMix) {
      if (mainLine.Type == 'Project' && mainLine.Project.StatusConvertedToActive === true) {
        if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
          for (const subLine of mainLine.ResourceRequestSubLine) {
            if (approvedStatusList.includes(subLine.Status)) {
              totalRevenueAllocated += Number(subLine.Revenue);
            }
          }
        } else {
          if (approvedStatusList.includes(mainLine.Status)) {
            totalRevenueAllocated += Number(mainLine.TotalRevenue);
          }
        }
      } else if (mainLine.Type == 'ChangeRequest' && mainLine.ChangeRequest.StatusConvertedToActive === true) {
        if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
          for (const subLine of mainLine.ResourceRequestSubLine) {
            if (approvedStatusList.includes(subLine.Status)) {
              totalRevenueAllocated += Number(subLine.Revenue);
            }
          }
        } else {
          if (approvedStatusList.includes(mainLine.Status)) {
            totalRevenueAllocated += Number(mainLine.TotalRevenue);
          }
        }
      }
    }

    remainingRevenue = totalRevenueProjAndCrsCMS - totalRevenueAllocated;

    if (remainingRevenue <= 0) {
      await this.setState({
        isProjectRevenueExceed: true,
      })
    } else {
      await this.setState({
        isProjectRevenueExceed: false,
      })
    }
  };

  getCurrencyForRegion = async (projectOrCR) => {
    if (projectOrCR) {
      await getRegionCurrencyIntegrated(projectOrCR.billingRegion).then(response => {
        this.state.newProjectCurrency = response.data;
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  }

  addToMainGrid = async event => {
    await this.setState({
      addResourceSaveBtnDisabled: true,
    });

    await this.addNewToGrid();

    if (this.state.addResourceGridData && this.state.addResourceGridData.length > 0) {
      let newAddedResourceRequest = []
      let existingGrid = this.state.resourceMix
      let newItems = this.state.addResourceGridData
      let rateValueInUSD = null;
      let TotalCostInUSD = null;
      let TotalRevenueInUSD = null;
      let CostToRevenueRatioInUSD = null;
      let DirectCostInUSD = null;
      let CostPerResourceInUSD = null;
      let CostPerOutSourcedResourceInUSD = null;

      for (let item of newItems) {
        const selectedProjOrCR = this.state.namesAll.filter(function (project) {
          return item.newResProjectOrCRName.id === project.id;
        });
        let itemToAdd = {}
        let projectId = null
        let paymentMethodName = null

        if (item.newResProjectOrCRName.paymentMethod === 1) {
          paymentMethodName = 'Fixed Price';
          item.PaymentMethod = 1;
        }
        else if (item.newResProjectOrCRName.paymentMethod === 2) {
          paymentMethodName = 'Time and Material'
          item.PaymentMethod = 2;
        }
        else if (item.newResProjectOrCRName.paymentMethod === 3) {
          paymentMethodName = 'Hybrid'
          item.dataValuePaymentMethod = item.newFixedorTM;
          item.PaymentMethod = item.newFixedorTM.id;
        }

        itemToAdd.Type = item.newResProjectOrCRName.type
        if (itemToAdd.Type === "Project") {
          let projcurrencyId = 0;
          let project = {
            ProjectName: item.newResProjectOrCRName.name,
            PaymentMethodRecord: {
              id: item.newResProjectOrCRName.paymentMethod,
              Name: paymentMethodName
            },
            id: item.newResProjectOrCRName.id,
            BillingRegion: item.newResProjectOrCRName.billingRegion
          }

          //when the currency is != USD
          await getRegionCurrencyIntegrated(item.newResProjectOrCRName.billingRegion).then(response => {
            projcurrencyId = response.data.CurrencyId;
          })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

          if (projcurrencyId !== 1) {

            this.setState({
              isNotCurrencyUSD: true
            });

            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
              projcurrencyId,
              new Date(selectedProjOrCR[0].StartDate).getMonth() + 1,
              new Date(selectedProjOrCR[0].StartDate).getFullYear()
            );

            rateValueInUSD = (item.newRate.Rate / projrateRecordCurrencyRate).toFixed(2);
            TotalRevenueInUSD = (item.TotalRevenue / projrateRecordCurrencyRate).toFixed(2);
            CostToRevenueRatioInUSD = Number(TotalRevenueInUSD) === 0 ? Number(0.00) : (item.TotalCost / TotalRevenueInUSD).toFixed(2)
            CostPerOutSourcedResourceInUSD = item.outsourcedCost ? (item.outsourcedCost / projrateRecordCurrencyRate).toFixed(2) : null;

          }

          itemToAdd.Project = project;
          itemToAdd.name = project.ProjectName;
          itemToAdd.CMSheetId = selectedProjOrCR[0].CMSheetId;
          itemToAdd.CMSheetVersion = selectedProjOrCR[0].CMSheetVersion;
          itemToAdd.StartDate = moment(this.state.addResourceGridData[0].newStartDate).format('YYYY-MM-DD');
          projectId = item.newResProjectOrCRName.id;

        } else if (itemToAdd.Type === 'ChangeRequest') {
          let CRcurrencyId = 0;
          let changeRquest = {
            CRName: item.newResProjectOrCRName.name,
            PaymentMethod: {
              id: item.newResProjectOrCRName.paymentMethod,
              Name: paymentMethodName
            },
            id: item.newResProjectOrCRName.id,
            BillingRegion: item.newResProjectOrCRName.billingRegion
          };

          //when the currency is != USD
          await getRegionCurrencyIntegrated(item.newResProjectOrCRName.billingRegion).then(response => {
            CRcurrencyId = response.data.CurrencyId;
          })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });

          if (CRcurrencyId !== 1) {

            this.setState({
              isNotCurrencyUSD: true
            });

            const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
              CRcurrencyId,
              new Date(selectedProjOrCR[0].StartDate).getMonth() + 1,
              new Date(selectedProjOrCR[0].StartDate).getFullYear()
            );

            rateValueInUSD = (item.newRate.Rate / projrateRecordCurrencyRate).toFixed(2);
            TotalRevenueInUSD = (item.TotalRevenue / projrateRecordCurrencyRate).toFixed(2);
            CostToRevenueRatioInUSD = Number(TotalRevenueInUSD) === 0 ? Number(0.00) : (item.TotalCost / TotalRevenueInUSD).toFixed(2)
            CostPerOutSourcedResourceInUSD = item.outsourcedCost ? (item.outsourcedCost / projrateRecordCurrencyRate).toFixed(2) : null;

          }
          itemToAdd.ChangeRequest = changeRquest;
          itemToAdd.name = changeRquest.CRName;
          itemToAdd.CMSheetId = selectedProjOrCR[0].CMSheetId;
          itemToAdd.CMSheetVersion = selectedProjOrCR[0].CMSheetVersion;
          itemToAdd.StartDate = moment(this.state.addResourceGridData[0].newStartDate).format('YYYY-MM-DD');
          projectId = item.newResProjectOrCRName.id;
        }



        itemToAdd.ProjectId = projectId;
        itemToAdd.Status = 'NEW_FROM_RM';
        itemToAdd.selected = false;
        itemToAdd.PhaseId = item.newPhase ? item.newPhase.id : null;
        itemToAdd.Phase = item.newPhase ? item.newPhase : null;
        itemToAdd.SubPhaseId = item.newSubPhase ? item.newSubPhase.id : null;
        itemToAdd.SubPhase = item.newSubPhase ? item.newSubPhase : null;
        itemToAdd.dataValueProjectRole = item.newResRole;
        itemToAdd.ProjectRole = item.newResRole.id;
        itemToAdd.dataValueCostDesignation = item.newResDesignation;
        itemToAdd.Designation = item.newResDesignation.id;
        itemToAdd.dataValueResourceRegion = item.newResRegion;
        itemToAdd.ResourceRegion = item.newResRegion.id;
        itemToAdd.OnsiteStatus = item.newOnSiteStatus.Name;
        itemToAdd.EndDate = moment(new Date(item.newEndDate)).format('YYYY-MM-DD')
        itemToAdd.CostPerOutSourcedResource = item.outsourcedCost;
        itemToAdd.EffortDays = item.newEffortDays;
        itemToAdd.TotalEffortDays = item.newEffortDays;
        itemToAdd.AllocationPercentage = item.newAllocation;
        itemToAdd.AllocationPercentage = item.newAllocation;
        itemToAdd.TotalRevenue = this.state.isNotCurrencyUSD === true ? TotalRevenueInUSD : item.TotalRevenue;
        itemToAdd.TotalCost = item.TotalCost;
        itemToAdd.CostToRevenueRatio = this.state.isNotCurrencyUSD === true ? CostToRevenueRatioInUSD : item.CostToRevenueRatio;
        itemToAdd.CostPerResource = item.CostPerResource;
        itemToAdd.DirectCost = item.DirectCost;
        itemToAdd.RateValue = this.state.isNotCurrencyUSD === true ? rateValueInUSD : item.newRate.Rate;
        itemToAdd.RateType = item.newRate.id;
        itemToAdd.dataValueRateType = item.newRate;
        itemToAdd.IsActive = true;
        itemToAdd.dataValuePaymentMethod = item.dataValuePaymentMethod;
        itemToAdd.PaymentMethod = item.PaymentMethod;
        //itemToAdd.StartDateType = 'RESOURCE_ALLOCATION'
        itemToAdd.LastUpdatedByUserAt = new Date();
        itemToAdd.ManagerComment = item.managerComment;

        existingGrid.push(itemToAdd);
        //to save to database
        newAddedResourceRequest.push(itemToAdd);

      }

      await this.setState({
        resourceMix: existingGrid,
        addResourceGridData: [],
        showAddDialog: false,
        toSaveNewResource: newAddedResourceRequest,
        isNotCurrencyUSD: false,
        selectedResources: [],
        selectedResourcesSubLine: []
      });



      this.handleResourceSave(newAddedResourceRequest);


    } else {
      // this.setState({
      //   showSuccessImage: false,
      //   showErrorImage: true
      // });
      // this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    }
    this.submitSearch();
    await this.setState({
      addResourceSaveBtnDisabled: false,
    });
  };

  filterMultiData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }



  filter = async event => {
    const name = event.target.name;
    switch (name) {
      //Line filteration
      case 'SelectedLineStatuses':
        this.setState({
          LineStatus: this.filterMultiData(event.filter, this.state.LineStatusAll)
        });
        break;
      //Add new values
      case 'newResProjectOrCRName':
        await this.setState({
          namesAll: this.filterData(event.filter, name)
        });
        break;
      case 'newResPhase':
        this.setState({
          phasesTemp: this.filterData(event.filter, name)
        });
        break;
      case 'newResSubPhase':
        this.setState({
          tmpSubPhasesAll: this.filterData(event.filter, name)
        });
        break;
      case 'newResRole':
        this.setState({
          RolesAll: this.filterData(event.filter, name)
        });
        break;
      case 'newResDesignation':
        this.setState({
          tmpDesignationsAll: this.filterData(event.filter, name)
        });
        break;
      case 'newResRegion':
        this.setState({
          RegionsAll: this.filterData(event.filter, name)
        });
        break;
      case 'newOnSiteStatus':
        this.setState({
          onSiteStatusAll: this.filterData(event.filter, name)
        });
        break;
      //Edit values
      case 'editRegion':
        this.setState({
          RegionsAll: this.filterData(event.filter, name)
        });
        break;
      case 'editOnSiteStatus':
        this.setState({
          onSiteStatusAll: this.filterData(event.filter, name)
        });
        break;
      case 'editAllocatedResource':
        this.setState({
          usersAll: this.filterData(event.filter, name)
        });
        break;
      default:
        break;
    }
  };

  filterData(filter, name) {
    switch (name) {
      //Adds
      case 'newResProjectOrCRName':
        const nameData = this.allTheNames.slice();
        return filterBy(nameData, filter);
      case 'newResPhase':
        const phaseData = this.state.phasesTempAll.slice();
        return filterBy(phaseData, filter);
      case 'newResSubPhase':
        const subphaseData = this.TmpSubPhasesAll.slice();
        return filterBy(subphaseData, filter);
      case 'newResRole':
        const roleData = this.designationsAll.slice();
        return filterBy(roleData, filter);
      case 'newResDesignation':
        const desgData = this.TmpDesignationsAll.slice();
        return filterBy(desgData, filter);
      case 'newResRegion':
        const regionData = this.regionsAll.slice();
        return filterBy(regionData, filter);
      case 'newOnSiteStatus':
        const onsiteData = this.onSiteStatusAll.slice();
        return filterBy(onsiteData, filter);

      //Edits
      case 'editRegion':
        const editRegionData = this.regionsAll.slice();
        return filterBy(editRegionData, filter);
      case 'editOnSiteStatus':
        const editOnsiteData = this.onSiteStatusAll.slice();
        return filterBy(editOnsiteData, filter);
      case 'editAllocatedResource':
        const editAllocationResData = this.usersForDesignation.slice();
        return filterBy(editAllocationResData, filter);
      default:
        break;
    }
  }

  editResource = async event => {
    if (this.props.location.action !== 'RPApprove') {
      if (this.state.userDefinedEndDate === null) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please insert an End date to proceed', 'Error');
      } else {
        if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
          //update project
          this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
          updateProject(this.state.project, this.state.project.id)
            .then(res => {
              this.onUserDefinedEndDateChange();
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
        this.editProcess();
      }
    } else {
      this.editProcess();
    }

    this.submitSearch();
  };

  editProcess = async () => {
    const resDesg = await getAllDesignationsIntegrated();
    let selecteddesignationId = this.state.editableItem.Designation;
    let filteredArray = [];
    for (const itm of this.usersAll) {
      if (itm.DesignationId) {
        /*for (const desig of resDesg.data) {
                        if (desig.Name.includes("Manager")) {*/
        filteredArray.push(itm);
        /*  }
                    }*/
      }
    }
    ;

    const filteredArr = Object.values(
      filteredArray.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})
    );

    this.usersForDesignation = filteredArr;
    this.state.usersAll = filteredArr;

    if (this.state.editableItem) {

      const editableResourceRequest = this.state.editableItem;
      let subLineStartDateMin = null;
      let subLineEndDateMax = null;
      let customerId, regionId, projectId, projectOrCrDate, type = null;

      /*TODO
      convert to ProjectConversionStartDate(activation start date) from StartDate*/
      if (editableResourceRequest.Type === 'Project') {
        if (editableResourceRequest.Project && editableResourceRequest.Project.StartDate) {
          subLineStartDateMin = editableResourceRequest.Project.StartDate;
        } else {
          subLineStartDateMin = new Date();
        }

        if (editableResourceRequest.Project && editableResourceRequest.Project.EstimatedEndDate) {
          subLineEndDateMax = editableResourceRequest.Project.EstimatedEndDate;
        } else {
          subLineEndDateMax = editableResourceRequest.EstimatedEndDate;
        }

        customerId = editableResourceRequest.Project.CustomerId;
        regionId = editableResourceRequest.Project.BillingRegion;
        projectId = editableResourceRequest.ProjectId;
        projectOrCrDate = editableResourceRequest.StartDate !== null ? editableResourceRequest.StartDate : editableResourceRequest.Project.StartDate;
        type = 'projectCMS';

      } else {
        /*TODO
        convert to ChangeRequestConversionStartDate - activation startdate of CR*/
        if (editableResourceRequest.ChangeRequest && editableResourceRequest.ChangeRequest.StartDate) {
          subLineStartDateMin = editableResourceRequest.ChangeRequest.StartDate;
        } else {
          subLineStartDateMin = new Date();
        }

        if (editableResourceRequest.Project && editableResourceRequest.Project.EstimatedEndDate) {
          subLineEndDateMax = editableResourceRequest.Project.EstimatedEndDate;
        } else {
          subLineEndDateMax = editableResourceRequest.EstimatedEndDate;
        }

        customerId = editableResourceRequest.ChangeRequest.CustomerId;
        regionId = editableResourceRequest.ChangeRequest.BillingRegion;
        projectId = editableResourceRequest.ChangeRequest.ProjectId;
        projectOrCrDate = editableResourceRequest.StartDate !== null ? editableResourceRequest.ChangeRequest.StartDate : editableResourceRequest.ChangeRequest.StartDate;
        type = 'crCMS';
      }

      const rateCardParamsObj = {
        CustomerId: customerId,
        RegionId: regionId,
        ProjectId: projectId,
        CostDate: projectOrCrDate,
        Type: type
      };

      this.getAllocationRates(editableResourceRequest.ProjectRole, 'edit', rateCardParamsObj);

      this.setState({
        subLineStartDateMin: subLineStartDateMin,
        subLineEndDateMax: subLineEndDateMax,
        disableSelectedResourceStartEndDate: true
      });
    }
    if (this.isMount) {
      this.setState({
        selctedResourceSubLineStartDate: null,
        selctedResourceSubLineEndDate: null,
        hasSubLines: false,
        confirmStatus: false,
        confirmStatusSub: false,
        selectedResources: [],
        selectedResourcesSubLine: [],
        requestAllocation: false,
        requestAllocationSoftDate: false,
        requestAllocationSoftSubDate: false,
        requestAllocationSoftResubmit: false,
        requestAllocationSubSoftResubmit: false,
        requestAllocationSubSoft: false,
        requestAllocationSoft: false
      });
    }
    this.toggleEditDialog();
    this.ticked = [];
    this.allocationId = [];
    this.sublineTicked = [];
  };

  toggleEditDialog = () => {
    if (this.isMount) {
      this.setState({
        showEditDialog: !this.state.showEditDialog,
        isEditResource: true,
      });
    }
  };

  toggleDialog = async (message, title) => {
    await this.setState({
      popupVisible: !this.state.popupVisible,
      dialogMessage: message,
      dialogTitle: title
    });
  };

  toggleConfirmDialog = (message, action) => {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action
    });
  };

  // For Resource Deallocation Confirm Message Box
  toggleDeallocationConfirmDialog = (message, action) => {
    this.setState({
      showDeallocConfirmDialog: !this.state.showDeallocConfirmDialog,
      confirmDialogMessage: message,
      confirmDialogAction: action,
      disableConfirmButton: true,
      deallocationDatesErrorMessage: false
    }, () => this.setSelectedLines());
  };

  setSelectedLines = async () => {
    if(this.state.showDeallocConfirmDialog == false){
      this.state.selectedResources = [];
      this.state.selectedResourcesSubLine = [];
    }
  };

  toggleAddDialog = async () => {
    if (this.props.location.action !== 'RPApprove') {
      if (this.state.userDefinedEndDate === null) {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please insert an End date to proceed', 'Error');
      } else {
        if (this.state.project.UserDefinedEndDate === null || reStructureDate(this.state.project.UserDefinedEndDate).getTime() !== reStructureDate(this.state.userDefinedEndDate).getTime()) {
          //update project
          this.state.project.UserDefinedEndDate = moment(this.state.userDefinedEndDate).format('YYYY-MM-DD');
          updateProject(this.state.project, this.state.project.id)
            .then(res => {
              this.onUserDefinedEndDateChange();
            })
            .catch(error => {
              loggerService.writeLog(error, LOG_TYPES.ERROR);
            });
        }
        if (this.isMount) {
          this.setState({
            showAddDialog: !this.state.showAddDialog,
            isWantAddedData: true
          });
        }

        this.clearAddingValues();

        await this.setState({
          newAllocation: 100
        });
      }
    }

  };

  addNewToGrid = async () => {
    if (!this.validateAddingValues()) {
      await this.setState({
        addResourceSaveBtnDisabled: false,
      });
      if (this.validateProperty(this.state.newResProjectOrCRName).toString().includes('error')){
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
      }
      else if (this.state.newStartDate && this.state.newEndDate && this.state.newOnSiteStatus && this.state.newEffortDays === 0) {
        const message = 'Effort days is 0. Please check all the mandatory fields and the start date.';
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showRateErrorImage: true
        });
        this.toggleRateValidatioDialog(message, title);
      } else {
        this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });
        this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
      }    
    } else {

      let newItem = {
        newResProjectOrCRName: this.state.newResProjectOrCRName,
        newResRole: this.state.newResRole,
        newResRegion: this.state.newResRegion,
        newOnSiteStatus: this.state.newOnSiteStatus,
        newStartDate: this.state.newStartDate ? moment(this.state.newStartDate).format('MM/DD/YYYY') : null,
        newEndDate: this.state.newEndDate ? moment(this.state.newEndDate).format('MM/DD/YYYY') : null,
        newEffortDays: this.state.newEffortDays.toFixed(2),
        newAllocation: this.state.newAllocation,
        newResDesignation: this.state.newResDesignation,
        newPhase: this.state.newResPhase,
        newSubPhase: this.state.newResSubPhase,
        outsourcedCost: this.state.outsourcedCost,
        newRate: this.state.newRate,
        newFixedorTM: this.state.newPaymentMethod,
        EstimatedEndDate : this.state.newEndDate ? moment(this.state.newEndDate).format('YYYY-MM-DD') : null,
        resourceCountry: this.state.newResRegion ? this.state.newResRegion.DefaultCountryId : null,
        projOrCrCountry: this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.countryId : null,
        resourceId : null,
        managerComment: this.state.managerComment,
        CostPerOutSourcedResource: this.state.outsourcedCost
      };

      let costToRevenueRatio = 0;
      if (newItem.newResDesignation.id === 57) {
        newItem = await this.getCostCard(newItem).then(() => {
          let rate = newItem.newRate.Rate ? newItem.newRate.Rate : 0;
          newItem.TotalRevenue = (Number(rate * newItem.newEffortDays).toFixed(2));

          if (Number(newItem.TotalRevenue) === 0) {
            newItem.CostToRevenueRatio = 0;
          } else {
            costToRevenueRatio = (Number(newItem.TotalCost / newItem.TotalRevenue).toFixed(2));
            newItem.CostToRevenueRatio = costToRevenueRatio;
          }
          if (newItem.TotalCost === 0 || !this.state.outsourcedCost) {
            const message = 'Cost card record not found for the selected designation and resource region';
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showRateErrorImage: true
            });
            this.toggleRateValidatioDialog(message, title);
          } else {
            let tmp = this.state.addResourceGridData;
            tmp.push(newItem);
            this.clearAddingValues();
            this.setState({
              addResourceGridData: tmp
            });
          }
        });
      } else {
        newItem = await this.getCostCard(newItem).then(() => {
          let rate = newItem.newRate.Rate ? newItem.newRate.Rate : 0;
          newItem.TotalRevenue = (Number(rate * newItem.newEffortDays).toFixed(2))

          if (Number(newItem.TotalRevenue) === 0) {
            newItem.CostToRevenueRatio = 0;
          } else {
            costToRevenueRatio = (Number(newItem.TotalCost / newItem.TotalRevenue).toFixed(2));
            newItem.CostToRevenueRatio = costToRevenueRatio;
          }
          if (newItem.TotalCost === 0) {
            const message = 'Cost card record not found for the selected designation and resource region';
            const title = 'Error';
            this.setState({
              showSuccessImage: false,
              showRateErrorImage: true
            });
            this.toggleRateValidatioDialog(message, title);
          } else {
            let tmp = this.state.addResourceGridData;
            tmp.push(newItem);
            this.clearAddingValues();
            this.setState({
              addResourceGridData: tmp
            });
          }
        });
      }
    }
  };

  getCostForDesignation = async dataItem => {

    let region = null;
    let billingRegion = 0;
    let projOrCr = null;
    let projectId = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let totalCostUSD = 0;
    let EffortDays = 0;

    if (this.props.cmSheetReference === 'crCMS') {
      region = this.props.location.changeRequest.Region;
      billingRegion = this.props.location.changeRequest.BillingRegion;
      projOrCr = this.props.location.changeRequest;
      projectId = this.props.location.changeRequest.ProjectId;
    } else {
      region = this.props.location.project.Region;
      billingRegion = this.props.location.project.BillingRegion;
      projOrCr = this.props.location.project;
      projectId = this.props.location.project.id;
    }

    let newItem = {
      newResProjectOrCRName: dataItem.Type == 'ChangeRequest' ? dataItem.ChangeRequest : dataItem.Project,
      newResRegion:dataItem.dataValueResourceRegion,
      newOnSiteStatus: dataItem.OnsiteStatus,
      newStartDate: moment(dataItem.StartDate).format('MM/DD/YYYY'),
      newEndDate:  dataItem.ResDeallocationStartDate ? moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY') : moment(new Date(dataItem.EndDate)).format('MM/DD/YYYY'),
      newEffortDays: dataItem.EffortDays.toFixed(2),
      newAllocation: dataItem.AllocationPercentage,
      EstimatedEndDate: dataItem.ResDeallocationStartDate ? moment(new Date(dataItem.ResDeallocationStartDate)).format('MM/DD/YYYY') : moment(new Date(dataItem.EndDate)).format('MM/DD/YYYY'),
      newResDesignation: dataItem.Designation == 57 ? { id: dataItem.Designation } : dataItem.AllocatedDesignationId ?  { id: dataItem.AllocatedDesignationId } : dataItem.AllocatedResource.Resource ? { id: dataItem.AllocatedResource.Resource.DesignationId } : dataItem.dataValueCostDesignation,
      resourceCountry : dataItem.dataValueResourceRegion.DefaultCountryId,
      projOrCrCountry : projOrCr.CountryId,
      resourceId: dataItem.ResourceId ? dataItem.ResourceId : null,
      ResourceRequestId: dataItem.ResourceRequestId ? dataItem.ResourceRequestId : dataItem.id,
      ResourceRequestSubLineId: dataItem.ResourceRequestId ? dataItem.id : null,
      CostPerOutSourcedResource: dataItem.Designation == 57 ? (dataItem.CostPerOutSourcedResource && dataItem.CostPerOutSourcedResource > 0) ? dataItem.CostPerOutSourcedResource : dataItem.InitialCostPerResource : null
    };

    let costDate_ = new Date(dataItem.StartDate);

    const costCard = {
      billingRegionId: billingRegion,
      resourceRegionId: dataItem.AllocatedResource.Resource.ResourceRegion ? dataItem.AllocatedResource.Resource.ResourceRegion.id : dataItem.AllocatedResource.Resource.Region,
      designationId: dataItem.Designation == 57 ? dataItem.Designation : dataItem.AllocatedDesignationId ? dataItem.AllocatedDesignationId : dataItem.AllocatedResource.Resource.DesignationId,
      projectId: projectId,
      costDate: moment(new Date()).format('YYYY-MM-DD')
    };

    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    };

    await getCostDetails(costItems)
        .then(res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
          EffortDays = res.data.EffortDays;
        })
        .catch( async err => {
          totalCostUSD = 0;
          DirectCostInUSD = 0;
          CostPerResourceInUSD = 0;
          EffortDays = 0;
          loggerService.writeLog(err, LOG_TYPES.ERROR);

          const message = 'Cost card record not found for the selected designation and resource region';
          const title = 'Error';
          await this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });

          await this.toggleMessageDialog(message, title);
        });

    dataItem.TotalCost = totalCostUSD;
    dataItem.Cost = totalCostUSD;
    dataItem.DirectCost = DirectCostInUSD;
    dataItem.CostPerResource = CostPerResourceInUSD;
    dataItem.EffortDays = EffortDays;

    // Revenue
    if (EffortDays && dataItem.RateValue) {
      dataItem.TotalRevenue = EffortDays * dataItem.RateValue;
      dataItem.Revenue = EffortDays * dataItem.RateValue;
    } else {
      dataItem.TotalRevenue = 0;
      dataItem.Revenue = 0;
    }

    // Cost to Revenue ratio
    if (totalCostUSD > 0 && dataItem.TotalRevenue > 0) {
      dataItem.CostToRevenueRatio = (totalCostUSD / dataItem.TotalRevenue).toFixed(2);
    } else {
      dataItem.CostToRevenueRatio = 0;
    }
  };

  getCostCard = async dataItem => {
    let newItem = dataItem;
    let costDate_ = new Date(dataItem.newStartDate);
    const costCard = {
      billingRegionId: newItem.newResProjectOrCRName.billingRegion,
      resourceRegionId: newItem.newResRegion.id,
      designationId: newItem.newResDesignation.id,
      costDate: moment(costDate_).format('YYYY-MM-DD'),
      projectId: this.props.location.project.id
    };
    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    };

    await getCostDetails(costItems)
      .then(async res => {
          newItem.CostPerResource = res.data.CostPerResource;
          newItem.DirectCost = res.data.DirectCost;
          newItem.TotalCost = res.data.TotalCost;

      })
      .catch( async err => {
        newItem.CostPerResource = 0;
        newItem.DirectCost = 0;
        newItem.TotalCost = 0;
        loggerService.writeLog(err, LOG_TYPES.ERROR);

        const message = 'Cost card record not found for the selected designation and resource region';
        const title = 'Error';
        await this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });

        await this.toggleMessageDialog(message, title);
      });
  };

  validateCostforRegion = async () => {

    let resRegionId = this.state.newResRegion ? this.state.newResRegion.id : null;
    let billingRegion = this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.billingRegion : this.props.location.project.BillingRegion;
    let newResDesignationId = this.state.newResDesignation ? this.state.newResDesignation.id : null;
    let projectStartDate = this.props.location.project.StartDate;
    let costDate = this.state.newStartDate ? moment(this.state.newStartDate).format('MM/DD/YYYY') : moment(projectStartDate).format('MM/DD/YYYY');
    let costDate_ = new Date(costDate);

    if (!(newResDesignationId && newResDesignationId === 57)) {
      if (resRegionId && newResDesignationId && billingRegion) {
        const costCard = {
          billingRegionId: billingRegion,
          resourceRegionId: resRegionId,
          designationId: newResDesignationId,
          projectId: this.props.location.project.id,
          costDate: moment(costDate_).format('YYYY-MM-DD')
        };
        await getCost(costCard)
          .then(async res => {
            if (res.data.length > 0 && res.data[0].id > 0) {
              const costCard = res.data[0];
              const costRecord = {
                CurrencyId: costCard.BillingRegion.Currency.id,
                Overhead: costCard.CostCardDesignations[0].Overhead,
                CostToCompany: costCard.CostCardDesignations[0].CostToCompany
              };

              let costPerResourceTotal = costRecord.Overhead + costRecord.CostToCompany;

              if (costPerResourceTotal === 0) {
                const message = 'Cost card record not found for the selected designation and resource region';
                const title = 'Error';
                this.setState({
                  showSuccessImage: false,
                  showRateErrorImage: true
                });
                this.toggleRateValidatioDialog(message, title);
              }
            }
          });
      }
    }
  };

  clearAddingValues = () => {
    this.setState({
      newResProjectOrCRName: null,
      newResRole: null,
      newResRegion: null,
      newOnSiteStatus: null,
      newStartDate: null,
      newEndDate: null,
      newEffortDays: 0,
      newAllocation: 100,
      newResDesignation: null,
      newResPhase: null,
      newResSubPhase: null,
      newPaymentMethod: null,
      newPaymentMethodAll: [],
      newProjectCurrency: null,
      newRatesAll: [],

      namesAll: this.allTheNames.slice(),
      phasesTemp: this.state.phasesTemp.slice(),
      RolesAll: this.designationsAll.slice(),
      RegionsAll: this.regionsAll.slice(),
      onSiteStatusAll: this.onSiteStatusAll.slice(),
      tmpDesignationsAll: [],
      tmpSubPhasesAll: [],
      showErrorImage: false,
      newRate: null,
      isMount: false,
      managerComment: ''
    });

    if (this.state.isWantAddedData) {
      this.setState({
        addResourceGridData: []
      });
    }
  };

  validateProperty = value => {
    if (value) {
      return 'd-none';
    } else {
      return 'inline-error';
    }
  };

  validateNumber = value => {
    if (value) {
      if (value > 0) {
        return 'd-none';
      }
      return 'inline-error';
    } else {
      return 'inline-error';
    }
  };

  validateAddingValues = () => {
    if (
      this.validateProperty(this.state.newResProjectOrCRName)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newResRole)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newResRegion)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newOnSiteStatus)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateNumber(this.state.newEffortDays)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateNumber(this.state.newAllocation)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newRate)
        .toString()
        .includes('error')
    ) {
      return false;
    } else if (
      this.validateProperty(this.state.newPaymentMethod)
        .toString()
        .includes('error')
    ) {
      return false;
    }else {
      return true;
    }
  };

  validateEditingValues = () => {
    if (this.validateProperty(this.state.editableItem.dataValueResourceRegion).toString().includes('error')) {
      return false;
    } else if (this.validateProperty(this.state.editableItem.OnsiteStatus).toString().includes('error')) {
      return false;
    } else if (this.validateProperty(this.state.editableItem.EffortDays).toString().includes('error')) {
      return false;
    } else if (this.validateProperty(this.state.editableItem.dataValueCostDesignation).toString().includes('error')) {
      return false;
    } else {
      return true;
    }
  };

  validateSublineEditingValues = () => {
    if (this.validateProperty(this.state.selectedSubLineToEdit.dataValueResourceRegion).toString().includes('error')) {
      return false;
    } else {
      return true;
    }
  };

  selectionChange = async (event) => {
    if ((event.dataItem.IsPreSales && event.dataItem.IsPreSales === true) || event.dataItem.IsPreviousCmsResource) {
    } else {
      event.dataItem.selected = !event.dataItem.selected;
      const index = this.state.resourceMix.indexOf(event.dataItem);
      if (event.dataItem.selected) {
        this.ticked.push(index);
        if (event.dataItem.AllocatedResourceID != null) {
          this.allocationId.push(event.dataItem.AllocatedResourceID);
        }
      } else {
        this.ticked.splice(this.ticked.indexOf(index), 1);
        if (event.dataItem.AllocatedResourceID != null) {
          this.allocationId.splice(this.allocationId.indexOf(index), 1);
        }
      }
      if (this.ticked.length === 1 && this.sublineTicked.length !== 1) {
        const selectedResource = JSON.parse(JSON.stringify(this.state.resourceMix[this.ticked[0]]));
        if (this.props.location.action !== 'RPApprove' && (selectedResource.Status === 'ALLOC_APP_PENDING' || selectedResource.Status === 'RQST_APP_PENDING')) {
          this.state.notEditableResource = true;
        } else {
          this.state.notEditableResource = false;
        }
        let resourcePaymentMethod = null;
        if (selectedResource.Type === 'Project') {
          if (selectedResource.Project && selectedResource.Project.PaymentMethodRecord && selectedResource.Project.PaymentMethodRecord.id !== 3) {
            resourcePaymentMethod = selectedResource.Project.PaymentMethodRecord;
          } else {
            resourcePaymentMethod = selectedResource.dataValuePaymentMethod;
          }

        } else {
          if (selectedResource.ChangeRequest && selectedResource.ChangeRequest.PaymentMethod && selectedResource.ChangeRequest.PaymentMethod.id !== 3) {
            resourcePaymentMethod = selectedResource.ChangeRequest.PaymentMethod;
          } else {
            resourcePaymentMethod = selectedResource.dataValuePaymentMethod;
          }
        }

        const filteredArray = this.designationsAll.filter(function (itm) {
          return selectedResource.dataValueProjectRole.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
        });

        this.state.tmpDesignationsAll = filteredArray;

        selectedResource.ResourcePaymentMethod = resourcePaymentMethod;
        if (!selectedResource.SubLines) {
          selectedResource.SubLines = [];
        }
        selectedResource.index = this.ticked[0];
        selectedResource.Designations = filteredArray;
        this.state.editableItem = selectedResource;

      }
      //Resource Allocation
      if (this.ticked.length === 1 && this.props.location.action === 'RPApprove') {
        const selectedResource = JSON.parse(JSON.stringify(this.state.resourceMix[this.ticked[0]]));
        if (selectedResource.Status === 'RES_ALLOC_PENDING')
          this.state.notEditableResource = false;
        else
          this.state.notEditableResource = true;
      }

      this.forceUpdate();

      const selectedl = await this.state.resourceMix.filter(resource => resource.selected === true);

      let isRemoveRequest = false;
      let isDeallocateRequest = false;
      let hasSubLines = false;
      let requestAllocation = false;
      let requestAllocationSoft = false;
      let requestAllocationSoftResubmit = false;
      let requestAllocationSoftDate = false;
      let confirmStatus = false;
      let isCanSoftBooking = false;
      let isWaitingConfirmResource = false;

      if (selectedl && selectedl.length > 0) {


        for (const resource of selectedl) {
          if (resource.IsSoftBookingResource === true) {
            isRemoveRequest = true;
          } else if (resource.Status === 'NEW_FROM_CMS' || resource.Status === 'NEW_FROM_RM' ||
            resource.Status === 'EDIT' || resource.Status === 'CONFIRMED'
            || resource.Status === 'RES_ALLOC_CANCELLED' || resource.Status === 'RES_ALLOC_REJECTED' || resource.Status === 'RQST_APP_REJECTED') {
            isRemoveRequest = true;
          } else {
            isRemoveRequest = false;
            break;
          }
        }

        //for deallocation
        for (const resource of selectedl) {
          if (resource.Status === 'COMPLETE') {
            isDeallocateRequest = true;
          } else {
            isDeallocateRequest = false;
            break;
          }
        }

        //resourceAllocationCheck
        for (const resource of selectedl) {
          if (resource.IsSoftBookingResource === true && resource.Status == 'COMPLETE') {
            requestAllocation = false
          } else if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED') && (resource.Status !== 'RES_ALLOC_PENDING' || resource.IsSoftBookingResource === false)) {
            requestAllocation = true
          }

          //softbook check
          if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource === true) {
            requestAllocationSoft = true
          }

          //for soft booking date check
          if (resource.EndDate === null || resource.StartDate === null) {
            requestAllocationSoftDate = true
          }

          //resubmitting same softbook check
          if (resource.IsSoftBookingResource === true) {
            requestAllocationSoftResubmit = true
          }

          //confirmStatus
          if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
            resource.Status !== 'NEW_FROM_CMS' && resource.Status !== 'RES_ALLOC_CANCELLED') {
            confirmStatus = true;
          }

          //check whether mainline has sublines
          if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
            let sublines = resource.ResourceRequestSubLine.filter(res => res.IsActive === true);
            if (sublines.length > 0) {
              hasSubLines = true
            }
          }

          //for soft booking resource request
          if (resource.Status === 'CONFIRMED') {
            isCanSoftBooking = true;
          } else {
            isCanSoftBooking = false;
          }

          if (resource.Type === 'Project') {
            if (resource.Project.Status === 2) {  // check if the project is in Waiting Confirmation status
              isWaitingConfirmResource = true
            }
          } else {
            if (resource.ChangeRequest.StatusId === 2) { // check if the CR is in Waiting Confirmation status
              isWaitingConfirmResource = true
            }
          }
        }
      }

      await this.setState({
        isCanRemoveRequest: isRemoveRequest,
        isCanDeallocateRequest: isDeallocateRequest,
        isCanSoftBooking: isCanSoftBooking,
        selectedResources: selectedl,
        hasSubLines: hasSubLines,
        requestAllocation: requestAllocation,
        requestAllocationSoft: requestAllocationSoft,
        requestAllocationSoftDate: requestAllocationSoftDate,
        requestAllocationSoftResubmit: requestAllocationSoftResubmit,
        confirmStatus: confirmStatus,
        numSelectedLines: this.ticked.length,
        isWaitingConfirmResource: isWaitingConfirmResource
      });
    }

  };

  headerSelectionChange = async (event) => {
    const checked = event.syntheticEvent.target.checked;
    this.ticked = [];
    this.sublineTicked = [];
    this.allocationId = [];
    this.setState({
      hasSubLines: false
    })
    var resourceList = [];
    if (this.props.location.action !== 'RPApprove') {
      resourceList = this.state.filteredResourceMix;
    } else {
      resourceList = this.state.resourceMix;
    }
    if (checked) {
      let i;
      for (i = 0; i < resourceList.length; i++) {
        this.ticked.push(i);
        if (resourceList[i].AllocatedResourceID != null) {
          this.allocationId.push(resourceList[i].AllocatedResourceID);
        }
      }
    }
    this.state.editableItem = null;
    this.state.resourceMix.forEach(item => item.selected = checked);
    this.forceUpdate();

    const selectedl = resourceList.filter(resource => resource.selected === true);
    if (selectedl.length === resourceList.length) {
      let isRemoveRequest = false;
      let isDeallocateRequest = false;
      let hasSubLines = false;
      let requestAllocation = false;
      let requestAllocationSoft = false;
      let requestAllocationSoftResubmit = false;
      let requestAllocationSoftDate = false;
      let confirmStatus = false;
      let isCanSoftBooking = false;

      if (selectedl && selectedl.length > 0) {


        for (const resource of selectedl) {
          if (resource.Status === 'NEW_FROM_CMS' || resource.Status === 'NEW_FROM_RM' ||
            resource.Status === 'EDIT' || resource.Status === 'CONFIRMED'
            || resource.Status === 'RES_ALLOC_CANCELLED' || resource.Status === 'RES_ALLOC_REJECTED' || resource.Status === 'RQST_APP_REJECTED') {
            isRemoveRequest = true;
          } else {
            isRemoveRequest = false;
            break;
          }
        }

        //for deallocation
        for (const resource of selectedl) {
          if (resource.Status === 'COMPLETE') {
            isDeallocateRequest = true;
          } else {
            isDeallocateRequest = false;
            break;
          }
        }

        //resourceAllocationCheck
        for (const resource of selectedl) {
          if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED') && (resource.Status !== 'RES_ALLOC_PENDING' || resource.IsSoftBookingResource === false)) {
            requestAllocation = true
          }
        }

        //softbook check
        for (const resource of selectedl) {
          if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource === true) {
            requestAllocationSoft = true
          }
        }

        //for soft booking date check
        for (const resource of selectedl) {
          if (resource.EndDate === null || resource.StartDate === null) {
            requestAllocationSoftDate = true
          }
        }

        //resubmitting same softbook check
        for (const resource of selectedl) {
          if (resource.IsSoftBookingResource === true) {
            requestAllocationSoftResubmit = true
          }
        }

        //confirmStatus
        for (const resource of selectedl) {
          if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
            resource.Status !== 'NEW_FROM_CMS' && resource.Status !== 'RES_ALLOC_CANCELLED') {
            confirmStatus = true;
          }
        }

        //check whether mainline has sublines
        for (const resource of selectedl) {
          if (resource.ResourceRequestSubLine && resource.ResourceRequestSubLine.length > 0) {
            let sublines = resource.ResourceRequestSubLine.filter(res => res.IsActive === true);
            if (sublines.length > 0) {
              hasSubLines = true
            }
          }
        }

        //for soft booking resource request
        for (const resource of selectedl) {
          if (resource.Status === 'CONFIRMED') {
            isCanSoftBooking = true;
          } else {
            isCanSoftBooking = false;
          }
        }
      }

      await this.setState({
        isCanRemoveRequest: isRemoveRequest,
        isCanDeallocateRequest: isDeallocateRequest,
        isCanSoftBooking: isCanSoftBooking,
        selectedResources: selectedl,
        hasSubLines: hasSubLines,
        requestAllocation: requestAllocation,
        requestAllocationSoft: requestAllocationSoft,
        requestAllocationSoftDate: requestAllocationSoftDate,
        requestAllocationSoftResubmit: requestAllocationSoftResubmit,
        confirmStatus: confirmStatus
      });

    }

  };

  getProjectOrCRName = (dataItem) => {
    if (dataItem.Type === 'Project') {
      return dataItem.Project.ProjectName;
    } else if (dataItem.Type === 'ChangeRequest') {
      return dataItem.ChangeRequest.CRName;
    } else {
      return '';
    }
  };

  handleRejectReasonInputChange = event => {
    const value = event.target.value;

    this.setState({
      rejectReason: value
    });
  };

  // Phase related changes
  handleOnChangeInput = event => {
    const field = event.target.name;
    const value = event.target.value;

    let minDate = null;
    if (value && value.Type === 'Project') {
      if (value.Project.StartDate) {
        minDate = new Date(value.Project.StartDate);
      } else {
        minDate = new Date();
      }
    } else {

      if (value) {
        if (value.ChangeRequest.StartDate) {
          minDate = new Date(value.ChangeRequest.StartDate);
        } else {
          minDate = new Date();
        }
      }
    }
    this.setState({
      minPhaseDate: minDate,
      selectedProjecOrCrForPhase: value
    });
  };

  handlePhaseNameInputChange = event => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      phaseName: value
    });
  };

  handlePhaseStartDateChange = event => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      phaseStartDate: value
    });
  };

  handleCancel = async () => {
    let canceledPhaseIndex = this.state.canceledPhaseIndex;

    if (this.state.cancelDataPhaseName) {
      const newPhase = this.state.phasesTemp.filter(obj =>
        obj.Text !== this.state.cancelDataPhaseName
      );

      await this.setState({
        phasesTemp: newPhase,
        projectPhaseVisible: !this.state.projectPhaseVisible,
        selectedProjecOrCrForPhase: null
      });
    } else if (this.state.cancelDataSubPhaseName) {
      //should be refactored - might not need this part
      const newSubPhase = this.state.phasesTemp[canceledPhaseIndex].SubPhases.filter(obj =>
        obj.Text !== this.state.cancelDataSubPhaseName
      );

      this.state.phasesTemp[canceledPhaseIndex].SubPhases = newSubPhase;

      await this.setState({
        projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
        selectedProjecOrCrForPhase: null
      });
    } else {
      await this.setState({
        projectPhaseVisible: !this.state.projectPhaseVisible
      });
    }


  };

  handleCancelSubPhase = async () => {
    let canceledPhaseIndex = this.state.canceledPhaseIndex;

    if (this.state.cancelDataPhaseName) {
      //should be refactored - might not need this part
      const newPhase = this.state.phasesTemp.filter(obj =>
        obj.Text !== this.state.cancelDataPhaseName
      );

      await this.setState({
        phasesTemp: newPhase,
        projectPhaseVisible: !this.state.projectPhaseVisible,
        selectedProjecOrCrForPhase: null
      });
    } else if (this.state.cancelDataSubPhaseName) {
      const newSubPhase = this.state.phasesTemp[canceledPhaseIndex].SubPhases.filter(obj =>
        obj.Text !== this.state.cancelDataSubPhaseName
      );

      this.state.phasesTemp[canceledPhaseIndex].SubPhases = newSubPhase;

      await this.setState({
        projectSubPhaseVisible: !this.state.projectSubPhaseVisible,
        selectedProjecOrCrForPhase: null
      });
    } else {
      await this.setState({
        projectSubPhaseVisible: !this.state.projectSubPhaseVisible
      });
    }


  };

  // sub phase related changes
  handleProjectCRChangeForSubPhase = event => {
    const field = event.target.name;
    const value = event.target.value;

    let selectedProjectOrCRPhases = null;
    let minDate = null;
    if (value && value.Type === 'Project') {
      if (value.Project.StartDate) {
        minDate = new Date(value.Project.StartDate);
      } else {
        minDate = new Date();
      }

      if (value.Project && value.Project.CMSheets && value.Project.CMSheets.length > 0) {
        if (value.Project.CMSheets[0].Phases && value.Project.CMSheets[0].Phases.length > 0) {
          selectedProjectOrCRPhases = value.Project.CMSheets[0].Phases;
        }
      }

    } else {
      if (value.ChangeRequest.StartDate) {
        minDate = new Date(value.ChangeRequest.StartDate);
      } else {
        minDate = new Date();
      }

      if (value.ChangeRequest && value.ChangeRequest.CMSheets && value.ChangeRequest.CMSheets.length > 0) {
        if (value.ChangeRequest.CMSheets[0].Phases && value.ChangeRequest.CMSheets[0].Phases.length > 0) {
          selectedProjectOrCRPhases = value.ChangeRequest.CMSheets[0].Phases;
        }
      }

    }

    this.setState({
      minSubPhaseDate: minDate,
      selectedProjecOrCrForPhase: value,
      selectedProjectOrCrPhases: selectedProjectOrCRPhases
    });

  };

  handleSubPhaseNameInputChange = event => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      subPhaseName: value
    });
  };

  handlePhaseChange = event => {
    const value = event.target.value;
    this.setState(
      prevState => {
        return {
          selectedPhaseIndex: prevState.selectedProjectOrCrPhases.indexOf(value)
        };
      },
      () => {
        //  this.setStartDateMin();
      }
    );
  };

  handleSubPhaseStartDateChange = event => {
    const field = event.target.name;
    const value = event.target.value;

    this.setState({
      subPhaseStartDate: value
    });
  };

  addSubPhase = async () => {
    this.setState({
      isAddDone: true
    });

    if (!this.state.isEditButton) {
      let startDateStr = null;
      let selectedProjectOrCr = null;
      if (this.state.subPhaseStartDate) {
        startDateStr = getFormattedDate(this.state.subPhaseStartDate);
      }

      if (this.state.selectedProjecOrCrForPhase) {
        selectedProjectOrCr = this.state.selectedProjecOrCrForPhase;
      }

      if (this.state.selectedPhaseIndex) {

      }

      this.handleSubPhasesAdd(this.state.selectedPhaseIndex, this.state.subPhaseName, startDateStr, this.state.selectedProjectOrCrPhases, selectedProjectOrCr);

      this.state.canceledPhaseIndex = this.state.selectedPhaseIndex;
      this.state.cancelDataSubPhaseName = this.state.subPhaseName;
      this.state.cancelDataProjectId = this.state.selectedProjecOrCrForPhase;


      this.setState({
        subPhaseName: '',
        startDate: '',
        selectedPhaseIndex: -1
      });
    } else {
      this.handleSubPhasesEdit(this.state.phaseSubPhaseId, this.state.phaseName, this.state.subPhaseName, this.state.subPhaseStartDate, this.state.projectAndCrData);

      await this.setState({
        isSaveButtonVisibleEdit: true,
        subPhaseName: '',
        startDate: '',
        selectedPhaseIndex: -1,
        isEditButton: false
      });
    }
  };

  handleSubPhasesAdd = async (phaseIndex, subPhaseName, startDate, selectedProjectOrCrPhases, projectOrCr) => {
    const cmsheetId = projectOrCr.Type === 'Project' ? projectOrCr.Project.CMSheets[0].id : projectOrCr.ChangeRequest.CMSheets[0].id;
    const phasesTemp = selectedProjectOrCrPhases;

    if (subPhaseName && phasesTemp[phaseIndex]) {
      subPhaseName = subPhaseName.trim();
      const exists = phasesTemp[phaseIndex].SubPhases.filter(obj => {
        let exist = obj.Text;
        if (exist) {
          const existLC = exist.toLowerCase();
          const subPhaseNameLC = subPhaseName.toLowerCase();

          if (subPhaseNameLC === existLC) {
            return true;
          }
          return false;
        }
        return false;
      });
      if (exists.length > 0) {
        const message = 'Sub Phase name already exists. Please use a unique name';
        const title = 'Name Already Exists';
        this.toggleMessageDialog(message, title);
      } else {
        phasesTemp[phaseIndex].SubPhases.push({
          Text: subPhaseName,
          StartDate: startDate
        });

        this.setState({
          selectedProjectOrCrPhases: phasesTemp,
          selectedCMSId: cmsheetId
        });

      }
    }

  };

  removeSubPhase = async () => {
    const phasesTemp = this.state.selectedProjectOrCrPhases;
    phasesTemp[this.state.subPhaseRemovePhaseIndex].SubPhases.splice(this.state.subPhaseRemoveSubPhaseIndex, 1);

    this.setState(
      {
        selectedProjectOrCrPhases: phasesTemp
      },
      () => {
        this.toggleConfirmDialog('', null);
      }
    );

  };

  handleSubPhaseSave = () => {
    if (this.state.selectedProjectOrCrPhases) {
      const phasesTemp = this.state.selectedProjectOrCrPhases;
      //call save phases API
      savePhasesOfResourceRequests(this.state.selectedCMSId, phasesTemp)
        .then(res => {

          this.toggleProjectSubPhase();
          const message = 'Successfully saved.';
          const title = 'Success';
          this.toggleMessageDialog(message, title);
        })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });
    }
  };

  addEditingValueToGrid = async event => {
    if (!this.validateEditingValues()) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true
      });
      this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
    } else {
      if (this.props.location.action !== 'RPApprove') {
        saveFrontEndLogs("Warn","main line's status change - Before",`ResourceRequestId : ${this.state.resourceMix[this.state.editableItem.index].id}, Status : ${this.state.resourceMix[this.state.editableItem.index].Status}`);
        console.log("main line's status change - before - ResourceRequestId : ",this.state.resourceMix[this.state.editableItem.index].id, ", Status : ",this.state.resourceMix[this.state.editableItem.index].Status)
        if (this.state.addedSubLinesToGrid === true) {
          await this.addMainLineAsSubLine(this.state.resourceMix[this.state.editableItem.index]);
          this.state.resourceMix[this.state.editableItem.index].Status = 'EDIT';
          saveFrontEndLogs("Warn","main line's status change - After",`ResourceRequestId : ${this.state.resourceMix[this.state.editableItem.index].id}, Status : ${this.state.resourceMix[this.state.editableItem.index].Status}`);
          console.log("main line's status change - after - ResourceRequestId : ",this.state.resourceMix[this.state.editableItem.index].id, ", Status : ",this.state.resourceMix[this.state.editableItem.index].Status)
          this.state.resourceMix[this.state.editableItem.index].selected = false;
          this.state.resourceMix[this.state.editableItem.index].IsActive = true;
          this.state.resourceMix[this.state.editableItem.index].ResourceId = null;
          this.state.resourceMix[this.state.editableItem.index].AllocatedResource = null;
          this.state.resourceMix[this.state.editableItem.index].AllocatedResourceID = null;
          this.state.resourceMix[this.state.editableItem.index].AllocatedDesignation = null;  
          this.state.resourceMix[this.state.editableItem.index].AllocatedDesignationId = null;
          this.state.resourceMix[this.state.editableItem.index].IsSoftBookingResource = false;
          this.state.resourceMix[this.state.editableItem.index].softbookingPreviousResource = null;    
          this.state.resourceMix[this.state.editableItem.index].ResourceRequestSubLine = this.state.editableItem.ResourceRequestSubLine;
        }       
      } else {
        this.state.editableItem.selected = false;
        this.state.editableItem.IsActive = true;
        this.state.resourceMix[this.state.editableItem.index] = this.state.editableItem;
      }

      if (this.state.managerCommentCMS !== '') {
        this.state.resourceMix[this.state.editableItem.index].ManagerComment = this.state.managerCommentCMS;
      }

      this.state.resourceMix[this.state.editableItem.index].LastUpdatedByUserAt = new Date();
      var resourceEdited = [this.state.resourceMix[this.state.editableItem.index]];
      this.state.showEditDialog = false;
      this.state.editableItem = null;

      this.forceUpdate();

      this.handleResourceSave(resourceEdited);

    }
  };

  addMainLineAsSubLine = (mainLine) => {
    var mainLineStatus = mainLine.Status;
    if (mainLineStatus !== 'NEW_FROM_CMS' && mainLineStatus !== 'NEW_FROM_RM' && mainLineStatus !== 'EDIT') {
      const index = this.state.editableItem.ResourceRequestSubLine ? this.state.editableItem.ResourceRequestSubLine.length : 0;
      const subLineObj = {
        StartDate: mainLine.StartDate,
        EndDate: mainLine.EndDate,
        EffortDays: mainLine.EffortDays,
        AllocationPercentage: mainLine.AllocationPercentage,
        RateType: mainLine.RateType,
        Rate: mainLine.RateValue,
        Status: mainLineStatus,
        index: index,
        RateValue: mainLine.RateValue,
        CostPerResource: mainLine.CostPerResource,
        DirectCost: mainLine.DirectCost,
        Cost: mainLine.TotalCost,
        Revenue: mainLine.TotalRevenue,
        CostToRevenueRatio: mainLine.CostToRevenueRatio,
        selected: false,
        ResourceRequestId: mainLine.id ? mainLine.id : null,
        dataValueResourceRegion: mainLine.dataValueResourceRegion,
        Phase: mainLine.Phase,
        SubPhase: mainLine.SubPhase,
        ProjectRole: mainLine.ProjectRole,
        Designation: mainLine.Designation,
        ResourceRegion: mainLine.ResourceRegion,
        OnsiteStatus: mainLine.OnsiteStatus,
        ResourceId: mainLine.ResourceId ? mainLine.ResourceId : null,
        PhaseId: mainLine.PhaseId,
        SubPhaseId: mainLine.SubPhaseId,
        PaymentMethod: mainLine.PaymentMethod,
        dataValueCostDesignation: mainLine.dataValueCostDesignation,
        dataValueProjectRole: mainLine.dataValueProjectRole,
        EffortOverrun: false,
        LastUpdatedByUserAt: new Date(),
        IsSoftBookingResource: mainLine.IsSoftBookingResource,
        softbookingPreviousResource: mainLine.softbookingPreviousResource,
        InitialCostPerResource: mainLine.InitialCostPerResource,
        InitialDirectCost: mainLine.InitialDirectCost,
        InitialCost: mainLine.InitialTotalCost,
        AllocatedDesignationId: mainLine.AllocatedDesignationId
      };
      if (this.state.editableItem.ResourceRequestSubLine) {
        this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
      } else {
        this.state.editableItem.ResourceRequestSubLine = [];
        this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
      }
      this.setState({
        mainLineAsSubLine: subLineObj
      });
    }
  };

  submitAllocationCheck = async () => {
    let currentRequests = this.state.selectedResources;
    let currentSubLines = this.state.selectedResourcesSubLine;

    const selectedLinesTotal = currentRequests.length + currentSubLines.length;

    const selectedSoftBookedLines = currentRequests.filter(eachLine => eachLine.IsSoftBookingResource === true);
    const selectedSoftBookedSubLines = currentSubLines.filter(eachLine => eachLine.IsSoftBookingResource === true);

    const selectedSoftBookedLinesTotal = selectedSoftBookedLines.length + selectedSoftBookedSubLines.length;

    if ((selectedSoftBookedLines.length > 0 || selectedSoftBookedSubLines.length > 0) && 
    selectedSoftBookedLinesTotal !== selectedLinesTotal) {
      this.setState({
        showSuccessImage: false,
        showErrorImage: true,
        selectedResources: [],
        selectedResourcesSubLine: []
      });
      this.toggleDialog('Please submit the soft booking allocations separately.', 'Error');
    } else if (selectedSoftBookedLines.length == 0 && selectedSoftBookedSubLines.length == 0) {
      this.submitAllocationToggle();
    } else if (selectedSoftBookedLinesTotal == selectedLinesTotal) {
      let minStartDate = null;
      for (const mainLine of selectedSoftBookedLines) {
        const proStartDate = mainLine.Type == "Project" ? mainLine.Project.StartDate : mainLine.ChangeRequest.StartDate;
        if (!minStartDate || proStartDate < minStartDate) {
          minStartDate = proStartDate;
        }
      }
      for (const subLine of selectedSoftBookedSubLines) {
        const proStartDate = subLine.Type == "Project" ? subLine.Project.StartDate : subLine.ChangeRequest.StartDate;
        if (!minStartDate || proStartDate < minStartDate) {
          minStartDate = proStartDate;
        }
      }
      await this.setState({
        softBookingStartDateMin: new Date(minStartDate),
      });
      await this.submitSoftbookedAllocationToggle();
    }
  };

  submitSoftbookedAllocationToggle = async () => {
    await this.setState({
      allocateSoftBookedResourcesDialog: !this.state.allocateSoftBookedResourcesDialog
    });
    if (this.state.allocateSoftBookedResourcesDialog === false) {
      await this.setState({
        isSoftBookingDatesChanged: false,
        softBookingStartDate: null,
        softBookingEndDate: null
      });
    }
  };

  handleSoftBookingStartDateChange = async event => {
    let startDate = event.target.value;
    await this.setState({
      softBookingStartDate: new Date(startDate),
      isSoftBookingDatesChanged: true
    });
  };

  handleSoftBookingEndDateChange = async event => {
    let endDate = event.target.value;
    await this.setState({
      softBookingEndDate: new Date(endDate),
      isSoftBookingDatesChanged: true
    });
  };

  submitSoftBookingResources = async () => {
    if (this.state.isSoftBookingDatesChanged === true) {
      if (this.state.softBookingStartDate == null || this.state.softBookingEndDate == null) {
        await this.setState({
          softBookingDatesError: true
        });
        return;
      } else {
        await this.setState({
          softBookingDatesError: false
        });
      }

      let currentRequests = this.state.selectedResources;
      let currentSubLines = this.state.selectedResourcesSubLine;

      for (const mainLine of currentRequests) {  // calculate new effort, cost and revenue
        await this.getCostForSoftBookingLines(mainLine);
      }
      for (const subLine of currentSubLines) {   // calculate new effort, cost and revenue
        await this.getCostForSoftBookingLines(subLine);
      }
      await this.submitResourcesForAllocation();
      
    } else {
      await this.submitResourcesForAllocation();
    }
  }

  getCostForSoftBookingLines = async dataItem => {

    let billingRegion = 0;
    let projOrCr = null;
    let projectId = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let totalCostUSD = 0;
    let EffortDays = 0;

    dataItem.StartDate = moment(new Date(this.state.softBookingStartDate)).format('MM/DD/YYYY');
    dataItem.EndDate = moment(new Date(this.state.softBookingEndDate)).format('MM/DD/YYYY');

    if (dataItem.Type === 'ChangeRequest') {
      billingRegion = dataItem.ChangeRequest.BillingRegion;
      projOrCr = dataItem.ChangeRequest;
      projectId = dataItem.ChangeRequest.ProjectId;
    } else {
      billingRegion = dataItem.Project.BillingRegion;
      projOrCr = dataItem.Project;
      projectId = dataItem.Project.id;
    }

    let newItem = {
      newResProjectOrCRName: dataItem.Type == 'ChangeRequest' ? dataItem.ChangeRequest : dataItem.Project,
      newResRegion:dataItem.dataValueResourceRegion,
      newOnSiteStatus: dataItem.OnsiteStatus,
      newStartDate: moment(new Date(this.state.softBookingStartDate)).format('MM/DD/YYYY'),
      newEndDate:  moment(new Date(this.state.softBookingEndDate)).format('MM/DD/YYYY'),
      newEffortDays: dataItem.EffortDays.toFixed(2),
      newAllocation: dataItem.AllocationPercentage,
      EstimatedEndDate:moment(new Date(this.state.softBookingEndDate)).format('MM/DD/YYYY'),
      newResDesignation: dataItem.dataValueCostDesignation ? dataItem.dataValueCostDesignation :  { id: dataItem.Designation },
      resourceCountry : dataItem.dataValueResourceRegion.DefaultCountryId,
      projOrCrCountry : projOrCr.CountryId,
      resourceId: null
    };

    let costDate_ = new Date(this.state.softBookingStartDate);

    const costCard = {
      billingRegionId: billingRegion,
      resourceRegionId: dataItem.dataValueResourceRegion ? dataItem.dataValueResourceRegion.id : dataItem.AllocatedResource.Resource.Region,
      designationId: dataItem.Designation,
      projectId: projectId,
      costDate: moment(new Date()).format('YYYY-MM-DD')
    };

    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    };

    // if (newItem.newResDesignation.id === 57) {

    //   await this.calculateMainLineEffort(dataItem);
    //   let costPerResource = dataItem.CostPerResource;
    //   totalCostUSD = (Number(costPerResource * dataItem.EffortDays).toFixed(2));
    //   CostPerResourceInUSD = costPerResource;
    //   EffortDays = dataItem.EffortDays;

    // } else {
      await getCostDetails(costItems)
        .then(res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
          EffortDays = res.data.EffortDays;
        })
        .catch( async err => {
          totalCostUSD = 0;
          DirectCostInUSD = 0;
          CostPerResourceInUSD = 0;
          EffortDays = 0;
          loggerService.writeLog(err, LOG_TYPES.ERROR);

          const message = 'Cost card record not found for the selected designation and resource region';
          const title = 'Error';
          await this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });

          await this.toggleMessageDialog(message, title);
        });
    // }

    dataItem.TotalCost = totalCostUSD;
    dataItem.DirectCost = DirectCostInUSD;
    dataItem.CostPerResource = CostPerResourceInUSD;
    dataItem.EffortDays = EffortDays;
    dataItem.Cost = totalCostUSD;

    // Revenue
    if (EffortDays && dataItem.RateValue) {
      dataItem.TotalRevenue = EffortDays * dataItem.RateValue;
      dataItem.Revenue = EffortDays * dataItem.RateValue;
    } else {
      dataItem.TotalRevenue = 0;
      dataItem.Revenue = 0;
    }

    // Cost to Revenue ratio
    if (totalCostUSD > 0 && dataItem.TotalRevenue > 0) {
      dataItem.CostToRevenueRatio = (totalCostUSD / dataItem.TotalRevenue).toFixed(2);
    } else {
      dataItem.CostToRevenueRatio = 0;
    }
  };

  submitAllocationToggle = async () => {
    await this.setState({
      allocateResourcesDialog: !this.state.allocateResourcesDialog
    });
  };

  submitSoftBookingToggle = async () => {
    await this.setState({
      softBookingDialog: !this.state.softBookingDialog
    });
  };

  toggleClosePhaseDialog = () => {
    if (this.isMount) {
      this.setState({
        projectPhaseVisible: !this.state.projectPhaseVisible
      });
    }
  };

  toggleCloseSubPhaseDialog = () => {
    if (this.isMount) {
      this.setState({
        projectSubPhaseVisible: !this.state.projectSubPhaseVisible
      });
    }
  };

  submitResourcesForAllocation = async event => {
    if (this.state.allocateResourcesDialog == true) {
      this.submitAllocationToggle();
    } else if (this.state.allocateSoftBookedResourcesDialog == true) {
      this.submitSoftbookedAllocationToggle();
    }

    const sendAll = await this.approvalWorkflow();

    if (sendAll) {

      let currentRequests = this.state.selectedResources;
      let currentSubLines = this.state.selectedResourcesSubLine;

      if (currentRequests && currentRequests.length > 0) {
        currentRequests.forEach(function (resource) {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
        });
      }

      if (currentSubLines && currentSubLines.length > 0) {
        currentSubLines.forEach(function (resource) {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
        });
      }

      await this.sendForAllocation();
      this.setState({
        selectedResources: [],
        selectedResourcesSubLine: []
      })

    } else {
      selectResourceRequestWorkflow({ billingRegion: this.state.project.Region.id, practice: this.state.project.Division.id }).then(res => {
        if (res.data.length > 1) {
          this.setState(
            {
              matchingWorkflowList: res.data
            },
            () => {
              this.toggleWorkflowSelectDialogRR();
            }
          );
        } else if (res.data.length === 1) {
          this.setState(
            {
              selectedWorkFlowId: res.data[0].id
            },
            () => {
              this.setWorkflowToResources();
            }
          );
        } else {
          const message =
            'This Project has failed to find a appropriate workflow for approvals. Please contact your system administrator to correct this issue';
          const title = 'Error';
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          this.toggleMessageDialog(message, title);
        }
      });
    }

    this.ticked = [];
    this.sublineTicked = [];
    this.allocationId = [];
    this.submitSearch();
    this.setState({
      hasSubLines: false,
      confirmStatus: false,
      confirmStatusSub: false
    })

  };

  sendForAllocation = () => {

    var currentRequestsFinal = [];
    var currentSubLinesFinal = [];
    if (this.state.selectedResources && this.state.selectedResources.length > 0) {
      currentRequestsFinal = this.state.selectedResources.filter(eachLine => eachLine.Status === 7);
    }
    if (this.state.selectedResourcesSubLine && this.state.selectedResourcesSubLine.length > 0) {
      currentSubLinesFinal = this.state.selectedResourcesSubLine.filter(eachLine => eachLine.Status === 7);
    }

    // Select the softbooking lines with COMPLETE state
    if (this.state.selectedResources && this.state.selectedResources.length > 0) {
      let tempAry = this.state.selectedResources.filter(eachLine => (eachLine.Status === 5) && eachLine.IsSoftBookingResource == true);
      Array.prototype.push.apply(currentRequestsFinal, tempAry);
    }
    if (this.state.selectedResourcesSubLine && this.state.selectedResourcesSubLine.length > 0) {
      let tempAry = this.state.selectedResourcesSubLine.filter(eachLine => (eachLine.Status === 5) && eachLine.IsSoftBookingResource == true);
      Array.prototype.push.apply(currentSubLinesFinal, tempAry);
    }

    if (currentRequestsFinal.length > 0 || currentSubLinesFinal.length > 0) {
      const allocationObj = {
        mainLines: currentRequestsFinal,
        subLines: currentSubLinesFinal,
        uniqueId: this.state.project.UniqueId,
        projectName: this.state.project.ProjectName
      };
      submitForResourceAllocation(allocationObj)
        .then(response => {
          this.updateLineHistory(currentRequestsFinal, currentSubLinesFinal);
          let message = 'Selected resources were sent for resource allocation.';
          const title = 'Success';
          this.setState({
            showSuccessImage: true,
            showErrorImage: false,
            managerComment: ''
          });
          this.toggleDialog(message, title);
          this.getInitialData();

        })
        .catch(error => {
          this.setState({
            showSuccessImage: false,
            showErrorImage: true
          });
          loggerService.writeLog(error, LOG_TYPES.ERROR);
          this.toggleDialog(error.response.data, 'Error');
        });
    }
  }

  updateLineHistory = (mainLines, subLines) => {
    var pmcomment = null;
    saveResourceLineHistory({ mainLines: mainLines, subLines: subLines, comment: pmcomment, type: 'ResourceRequest' })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

  };

  getLineHistory = async (resourceRequestId, resourceRequestIdSub) => {
    this.setState({
      lineStatusHistoryList: []
    });
    const resourceDetails = {
      resourceRequestId: resourceRequestId,
      resourceRequestIdSub: resourceRequestIdSub
    };

    var approvals = [];
    await getAllApproversForResource({ mainLineId: resourceRequestId, subLineId: resourceRequestIdSub, projId: this.state.project.id })
      .then(res => {
        approvals = res.data;
      }).catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });

    getLineHistory(resourceDetails)
      .then(res => {
        for (const each of res.data) {
          each.statusChangedAt = each.statusChangedAt ? each.statusChangedAt : null;
          each.GroupCardName = each.Approval ? each.Approval.CardName : null;
          if(!each.Approval && (each.ToStatus == 'COMPLETE' || each.ToStatus == 'RES_ALLOC_CANCELLED') && each.Comment !== 'Cancelled due to CMS revision.') {
            each.GroupCardName = 'RP'
          } else if (!each.Approval && each.id === res.data[0].id && each.Comment !== 'Cancelled due to CMS revision.') {
            each.GroupCardName = 'PM'
          }
        }
        //append pending approvers to the end
        if (res.data[res.data.length - 1].ToStatus == 'RQST_APP_PENDING' || res.data[res.data.length - 1].ToStatus == 'ALLOC_APP_PENDING') { //request approval pending/allocation approval pending - append all approvers
          let lastStatus = res.data[res.data.length - 1].ToStatus;
          for (const approval of approvals) {
            if (approval.Status == "PENDING" && lastStatus == 'RQST_APP_PENDING') {
              var history = {
                ToStatus: 'RQST_APP_PENDING',
                updatedUser: approval.Resource.Name,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            } else if (approval.Status == "PENDING" && lastStatus == 'ALLOC_APP_PENDING') {
              var history = {
                ToStatus: 'ALLOC_APP_PENDING',
                updatedUser: approval.Resource.Name,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            } else {
              var history = {
                ToStatus: approval.Status,
                updatedUser: approval.Resource.Name,
                statusChangedAt: approval.Date ? approval.Date : null,
                GroupCardName: approval.CardName
              };
              res.data.push(history);
            }
          }
        }
        this.setState({
          lineStatusHistoryList: res.data
        });
      })
      .catch(err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
      });
  };

  toggleWorkflowSelectDialogRR = () => {
    this.setState({
      showWorkflowSelectDialogRR: !this.state.showWorkflowSelectDialogRR
    });
  };

  setSelectedWorkflowId = workflowId => {
    this.setState({
      selectedWorkFlowId: workflowId
    });
  };

  setWorkflowToResources = async () => {
    await this.setState({
      isLoading: true,
      disableAddWorkflowBtn: true
    });
    var mainLinesToSend = [];
    var subLinesToSend = [];
    var allocMainLines = [];
    var allocSubLines = [];
    if (this.state.selectedResources) {
      mainLinesToSend = this.state.selectedResources.filter(line => line.Status === 5);
      allocMainLines = this.state.selectedResources.filter(line => line.Status === 7);

      let tmpAry = this.state.selectedResources.filter(line => line.Status === 5 && line.IsSoftBookingResource === true);
      Array.prototype.push.apply(allocMainLines, tmpAry);
    }
    if (this.state.selectedResourcesSubLine) {
      subLinesToSend = this.state.selectedResourcesSubLine.filter(line => line.Status === 5);
      allocSubLines = this.state.selectedResourcesSubLine.filter(line => line.Status === 7);

      let tmpAry = this.state.selectedResourcesSubLine.filter(line => line.Status === 5 && line.IsSoftBookingResource == true);
      Array.prototype.push.apply(allocSubLines, tmpAry);
    }

    const workflowData =
    {
      mainLines: mainLinesToSend,
      subLines: subLinesToSend,
      projId: this.state.project.id,
      workflowId: this.state.selectedWorkFlowId
    };
    await this.updateLineHistory(mainLinesToSend, subLinesToSend);
    await setWorkFlow(workflowData)
      .then(async res => {
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialogRR();
        }
        this.setState({ selectedWorkFlowId: null });
        if (allocMainLines.length > 0 || allocSubLines.length > 0) {
          this.sendForAllocation(); //allocate resources of status 7
        } else {
          let message = 'Selected resources were sent for resource allocation.';
          const title = 'Success';
          this.setState({
            showSuccessImage: true,
            showErrorImage: false,
            selectedResources: [],
            selectedResourcesSubLine: [],
            managerComment: ''
          });
          this.toggleDialog(message, title);
          await this.getInitialData();
        }
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        if (this.state.matchingWorkflowList.length > 1) {
          this.toggleWorkflowSelectDialogRR();
        }
        const message = error.response.data;
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showErrorImage: true,
          selectedResources: [],
          selectedResourcesSubLine: []
        });
        this.toggleMessageDialog(message, title);
      });
      await this.setState({
        isLoading: false,
        disableAddWorkflowBtn: false
      });
  };

  approvalWorkflow = async () => {
    const calculatedValues = this.state.calculatedValues;
    var totalCostProjAndCrsCMS = calculatedValues.totalCostCMS;
    var totalRevenueProjAndCrsCMS = calculatedValues.discountedRevenueToFortudeCMS;
    var totalCostLinesSelected = 0.00;
    var totalRevenueLinesSelected = 0.00;
    var totalCostAllocated = 0.00;
    var totalRevenueAllocated = 0.00;
    var remainingCost = 0.00;
    var remainingRevenue = 0.00;
    var submitForAllocation = false;

    //Already allocated lines cost
    for (const mainLine of this.state.resourceMix) {
      if (mainLine.Project && mainLine.Project.StatusConvertedToActive === true) {
        if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
          for (const subLine of mainLine.ResourceRequestSubLine) {
            if (subLine.Status === 'RES_ALLOC_PENDING' || subLine.Status === 'COMPLETE' || subLine.Status === 'CLOSE' || subLine.Status === 'ALLOC_APP_PENDING') {
              totalCostAllocated += Number(subLine.Cost);
              totalRevenueAllocated += Number(subLine.Revenue);
            }
          }
        } else {
          if (mainLine.Status === 'RES_ALLOC_PENDING' || mainLine.Status === 'COMPLETE' || mainLine.Status === 'CLOSE' || mainLine.Status === 'ALLOC_APP_PENDING') {
            totalCostAllocated += Number(mainLine.TotalCost);
            totalRevenueAllocated += Number(mainLine.TotalRevenue);
          }
        }
      } else if (mainLine.ChangeRequest && mainLine.ChangeRequest.StatusConvertedToActive === true) {
        if (mainLine.ResourceRequestSubLine && mainLine.ResourceRequestSubLine.length > 0) {
          for (const subLine of mainLine.ResourceRequestSubLine) {
            if (subLine.Status === 'RES_ALLOC_PENDING' || subLine.Status === 'COMPLETE' || subLine.Status === 'CLOSE' || subLine.Status === 'ALLOC_APP_PENDING') {
              totalCostAllocated += Number(subLine.Cost);
              totalRevenueAllocated += Number(subLine.Revenue);
            }
          }
        } else {
          if (mainLine.Status === 'RES_ALLOC_PENDING' || mainLine.Status === 'COMPLETE' || mainLine.Status === 'CLOSE' || mainLine.Status === 'ALLOC_APP_PENDING') {
            totalCostAllocated += Number(mainLine.TotalCost);
            totalRevenueAllocated += Number(mainLine.TotalRevenue);
          }
        }
      }
    }

    if (totalCostProjAndCrsCMS > totalCostAllocated) {
      remainingCost = totalCostProjAndCrsCMS - totalCostAllocated;
    }
    if (totalRevenueProjAndCrsCMS > totalRevenueAllocated) {
      remainingRevenue = totalRevenueProjAndCrsCMS - totalRevenueAllocated;
    }

    //cost and revenue of lines that are selected
    let currentRequests = this.state.selectedResources;
    let currentSubLines = this.state.selectedResourcesSubLine;

    if (currentRequests && currentRequests.length > 0) {
      currentRequests.forEach(function (resource) {
        totalCostLinesSelected += Number(resource.TotalCost);
        totalRevenueLinesSelected += Number(resource.TotalRevenue);
      });
    }

    if (currentSubLines && currentSubLines.length > 0) {
      currentSubLines.forEach(function (resource) {
        totalCostLinesSelected += Number(resource.Cost);
        totalRevenueLinesSelected += Number(resource.Revenue);
      });
    }
    if (totalCostLinesSelected - Math.ceil(remainingCost) > 500) {
      //line logic and then approval workflow
      var mainlinesToSendCMS = [];
      var mainlinesToSendRM = [];
      if (currentRequests && currentRequests.length > 0) { //selected main lines
        currentRequests.forEach(function (resource) {
          if (resource.CMSResource !== true) {
            mainlinesToSendRM.push(resource);
          } else {
            mainlinesToSendCMS.push(resource);

          }
        });
      }
      var sublinesToSendCMS = [];
      var sublinesToSendRM = [];
      var resourceMix = this.state.resourceMix;
      if (currentSubLines && currentSubLines.length > 0) { //selected sublines
        currentSubLines.forEach(function (resource) {
          const sublineFromCMS = resourceMix.filter(item => (item.id === resource.ResourceRequestId) && (item.CMSResource === true));
          if (resource.EffortOverrun === false && sublineFromCMS.length > 0) {
            sublinesToSendCMS.push(resource);
          } else {
            sublinesToSendRM.push(resource);
          }
        });
      }

      var returnedCMSMainVals = await this.sortMainLines(mainlinesToSendCMS, remainingCost, remainingRevenue);
      var returnedCMSSubVals = await this.sortSubLines(sublinesToSendCMS, returnedCMSMainVals[0], returnedCMSMainVals[1]);
      var returnedRMMainVals = await this.sortMainLines(mainlinesToSendRM, returnedCMSSubVals[0], returnedCMSSubVals[1]);
      await this.sortSubLines(sublinesToSendRM, returnedRMMainVals[0], returnedRMMainVals[1]);

      let mainLinesToSend = [];
      let subLinesToSend = [];
      if (this.state.selectedResources) {
        mainLinesToSend = this.state.selectedResources.filter(line => line.Status === 5);
      }
      if (this.state.selectedResourcesSubLine) {
        subLinesToSend = this.state.selectedResourcesSubLine.filter(line => line.Status === 5);
      }

      if (mainLinesToSend.length > 0 || subLinesToSend.length > 0) {
        submitForAllocation = false;
      } else {
        submitForAllocation = true;
      }

    } else {
      submitForAllocation = true; //send all lines for allocation
    }

    return submitForAllocation;

  };

  sortMainLines = async (allLines, remainingCost, remainingRevenue) => {
    // sort by value
    await allLines.sort(function (a, b) {
      return a.TotalCost - b.TotalCost;
    });

    allLines.forEach(function (resource) {
      let paymentMethod = resource.Type === "Project" ? resource.Project.PaymentMethod : resource.ChangeRequest.PaymentMethodId;

      if (paymentMethod !== 2) {  // check if the payment method is T/M
        if (((Number(resource.TotalCost) - Number(remainingCost)) > 500)) {
          resource.Status = 5;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.TotalCost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.TotalRevenue);
        } else {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.TotalCost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.TotalRevenue);
        }
      } else {
        if (resource.TotalRevenue <= 0 && ((Number(resource.TotalCost) - Number(remainingCost)) > 500)) {
          resource.Status = 5;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.TotalCost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.TotalRevenue);
        } else {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.TotalCost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.TotalRevenue);
        }
      }
    })
    return [remainingCost, remainingRevenue];
  };

  sortSubLines = async (allLines, remainingCost, remainingRevenue) => {
    // sort by value
    await allLines.sort(function (a, b) {
      return a.Cost - b.Cost;
    });
    let resourceMix = this.state.resourceMix;
    allLines.forEach(function (resource) {
      const mainLine = resourceMix.filter(item => (item.id === resource.ResourceRequestId));
      let paymentMethod = mainLine[0].Type === "Project" ? mainLine[0].Project.PaymentMethod : mainLine[0].ChangeRequest.PaymentMethodId;
      if (paymentMethod !== 2) { // check if the payment method is T/M
        if (((Number(resource.Cost) - Number(remainingCost)) > 500)) {
          resource.Status = 5;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.Cost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.Revenue);
        } else {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.Cost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.Revenue);
        }
      } else {
        if (resource.Revenue <= 0 && ((Number(resource.Cost) - Number(remainingCost)) > 500)) {
          resource.Status = 5;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.Cost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.Revenue);
        } else {
          resource.Status = 7;
          resource.ToStatus = resource.Status;
          remainingCost = Number(remainingCost) - Number(resource.Cost);
          remainingRevenue = Number(remainingRevenue) - Number(resource.Revenue);
        }
      }
    })
    return [remainingCost, remainingRevenue];
  };

  onClickCancel = event => {
    this.toggleCancelConfirmDialog();
  };

  onClickUpdateCost = async () => {
    await this.setState({
      isLoading: true
    });
    const invalidStatuses = ['REMOVED', 'CLOSE', 'EDIT'];
    for(let resourceRequest of this.state.resourceMix) {

      //mainLines
      if(!invalidStatuses.includes(resourceRequest.Status)) {
        let requestTypeProject = true;
        let billingRegion = null;
        let region = null;
        let projOrCrCountry = null;

        let projectId = resourceRequest.ProjectId;
        if (resourceRequest.Type == 'Project') {
        } else {
          requestTypeProject = false;
        }

        if (requestTypeProject) {
          let projectObj = null;
          await getProjectDetails(resourceRequest.ProjectId)
            .then(res => {
              projectObj = res.data;
              if (projectObj && projectObj.length > 0) {
                billingRegion = projectObj[0].BillingRegion;
                region = projectObj[0].Region;
                projOrCrCountry = projectObj[0].CountryId;
              }
            })
            .catch(async err => {
              loggerService.writeLog(err, LOG_TYPES.ERROR);
            });
        } else {
          let crObj = null;
          await getCRDetails(resourceRequest.ProjectId)
            .then(res => {
              crObj = res.data;
              if (crObj && crObj.length > 0) {
                billingRegion = crObj[0].BillingRegion;
                projectId = crObj[0].ProjectId;
                region = crObj[0].Region;
                projOrCrCountry = crObj[0].CountryId;
              }
            }).catch(async err => {
              loggerService.writeLog(err, LOG_TYPES.ERROR);
            });

        }

        if (resourceRequest.AllocatedResourceID !== null) { //there is a resource
          let ofTypeProject = false;
          if(resourceRequest.Type == 'Project'){
            ofTypeProject = true
          }
          const costItems = {
            resourceRequest: resourceRequest,
            mainLine: true,
            projectId: resourceRequest.ProjectId,
            ofTypeProject: ofTypeProject
          };
          await getCostofAllocatedResource(costItems)
            .then(res => {
              resourceRequest.EffortDays = res.data.EffortDays;
              resourceRequest.TotalCost = res.data.TotalCost;
              resourceRequest.DirectCost = res.data.DirectCost;
              resourceRequest.CostPerResource = res.data.CostPerResource;
            })
            .catch(async err => {
              loggerService.writeLog(err, LOG_TYPES.ERROR);
            });
        } else {
          //make newItem and call calculate method
          let newItem = {
            newResProjectOrCRName: { billingRegion: billingRegion },
            newResRegion: { id: resourceRequest.ResourceRegion },
            newOnSiteStatus: resourceRequest.OnsiteStatus,
            newStartDate: resourceRequest.StartDate ? moment(resourceRequest.StartDate).format('MM/DD/YYYY') : null,
            newEndDate: resourceRequest.EndDate ? moment(resourceRequest.EndDate).format('MM/DD/YYYY') : null,
            newEffortDays: resourceRequest.EffortDays.toFixed(2),
            newAllocation: resourceRequest.AllocationPercentage,
            EstimatedEndDate: resourceRequest.EndDate ? resourceRequest.EndDate : null,
            newResDesignation: { id: resourceRequest.Designation },
            resourceCountry: resourceRequest.dataValueResourceRegion.DefaultCountryId,
            projOrCrCountry: projOrCrCountry,
            resourceId: null,
            CostPerOutSourcedResource: resourceRequest.Designation == 57 ? (resourceRequest.CostPerOutSourcedResource && resourceRequest.CostPerOutSourcedResource > 0) ? resourceRequest.CostPerOutSourcedResource : resourceRequest.InitialCostPerResource : null
          };
          let costDate_ = new Date(newItem.newStartDate);
          const costCard = {
            billingRegionId: newItem.newResProjectOrCRName.billingRegion,
            resourceRegionId: newItem.newResRegion.id,
            designationId: newItem.newResDesignation.id,
            costDate: moment(costDate_).format('YYYY-MM-DD'),
            projectId: projectId
          };
          costDate_ = moment(costDate_).format('YYYY-MM-DD')
          const costItems = {
            costCard: costCard,
            newItem: newItem,
            costDate: costDate_
          };

          await getCostDetails(costItems)
            .then(res => {
              resourceRequest.TotalCost = res.data.TotalCost;
              resourceRequest.DirectCost = res.data.DirectCost;
              resourceRequest.CostPerResource = res.data.CostPerResource;
            })
            .catch(async err => {
              loggerService.writeLog(err, LOG_TYPES.ERROR);
            });
        }
      }
      //sublines
      if(resourceRequest.ResourceRequestSubLine.length > 0){
        for (const subline of resourceRequest.ResourceRequestSubLine) {
          if(!invalidStatuses.includes(subline.Status)) {
            let billingRegion = null;
            let region = null;
            let projOrCrCountry = null;
            let requestTypeProject = true;

            let projectId = resourceRequest.ProjectId;
            if (resourceRequest.Type == 'Project') {
            } else {
              requestTypeProject = false;
            }

            if (requestTypeProject) {
              let projectObj = null;
              await getProjectDetails(resourceRequest.ProjectId)
                .then(res => {
                projectObj = res.data;
                if (projectObj && projectObj.length > 0) {
                  billingRegion = projectObj[0].BillingRegion;
                  region = projectObj[0].Region;
                  projOrCrCountry = projectObj[0].CountryId;
                }
              })
                .catch(async err => {
                loggerService.writeLog(err, LOG_TYPES.ERROR);
              });
            } else {
              let crObj = null;
              await getCRDetails(resourceRequest.ProjectId)
                .then(res => {
                  crObj = res.data;
                  if (crObj && crObj.length > 0) {
                    billingRegion = crObj[0].BillingRegion;
                    projectId = crObj[0].ProjectId;
                    region = crObj[0].Region;
                    projOrCrCountry = crObj[0].CountryId;
                  }
                }).catch(async err => {
                  loggerService.writeLog(err, LOG_TYPES.ERROR);
                });
            }

            if (subline.ResourceId !== null) {//there is a resource
              let ofTypeProject = false;
              if(resourceRequest.Type == 'Project'){
                ofTypeProject = true
              }
              const costItems = {
                resourceRequest: subline,
                mainLine: false,
                projectId: resourceRequest.ProjectId,
                ofTypeProject: ofTypeProject
              };
              await getCostofAllocatedResource(costItems)
                .then(res => {
                  subline.EffortDays = res.data.EffortDays;
                  subline.Cost = res.data.TotalCost;
                  subline.DirectCost = res.data.DirectCost;
                  subline.CostPerResource = res.data.CostPerResource;
                })
                .catch(async err => {
                  loggerService.writeLog(err, LOG_TYPES.ERROR);
                });
            } else {
              //make newItem and call calculate method
              let newItem = {
                newResProjectOrCRName: { billingRegion: billingRegion },
                newResRegion: { id: subline.ResourceRegion },
                newOnSiteStatus: subline.OnsiteStatus,
                newStartDate: subline.StartDate ? moment(subline.StartDate).format('MM/DD/YYYY') : null,
                newEndDate: subline.EndDate ? moment(subline.EndDate).format('MM/DD/YYYY') : null,
                newEffortDays: subline.EffortDays.toFixed(2),
                newAllocation: subline.AllocationPercentage,
                EstimatedEndDate: subline.EndDate ? subline.EndDate : null,
                newResDesignation: { id: subline.Designation },
                resourceCountry: subline.dataValueResourceRegion.DefaultCountryId,
                projOrCrCountry: projOrCrCountry,
                resourceId: null,
                CostPerOutSourcedResource: subline.Designation == 57 ? (subline.CostPerOutSourcedResource && subline.CostPerOutSourcedResource > 0) ? subline.CostPerOutSourcedResource : subline.InitialCostPerResource : null
              };
              let costDate_ = new Date(newItem.newStartDate);
              const costCard = {
                billingRegionId: newItem.newResProjectOrCRName.billingRegion,
                resourceRegionId: newItem.newResRegion.id,
                designationId: newItem.newResDesignation.id,
                costDate: moment(costDate_).format('YYYY-MM-DD'),
                projectId: projectId
              };
              costDate_ = moment(costDate_).format('YYYY-MM-DD');
              const costItems = {
                costCard: costCard,
                newItem: newItem,
                costDate: costDate_
              };

              await getCostDetails(costItems)
                .then(res => {
                  subline.Cost = res.data.TotalCost;
                  subline.DirectCost = res.data.DirectCost;
                  subline.CostPerResource = res.data.CostPerResource;
                })
                .catch(async err => {
                  loggerService.writeLog(err, LOG_TYPES.ERROR);
                });
            }
          }
        }
      }
    }
    await this.handleResourceSave(this.state.resourceMix);
    await this.setState({
      isLoading: false
    });
  };

  onClickReject = event => {
    this.setState({
      rejectReason: ''
    });
    this.toggleRejectConfirmDialog();
  };

  onClickApprove = event => {
    this.toggleConfirmDialog('Are you sure you want to approve this?', this.approveConfirm);
  };

  approveConfirm = event => {
    const approveObj = {
      projectId: this.state.project.id,
      mainLines: this.state.selectedResources ? this.state.selectedResources : [],
      subLines: this.state.selectedResourcesSubLine ? this.state.selectedResourcesSubLine : []
    };
    this.toggleConfirmDialog('', null);

    approveResourceRequest(approveObj)
      .then( res => {
        this.setState({
          showSuccessImage: true,
          showErrorImage: false,
        });
        this.ticked = [];
        this.toggleDialog('Selected resources was approved successfully', 'Success');
        this.getInitialData();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  rejectConfirm = event => {
    const rejectObj = {
      projectId: this.state.project.id,
      comment: this.state.rejectReason,
      mainLines: this.state.selectedResources ? this.state.selectedResources : [],
      subLines: this.state.selectedResourcesSubLine ? this.state.selectedResourcesSubLine : [],
      statusIn: 5
    };

    this.toggleRejectConfirmDialog();
    rejectResourceRequest(rejectObj)
      .then(res => {
        this.setState({
          rejectReason: '',
          selectedResources: [],
          selectedResourcesSubLine: [],
          showSuccessImage: true,
          showErrorImage: false,
        });
        this.ticked = [];
        this.toggleDialog('Selected resources was rejected successfully', 'Success');
        this.getInitialData();
      })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
        this.toggleDialog(error.response.data, 'Error');
      });
  };

  onClickCancelResource = event => {
    this.clearAddingValues();
    if (this.state.isMount) {
      this.toggleCancelConfirmDialog();
    } else {
      this.setState({
        showAddDialog: false,
      });
    }

    this.setState({
      isCancelAddPopUp: true
    });
  };

  toggleCancelConfirmDialog = () => {
    this.setState({
      ...this.state,
      showCancelConfirmation: !this.state.showCancelConfirmation
    });
  };

  toggleRejectConfirmDialog = () => {
    this.setState({
      showRejectConfirmation: !this.state.showRejectConfirmation
    });
  };

  navigateBack = async (event) => {
    if (this.state.isCancelAddPopUp === true) {
      return this.setState({
        showAddDialog: false,
        showRejectConfirmation: false,
        showCancelConfirmation: false,
        isCancelAddPopUp: false
      });
    }
    if (this.state.addOtherExpensesPopup === true || this.state.addNewOtherExpensePopup === true) {
      if (this.state.addOtherExpensesPopup === true) {
        await this.populateOtherExpenses();
        this.handleActualCalculations();
      }
      return this.setState({
        addOtherExpensesPopup: false,
        showRejectConfirmation: false,
        showCancelConfirmation: false,
        addNewOtherExpensePopup: false,
        isEditOtherExpense: false,
        disableOtherExpenseSaveBtn: false
      });
    }
    this.state.isEditResource ? this.setState({showEditDialog: false, showCancelConfirmation: false, isEditResource: false, addedSubLinesToGrid: false}) : this.setState({goBack: true});
  };

  // Resource request sub line related changes
  handleSubLineStartDateChange = async event => {
    let endDate = event.target.value;

    const dateObj = {
      StartDate: moment(endDate).format('YYYY-MM-DD')
    };

    await this.setState({
      selctedResourceSubLineStartDate: endDate,
      isFormDirty: true
    });

    await this.calculateEffort();
  };

  handleSubLineEndDateChange = async event => {
    let endDate = event.target.value;

    const dateObj = {
      EndDate: moment(endDate).format('YYYY-MM-DD')
    };

    await this.setState({
      selctedResourceSubLineEndDate: endDate
    });

    await this.calculateEffort();
  };

  handleSubLineAllocationPercentageChange = async event => {
    let allocPercentage = event.target.value;

    if (allocPercentage && allocPercentage > 100) {
      allocPercentage = 100;
    } else if (!allocPercentage) {
      allocPercentage = 100;
    } else if (allocPercentage === 0) {
      allocPercentage = 100;
    }

    const allocPercentageObj = {
      allocation: allocPercentage
    };

    await this.setState({
      subLineDefaultAllocationPercentage: Number((Number(allocPercentage)).toFixed(2))
    });

    if (this.state.showEditSubLineDialog === true) {
      await this.calculateEffortForEditSubline()
    } else {
      await this.calculateEffort();
    }

  };

  handleSubLineRateTypeChange = async event => {
    let rateType = event.target.value;

    let index = 0;
    if (this.state.editableItem && this.state.editableItem.SubLines && this.state.editableItem.SubLines.length > 0) {
      for (let index of this.state.editableItem.SubLines) {
        index++;
      }
    }

    let resourceLineObj = [];
    if (index > 0) {
      resourceLineObj = JSON.parse(JSON.stringify(this.state.editableItem.SubLines[index]));
    }

    resourceLineObj.RateType = rateType;
    resourceLineObj.Rate = rateType.Rate ? rateType.Rate : 0;

    this.state.editableItem.SubLines[index] = resourceLineObj;

    await this.setState({
      subLineDefaultRateType: rateType,
      subLineDefaultRateValue: rateType.Rate ? rateType.Rate : 0
    });

    this.calculateEffort();
  };

  calculateEffort = async () => {
    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    if (this.state.selctedResourceSubLineStartDate && this.state.selctedResourceSubLineEndDate && this.state.subLineDefaultAllocationPercentage > 0) {
      let startDate = this.state.selctedResourceSubLineStartDate;
      let endDate = this.state.selctedResourceSubLineEndDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      let resourceCountryId = null;
      if(this.state.editableItem.OnsiteStatus == "Onsite"){
        const selectedProjOrCr = this.state.projectAndCrData.filter( item => item.Name == this.state.editableItem.name);
        // if(selectedProjOrCr[0].Type == "ChangeRequest"){
        //   resourceCountryId = selectedProjOrCr[0].ChangeRequest.CountryId
        // }else{
        //   resourceCountryId = selectedProjOrCr[0].Project.CountryId
        // }
        resourceCountryId = this.state.editableItem.Type == "ChangeRequest" ? this.state.editableItem.ChangeRequest.CountryId : this.state.editableItem.Project.CountryId;
      } else {
        resourceCountryId = this.state.editableItem.dataValueResourceRegion.DefaultCountryId;
      }
      let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (holidays && holidays.length === 0) {
      } else if (!holidays) {
        holidays = [];
      }

      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (!isHoliday && !isWeekend(loop)) {
          totalWorkingDays++;
        } else {
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      const effort = totalWorkingDays * (this.state.subLineDefaultAllocationPercentage / 100);
      this.setState({
        subLineEffortDays: effort
      });
    }
  };

  calculateEffortForAddResource = async () => {
    await this.setState({
      isLoading: true
    });
    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    if (this.state.newStartDate && this.state.newEndDate && this.state.newAllocation > 0 && this.state.newOnSiteStatus && this.state.newResProjectOrCRName) {
      let startDate = this.state.newStartDate;
      let endDate = this.state.newEndDate;
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      var effort = 0;
      if (this.state.newOnSiteStatus.id === 2) {
        const resourceCountryId = this.state.newResRegion ? this.state.newResRegion.DefaultCountryId : null;
        let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

        if (holidays && holidays.length === 0) {
        } else if (!holidays) {
          holidays = [];
        }

        let loop = new Date(startDate);
        while (loop <= endDate) {
          isHoliday = false;
          const dateStr = getFormattedDate(loop);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (!isHoliday && !isWeekend(loop)) {
            totalWorkingDays++;
          } else {
          }

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        effort = totalWorkingDays * (this.state.newAllocation / 100);
      } else if (this.state.newOnSiteStatus.id === 1) {
        const resourceCountryId = this.state.newResProjectOrCRName ? this.state.newResProjectOrCRName.countryId : null;
        let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
          if (res && res.data) {
            return res.data;
          }
          return [];
        })
          .catch(error => {
            loggerService.writeLog(error, LOG_TYPES.ERROR);
          });

        if (holidays && holidays.length === 0) {
        } else if (!holidays) {
          holidays = [];
        }

        let loop = new Date(startDate);
        while (loop <= endDate) {
          isHoliday = false;
          const dateStr = getFormattedDate(loop);
          if (holidays.indexOf(dateStr) > -1) {
            isHoliday = true;
          }

          if (!isHoliday && !isWeekend(loop)) {
            totalWorkingDays++;
          } else {
          }

          let newDate = loop.setDate(loop.getDate() + 1);
          loop = new Date(newDate);
        }

        effort = totalWorkingDays * (this.state.newAllocation / 100);
      }
      await this.setState({
        isLoading: false,
        newEffortDays: effort
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  };

  calculateEffortForEditSubline = async () => {
    let totalWorkingDays = 0; // TO KEEP TRACK OF THE TOTAL WORKING DAYS
    let isHoliday = false;
    if (this.state.selectedSubLineToEdit.StartDate && this.state.selectedSubLineToEdit.EndDate && this.state.subLineDefaultAllocationPercentage > 0) {
      let startDate = new Date(this.state.selectedSubLineToEdit.StartDate);
      let endDate = new Date(this.state.selectedSubLineToEdit.EndDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(0, 0, 0, 0);

      const resourceCountryId = this.state.selectedSubLineToEdit.dataValueResourceRegion.DefaultCountryId;
      let holidays = await getHolidaysIntegrated(resourceCountryId, startDate.getFullYear()).then(res => {
        if (res && res.data) {
          return res.data;
        }
        return [];
      })
        .catch(error => {
          loggerService.writeLog(error, LOG_TYPES.ERROR);
        });

      if (holidays && holidays.length === 0) {
      } else if (!holidays) {
        holidays = [];
      }

      let loop = new Date(startDate);
      while (loop <= endDate) {
        isHoliday = false;
        const dateStr = getFormattedDate(loop);
        if (holidays.indexOf(dateStr) > -1) {
          isHoliday = true;
        }

        if (!isHoliday && !isWeekend(loop)) {
          totalWorkingDays++;
        } else {
        }

        let newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      const effort = totalWorkingDays * (this.state.subLineDefaultAllocationPercentage / 100);
      this.state.selectedSubLineToEdit.EffortDays = effort

      for (let item of this.state.resourceMix) {
        if (item.id === this.state.selectedSubLineToEdit.ResourceRequestId) {
          const totalCost = (item.CostPerResource * effort).toFixed(2)
          const totalRev = (item.RateValue * effort).toFixed(2)
          this.state.selectedSubLineToEdit.AllocationPercentage = this.state.subLineDefaultAllocationPercentage
          this.state.selectedSubLineToEdit.Cost = totalCost;
          this.state.selectedSubLineToEdit.Revenue = totalRev;
          this.state.selectedSubLineToEdit.CostToRevenueRatio = (totalCost / totalRev).toFixed(2);
          this.forceUpdate()
          return
        }
      }
    }
  }

  onAddSubLineToGrid = async () => {
    let totalEffortDays = this.state.editableItem.TotalEffortDays;
    let currentEffortDays = 0.00;

    await this.setState({
      setNonBillable: false
    });

    if (this.state.editableItem.ResourceRequestSubLine) {
      for (const subline of this.state.editableItem.ResourceRequestSubLine) {
        if (subline.RateType !== 5) {
          currentEffortDays += Number(subline.EffortDays);
        }
      }
    }

    currentEffortDays += Number(this.state.subLineEffortDays.toFixed(2));
    if (currentEffortDays > totalEffortDays) {
      if (this.state.editableItem.ResourcePaymentMethod.id === 1) {
        this.setState({
          setNonBillable: true
        });
      }
      await this.setState({
        effortOverrun: true
      });
      this.addSubLineToggle();

    } else {
      await this.setState({
        effortOverrun: false
      });
      this.confirmAddSubLineToGrid();
    }
  }

  confirmAddSubLineToGrid = async () => {
    let projcurrencyId = 0;
    let rateValueInUSD = null;
    let rateInUSD = null;
    let TotalRevenueInUSD = null;
    let DirectCostInUSD = 0;
    let CostPerResourceInUSD = 0;
    let month = '';
    let year = '';
    let StartDateArray;
    let SelectedbillingRegion = '';
    let totalCostUSD = 0;

    this.setState({
      approveAddSubLine: false
    });

    let billingRegion = this.state.editableItem.Type === 'ChangeRequest' ? this.state.editableItem.ChangeRequest.BillingRegion : this.state.editableItem.Project.BillingRegion;
    await getRegionCurrencyIntegrated(billingRegion).then(response => {
      this.state.newProjectCurrency = response.data;
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    let outsourcedCost = this.state.editableItem.Designation == 57 ? (!this.state.editableItem.CostPerOutSourcedResource || this.state.editableItem.CostPerOutSourcedResource == 0) ?  Number(this.state.outsourcedCost) : this.state.editableItem.CostPerOutSourcedResource : Number(this.state.outsourcedCost);

    let newItem = {
      newResProjectOrCRName: this.state.editableItem.Type === 'ChangeRequest' ? this.state.editableItem.ChangeRequest : this.state.editableItem.Project,
      newResRegion: this.state.editableItem.dataValueResourceRegion,
      newOnSiteStatus: this.state.editableItem.OnsiteStatus,
      newStartDate: moment(this.state.selctedResourceSubLineStartDate).format('MM/DD/YYYY'),
      newEndDate: moment(this.state.selctedResourceSubLineEndDate).format('MM/DD/YYYY'),
      newEffortDays: this.state.subLineEffortDays.toFixed(2),
      newAllocation: this.state.subLineDefaultAllocationPercentage,
      EstimatedEndDate: moment(this.state.selctedResourceSubLineEndDate).format('MM/DD/YYYY'),
      newResDesignation: this.state.editableItem.dataValueCostDesignation,
      resourceCountry : this.state.editableItem.dataValueResourceRegion.DefaultCountryId,
      projOrCrCountry : this.state.editableItem.Type === 'ChangeRequest' ? this.state.editableItem.ChangeRequest.countryId : this.state.editableItem.Project.countryId,
      resourceId: null,
      CostPerOutSourcedResource: outsourcedCost
    };

    let costDate_ = new Date(this.state.selctedResourceSubLineStartDate);
    const costCard = {
      billingRegionId: this.state.editableItem.Type === 'ChangeRequest' ? this.state.editableItem.ChangeRequest.BillingRegion : this.state.editableItem.Project.BillingRegion,
      resourceRegionId: this.state.editableItem.dataValueResourceRegion.id,
      designationId: this.state.editableItem.dataValueCostDesignation.id,
      costDate: moment(costDate_).format('YYYY-MM-DD'),
      projectId: this.props.location.project.id
    };
    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    };

    if (newItem.newResDesignation.id === 57) {
      await getCostDetails(costItems)
        .then(async res => {
          totalCostUSD = res.data.TotalCost;
          DirectCostInUSD = res.data.DirectCost;
          CostPerResourceInUSD = res.data.CostPerResource;
      })
      .catch( async err => {
        loggerService.writeLog(err, LOG_TYPES.ERROR);
        const message = 'Cost card record not found for the selected designation and resource region';
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showRateErrorImage: true
        });
        this.toggleRateValidatioDialog(message, title);
      });
      if (totalCostUSD === 0 || !CostPerResourceInUSD) {
        const message = 'Cost card record not found for the selected designation and resource region';
        const title = 'Error';
        this.setState({
          showSuccessImage: false,
          showRateErrorImage: true
        });
        this.toggleRateValidatioDialog(message, title);
        return;
      }
    } else {
      await getCostDetails(costItems)
      .then(res => {
        totalCostUSD = res.data.TotalCost;
        DirectCostInUSD = res.data.DirectCost;
        CostPerResourceInUSD = res.data.CostPerResource;
      })
      .catch( async err => {
        totalCostUSD = 0;
        DirectCostInUSD = 0;
        CostPerResourceInUSD = 0;
        loggerService.writeLog(err, LOG_TYPES.ERROR);

        const message = 'Cost card record not found for the selected designation and resource region';
        const title = 'Error';
        await this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });

        await this.toggleMessageDialog(message, title);
      });
    } 


    const index = this.state.editableItem.ResourceRequestSubLine ? this.state.editableItem.ResourceRequestSubLine.length : 0;
    const totalRev = this.state.setNonBillable === true ? Number(0.00) : (this.state.subLineDefaultRateValue * this.state.subLineEffortDays).toFixed(2);

    let projectOrCrStartDate = null;
    //get selected pro/cr billing region
    if (this.state.editableItem.Type === 'Project') {
      SelectedbillingRegion = this.state.editableItem.Project.BillingRegion
      projectOrCrStartDate = this.state.editableItem.Project.StartDate;
    }
    else {
      SelectedbillingRegion = this.state.editableItem.ChangeRequest.BillingRegion
      projectOrCrStartDate = this.state.editableItem.ChangeRequest.StartDate;
    }


    //when the currency is != USD
    await getRegionCurrencyIntegrated(SelectedbillingRegion).then(response => {
      projcurrencyId = response.data.CurrencyId;
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });


    // Get year and month of the project
    StartDateArray = moment(this.state.selctedResourceSubLineStartDate).format('YYYY-MM-DD').split('-');
    year = StartDateArray[0];
    month = StartDateArray[1];

    saveFrontEndLogs("Warn","Resource Request SubLine Revenue Conversion",`ResourceRequestId : ${this.state.editableItem.id}, ProjCurrencyId : ${projcurrencyId}`);
    if (projcurrencyId !== 1) {

      await this.setState({
        isNotCurrencyUSD: true
      });

      const projrateRecordCurrencyRate = await retrieveConversionRateIntegrated(
        projcurrencyId,
        new Date(projectOrCrStartDate).getMonth() + 1,
        new Date(projectOrCrStartDate).getFullYear()
      );

      rateValueInUSD = (this.state.subLineDefaultRateValue / projrateRecordCurrencyRate).toFixed(2);
      rateInUSD = (this.state.subLineDefaultRateValue / projrateRecordCurrencyRate).toFixed(2);
      TotalRevenueInUSD = (totalRev / projrateRecordCurrencyRate).toFixed(2);

      saveFrontEndLogs("Warn","Resource Request SubLine Revenue Conversion",`ResourceRequestId : ${this.state.editableItem.id}, projrateRecordCurrencyRate : ${projrateRecordCurrencyRate}, Rate Before : ${this.state.subLineDefaultRateValue}, Rate After : ${rateInUSD}, TotalRevenue Before : ${totalRev}, TotalRevenue After : ${TotalRevenueInUSD}`);
    }

    const subLineObj = {
      StartDate: moment(this.state.selctedResourceSubLineStartDate).format('YYYY-MM-DD'),
      EndDate: moment(this.state.selctedResourceSubLineEndDate).format('YYYY-MM-DD'),
      EffortDays: this.state.subLineEffortDays.toFixed(2),
      AllocationPercentage: this.state.subLineDefaultAllocationPercentage,
      RateType: this.state.setNonBillable === true ? 5 : this.state.subLineDefaultRateType.id,
      Rate: this.state.setNonBillable === true ? Number(0.00) : (this.state.isNotCurrencyUSD === true ? rateInUSD : this.state.subLineDefaultRateValue),
      Status: 'NEW_FROM_RM',
      index: index,
      RateValue: this.state.setNonBillable === true ? Number(0.00) : (this.state.isNotCurrencyUSD === true ? rateInUSD : this.state.subLineDefaultRateValue),
      CostPerResource: CostPerResourceInUSD,
      CostPerOutSourcedResource: outsourcedCost,
      DirectCost: DirectCostInUSD,
      Cost: totalCostUSD,
      Revenue: this.state.isNotCurrencyUSD === true ? TotalRevenueInUSD : totalRev,
      CostToRevenueRatio: this.state.setNonBillable === true ? Number(0.00) : this.state.isNotCurrencyUSD === true ? (Number(TotalRevenueInUSD) === 0 ? Number(0.00) : (totalCostUSD / TotalRevenueInUSD).toFixed(2)) : (Number(totalRev) === 0 ? Number(0.00) : (totalCostUSD / totalRev).toFixed(2)),
      selected: false,
      ResourceRequestId: this.state.editableItem.id ? this.state.editableItem.id : null,
      dataValueResourceRegion: this.state.editableItem.dataValueResourceRegion,
      Phase: this.state.editableItem.Phase,
      SubPhase: this.state.editableItem.SubPhase,
      ProjectRole: this.state.editableItem.ProjectRole,
      Designation: this.state.editableItem.Designation,
      ResourceRegion: this.state.editableItem.ResourceRegion,
      OnsiteStatus: this.state.editableItem.OnsiteStatus,
      ResourceId: null,
      PhaseId: this.state.editableItem.PhaseId,
      SubPhaseId: this.state.editableItem.SubPhaseId,
      PaymentMethod: this.state.editableItem.PaymentMethod,
      dataValueCostDesignation: this.state.editableItem.dataValueCostDesignation,
      dataValueProjectRole: this.state.editableItem.dataValueProjectRole,
      LastUpdatedByUserAt: new Date(),
      EffortOverrun: this.state.effortOverrun,
      ManagerComment: this.state.managerComment
    };

    if (subLineObj.Cost === 0) {
      const message = 'Cost card record not found for the selected designation and resource region';
      const title = 'Error';
      this.setState({
        showSuccessImage: false,
        showRateErrorImage: true
      });
      this.toggleRateValidatioDialog(message, title);
      return;
    }

    if (this.state.editableItem.ResourceRequestSubLine) {
      this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
    } else {
      this.state.editableItem.ResourceRequestSubLine = [];
      this.state.editableItem.ResourceRequestSubLine[index] = subLineObj;
    }


    this.setState({
      editableResourceSubLines: this.state.editableItem.ResourceRequestSubLine,
      addedSubLinesToGrid: true,
      isNotCurrencyUSD: false
    });
    this.clearSubLineOnAddValues();
  };

  clearSubLineOnAddValues = () => {
    this.setState({
      selctedResourceSubLineStartDate: null,
      selctedResourceSubLineEndDate: null,
      subLineDefaultAllocationPercentage: 0,
      subLineEffortDays: 0,
      setNonBillable: false,
      managerComment: ''
    });
  };

  addSubLineToggle = async () => {
    await this.setState({
      approveAddSubLine: !this.state.approveAddSubLine,
    });
  }

  // SUBLINE CHANGES
  onSubLineSelect = async (param) => {
    if (param.dataItem && !param.dataItem.IsPreviousCmsResource) {
    const checked = param.syntheticEvent.target.checked;

    let isRemoveRequest = false;
    let isDeallocateRequest = false;
    let resourceSubLine = [];
    let resourceLine = [];
    let isHaveMainLine = false;
    let requestAllocation = false;
    let requestAllocationSoft = false;
    let requestAllocationSoftResubmit = false;
    let requestAllocationSoftDate = false

    let confirmStatus = false;
    let isCanSoftBooking = false

    await this.setState({
      isCheckedSubline: true,
    });

    if (param) {
      if (param.dataItem.hasOwnProperty('selected')) {
        param.dataItem.selected = !param.dataItem.selected
      } else {
        param.dataItem["selected"] = true
      }
      for (let item of this.state.resourceMix) {
        if (item.id === param.dataItem.ResourceRequestId) {
          item.ResourceRequestSubLine[param.dataItem.index] = param.dataItem
          // resourceSubLine.push(item.ResourceRequestSubLine)
          this.forceUpdate();

        }
      }
      //TODO
      //indexes are incorrect
      const index = this.state.resourceMix.indexOf(param.dataItem);

      if (checked) {
        this.ticked.push(index);
        this.sublineTicked.push(index);
      } else {
        this.ticked.splice(this.ticked.indexOf(index), 1);
        this.sublineTicked.splice(this.ticked.indexOf(index), 1);
      }

      for (const item of this.state.resourceMix) {
        if (item.ResourceRequestSubLine && item.ResourceRequestSubLine.length > 0) {
          item.ResourceRequestSubLine.filter(resource => {
            if (resource.selected === true) {
              resource['Type'] = item.Type
              resource['ChangeRequest'] = item.Type === 'ChangeRequest' ? item.ChangeRequest : [];
              resource['Project'] = item.Type === 'Project' ? item.Project : [];
              resourceSubLine.push(resource)
            }
          })
        }
      }

      //requestAllocation
      for (const resource of resourceSubLine) {
        if (resource.IsSoftBookingResource === true && resource.Status == 'COMPLETE') {
          requestAllocation = false
        } else if ((resource.Status !== 'CONFIRMED' && resource.Status !== 'RQST_APP_REJECTED') && (resource.Status !== 'RES_ALLOC_PENDING' || resource.IsSoftBookingResource === false)) {
          requestAllocation = true;
        }

        //soft booking
        if (resource.Status !== 'CONFIRMED' || resource.IsSoftBookingResource === true) {
          requestAllocationSoft = true;
        }

        //soft booking to date check
        if (resource.StartDate === null || resource.EndDate === null) {
          requestAllocationSoftDate = true;
        }

        //resubmit same soft book
        if (resource.IsSoftBookingResource === true) {
          requestAllocationSoftResubmit = true;
        }

        //confirm
        if (resource.Status !== 'EDIT' && resource.Status !== 'NEW_FROM_RM' &&
          resource.Status !== 'NEW_FROM_CMS' && resource.Status !== 'RES_ALLOC_CANCELLED') {
          confirmStatus = true;
        }
      }

      const selected = this.state.resourceMix.filter(resource => resource.selected === true);
      resourceLine.push(selected)

      if (selected.length > 0) {
        isHaveMainLine = true;

        for (const item of resourceLine) {
          if (item.IsSoftBookingResource === true) {
            isRemoveRequest = true;
          } else if (item[0].Status === 'NEW_FROM_CMS' || item.Status === 'NEW_FROM_RM' ||
            item.Status === 'EDIT' || item[0].Status === 'CONFIRMED'
            || item[0].Status === 'RES_ALLOC_CANCELLED' || item[0].Status === 'RQST_APP_REJECTED') {

            isRemoveRequest = true;
          } else {
            isRemoveRequest = false;
            break;
          }
        }



        //for soft booking resource request
        for (const item of resourceLine) {
          if (item.Status === 'CONFIRMED') {
            isCanSoftBooking = true;
          } else {
            isCanSoftBooking = false;
            break;
          }
        }

      }

      if (isRemoveRequest === true || isHaveMainLine === false) {
        for (const item of resourceSubLine) {
          if (item.IsSoftBookingResource === true) {
            isRemoveRequest = true;
          } else if (item.Status === 'NEW_FROM_CMS' || item.Status === 'NEW_FROM_RM' ||
            item.Status === 'EDIT' || item.Status === 'CONFIRMED'
            || item.Status === 'RES_ALLOC_CANCELLED' || item.Status === 'RQST_APP_REJECTED') {
            isRemoveRequest = true;
          } else {
            isRemoveRequest = false;

          }
        }
      }

      //for deallocation
      for (const item of resourceSubLine) {
        if (item.Status === 'COMPLETE') {
          isDeallocateRequest = true;
        } else {
          isDeallocateRequest = false;
          break;
        }
      }

      //for soft booking resource
      if (isCanSoftBooking === true || isHaveMainLine === false) {
        for (const item of resourceSubLine) {
          if (item.Status === 'CONFIRMED') {
            isCanSoftBooking = true;
          } else {
            isCanSoftBooking = false;
          }
        }
      }

      await this.setState({
        isCanRemoveRequest: isRemoveRequest,
        isCanDeallocateRequest: isDeallocateRequest,
        isCanSoftBooking: isCanSoftBooking,
        selectedResourcesSubLine: resourceSubLine,
        requestAllocationSub: requestAllocation,
        requestAllocationSubSoft: requestAllocationSoft,
        requestAllocationSoftSubDate: requestAllocationSoftDate,
        requestAllocationSubSoftResubmit: requestAllocationSoftResubmit,
        confirmStatusSub: confirmStatus
      });
    }
  }
  }

  onSubLineEdit = (param, mainLineofsublineObj) => {

    if (param) {
      this.setState({
        selectedSubLineToEdit: param,
        mainLineofsublineObj: mainLineofsublineObj
      }, () => {
        this.toggleSubLineEditDialog();
      })

    }
  };

  handleOnChangeDropDown = async event => {
    const field = event.target.name;
    const value = event.target.value;


    if (this.isMount) {
      await this.setState({
        [field]: value,
        excelDataObsolete: false
      });
    }

  };

  toggleSubLineEditDialog = () => {
    this.setState({ showEditSubLineDialog: !this.state.showEditSubLineDialog });
  };

  handleComboBoxChangeOnSubLineEdit = async event => {
    const value = event.target.value;
    const name = event.target.name;
    switch (name) {
      case 'editRole':
        if (value) {
          let filteredArray = this.designationsAll.filter(function (itm) {
            return value.CategoryId === itm.CategoryId || itm.Name === 'Outsourced';
          });
          this.TmpDesignationsAll = filteredArray;
          this.state.editableItem.dataValueProjectRole = value;
          this.state.editableItem.ProjectRole = value.id;
          this.state.tmpDesignationsAll = filteredArray;
        } else {
          this.TmpDesignationsAll = [];
          this.state.tmpDesignationsAll = [];
          this.state.editableItem.dataValueProjectRole = null;
          this.state.editableItem.ProjectRole = null;
        }
        this.state.editableItem.dataValueCostDesignation = null;
        this.state.editableItem.Designation = null;
        this.forceUpdate();
        break;
      case 'editSubLineDesignation':
        if (value) {
          this.state.selectedSubLineToEdit.dataValueCostDesignation = value;
          this.state.selectedSubLineToEdit.Designation = value.id;
        } else {
          this.state.selectedSubLineToEdit.dataValueCostDesignation = null;
          this.state.selectedSubLineToEdit.Designation = null;
        }
        this.forceUpdate();
        await this.updateSublineCost();
        break;
      case 'editSubLineOnSiteStatus':
        if (value) {
          this.state.selectedSubLineToEdit.OnsiteStatus = value.Name;
        } else {
          this.state.selectedSubLineToEdit.OnsiteStatus = null;
        }
        this.forceUpdate();
        break;
      case 'editAllocatedResource':
        if (value) {
          this.state.editableItem.AllocatedResource = value;
          this.state.editableItem.AllocatedResourceID = value.id;
          this.state.editableItem.ResourceId = value.id;
        } else {
          this.state.editableItem.AllocatedResource = null;
          this.state.editableItem.AllocatedResourceID = null;
          this.state.editableItem.ResourceId = null;
        }
        this.forceUpdate();
        break;
      case 'editSubLinePhase':
        if (value) {
          this.state.selectedSubLineToEdit.Phase = value;
          this.state.selectedSubLineToEdit.PhaseId = value.id;

          let subPhasesOfSelectedPhase = [];
          if (value && value.SubPhases && value.SubPhases.length > 0) {
            subPhasesOfSelectedPhase = value.SubPhases;
          }
          this.setState({
            subPhasesOfSelectedPhase: subPhasesOfSelectedPhase
          });

        } else {
          this.state.selectedSubLineToEdit.Phase = null;
          this.state.selectedSubLineToEdit.PhaseId = null;

          this.state.selectedSubLineToEdit.SubPhase = null;
          this.state.selectedSubLineToEdit.SubPhaseId = null;

          this.setState({
            subPhasesOfSelectedPhase: []
          });

        }
        break;
      case 'editSubLineSubPhase':
        if (value) {
          this.state.selectedSubLineToEdit.SubPhase = value;
          this.state.selectedSubLineToEdit.SubPhaseId = value.id;
        } else {
          this.state.selectedSubLineToEdit.SubPhase = null;
          this.state.selectedSubLineToEdit.SubPhaseId = null;
        }
        this.forceUpdate();
        break;
      case 'editSubLineRegion':
        if (value) {
          this.state.selectedSubLineToEdit.dataValueResourceRegion = value;
          this.state.selectedSubLineToEdit.RegionId = value.id;
          this.state.selectedSubLineToEdit.ResourceRegion = value.id;
        } else {
          this.state.selectedSubLineToEdit.dataValueResourceRegion = null;
          this.state.selectedSubLineToEdit.RegionId = null;
          this.state.selectedSubLineToEdit.ResourceRegion = null;
        }
        this.forceUpdate();
        await this.updateSublineCost();
        break;
      default:
        break;
    }
  };

  updateSublineCost = async () => {

    let newItem = {};
    let totalCostUSD = null;
    let DirectCostInUSD = null;
    let CostPerResourceInUSD = null;
    let CostToRevenueRatio = 0;
    let totalRev = 0;

    let billingRegion = this.state.mainLineofsublineObj.ChangeRequest ? this.state.mainLineofsublineObj.ChangeRequest.BillingRegion : this.state.mainLineofsublineObj.Project.BillingRegion;
    await getRegionCurrencyIntegrated(billingRegion).then(response => {
      this.state.newProjectCurrency = response.data;
    })
      .catch(error => {
        loggerService.writeLog(error, LOG_TYPES.ERROR);
      });

    let costDate_ = new Date(this.state.editableItem.StartDate);
    const costCard = {
      billingRegionId: this.state.mainLineofsublineObj.ChangeRequest ? this.state.mainLineofsublineObj.ChangeRequest.BillingRegion : this.state.mainLineofsublineObj.Project.BillingRegion,
      resourceRegionId: this.state.selectedSubLineToEdit.dataValueResourceRegion.id,
      designationId: this.state.selectedSubLineToEdit.dataValueCostDesignation.id,
      costDate: moment(costDate_).format('YYYY-MM-DD'),
      projectId: this.props.location.project.id
    };
    costDate_ = moment(costDate_).format('YYYY-MM-DD');
    const costItems = {
      costCard : costCard,
      newItem : newItem,
      costDate: costDate_
    }

    await getCostDetails(costItems)
      .then(res => {
        totalCostUSD = res.data.TotalCost;
        DirectCostInUSD = res.data.DirectCost;
        CostPerResourceInUSD = res.data.CostPerResource;
      })
      .catch( async err => {
        totalCostUSD = 0;
        DirectCostInUSD = 0;
        CostPerResourceInUSD = 0;
        loggerService.writeLog(err, LOG_TYPES.ERROR);

        const message = 'Cost card record not found for the selected designation and resource region';
        const title = 'Error';
        await this.setState({
          showSuccessImage: false,
          showErrorImage: true
        });

        await this.toggleMessageDialog(message, title);
      });

    totalRev = this.state.selectedSubLineToEdit.Revenue;

    CostToRevenueRatio = Number(totalRev) == 0 ? 0.00 : (totalCostUSD / totalRev).toFixed(2);

    this.state.selectedSubLineToEdit.Cost = totalCostUSD;
    this.state.selectedSubLineToEdit.DirectCost = DirectCostInUSD;
    this.state.selectedSubLineToEdit.CostPerResource = CostPerResourceInUSD;
    this.state.selectedSubLineToEdit.CostToRevenueRatio = CostToRevenueRatio;

  };

  toggleSubLineUpdatedDialog = () => {
    this.setState({
      subLineUpdatedDialog: !this.state.subLineUpdatedDialog
    });
  };


  //to filter project or CR
  filterProjectOrCR = async (filteredArr) => {
    let filteredResourceMixLines = [];
    for (const item of filteredArr) {

      for (const obj of this.state.newResProjectOrCRNames) {
        if (obj.type === item.Type) {
          if (obj.type === 'ChangeRequest') {
            if (obj.id === item.ChangeRequest.id) {
              filteredResourceMixLines.push(item)
            }
          } else {
            if (obj.id === item.Project.id) {
              filteredResourceMixLines.push(item)
            }
          }
        }
      }
    }
    return filteredResourceMixLines;
  }

  //to filter by status of resource mix lines
  filterByStatus = async (filterArr) => {

    let finalized = [];

    for (const item of filterArr) {
      if (this.props.location.action === 'create') {
        const validRequest = this.state.validPMsProjAndCrs.filter(value => value.id === item.ProjectId && value.type === item.Type);
        if (validRequest && validRequest.length <= 0) {
          continue;
        }
      }

      let newSubLine = [];
      let index = 0;
      if (item.ResourceRequestSubLine && item.ResourceRequestSubLine.length >= 1) {

        for (const resourceSubLine of item.ResourceRequestSubLine) {

          for (const status of this.state.SelectedLineStatuses) {
            if ((resourceSubLine.Status === status.code)) {
              resourceSubLine.index = index;
              newSubLine.push(resourceSubLine);
              index++;
            }
          }
        }

        if (newSubLine.length > 0) {
          item.ResourceRequestSubLine = newSubLine;
          finalized.push(item);
        }


      }

      else {
        for (const status of this.state.SelectedLineStatuses) {
          if ((item.Status === status.code)) {

            finalized.push(item);

          }
        }
      }
    }

    return finalized;

  };

  handleDownloadButtonClick = async() => {
    await this.setState({
      excelDataObsolete: true
    });
  }


  submitSearch = async event => {
    var filteredMix = [];
    var index = 0;
    if (!this.state.isInitLoad) {
      await this.populateConsolidatedResourceMix();
    }
    let newResProjectOrCRNames = this.state.newResProjectOrCRNames;
    let reasourceAll = this.state.forFilterResourceLine;
    // const IdList = this.getCMSheetVersionAndIdList();

    if (event) {
      event.preventDefault();
    }

    this.setState({
      loading: true,
      filteredTotalCost: '',
      filteredTotalRevenue: '',
      filteredCostToRevenueRatio: '',
      newResProjectOrCRNames: newResProjectOrCRNames,
    });

    if ((this.state.newResProjectOrCRNames && this.state.newResProjectOrCRNames.length >= 1) && (this.state.SelectedLineStatuses && this.state.SelectedLineStatuses.length >= 1)) {

      let filteredProCR = [];
      let filteredProCRStatus = [];

      filteredProCR = await this.filterProjectOrCR(reasourceAll);
      filteredProCRStatus = await this.filterByStatus(filteredProCR);
      filteredMix = filteredProCRStatus;
    }
    else if (this.state.newResProjectOrCRNames && this.state.newResProjectOrCRNames.length >= 1) {
      let projectOnlyFilter = [];

      projectOnlyFilter = await this.filterProjectOrCR(reasourceAll);
      filteredMix = projectOnlyFilter;
    }
    else if (this.state.SelectedLineStatuses && this.state.SelectedLineStatuses.length >= 1) {
      let statusOnlyFilter = [];

      statusOnlyFilter = await this.filterByStatus(reasourceAll);
      filteredMix = statusOnlyFilter;

    }

    for (const eachLine of filteredMix) {
      eachLine.index = index;
      index++;
      var indexSub = 0;
      if (eachLine.ResourceRequestSubLine && eachLine.ResourceRequestSubLine.length > 0) {
        for (const eachSub of eachLine.ResourceRequestSubLine) {
          eachSub.index = indexSub;
          indexSub++;
        }
      }
    }

    await this.setState({
      filteredResourceMix: filteredMix,
      loading: false,
      skip: 0,
      excelDataObsolete: false
    });

    await this.filterCalculations();

    this.ticked = [];
    this.sublineTicked = [];
    this.allocationId = [];
    this.setState({
      hasSubLines: false,
      confirmStatus: false,
      confirmStatusSub: false
    })
  };


  filterCalculations = async () => {

    let totalCost = null;
    let totalRevenue = null;
    let costToRevenueRatio = null;

    if (this.state.filteredResourceMix && this.state.filteredResourceMix.length > 0) {
      for (const item of this.state.filteredResourceMix) {


        //calculate total Revenue
        totalRevenue += Number(item.TotalRevenue)

        //calculate total cost
        totalCost += Number(item.TotalCost)
      }

      //calculate Cost to Revenue Ratio
      if (totalRevenue === 0) {
        costToRevenueRatio = '0.00';
      }
      else {
        costToRevenueRatio = (totalCost / totalRevenue).toFixed(2)
      }

      this.setState({
        filteredTotalCost: totalCost.toFixed(2),
        filteredTotalRevenue: totalRevenue.toFixed(2),
        filteredCostToRevenueRatio: costToRevenueRatio,
        excelDataObsolete: false
      });
    }
  }


  onUpdateSubLineResource = async () => {
    if (!this.validateSublineEditingValues()) {
      /*await this.setState({
         showSuccessImage: false,
         showErrorImage: true
       });
       this.toggleDialog('Please fix the highlighted errors to continue', 'Error');
     */} else {
      for (let item of this.state.resourceMix) {

        if (item.id === this.state.selectedSubLineToEdit.ResourceRequestId) {
          this.state.selectedSubLineToEdit.Status = "EDIT";
          this.state.selectedSubLineToEdit.LastUpdatedByUserAt = new Date();
          item.ResourceRequestSubLine[this.state.selectedSubLineToEdit.index] = this.state.selectedSubLineToEdit;
          this.toggleSubLineEditDialog();
          var resourcesToSave = [item];
          this.handleResourceSave(resourcesToSave);
          return
        }
      }

    }
  };

  filterChangeCombo = (event, dataItem) => {
    clearTimeout(this.timeout);

    const field = event.target.name;
    const filter = event.filter;

    this.timeout = setTimeout(() => {
      switch (field) {
        case 'editPhase': {
          this.state.editableItem.Phases = this.filterComboData(filter, this.state.allProjectCrPhases);
          break;
        }
        case 'editDesignation':
          this.state.editableItem.Designations = this.filterComboData(filter, this.state.tmpDesignationsAll);
        default: {
          break;
        }
      }

      this.setState({
        resourceMix: [...this.state.resourceMix]
      });
    }, 500);
  };

  filterComboData(filter, allData) {
    const data = allData.slice();
    return filterBy(data, filter);
  }

  handlePMCommentChange = (event) => {
    this.setState({
      managerComment: event.target.value
    })
  };

  handlePMCommentChangeCMS = (event) => {
    this.setState({
      managerCommentCMS: event.target.value
    })
  };

  checkNewAllocationEffort = async () => {
    let selectedRequest = this.state.allocationHistoryDataItem;
    let resourceRequestId = null;
    let resourceRequestIdSub = null;
    if (selectedRequest.ResourceRequestId) {
      let mainLine = this.state.resourceMix.filter(
        item => item.id === selectedRequest.ResourceRequestId
      );
      resourceRequestId = selectedRequest.ResourceRequestId;
      resourceRequestIdSub = selectedRequest.id;
      selectedRequest.Type = mainLine[0].Type;
      selectedRequest.Project = mainLine[0].Project;
      selectedRequest.ChangeRequest = mainLine[0].ChangeRequest;
    } else {
      resourceRequestId = selectedRequest.id;
    }
    const item = {
      ResourceRequestId: resourceRequestId,
      ResourceRequestSubLineId: resourceRequestIdSub,
      ResourceId: selectedRequest.ResourceId,
      StartDate: moment(this.state.allocationPercentageStartDate).format('YYYY-MM-DD'),
      EndDate: moment(this.state.allocationPercentageEndDate).format('YYYY-MM-DD'),
      AllocationPercentage: this.state.changedllocationPercentage
    }
    await this.calculateAllocationHistoryLineEffort(item, selectedRequest);
    await this.setState({
      allocationPercentageEffortDays: item.EffortDays,
    });
  }

  handleAllocationStartDateChange = async event => {
    let allocationPercentageStartDate = event.target.value;
    await this.setState({
      allocationPercentageStartDate: allocationPercentageStartDate
    });
    if (this.state.allocationPercentageStartDate && this.state.allocationPercentageEndDate && this.state.changedllocationPercentage) {
      await this.checkNewAllocationEffort();
      await this.setState({
        disableConfirmButton: false
      });
    } else {
      await this.setState({
        disableConfirmButton: true
      });
    }
  }

  handleAllocationEndDateChange = async event => {
    let allocationPercentageEndDate = event.target.value;
    await this.setState({
      allocationPercentageEndDate: allocationPercentageEndDate
    });
    if (this.state.allocationPercentageStartDate && this.state.allocationPercentageEndDate && this.state.changedllocationPercentage) {
      await this.checkNewAllocationEffort();
      await this.setState({
        disableConfirmButton: false
      });
    } else {
      await this.setState({
        disableConfirmButton: true
      });
    }
  }

  handleAllocationPercentageChange = async event => {
    let value = event.target.value;
    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }
    await this.setState({
      changedllocationPercentage: Number((Number(value)).toFixed(2))
    });
    if (this.state.allocationPercentageStartDate && this.state.allocationPercentageEndDate && this.state.changedllocationPercentage) {
      await this.checkNewAllocationEffort();
      await this.setState({
        disableConfirmButton: false
      });
    } else {
      await this.setState({
        disableConfirmButton: true
      });
    }

  };

  handleDeallocationStartDateChange = event => {
    let deallocationStartDate = event.target.value;
    this.state.deallocationStartDate = deallocationStartDate;

    // When typed date isn't in valied range of dates
    if(deallocationStartDate && deallocationStartDate !== null){

      if(((moment(this.state.resDeallocationStartDate).format('YYYY-MM-DD')) <= (moment(deallocationStartDate).format('YYYY-MM-DD'))) &&
        ((moment(deallocationStartDate).format('YYYY-MM-DD')) <= (moment(this.state.resDeallocationEndDate).format('YYYY-MM-DD')))){
        this.state.deallocationDatesErrorMessage = false;
      }
      else{
        this.state.deallocationDatesErrorMessage = true;
      }
    }
    else if(deallocationStartDate == null){
      this.state.deallocationDatesErrorMessage = false;
    }

    // Enable/Disable resource deallocation confirm button
    if((deallocationStartDate && deallocationStartDate !== null) && (!this.state.deallocationDatesErrorMessage)){
      this.setState({
        disableConfirmButton: false
      });
    }
    else if((deallocationStartDate === null) || (deallocationStartDate == 'Invalid date') || (this.state.deallocationDatesErrorMessage)){
      this.setState({
        disableConfirmButton: true
      });
    }
  };

  render() {
    if (this.state.goBack === true) {
      return <Redirect to="/projects/search/search" />;
    }

    if (!this.props.location.hasOwnProperty('project')) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div className="main-card">

          <TabStrip selected={this.state.selected} onSelect={this.handleSelect}>

            <TabStripTab title="Summary">
              <Loader loading={this.state.isLoading} />

              <div className="row">

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Project Name:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.ProjectName : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5">
                      <label className="highlighted-sec">Project ID:</label>
                    </div>
                    <div className="col-md-7">
                      <Link
                        to={{
                          pathname: '/projects/create/create',
                          projectId: this.state.project.id,
                          action: 'view'
                        }}
                      >
                        <label
                          className="font-weight-normal">{this.state.project ? this.state.project.UniqueId : ''} {this.getProjCMS() ? '(' + this.getProjCMS() + ')' : ''}
                        </label>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Start Date:</label></div>
                    <div className="col-md-7"><label className="font-weight-normal">{this.getStartDate()}</label></div>
                  </div>
                </div>

                {/* End Date */}
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">End Date:</label></div>
                    <div className="col-md-7">
                      {this.props.location.action === 'RPApprove' || this.props.location.action === 'view' && (
                        <label className="font-weight-normal">{this.state.userDefinedEndDate !== null ? moment(this.state.userDefinedEndDate).format('MM-DD-YYYY') : ''}</label>
                      )}
                      {this.props.location.action !== 'RPApprove' && this.props.location.action !== 'view' && (
                        <DatePicker
                          format="MM/dd/yyyy"
                          formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                          value={reStructureDate(this.state.userDefinedEndDate)}
                          onChange={this.handleProjEndDateChange}
                          name="projEndDate"
                          min={reStructureDate(this.getMaxEndDate())}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="resc-separator"></div>

              <div className="row">

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Billing Region:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Region.Name : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Country:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Country.Name : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Customer:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Customer.Name : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">OP ID:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.OPID : ''}</label></div>
                  </div>
                </div>

                {/* <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CR Name:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getCRNames()}</label></div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CR ID:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getCRIDs()}</label></div>
                                    </div>
                                </div> */}
              </div>

              <div className="resc-separator"></div>

              <div className="row">


                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Department:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Division.Name : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Engagement Type:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Type.Name : ''}</label>
                    </div>
                  </div>
                </div>


                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Offering:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.Offering.Name : ''}</label>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-5"><label className="highlighted-sec">Billing Method:</label></div>
                    <div className="col-md-7"><label
                      className="font-weight-normal">{this.state.project ? this.state.project.PaymentMethodRecord.Name : ''}</label>
                    </div>
                  </div>
                </div>


                {/* <div className="col-md-3">
                                    <div className="row">
                                        <div className="col-md-5"><label className="highlighted-sec">CMS version:</label></div>
                                        <div className="col-md-7"><label className="font-weight-normal">{this.getVersions()}</label></div>
                                    </div>
                                </div> */}
              </div>

              <div className="resc-separator"></div>

              {this.state.activeCRs.length > 0 && (
                <div className="row mt-4">
                  <div className="col-md-12 crdetails-grid">
                    <Grid data={this.state.activeCRs}
                      resizable
                      total={this.state.activeCRs.length}
                    >
                      <Column field="CRName" title="CR Name" />
                      <Column field="UniqueId" title="CR ID"
                        cell={props => <CRIdLinkCell {...props} />}
                      />
                      <Column field="cmsVersion" title="CMS Version" />
                    </Grid>
                  </div>
                </div>
              )}


              {/*<div className="row mt-3 mb-3">*/}
              {/*  {this.props.location.action !== 'RPApprove' &&*/}
              {/*  <div className="col-md-12 btn-align-left">*/}
              {/*    <Button type="button" primary={true} look="outline" onClick={this.toggleProjectPhase}>Add Project*/}
              {/*      Phases</Button>*/}
              {/*    <Button type="button" primary={true} look="outline" onClick={this.toggleProjectSubPhase}>Add Project*/}
              {/*      Sub Phases</Button>*/}
              {/*  </div>*/}
              {/*  }*/}
              {/*</div>*/}

              {/*{this.state.phasesSubPhases.length > 0 && (*/}
              {/*  <div className="row">*/}
              {/*    <div className="col-md-12">*/}
              {/*      <Grid data={this.state.phasesSubPhases}*/}
              {/*            resizable*/}
              {/*            total={this.state.phasesSubPhases.length}*/}

              {/*      >*/}
              {/*        <Column*/}
              {/*          field=""*/}
              {/*          title="Type"*/}
              {/*          cell={props => (*/}
              {/*            <ResourceRequestTypeCell*/}
              {/*              {...props}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        />*/}
              {/*        <Column*/}
              {/*          field=""*/}
              {/*          title="Name"*/}
              {/*          cell={props => (*/}
              {/*            <ResourceRequestNameCell*/}
              {/*              {...props}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        />*/}
              {/*        <Column*/}
              {/*          field=""*/}
              {/*          title="Phase"*/}
              {/*          cell={props => (*/}
              {/*            <ResourceRequestPhaseCell*/}
              {/*              {...props}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        />*/}
              {/*        <Column field="phaseStartDate" title="Start Date"/>*/}
              {/*        <Column field="SubPhase_name" title="Sub-Phase"/>*/}
              {/*        <Column field="subPhaseStartDate" title="Start Date"/>*/}
              {/*        {this.props.location.action === 'RPApprove' &&*/}
              {/*        <Column*/}
              {/*          field=""*/}
              {/*          title="Action"*/}
              {/*          cell={props => (*/}
              {/*            <ResourceRequestNameCell*/}
              {/*              {...props}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        />*/}
              {/*        }*/}

              {/*      </Grid>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              <div className="main-seperator"></div>

              <div className="row mt-4 resource-chart">
                <div className="col-md-12 sub-chart-section">
                  <Grid width="100%" data={this.state.calculations} rowRender={this.rowRender}
                    className="summary-grid-rr" resizable={true}>
                    <Column title="" locked={true} >
                    <Column
                      field="Field"
                      title="Field"
                      width="250px"
                      headerClassName="calculation-grid-header"
                      locked={true} 
                      cell={(props) => (
                        <td className={props.dataItem.isHeader ? 'summary-grid-rr-header': props.dataItem.IsMajorField ? 'summary-grid-rr-majorfield' : 'summary-grid-rr-default'}>
                            {props.dataItem.Field}
                        </td>
                      )}
                    />
                    </Column>
                    <Column title="View 1: Approved CMS Value​">
                    <Column className="col-head summary-grid-text-align" field="Value1" width="80px"
                      headerClassName="calculation-grid-header" />
                    <Column
                      field="Value2"
                      width="120px"
                      headerClassName="calculation-grid-header" className="summary-grid-text-align"
                    />
                    </Column>
                    <Column title="View 2: Resource Allocation Based Cost and Revenue​">
                    <Column className="col-head" field="Value3" cell={ResourceRequestValueThreeCell}
                      headerClassName="calculation-grid-header" width="80px"/>
                    <Column field="Value4" width="120px" cell={ResourceRequestValueFourCell} headerClassName="calculation-grid-header" />
                    <Column className="col-head summary-grid-text-align" field="Value5" width="80px"
                      headerClassName="calculation-grid-header" />
                    <Column className="summary-grid-text-align" field="Value6" width="120px" headerClassName="calculation-grid-header" />
                    </Column>
                    <Column title="View 3: Allocated Cost and Actual + Forecast Revenue​">
                    <Column className="col-head" field="Value7" width="80px" headerClassName="calculation-grid-header" cell={this.formatValueSevenCell}/>
                    <Column className="col-head" field="Value8" width="120px" headerClassName="calculation-grid-header" cell={this.formatValueEightCell} />
                    <Column className="summary-grid-text-align" field="Value9" width="80px" headerClassName="calculation-grid-header" />
                    <Column className="summary-grid-text-align" field="Value10" width="120px" headerClassName="calculation-grid-header" />
                    </Column>
                    <Column title="View 4: Actual + Forecasted Cost and Revenue​">
                    <Column className="col-head" field="Value11" width="80px" headerClassName="calculation-grid-header" cell={this.formatValueElevenCell}/>
                    <Column className="col-head" field="Value12" width="120px" headerClassName="calculation-grid-header" cell={this.formatValueTwelveCell}/>
                    <Column className="summary-grid-text-align" field="Value13" width="80px" headerClassName="calculation-grid-header" />
                    <Column className="summary-grid-text-align" field="Value14" width="120px" headerClassName="calculation-grid-header" />
                    </Column>
                  </Grid>
                </div>
              </div>

              {/* <div className="row my-2">
                <div className="col-md-12">
                  <Grid width="100%" data={this.state.calculationsfactory} rowRender={this.rowRender} className="summary-grid">
                    <Column
                      field="Field"
                      title="Field"
                      width="200px"
                      headerClassName="calculation-grid-header"
                    />
                    <Column field="Value1" headerClassName="calculation-grid-header" />
                    <Column field="Value2" headerClassName="calculation-grid-header" />
                    <Column field="Value3" headerClassName="calculation-grid-header" />

                  </Grid>
                </div>
              </div> */}

              {this.state.projectPhaseVisible === true && (
                <div>
                  <Dialog title={'Define Project Phases'} onClose={this.toggleClosePhaseDialog} width={600}>
                    <div className="row">
                      <div className="col-md-6 pro-phases">
                        <div className="">
                          <div className="d-block">
                            <label htmlFor="">Project/CR:</label>
                          </div>
                          <div className="d-block pr-0">
                            <ComboBox
                              name="searchStatus"
                              textField="Name"
                              dataItemKey="id"
                              value={this.state.selectedProjecOrCrForPhase}
                              data={this.state.projectAndCrData}
                              placeholder={'Please Select'}
                              onChange={this.handleOnChangeInput}
                              disabled={this.state.isEditButton}
                            />

                          </div>
                        </div>
                        <div className="">
                          <div className="d-block">
                            <label htmlFor="">Phase Name:</label>
                          </div>
                          <div className="d-block pr-0">
                            <Input
                              value={this.state.phaseName}
                              onChange={this.handlePhaseNameInputChange}
                              name="phaseName"
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="d-block">
                            <label htmlFor="">Start Date:</label>
                          </div>
                          <div className="d-block pr-0">
                            <DatePicker
                              disabled={!this.state.selectedProjecOrCrForPhase}
                              value={this.state.phaseStartDate}
                              width="99%"
                              onChange={this.handlePhaseStartDateChange}
                              name="startDate"
                              min={this.state.minPhaseDate}
                              format="MM/dd/yyyy"
                              formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            />

                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-7 phase-button">
                            <Button
                              className="k-button project-phase"
                              disabled={!this.state.phaseName || !this.state.selectedProjecOrCrForPhase}
                              onClick={this.addPhase}
                            > Add phase
                            </Button>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 overflow-auto pb-2">
                        {this.state.phasesTemp.map((value, index) => {
                          return (
                            <PhaseRow
                              phase={value}
                              isEditPhases={true}
                              isEditSubPhases={false}
                              onRemovePhase={() => this.handlePhasesRemove(index, value)}
                              onEditPhase={() => this.EditPhase(index, value)}
                              key={index}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <DialogActionsBar>
                      <button
                        className="k-button"
                        disabled={!(this.state.isAddDone)}
                        onClick={this.handlePhasesSave}
                      >
                        Save
                      </button>
                      <button className="k-button modal-primary" onClick={this.handleCancel}>
                        Cancel
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                </div>
              )}

              {this.state.projectSubPhaseVisible && (
                <div>
                  <Dialog title={'Define Project Sub-Phases'} onClose={this.toggleCloseSubPhaseDialog} width={600}>
                    <div className="row">
                      <div className="col-md-6 pro-subphase">
                        <div className="">
                          <div className="d-block">
                            <label htmlFor="">Project/CR:</label>
                          </div>
                          <div className="d-block pr-0">
                            <ComboBox
                              name="searchStatus"
                              textField="Name"
                              dataItemKey="id"
                              value={this.state.selectedProjecOrCrForPhase}
                              data={this.state.projectAndCrData}
                              placeholder={'Please Select'}
                              onChange={this.handleProjectCRChangeForSubPhase}
                              disabled={this.state.isEditButton}
                            />

                          </div>
                        </div>
                        <div className="">
                          <div className="d-block">
                            <label for="">Sub-Phase Name:</label>
                          </div>
                          <div className="d-block pr-0">
                            <Input
                              value={this.state.subPhaseName}
                              onChange={this.handleSubPhaseNameInputChange}
                              name="subPhaseName"
                              autoComplete="off"
                            />
                          </div>
                        </div>

                        <div className="">
                          <div className="d-block">
                            <label for="">Phase:</label>
                          </div>
                          <div className="d-block pr-0">
                            <DropDownList
                              value={this.state.selectedPhaseIndex === -1 ? '' : this.state.selectedProjectOrCrPhases[this.state.selectedPhaseIndex]}
                              data={this.state.selectedProjectOrCrPhases}
                              textField="Text"
                              dataItemKey="id"
                              onChange={this.handlePhaseChange}
                              disabled={this.state.isEditButton}
                            />
                          </div>
                        </div>

                        <div className="">
                          <div className="d-block">
                            <label for="">Start Date:</label>
                          </div>
                          <div className="d-block pr-0">
                            <DatePicker
                              value={this.state.subPhaseStartDate}
                              disabled={!this.state.selectedProjecOrCrForPhase}
                              width="99%"
                              onChange={this.handleSubPhaseStartDateChange}
                              name="startDate"
                              min={this.state.minSubPhaseDate}
                              format="MM/dd/yyyy"
                              formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                            />
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-7 phase-button">
                            <Button
                              className="k-button project-phase"
                              disabled={!this.state.subPhaseName || !this.state.selectedPhaseIndex < 0}
                              onClick={this.addSubPhase}
                            > Add sub-phase
                            </Button>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-md-12 overflow-auto pb-2">
                          {this.state.selectedProjectOrCrPhases && this.state.selectedProjectOrCrPhases.map((value, index) => {
                            return (
                              <PhaseRow
                                phase={value}
                                isEditPhases={false}
                                isEditSubPhases={true}
                                onRemoveSubPhase={this.handleSubPhasesRemove}
                                onEditSubPhase={this.EditSubPhase}
                                key={index}
                                phaseIndex={index}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <DialogActionsBar>
                      <button
                        className="k-button"
                        disabled={!this.state.isAddDone}
                        onClick={this.handleSubPhaseSave}
                      >
                        Save
                      </button>
                      <button className="k-button modal-primary" onClick={this.handleCancelSubPhase}>
                        Cancel
                      </button>
                    </DialogActionsBar>
                  </Dialog>
                </div>
              )}

            </TabStripTab>

            <TabStripTab title="Resource Mix">
              <Loader loading={this.state.isLoading} />
              {this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPApproveWF' && (
                <>
                  <PanelBar className="resourcemix-search">
                    <PanelBarItem expanded={true} title={<div className="filter-timesheet"> Resource Mix Filter</div>}>
                      <div className="col-md-12 row mb-4 resreqresmix">
                        <div className="col-md-4">
                          <div className="d-block">
                            <div className=""><label>Project / CR:</label></div>
                          </div>
                          <div className="d-block">
                            <div className="">
                              <MultiSelect
                                name="newResProjectOrCRNames"
                                data={this.props.location.action === 'create' ? this.state.validPMsProjAndCrs : this.state.validProjAndCrs}
                                textField="name"
                                dataItemKey="id"
                                value={this.state.newResProjectOrCRNames}
                                filterable={true}
                                onFilterChange={this.filter}
                                onChange={this.handleOnChangeDropDown}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="d-block">
                                <div className=""><label>Status:</label></div>
                              </div>
                              <div className="d-block">
                                <div className="">
                                  <MultiSelect
                                    data={this.state.LineStatus}
                                    textField="name"
                                    dataItemKey="value"
                                    value={this.state.SelectedLineStatuses}
                                    onChange={this.handleOnChangeDropDown}
                                    name="SelectedLineStatuses"
                                    placeholder="Please Select"
                                    filterable={true}
                                    onFilterChange={this.filter}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2 mt-auto">
                              <button className="k-button k-primary" onClick={this.submitSearch}>Filter</button>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-2 mt-auto">
                    <div className="align-middle">
                    <button className="k-button k-primary" onClick={this.submitSearch}>Filter</button>
                    </div> */}
                        {/* {this.state.workflowDialog && <Dialog title={"Resource Line Workflow"} onClose={this.toggleWorkflow}>
                                        <div className="col-md-12">
                                            <Grid style={{ height: '200px' }}
                                                resizable
                                            >
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Action</label>}
                                                    width="120px"
                                                    maxLength={10}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Date and Time</label>}
                                                    width="120px"
                                                    maxLength={10}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>User</label>}
                                                    width="120px"
                                                    maxLength={40}
                                                />
                                                <Column
                                                    field=""
                                                    headerCell={props => <label>Status</label>}
                                                    width="120px"
                                                    maxLength={40}
                                                />
                                            </Grid>
                                        </div>
                                        <DialogActionsBar>
                                            <button className="k-button" onClick={this.toggleWorkflow}>Close</button>
                                        </DialogActionsBar>
                                    </Dialog>} */}
                        {/* </div> */}
                      </div>

                      <div className="col-md-12 row mb-4">
                        <div className="col-md-3 mt-auto">
                          <div className="d-block">
                            <div className=""><label>Revenue from Resource Deployment:</label></div>
                          </div>
                          <div className="d-block">
                            <div className="">
                              <Input
                                disabled={true}
                                value={this.state.filteredTotalRevenue}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mt-auto">
                          <div className="d-block">
                            <div className=""><label>Cost of Resource Deployment:</label></div>
                          </div>
                          <div className="d-block">
                            <div className="">
                              <Input
                                disabled={true}
                                value={this.state.filteredTotalCost}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 mt-auto">
                          <div className="d-block">
                            <div className=""><label>Cost to Revenue Ratio:</label></div>
                          </div>
                          <div className="d-block">
                            <div className="">
                              <Input
                                disabled={true}
                                value={this.state.filteredCostToRevenueRatio}
                              />
                            </div>
                          </div>
                        </div>


                        <div className="col-md-3 mt-auto">
                          <div className="">
                            <Button primary className="pull-left" onClick={this.handleDownloadButtonClick}>
                              {'Download Resource Request'}
                            </Button>
                            {!this.state.isLoading && (
                              <ExcelDownload
                                project={this.state.project}
                                newResProjectOrCRNames={this.state.newResProjectOrCRNames}
                                SelectedLineStatuses={this.state.SelectedLineStatuses}
                                filteredTotalRevenue={this.state.filteredTotalRevenue}
                                filteredTotalCost={this.state.filteredTotalCost}
                                filteredCostToRevenueRatio={this.state.filteredCostToRevenueRatio}
                                calculatedValues={this.state.calculatedValues}
                                approvedCalculatedValues={this.state.approvedCalculatedValues}
                                resourceMix={this.state.filteredResourceMix ? this.state.filteredResourceMix : this.state.resourceMix}
                                dataUpdated={() => {
                                  this.setState({ excelDataObsolete: false });
                                }}
                                updateData={this.state.excelDataObsolete}
                                location={this.props.location}
                                endDate={this.state.userDefinedEndDate ? getFormattedDate(new Date(this.state.userDefinedEndDate)) : null}
                              />
                            )}
                          </div>
                        </div>



                      </div>
                    </PanelBarItem>
                  </PanelBar>
                  <div className="main-seperator"></div>
                </>
              )}

              <div className="row mt-3 resource-request-btn-wrap">
                <div className="col-md-12 btn-align-right">
                  {this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPApproveWF' && (
                    <Button type="button" primary={true} disabled={this.ticked.length == 0 || this.props.location.action === 'view' || this.state.requestAllocation || this.state.requestAllocationSub || this.state.hasSubLines || this.state.requestAllocationSoftDate || this.state.requestAllocationSoftSubDate || this.state.isWaitingConfirmResource}
                      onClick={this.submitAllocationCheck}>Submit for Resource Allocation</Button>
                  )}

                  {this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPApproveWF' && (
                    <Button type="button" primary={true} disabled={this.ticked.length == 0 || this.props.location.action === 'view' || this.state.requestAllocationSoft || this.state.requestAllocationSubSoft || this.state.hasSubLines || this.state.requestAllocationSubSoftResubmit || this.state.requestAllocationSoftResubmit || this.state.requestAllocationSoftDate || this.state.requestAllocationSoftSubDate}
                      onClick={this.submitSoftBookingToggle}>Submit for Soft Booking</Button>
                  )}

                  {/*<Button type="button" primary={true}>Save</Button>*/}
                  {this.props.location.action !== 'RPApproveWF' && (
                    <>
                      <Button type="button" primary={true} disabled={this.props.location.action === 'RPApprove' || this.props.location.action === 'view' || this.state.isWaitingConfirmResource || this.state.addButtonDisabled || this.state.selectedResources.length > 0 || this.state.selectedResourcesSubLine.length > 0}
                        onClick={this.toggleAddDialog}>Add Resource</Button>
                      <Button type="button" primary={true} onClick={this.handleResourcesSave} disabled={this.props.location.action === 'view'}>Save</Button>
                      <Button type="button" primary={true} disabled={this.props.location.action === 'RPApprove' || this.props.location.action === 'view' || this.state.hasSubLines || this.state.confirmStatus || this.state.confirmStatusSub || this.ticked.length === 0}
                        onClick={this.confirmSelectedResources}>Confirm</Button>
                      <Button type="button" primary={true} onClick={this.handleResourceRemove}
                        disabled={this.props.location.action !== 'create' || this.props.location.action === 'RPApprove' || this.ticked.length == 0 || this.state.isCanRemoveRequest === false || this.state.hasSubLines || this.props.location.action === 'view' || this.state.isWaitingConfirmResource}>Remove
                    Resources</Button>
                      <Button type="button" primary={true} onClick={this.handleResourceDeallocate}
                        disabled={this.props.location.action !== 'create' || this.props.location.action === 'RPApprove' || this.ticked.length == 0 || this.state.isCanDeallocateRequest === false || this.state.hasSubLines || this.props.location.action === 'view'}>
                        Deallocate</Button>
                      <Button type="button" primary={true} onClick={this.editResource} disabled={this.ticked.length !== 1 || this.sublineTicked.length !== 0 || this.state.notEditableResource || this.props.location.action === 'view' || this.state.isWaitingConfirmResource}>
                        Add Subline</Button>
                      {/*<Button type="button" primary={true}>Define Skills</Button>*/}
                      <Button type="button" onClick={this.onClickCancel}>Cancel</Button>
                      <Button type="button" onClick={this.onClickUpdateCost} disabled={this.props.location.action === 'view'}>Update Cost</Button>
                    </>
                  )}
                </div>
              </div>

              {this.props.location.action === 'RPApproveWF' &&
                (
                  <div className="row mt-3">
                    <div className="col-md-12 btn-align-right">
                      <Button type="button" primary={true}
                        disabled={this.ticked.length == 0 || this.state.hasSubLines}
                        onClick={this.onClickApprove}>Approve</Button>

                      <Button type="button" primary={true}
                        disabled={this.ticked.length == 0 || this.state.hasSubLines}
                        onClick={this.onClickReject}>Reject</Button>

                      <Button type="button" onClick={this.onClickCancel}>Cancel</Button>
                    </div>
                  </div>
                )
              }

              <div className="table-subrow mt-3 resource-request-grid resource-mix-grid-wrap">
                <Grid data={(this.props.location.action !== 'RPApprove' && this.props.location.action !== 'RPApproveWF') ? this.state.filteredResourceMix : this.state.resourceMix}
                  rowRender={this.rowRenderSB}
                  style={{ height: '400px' }}
                  skip={this.state.skip}
                  take={this.state.take}
                  selectedField="selected"
                  onPageChange={this.pageChange}
                  onItemChange={this.itemChange}
                  //onHeaderSelectionChange={this.headerSelectionChange}
                  onSelectionChange={this.selectionChange}
                  className="resource-mix-grid"
                  detail={props => (<GridSubLineCell {...props} RateTypes={this.state.allRateTypes} onSubLineSelect={this.onSubLineSelect} action={this.props.location.action} onSublineEdit={this.onSubLineEdit} onClickLineStatus={this.onClickLineStatus} onClickMore={this.onClickMore} rowRenderSubSB={this.rowRenderSubSB} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} toggleDialogAllocationPercentage={this.toggleDialogAllocationPercentage} onClickAllocationHistory={this.onClickAllocationHistory}  />)}
                  expandField="expanded"
                  onExpandChange={this.expandChange}
                  sortable={true}
                  sort={this.state.sort}
                  onSortChange={this.sortChange}
                >

                  <Column
                    field="selected"
                    width="41px"
                    headerSelectionValue={
                      this.state.resourceMix.findIndex(dataItem => dataItem.selected === false) === -1
                    } />

                  {this.state.projectSubPhaseVisible && (
                    <Column
                      field=""
                      headerCell={props => <label></label>}
                      width="100px"
                    />
                  )}
                  <Column
                    field=""
                    headerCell={props => <label className="">Status</label>}
                    cell={props => (<ResourceRequestStatusCell {...props} />)}
                    width="145px"
                    maxLength={3}
                  />
                  <Column
                    field="dataValueCostDesignation.Name"
                    headerCell={props => <label className="">Designation (Cost)</label>}
                    width="140px"
                    maxLength={40}
                  />
                  <Column
                    field=""
                    headerCell={props => <label>Start Date</label>}
                    cell={props => (<ResourceRequestLineStartDateCell {...props} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} />)}
                    width="100px"
                  />
                  <Column
                    field="EndDate"
                    headerCell={props => <label>End Date</label>}
                    cell={props => (<ResourceRequestLineEndDateCell {...props} projectStartDate={this.state.project.StartDate} calculateNewEstimatedEndDate={this.calculateNewEstimatedEndDate} />)}
                    width="100px"
                  />
                  <Column
                    field="EffortDays"
                    headerCell={props => <label className="">Effort Days</label>}
                    width="100px"
                    maxLength={3}
                  />
                  <Column
                    field=""
                    headerCell={props => <label className="">Allocation (%)</label>}
                    width="100px"
                    maxLength={3}
                    cell={props => (<AllocationPercentageCell 
                      {...props}
                      action={this.props.location.action} 
                      toggleDialogAllocationPercentage={this.toggleDialogAllocationPercentage} 
                      />)}

                  />
                  <Column
                    width="150px"
                    field="AllocatedResource.Name"
                    headerCell={props => <label className="">Allocated Resource</label>}
                    maxLength={3}
                  />
                  <Column
                    field=""
                    width="150px"

                    cell={props => (<MoreDetailsCell {...props} action={this.props.location.action} onClickMore={this.onClickMore} onClickLineStatus={this.onClickLineStatus} onClickAllocationHistory={this.onClickAllocationHistory}  />)}
                  />

                </Grid>
              </div>



              {/*{this.state.visible &&
                                <div className="prr-window">
                                    <Window title={"Search"} initialHeight={400}>
                                        <form className="form-inline">
                                            <div class="form-group mx-sm-3">
                                                <Input />
                                            </div>
                                            <button className="k-button k-primary">Search</button>
                                            <div className="main-seperator" />
                                        </form>
                                        <form className="resources">

                                            <span className="title-two">Resource Suggestions</span>

                                            <div className="d-flex res-parent mt-2">
                                                <button className="resource-button btn">
                                                    <span className="k-icon k-i-arrow-60-left"></span>
                                                </button>
                                                <div className="resource-details ml-2 ">
                                                    <div className="text-uppercase font-weight-bold">Dushman Elvitigala</div>
                                                    <div>Untilization for the period: 100%</div>
                                                    <div>Presales Allocation: 24</div>
                                                    <div className="text-truncate w-70">Skills: Java, Node,...</div>
                                                </div>
                                            </div>

                                            <div className="d-flex res-parent mt-2">
                                                <button className="resource-button btn">
                                                    <span className="k-icon k-i-arrow-60-left"></span>
                                                </button>
                                                <div className="resource-details ml-2 ">
                                                    <div className="text-uppercase font-weight-bold">Dilanka Pieris</div>
                                                    <div>Untilization for the period: 120%</div>
                                                    <div>Presales Allocation: 24</div>
                                                    <div className="text-truncate w-70">Skills: .Net, M3,...</div>
                                                </div>
                                            </div>

                                            <div className="d-flex res-parent mt-2">
                                                <button className="resource-button btn">
                                                    <span className="k-icon k-i-arrow-60-left"></span>
                                                </button>
                                                <div className="resource-details ml-2 ">
                                                    <div className="text-uppercase font-weight-bold">Kelum Ganegoda</div>
                                                    <div>Untilization for the period: 80%</div>
                                                    <div>Presales Allocation: 24</div>
                                                    <div className="text-truncate w-70">Skills: M3, Tech consultant</div>
                                                </div>
                                            </div>
                                        </form>
                                    </Window>
                                </div>}*/}

              {/* {this.props.location.action !== 'RPApproveWF' && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <label htmlFor="">Project Manager's Comment:</label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <ReactQuill
                        maxLength="1000"
                        value={this.state.managerComment}
                        onChange={this.handlePMCommentChange}
                      />
                    </div>
                  </div>
                </>
              )} */}

            </TabStripTab>
            <TabStripTab title="Other Expenses">
              <Loader loading={this.state.isLoading} />
              <div className="row">
                <div className="col-md-12 btn-align-right">
                  <Button
                    primary={true}
                    look="outline"
                    onClick={this.addNewOtherExpense}
                    disabled={this.props.location.action !== 'create'}
                  >
                    Add New Other Expense
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="cms-oe">
                  <Grid
                    data={this.state.otherExpenses}
                    total={this.state.otherExpenses.length}
                    resizable
                  >
                    <Column
                      field="ProjectName"
                      title="Project/CR Name"
                      width="175px"
                    />
                    <Column
                      field="ExpenseType"
                      title="Expense Type"
                      width="140px"
                    />
                    <Column
                      field="BudgetedOccurrences"
                      title="Budgeted Occurrence"
                      maxLength={4}
                      width="140px"
                    />
                    <Column
                      field="BudgetedRate"
                      title="Budgeted Rate"
                      maxLength={7}
                      width="100px"
                    />
                    <Column
                      field="BillableToClient"
                      title="Billable to the Client (OPE)"
                      maxLength={12}
                      width="160px"
                    />
                    <Column
                      field="CMSCost"
                      title="Planned Cost"
                      width="120px"
                    />
                    <Column
                      field="TotalActualCost"
                      title="Actual Cost"
                      width="120px"
                    />
                    <Column
                      field=""
                      width="140px"
                      cell={props => (<OtherExpensesActionsCell {...props} action={this.props.location.action} onClickAddOtherExpense={this.onClickAddOtherExpense} onClickOtherExpenseHistory={this.onClickOtherExpenseHistory} onClickEditOtherExpense={this.onClickEditOtherExpense}  onClickDeleteOtherExpense={this.onClickDeleteOtherExpense}/>)}
                    />
                  </Grid>
                  </div>
                </div>
              </div>

            </TabStripTab>
          </TabStrip>



        </div>

        {/* <Dialog title={"Define Skill"} onClose={this.toggleDialog} width={700}>

                    <div className="row mb-3">
                        <div className="col-md-12 btn-align-right">
                            <Button type="button" primary={true}>Add</Button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Grid style={{ height: '400px' }}>
                                <Column field="Type" title="Type" />
                                <Column field="Skill" title="Skill" />
                                <Column field="ProficiencyLevel" title="Proficiency Level" />
                                <Column field="Actions" title="Actions" />
                            </Grid>
                        </div>
                    </div>

                    <DialogActionsBar>
                        <button className="k-button" onClick={this.toggleDialog}>Cancel</button>
                        <button className="k-button modal-primary" onClick={this.toggleDialog}>Save</button>
                    </DialogActionsBar>
                </Dialog> */}

        {this.state.mainmoredetails &&

          <Dialog title={"More Details"} onClose={this.toggleMoreDialog} width={900}>
            <div className="row my-3">
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Status:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.Status}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Project Role:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueProjectRole.Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Designation (Cost):</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueCostDesignation.Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Resource Region:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueResourceRegion.Name}</label>
                </div>
              </div>

            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">
            <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Project / CR:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Resource:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Resource.Name : ""}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Designation:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedDesignation}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocated Region:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Resource.ResourceRegion.Name : ""}</label>
                </div>
              </div>



            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">
            <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Start Date:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{moment(this.state.dataItem.StartDate).format('MM-DD-YYYY')}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">End Date:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{moment(this.state.dataItem.EndDate).format('MM-DD-YYYY')}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Effort Days:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.EffortDays && this.state.dataItem.EffortDays > 0 ? Number(this.state.dataItem.EffortDays).toFixed(2): 0}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Allocation (%):</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.CurrentAllocationPercentage}</label>
                </div>
              </div>




            </div>
            <div className="main-seperator"></div>

            <div className="row my-3">

              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Rate Type:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.dataValueRateType  && this.state.dataItem.dataValueRateType.Name}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Revenue:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.TotalRevenue && this.state.dataItem.TotalRevenue > 0 ? Number(this.state.dataItem.TotalRevenue).toFixed(2) : 0.00}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Cost:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.TotalCost && this.state.dataItem.TotalCost > 0 ? Number(this.state.dataItem.TotalCost).toFixed(2) : 0.00}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Cost to Revenue Ratio:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.CostToRevenueRatio ? Number(this.state.dataItem.CostToRevenueRatio).toFixed(2) : 0.00}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Onsite / Offshore:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.OnsiteStatus}</label>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label className="font-weight-bold mr-1">Billing Method:</label></div>
                <div className="d-block">
                  <label className="font-weight-normal">
                    {
                      this.state.dataItem.dataValuePaymentMethod ? this.state.dataItem.dataValuePaymentMethod.Name : 
                      this.state.dataItem.Type === "Project" ? this.state.dataItem.Project.PaymentMethodRecord.Name :  
                      this.state.dataItem.ChangeRequest.PaymentMethod.Name
                    }
                    </label>
                </div>
              </div>
            </div>


            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleMoreDialog}>Close</button>
            </DialogActionsBar>
          </Dialog>}

        {this.state.visibleLineStatus &&
          <Dialog width={1100} title={"Line Status"} onClose={this.toggleLineStatusDialog}>
            <div className="row">
              <div className="col-md-3">
                <div className="d-block"><label>Designation:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedDesignation : this.state.dataItem.dataValueCostDesignation.Name}
                  disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Allocated Resource:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.AllocatedResource ? this.state.dataItem.AllocatedResource.Resource.Name : ""}
                  disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Allocation:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.CurrentAllocationPercentage ? this.state.dataItem.CurrentAllocationPercentage : ""}
                  disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Line Status:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.Status ? ResourceRequestStatusENUMS[this.state.dataItem.Status] : ""}
                  disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="d-block"><label>Start Date:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.StartDate ? moment(this.state.dataItem.StartDate).format('MM-DD-YYYY') : ""}
                  disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>End Date:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.EndDate ? moment(this.state.dataItem.EndDate).format('MM-DD-YYYY') : ""}
                  disabled={true} />
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-block"><label>Effort:</label></div>
                <div className="d-block">
                  <Input 
                  value={this.state.dataItem.EffortDays ? this.state.dataItem.EffortDays : ""}
                  disabled={true} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Grid
                  data={this.state.lineStatusHistoryList}
                  style={{ height: '150px' }}
                >
                  <Column field="GroupCardName" title="Group" width="150px"/>
                  <Column field="updatedUser" title="User" width="200px"/>
                  <Column
                    field="ToStatus"
                    title="Status"
                    cell={props => (<ResourceRequestStatusCell {...props} />)}
                    width="200px"
                    maxLength={3}
                  />
                  <Column field="statusChangedAt" title="Date and Time" 
                    cell={props => (<LineHistoryDateCell {...props} />)}/>
                  <Column field="Comment" title="Comment" />
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleLineStatusDialog}>Close</button>
            </DialogActionsBar>
          </Dialog>
        }

        {this.state.visibleAllocationHistory &&
          <Dialog width={900} title={"Allocation Percentage History"} onClose={this.toggleAllocationHistoryDialog}>
            <div className="row">
              <div className="col-md-12">
                <Grid
                  data={this.state.lineAllocationHistoryList}
                  style={{ height: '150px' }}
                >
                  <Column field="StartDate" title="Start Date" />
                  <Column field="EndDate" title="End Date" />
                  <Column field="EffortDays" title="Effort Days" />
                  <Column field="AllocationPercentage" title="Allocation (%)" />
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleAllocationHistoryDialog}>Close</button>
            </DialogActionsBar>
          </Dialog>
        }

        {this.state.showEditDialog === true && (
          <Dialog title={'Edit Resource Main Line'} onClose={this.toggleCancelConfirmDialog} width={900} height={450}>

            <div className="row">
              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editProjectOrCRName"
                      value={this.state.editableItem.name}
                      onChange={this.handleComboChangeEdit}
                      required={true}
                      disabled={true}
                    />
                    <span className={this.validateProperty(this.state.editableItem.name)}>
                      Please select a valid type
                                  </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Phase :</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editPhase"
                      data={this.state.editableItem.Phases}
                      textField="Text"
                      dataItemKey="id"
                      value={this.state.editableItem.Phase}
                      onChange={this.handleComboChangeEdit}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Sub-Phase :</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editSubPhase"
                      data={this.state.subPhasesOfSelectedPhase}
                      textField="Text"
                      dataItemKey="id"
                      value={this.state.editableItem.SubPhase}
                      onChange={this.handleComboChangeEdit}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    // filterable={true}
                    // onFilterChange={this.filter}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block"><label>Fixed/T&M:</label></div>
                <div className="d-block">
                  <Input
                    value={this.state.editableItem.ResourcePaymentMethod.Name}
                    //onChange={this.handleOnChangeInput}
                    name="PaymentMethod"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </div>

            </div>

            <div className="row">

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Start Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      name="editStartDate"
                      value={this.state.editableItem.StartDate === null ? '' : reStructureDate(this.state.editableItem.StartDate)}
                      min={new Date()}
                      format="MM/dd/yyyy"
                      onChange={this.handleAddStartDateChange}
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={this.state.disableSelectedResourceStartEndDate}
                    >
                    </DatePicker>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>End Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      name="editEndDate"
                      value={this.state.editableItem.EndDate === null ? '' : reStructureDate(this.state.editableItem.EndDate)}
                      min={new Date()}
                      format="MM/dd/yyyy"
                      onChange={this.handleAddStartDateChange}
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={this.state.disableSelectedResourceStartEndDate}
                    >
                    </DatePicker>
                  </div>
                </div>
              </div>

              {
                <div className="col-md-3">
                  <div className="d-block"><label className="mandatory">Effort Days:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editEffortDays"
                      value={this.state.editableItem.TotalEffortDays && this.state.editableItem.TotalEffortDays >0  ? Number(this.state.editableItem.TotalEffortDays).toFixed(2) : 0 }
                      onChange={this.handleComboChangeEdit}
                      required={true}
                      disabled={true}
                    />
                  </div>
                </div>
              }

              {<div className="col-md-3">
                <div className="d-block"><label className="mandatory">Allocation (%):</label></div>
                <div className="d-block">
                  <ComboBox
                    placeholder="Please select"
                    name="editAllocationPrecentage"
                    value={this.state.editableItem.AllocationPercentage}
                    onChange={this.handleComboChangeEdit}
                    required={true}
                    disabled={true}
                  />
                </div>
              </div>}

            </div>

            <div className="row">

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Project Role:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editRole"
                      data={this.state.RolesAll}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.editableItem.dataValueProjectRole}
                      onChange={this.handleComboChangeEdit}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                      disabled={true}
                    />
                    <span className={this.validateProperty(this.state.editableItem.dataValueProjectRole)}>
                      Please select a valid Role
                                        </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Designation:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editDesignation"
                      data={this.state.editableItem.Designations}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.editableItem.dataValueCostDesignation}
                      onChange={this.handleComboChangeEdit}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filterChangeCombo}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />
                    <span className={this.validateProperty(this.state.editableItem.dataValueCostDesignation)}>
                      Please select a valid Role
                                 </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Resource Region:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editRegion"
                      data={this.state.RegionsAll}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.editableItem.dataValueResourceRegion}
                      onChange={this.handleComboChangeEdit}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />
                    <span className={this.validateProperty(this.state.editableItem.dataValueResourceRegion)}>
                      Please select a valid Region
                                        </span>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block"><label className="mandatory">Onsite / Offshore:</label></div>
                <div className="d-block">
                  <ComboBox
                    placeholder="Please select"
                    name="editOnSiteStatus"
                    data={this.state.onSiteStatusAll}
                    value={this.state.editableItem.OnsiteStatus === 'Onsite' ? this.onSiteStatusAll[0] : this.state.editableItem.OnsiteStatus === 'Offshore' ? this.onSiteStatusAll[1] : null}
                    textField="Name"
                    dataItemKey="id"
                    onChange={this.handleComboChangeEdit}
                    required={true}
                    filterable={true}
                    onFilterChange={this.filter}
                    disabled={this.props.location.action === 'RPApprove' ? true : false}
                  />
                  <span className={this.validateProperty(this.state.editableItem.OnsiteStatus)}>
                    Please select a valid Value
                                      </span>
                </div>
              </div>

            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="d-block"><label>Daily Rate:</label></div>
                <div className="d-block">
                  <Input
                    value={this.state.editableItem.RateValue}
                    //onChange={this.handleOnChangeInput}
                    name="editRateValue"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block"><label>Revenue:</label></div>
                <div className="d-block">
                  <Input
                    value={this.state.editableItem.TotalRevenue && this.state.editableItem.TotalRevenue > 0 ? Number(this.state.editableItem.TotalRevenue).toFixed(2) : 0.00 }
                    //onChange={this.handleOnChangeInput}
                    name="editRevenueValue"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block"><label>Cost:</label></div>
                <div className="d-block">
                  <Input
                    value={this.state.editableItem.TotalCost}
                    //onChange={this.handleOnChangeInput}
                    name="editTotalCostValue"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="d-block"><label>Cost to Revenue Ratio:</label></div>
                <div className="d-block">
                  <Input
                    value={this.state.editableItem.CostToRevenueRatio}
                    //onChange={this.handleOnChangeInput}
                    name="editTotalCostValue"
                    autoComplete="off"
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">





              {/*<div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Effort Days:</label></div>
                  <div className="d-block">
                    <DebounceInput
                      debounceTimeout={2000}
                      width="100%"
                      onChange={this.handleAddEffortDaysChange}
                      value={this.state.editableItem.EffortDays}
                      name="editEffortDays"
                      type="number"
                      className="k-textbox"
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />
                    <span className={this.validateNumber(this.state.editableItem.EffortDays)}>
                                            Please insert a valid Value
                                  </span>
                  </div>
                </div>
              </div>*/}

              {this.props.location.action === 'RPApprove' && (
                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label className="mandatory">Allocated Resource:</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editAllocatedResource"
                        data={this.state.usersAll}
                        value={this.state.editableItem.AllocatedResource}
                        textField="Name"
                        dataItemKey="id"
                        onChange={this.handleComboChangeEdit}
                        required={true}
                        filterable={true}
                        onFilterChange={this.filter}
                      />
                      <span className={this.validateProperty(this.state.editableItem.AllocatedResource)}>
                        Please select a valid Value
                                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-3">
                <div className="d-block"><label>Project Manager's Comment:</label></div>
                <div className="d-block">
                  <textarea
                    className="k-textarea"
                    value={this.state.editableItem.ManagerComment ? this.state.editableItem.ManagerComment : this.state.managerCommentCMS}
                    onChange={this.handlePMCommentChangeCMS}
                    maxLength={1000}
                  />
                </div>
              </div>


            </div>

            <div className="row mt-5">
              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Start Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      width="100"
                      name="endDate"
                      onChange={this.handleSubLineStartDateChange}
                      value={this.state.selctedResourceSubLineStartDate}
                      min={reStructureDate(this.state.subLineStartDateMin)}
                      max={this.state.selctedResourceSubLineEndDate === null ? reStructureDate(this.state.userDefinedEndDate) : this.state.selctedResourceSubLineEndDate}
                      disabled={false}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />

                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>End Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      width="100"
                      name="endDate"
                      onChange={this.handleSubLineEndDateChange}
                      value={this.state.selctedResourceSubLineEndDate}
                      min={this.state.selctedResourceSubLineStartDate === null ? reStructureDate(this.state.subLineStartDateMin) : this.state.selctedResourceSubLineStartDate}
                      max={reStructureDate(this.state.userDefinedEndDate)}
                      disabled={!this.state.selctedResourceSubLineStartDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      disabled={this.props.location.action === 'RPApprove' ? true : false}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-block"><label>Allocation %:</label></div>
                <div className="d-block">
                  <DebounceInput
                    debounceTimeout={2000}
                    width="100%"
                    value={this.state.subLineDefaultAllocationPercentage}
                    onChange={this.handleSubLineAllocationPercentageChange}
                    name="subLineAllocPercentage"
                    type="number"
                    pattern="[0-9]*"
                    className="k-textbox"
                    disabled={this.props.location.action === 'RPApprove' ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-block"><label>Effort:</label></div>
                <div className="d-block">
                  <DebounceInput
                    debounceTimeout={1000}
                    width="100%"
                    value={
                      this.state.subLineEffortDays > 0
                        ? this.state.subLineEffortDays.toFixed(2).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ','
                        )
                        : '0'}
                    name="subLineEffortDays"
                    type="number"
                    pattern="[0-9]*"
                    className="k-textbox"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Rate Type :</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="subLineEditRate"
                      data={this.state.RateTypes}
                      textField="name"
                      dataItemKey="id"
                      value={this.state.subLineDefaultRateType}
                      onChange={this.handleSubLineRateTypeChange}
                      required={true}
                      disabled={this.state.editableItem.ResourcePaymentMethod.Name !== 'Time and Material' || this.props.location.action === 'RPApprove'}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-block"><label>Daily Rate:</label></div>
                <div className="d-block">
                  <DebounceInput
                    debounceTimeout={100}
                    width="100%"
                    value={this.state.subLineDefaultRateValue}
                    onChange={this.handleSubLineAllocationPercentageChange}
                    name="subLineEditRateValue"
                    type="number"
                    pattern="[0-9]*"
                    className="k-textbox"
                    disabled={true}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="d-block"><label>Project Manager's Comment for Subline:</label></div>
                <div className="d-block">
                  <textarea
                    className="k-textarea"
                    value={this.state.managerComment}
                    onChange={this.handlePMCommentChange}
                    maxLength={1000}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="d-block"><label></label></div>
                <div className="d-block billable-mt">
                  <Button type="button" primary={true}
                    disabled={!this.state.selctedResourceSubLineStartDate || !this.state.selctedResourceSubLineEndDate ||
                      !this.state.subLineDefaultAllocationPercentage || Number(this.state.subLineDefaultAllocationPercentage) === 0 || this.state.subLineEffortDays == 0}
                    onClick={this.onAddSubLineToGrid}
                  >Add</Button>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-md-12">
                <Grid className="resource-mix-grid"
                  data={this.state.editableItem.ResourceRequestSubLine}
                >

                  <Column
                    field="StartDate"
                    headerCell={props => <label className="">Start Date</label>}
                  />
                  <Column
                    field="EndDate"
                    headerCell={props => <label className="">End Date</label>}
                  />
                  <Column
                    field="AllocationPercentage"
                    headerCell={props => <label className="">Allocation %</label>}
                  />
                  <Column
                    field="EffortDays"
                    headerCell={props => <label className="">Effort</label>}
                  />
                  <Column
                    field="RateValue"
                    headerCell={props => <label className="">Rate</label>}
                  />
                  <Column
                    field="Status"
                    headerCell={props => <label className="">Status</label>}
                    cell={props => (<ResourceRequestEditStatusCell {...props} />)}
                  />
                  <Column
                    field="ManagerComment"
                    headerCell={props => <label className="">Comment</label>}
                  />

                </Grid>
              </div>
            </div>


            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleCancelConfirmDialog}>Cancel</button>
              <button className="k-button modal-primary" onClick={this.addEditingValueToGrid}>Save</button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showAddDialog === true && (
          <Dialog title={'Add Resource(s)'} onClose={this.toggleAddDialog} width={1000}>

            <div className="row">

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResProjectOrCRName"
                      data={this.props.location.action === 'create' ? this.state.validPMsProjAndCrs : this.state.namesAll}
                      textField="name"
                      dataItemKey="id"
                      value={this.state.newResProjectOrCRName}
                      onChange={this.handleComboChange}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newResProjectOrCRName)}>
                        Please select a valid Project/CR
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Phase:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResPhase"
                      value={this.state.newResPhase}
                      data={this.state.phasesTemp}
                      textField="Text"
                      dataItemKey="id"
                      onChange={this.handleComboChange}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label>Sub Phase:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResSubPhase"
                      value={this.state.newResSubPhase}
                      data={this.state.tmpSubPhasesAll}
                      textField="Text"
                      dataItemKey="id"
                      onChange={this.handleComboChange}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Fixed/T&M:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newPaymentMethod"
                      data={this.state.newPaymentMethodAll}
                      value={this.state.newPaymentMethod}
                      textField="Name"
                      dataItemKey="id"
                      onChange={this.handleComboChange}
                      disabled={this.state.paymentMethodDisabled}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newPaymentMethod)}>
                        Please select a valid payment method
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>

            </div>


            <div className="row">

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Project Role:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResRole"
                      data={this.state.RolesAll.filter(obj => obj.Name !== "Outsourced")}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.newResRole}
                      onChange={this.handleComboChange}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newResRole)}>
                        Please select a valid Role
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Designation:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResDesignation"
                      data={this.state.tmpDesignationsAll}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.newResDesignation}
                      onChange={this.handleComboChange}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newResDesignation)}>
                        Please select a valid Designation
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Resource Region:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newResRegion"
                      data={this.state.RegionsAll}
                      textField="Name"
                      dataItemKey="id"
                      value={this.state.newResRegion}
                      onChange={this.handleComboChange}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newResRegion)}>
                        Please select a valid Region
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Onsite / Offshore:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newOnSiteStatus"
                      data={this.state.onSiteStatusAll}
                      value={this.state.newOnSiteStatus}
                      textField="Name"
                      dataItemKey="id"
                      onChange={this.handleComboChange}
                      required={true}
                      filterable={true}
                      onFilterChange={this.filter}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newOnSiteStatus)}>
                        Please select a valid Value
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>


            </div>

            <div className="row">
              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Rate Type:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="newRate"
                      data={this.state.newPaymentMethod ? this.state.newPaymentMethod.Name === 'Fixed Price' && this.state.isProjectRevenueExceed ? this.rateTypesAll.filter(type => type.Code === 'ADMIN_RATE') : this.state.newRatesAll : this.state.newRatesAll}
                      textField="name"
                      dataItemKey="Code"
                      value={this.state.newRate}
                      onChange={this.handleComboChange}
                      required={true}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newRate)}>
                        Please select a valid Rate Type
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Daily Rate Value:</label></div>
                  <div className="d-block">
                    <DebounceInput
                      debounceTimeout={2000}
                      width="100%"
                      onChange={this.handleAddAllocationChange}
                      value={this.state.newRate &&  this.state.newRate.Rate != null ? this.state.newRate.Rate : 0 }
                      name="newRateValue"
                      type="number"
                      className="k-textbox"
                      required={true}
                      minValue={0}
                      disabled={this.state.newRate ? this.state.newRate.RateValueDisabled : true}
                    />
                    {this.state.showErrorImage === true && this.state.newRate && this.state.newRate.id === 4 ? (
                      <span className={this.validateNumber(this.state.newRate.Rate)}>
                        Please select a valid Rate Value
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label >Project Manager's Comment:</label></div>
                  <div className="d-block">
                    <textarea
                      className="k-textarea"
                      value={this.state.managerComment}
                      onChange={this.handlePMCommentChange}
                      maxLength={1000}
                    />
                  </div>
                </div>
              </div>

            </div>

            <div className="row">

              <div className="col-md-3">
                <div className="">

                  <div className="d-block"><label className="mandatory">Start Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      name="addStartDate"
                      value={this.state.newStartDate}
                      min={this.state.newStartDateMin ? this.state.newStartDateMin : (this.state.newResProjectOrCRName ? reStructureDate(this.state.newResProjectOrCRName.StartDate) : this.state.addResourceMinDate)}
                      max={reStructureDate(this.state.userDefinedEndDate)}
                      format="yyyy-MM-dd"
                      onChange={this.handleAddStartDateChange}
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                    >
                    </DatePicker>
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newStartDate)}>
                        Please select a Start Date
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">End Date:</label></div>
                  <div className="d-block">
                    <DatePicker
                      name="addEndDate"
                      value={this.state.newEndDate}
                      format="yyyy-MM-dd"
                      min={this.state.newStartDate ? reStructureDate(this.state.newStartDate) : new Date(this.state.newStartDate)}
                      max={reStructureDate(this.state.userDefinedEndDate)}
                      onChange={this.handleAddEndDateChange}
                      formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      required={true}
                    >
                    </DatePicker>
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateProperty(this.state.newEndDate)}>
                        Please select a End Date
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Effort Days:</label></div>
                  <div className="d-block">
                    <DebounceInput
                      debounceTimeout={2000}
                      width="100%"
                      onChange={this.handleAddEffortDaysChange}
                      value={
                        this.state.newEffortDays > 0
                          ? this.state.newEffortDays.toFixed(2).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ','
                          )
                          : '0'}
                      name="newEffortDays"
                      type="number"
                      className="k-textbox"
                      required={true}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="">
                  <div className="d-block"><label className="mandatory">Allocation (%):</label></div>
                  <div className="d-block">
                    <Loader loading={this.state.isLoading} />
                    <DebounceInput
                      debounceTimeout={2000}
                      width="100%"
                      onChange={this.handleAddAllocationChange}
                      value={this.state.newAllocation}
                      name="newAllocation"
                      type="number"
                      className="k-textbox"
                      required={true}
                    />
                    {this.state.showErrorImage === true ? (
                      <span className={this.validateNumber(this.state.newAllocation)}>
                        Please select a valid Value
                                            </span>
                    ) : null}
                  </div>
                </div>
              </div>

            </div>

            {/*<div className="row mt-3 mb-3">
              <div className="col-md-12 btn-align-right">
                <Button type="button" primary={true} onClick={this.addNewToGrid}>Add</Button>
                <Button type="button" onClick={this.clearAddingValues}>Clear</Button>
              </div>
            </div>*/}

            {/* <div className="row">
                            <div className="col-md-12">
                                <Grid data={this.state.addResourceGridData}
                                    skip={this.state.skip}
                                    take={this.state.take}
                                    pageable={true}
                                    onPageChange={this.pageChange}
                                    onItemChange={this.itemChange}
                                    onSelectionChange={this.selectionChange}
                                    className="resource-mix-grid"
                                    resizable={true}>

                                    <Column
                                        field="newResProjectOrCRName.name"
                                        headerCell={props => <label className="">Project / CR</label>}
                                    />
                                    <Column
                                        field="newPhase.Text"
                                        headerCell={props => <label className="">Phase</label>}
                                    />
                                    <Column
                                        field="newSubPhase.Text"
                                        headerCell={props => <label className="">Sub Phase</label>}
                                    />
                                    <Column
                                        field="newResRole.Name"
                                        headerCell={props => <label className="">Role</label>}
                                    />
                                    <Column
                                        field="newResDesignation.Name"
                                        headerCell={props => <label className="">Designation</label>}
                                    />
                                    <Column
                                        field="newResRegion.Name"
                                        headerCell={props => <label className="">Region</label>}
                                    />
                                    <Column
                                        field="newOnSiteStatus.Name"
                                        headerCell={props => <label className="">Onsite / Offshore</label>}
                                    />
                                    <Column
                                        field="newStartDate"
                                        headerCell={props => <label className="">Start Date</label>}
                                    />
                                    <Column
                                        field="newEndDate"
                                        headerCell={props => <label className="">End Date</label>}
                                    />
                                    <Column
                                        field="newEffortDays"
                                        headerCell={props => <label className="">Effort Days</label>}
                                    />
                                    <Column
                                        field="newAllocation"
                                        headerCell={props => <label className="">Allocation (%)</label>}
                                    />

                                    <Column
                                        field="TotalCost"
                                        headerCell={props => <label className="">Cost</label>}
                                    />

                                    <Column
                                        field="TotalRevenue"
                                        headerCell={props => <label className="">Revenue</label>}
                                    />

                                    <Column
                                        field="CostToRevenueRatio"
                                        headerCell={props => <label className="">Cost to Revenue Ratio</label>}
                                    />

                                </Grid>
                            </div>
                        </div> */}

            <DialogActionsBar>
              <button className="k-button" onClick={this.onClickCancelResource}>Cancel</button>
              <button className="k-button modal-primary" onClick={this.addToMainGrid} disabled={this.state.addResourceSaveBtnDisabled}>Save</button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.addOtherExpensesPopup === true && (
          <Dialog title={'Add Other Expenses Actual Cost'} onClose={this.toggleCancelConfirmDialog} width={750} height={450}>

            <div className="row">
              <div className="col-md-4">
                <div className="">
                  <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                  <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.ProjectName}</label>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="">
                  <div className="d-block"><label>Expense Type:</label></div>
                  <div className="d-block">
                  <label className="font-weight-normal">{this.state.dataItem.ExpenseType}</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-md-4">
                <div className="d-block"><label className="mandatory">Date:</label></div>
                <div className="d-block">
                  <DatePicker
                    width="100"
                    name="otherExpenseDate"
                    onChange={this.otherExpenseDateChange}
                    value={this.state.otherExpenseDate}
                    min={this.state.actualExpenseMinDate ? new Date(this.state.actualExpenseMinDate) : new Date(this.state.dataItem.ProjectStartDate)}
                    max={new Date(this.state.userDefinedEndDate)}
                    disabled={false}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                  />

                </div>
              </div>

              <div className="col-md-4">
                <div className="d-block"><label className="mandatory">Cost:</label></div>
                <div className="d-block">
                  <DebounceInput
                    debounceTimeout={1000}
                    width="100%"
                    value={this.state.otherExpenseCostValue}
                    onChange={this.handleOtherExpenseCostChange}
                    name="otherExpenseCostValue"
                    type="number"
                    pattern="[0-9]*"
                    className="k-textbox"
                    maxLength={8} 
                  />
                </div>
              </div>
              
              <div className="col-md-4">
                <div className="d-block"><label></label></div>
                <div className="d-block billable-mt">
                  <Button 
                    type="button" 
                    primary={true}
                    disabled={!(this.state.otherExpenseCostValue && this.state.otherExpenseCostValue > 0) || !this.state.otherExpenseDate}
                    onClick={this.addOtherExpenseToGrid}
                  >Add</Button>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-md-12">
                <Grid className="resource-mix-grid"
                  data={this.state.actualOtherExpenses}
                  total={this.state.actualOtherExpenses.length}
                  resizable
                >
                  <Column
                    field="Date"
                    headerCell={props => <label className="">Date</label>}
                  />
                  <Column
                    field="Cost"
                    headerCell={props => <label className="">Cost</label>}
                  />
                  <Column
                    field=""
                    width="120px"
                    cell={props => (<OtherExpenseActualCostActionCell {...props} action={this.props.location.action} onClickEditActualExpenseCost={this.onClickEditActualExpenseCost} onClickDeleteActualExpenseCost={this.onClickDeleteActualExpenseCost} />)}
                  />

                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleCancelConfirmDialog}>Cancel</button>
              <button className="k-button modal-primary" 
                onClick={this.saveActualOtherExpenseDates} 
                disabled={this.state.disableOtherExpenseSaveBtn || this.state.otherExpenseCostValue || this.state.otherExpenseDate}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.addActualOtherExpensesHistoryPopup === true && (
          <Dialog title={'Other Expenses Actual Cost History'} onClose={this.toggleActualOtherExpensesHistory} width={600} height={300}>
            <div className="row">
              <div className="col-md-12">
                <Grid className="resource-mix-grid"
                  data={this.state.actualOtherExpenses}
                  total={this.state.actualOtherExpenses.length}
                  resizable
                >
                  <Column
                    field="Date"
                    headerCell={props => <label className="">Date</label>}
                  />
                  <Column
                    field="Cost"
                    headerCell={props => <label className="">Cost</label>}
                  />
                </Grid>
              </div>
            </div>
            <DialogActionsBar>
              <button className="k-button modal-primary" onClick={this.toggleActualOtherExpensesHistory}>Close</button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.addNewOtherExpensePopup === true && (
          <Dialog title={`${this.state.isEditOtherExpense ? 'Edit' : 'Add New'} Expense Type`} onClose={this.toggleCancelConfirmDialog} width="450px">

            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block  col-md-4">
                <label className="mandatory">Project / CR:</label>
              </div>

              <div className="d-block col-md-7" style={{marginLeft: '-20px'}}>
                <div>
                <ComboBox
                    placeholder="Please select"
                    name="newOtherExpenseProjectOrCR"
                    data={this.state.validPMsProjAndCrs}
                    textField="name"
                    dataItemKey="id"
                    value={this.state.newOtherExpenseProjectOrCR}
                    onChange={this.handleOtherExpenseProjectOrCr}
                    required={true}
                    filterable={true}
                    // onFilterChange={this.filter}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block  col-md-4">
                <label className="mandatory">Expense Type:</label>
              </div>

              <div className="d-block col-md-7" style={{marginLeft: '-20px'}}>
                <div>
                  <ComboBox
                    placeholder="Please select"
                    name="newExpenseType"
                    data={this.state.expenseTypes}
                    textField="Name"
                    dataItemKey="id"
                    value={this.state.newExpenseType}
                    onChange={this.handleOtherExpenseTypeChange}
                    required={true}
                    filterable={true}
                    // onFilterChange={this.filter}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block  col-md-4">
                <label className="mandatory">Billable to the Client (OPE):</label>
              </div>

              <div className="d-block col-md-7" style={{marginLeft: '-20px'}}>
                <div>
                  <ComboBox
                    placeholder="Please select"
                    name="otherExpenseBillableStatus"
                    data={this.billableStatusValues}
                    textField="Text"
                    dataItemKey="id"
                    value={this.state.otherExpenseBillableStatus}
                    onChange={this.handleOtherExpenseBillableStatusChange}
                    required={true}
                  />
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleCancelConfirmDialog}>Cancel</button>
              <button 
                className="k-button modal-primary" 
                disabled={!this.state.newOtherExpenseProjectOrCR || !this.state.newExpenseType || !this.state.otherExpenseBillableStatus || this.state.disableOtherExpenseSaveBtn}
                onClick={this.saveNewActualOtherExpense}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.deleteOtherExpensePopup && (
          <Dialog title={'Please Confirm'} onClose={this.deleteConfirmationClose}>
            <p style={{ margin: '25px', textAlign: 'center' }}>
              Are you sure you want to delete this expense?
            </p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.deleteConfirmationClose}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.deleteOtherExpense}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        
        {this.state.deleteOtherExpenseActualCostPopup && (
            <Dialog title={'Please Confirm'} onClose={this.deleteActualCostConfirmationClose}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Are you sure you want to delete this cost?
              </p>
              <DialogActionsBar>
                <button className="k-button" onClick={this.deleteActualCostConfirmationClose}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.deleteOtherExpenseActualCost}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

        <div>
          {this.state.popupVisible === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleDialog} width="400px">

              {this.state.showSuccessImage === true && (
                <div className="text-center">
                  <img className="successImg" src={SuccessImg} alt="Success" />
                </div>
              )}

              {this.state.showErrorImage === true && (
                <div className="text-center">
                  <img className="successImg" src={ErrorImg} alt="Error Image" />
                </div>
              )}
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>

              <DialogActionsBar>

                {this.state.showSuccessImage === true && this.state.isSubmitted && (
                  <button className="k-button modal-primary" onClick={this.navigateBack}>
                    OK
                  </button>
                )}

                {this.state.showSuccessImage === true && !this.state.isSubmitted && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}

                {this.state.showErrorImage === true && (
                  <button className="k-button modal-primary" onClick={this.toggleDialog}>
                    OK
                  </button>
                )}
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        {this.state.showConfirmDialog === true && (
          <Dialog title="Confirm" onClose={this.toggleConfirmDialog} width="400px">
            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.confirmDialogMessage}</p>
            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleConfirmDialog}>
                No
              </button>
              <button className="k-button modal-primary" onClick={this.state.confirmDialogAction}>
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showDeallocConfirmDialog === true && (
          <Dialog title="Resource Deallocation" onClose={this.toggleDeallocationConfirmDialog} width="400px">

            <div className="d-flex flex-col justify-content-start" style={{margin: '25px'}}>
              <div className="d-block">
                <label>Deallocation From</label>
              </div>

              <div className="d-block col-md-1">
                <label></label>
              </div>

              <div className="d-block">
                <div>
                  <DatePicker
                    width="100"
                    name="deallocationStartDate"
                    min={this.state.resDeallocationStartDate}
                    max={this.state.resDeallocationEndDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                    onChange={this.handleDeallocationStartDateChange}
                  />
                </div>

                <div>
                  {(this.state.deallocationDatesErrorMessage) ?
                    ( <span className={this.validateProperty()}>
                        Invalid deallocation date
                      </span>
                    ) :
                    null
                  }
                </div>
              </div>
            </div>

            <div>
              <p style={{ margin: '25px', textAlign: 'justify' }}>{this.state.confirmDialogMessage}</p>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleDeallocationConfirmDialog}>
                Cancel
              </button>

              <button className="k-button modal-primary" disabled={this.state.disableConfirmButton} onClick={this.state.confirmDialogAction}>
                Confirm
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showChangeAllocationDialog === true && (
          <Dialog title="Allocation Percentage Change" onClose={this.toggleDialogAllocationPercentage} width="400px">
            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block  col-md-4">
                <label>Allocation (%):</label>
              </div>

              <div className="d-block">
                <div>
                  <DebounceInput
                    debounceTimeout={2000}
                    width="100"
                    onChange={this.handleAllocationPercentageChange}
                    value={this.state.changedllocationPercentage}
                    name="changedllocationPercentage"
                    type="number"
                    className="k-textbox"
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block  col-md-4">
                <label>Start Date:</label>
              </div>

              <div className="d-block">
                <div>
                  <DatePicker
                    width="100"
                    name="allocationPercentageStartDate"
                    value={this.state.allocationPercentageStartDate}
                    min={this.state.allocationHistoryMinDate}
                    max={this.state.allocationPercentageEndDate ? this.state.allocationPercentageEndDate : this.state.allocationHistoryMaxDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                    onChange={this.handleAllocationStartDateChange}
                    popup={CustomDatePickerPopUp}
                  />
                </div>
              </div>
            </div>

            <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
              <div className="d-block col-md-4">
                  <label>End Date:</label>
                </div>

                <div className="d-block">
                  <div>
                    <DatePicker
                      width="100"
                      name="allocationPercentageEndDate"
                      value={this.state.allocationPercentageEndDate}
                      min={this.state.allocationPercentageStartDate ? this.state.allocationPercentageStartDate : this.state.allocationHistoryMinDate}
                      max={this.state.allocationHistoryMaxDate}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                      onChange={this.handleAllocationEndDateChange}
                      popup={CustomDatePickerPopUp}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-col justify-content-start" style={{margin: '15px'}}>
                <div className="d-block col-md-4">
                  <label>Effort Days:</label>
                </div>

                <div className="d-block">
                  <div>
                    <DebounceInput
                      width="100"
                      name="allocationPercentageEndDate"
                      value={this.state.allocationPercentageEffortDays}
                      disabled={true}
                      className="k-textbox"
                    />
                  </div>
                </div>
              </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleDialogAllocationPercentage}>
                Cancel
              </button>

              <button className="k-button modal-primary" disabled={this.state.disableConfirmButton} onClick={this.updateAllocationPercentageHistory}>
                Confirm
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showOutsourcedCostDialog === true && (
          <Dialog title="Enter Cost" onClose={this.toggleOutsourcedCostCloseDialog} width="400px">
            <div className="row">
              <div className="col-md-12">
                <p style={{ margin: '25px', textAlign: 'center' }}>
                  Please enter the daily cost per resource in USD
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label className="mandatory" for="">Cost:</label>
              </div>
              <div className="col-md-6 pr-0">
                <NumericTextBox
                  value={this.state.outsourcedCost}
                  onChange={this.handleOutsourcedCostInputChange}
                  min={0} />
              </div>
            </div>

            <DialogActionsBar>
              {/* <button className="k-button" onClick={this.toggleOutsourcedCostDialog}>
                Cancel
              </button> */}
              <button
                className="k-button modal-primary"
                onClick={this.confirmOutsourcedCost}
                disabled={this.state.outsourcedCost === 0 || !this.state.outsourcedCost}
              >
                Save
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

        {this.state.showMessageDialog === true && (
          <div className="okdialog">
            <Dialog title={this.state.dialogTitle} onClose={this.toggleMessageDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleMessageDialog}>
                  OK
              </button>
              </DialogActionsBar>
            </Dialog>
          </div>
        )}

        {this.state.showRateValidationDialog === true && (
          <Dialog title={this.state.dialogTitle} onClose={this.toggleRateValidatioDialog} width="400px">

            {this.state.showSuccessImage === true && (
              <div className="text-center">
                <img className="successImg" src={SuccessImg} alt="Success"/>
              </div>
            )}

            {this.state.showRateErrorImage === true && (
              <div className="text-center">
                <img className="successImg" src={ErrorImg} alt="Error Image"/>
              </div>
            )}

            <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
            <DialogActionsBar>
              {this.state.showRateErrorImage === true && (
                <button className="k-button modal-primary" onClick={this.toggleRateValidatioDialog}>
                  OK
                </button>
              )}
            </DialogActionsBar>
          </Dialog>
        )}

        <div>
          {this.state.showEditSubLineDialog === true && (
            <Dialog title={'Edit Resource Sub Line'} onClose={this.toggleSubLineEditDialog} width={800}>

              <div className="row">
                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label className="mandatory">Project / CR:</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editProjectOrCRName"
                        value={this.state.mainLineofsublineObj.name}
                        //onChange={this.handleComboChangeEdit}
                        required={true}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label>Phase :</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editSubLinePhase"
                        data={this.state.allProjectCrPhases}
                        textField="Text"
                        dataItemKey="id"
                        value={this.state.selectedSubLineToEdit.Phase}
                        onChange={this.handleComboBoxChangeOnSubLineEdit}
                      //required={true}
                      //filterable={true}
                      //onFilterChange={this.filter}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label>Sub-Phase :</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editSubLineSubPhase"
                        data={this.state.subPhasesOfSelectedPhase}
                        textField="Text"
                        dataItemKey="id"
                        value={this.state.selectedSubLineToEdit.SubPhase}
                        onChange={this.handleComboBoxChangeOnSubLineEdit}
                      //required={true}
                      // filterable={true}
                      // onFilterChange={this.filter}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block"><label>Fixed/T&M:</label></div>
                  <div className="d-block">
                    <Input
                      value={this.state.mainLineofsublineObj.PaymentMethod.Name === 'Hybrid' ? this.state.mainLineofsublineObj.dataValuePaymentMethod.Name : this.state.mainLineofsublineObj.PaymentMethod.Name}
                      //onChange={this.handleOnChangeInput}
                      name="PaymentMethod"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

              </div>

              <div className="row">

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label>Start Date:</label></div>
                    <div className="d-block">
                      <DatePicker
                        name="editStartDate"
                        value={new Date(this.state.selectedSubLineToEdit.StartDate)}
                        format="MM/dd/yyyy"
                        disabled={true}
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                      >
                      </DatePicker>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label>End Date:</label></div>
                    <div className="d-block">
                      <DatePicker
                        name="editEndDate"
                        value={new Date(this.state.selectedSubLineToEdit.EndDate)}
                        format="MM/dd/yyyy"
                        formatPlaceholder={{ year: 'YYYY', month: 'MM', day: 'DD' }}
                        disabled={true}
                      >
                      </DatePicker>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="d-block"><label>Effort Days:</label></div>
                  <div className="d-block">
                    <DebounceInput
                      width="100%"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-3">
                  <div className="d-block"><label>Allocation (%):</label></div>
                  <div className="d-block">
                    <DebounceInput
                      width="100%"
                      type="number"
                      pattern="[0-9]*"
                      className="k-textbox"
                    />
                  </div>
                </div> */}

              </div>
              <div className="row">

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label className="mandatory">Project Role:</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editRole"
                        data={this.state.allProjectCrPhases}
                        textField="Name"
                        dataItemKey="id"
                        value={this.state.selectedSubLineToEdit.dataValueProjectRole}
                        onChange={this.handleComboChangeEdit}
                        required={true}
                        filterable={true}
                        onFilterChange={this.filter}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label className="mandatory">Designation:</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editSubLineDesignation"
                        data={this.state.tmpDesignationsAll}
                        textField="Name"
                        dataItemKey="id"
                        value={this.state.selectedSubLineToEdit.dataValueCostDesignation}
                        onChange={this.handleComboBoxChangeOnSubLineEdit}
                        required={true}
                        filterable={true}
                        onFilterChange={this.filter}
                      />
                      <span className={this.validateProperty(this.state.selectedSubLineToEdit.dataValueCostDesignation)}>
                        Please select a valid Role
                                 </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="">
                    <div className="d-block"><label className="mandatory">Resource Region:</label></div>
                    <div className="d-block">
                      <ComboBox
                        placeholder="Please select"
                        name="editSubLineRegion"
                        data={this.state.RegionsAll}
                        textField="Name"
                        dataItemKey="id"
                        value={this.state.selectedSubLineToEdit.dataValueResourceRegion}
                        onChange={this.handleComboBoxChangeOnSubLineEdit}
                        required={true}
                        filterable={true}
                        onFilterChange={this.filter}
                      />
                      <span className={this.validateProperty(this.state.selectedSubLineToEdit.dataValueResourceRegion)}>
                        Please select a valid Region
                                        </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block"><label className="mandatory">Onsite / Offshore:</label></div>
                  <div className="d-block">
                    <ComboBox
                      placeholder="Please select"
                      name="editSubLineOnSiteStatus"
                      data={this.state.onSiteStatusAll}
                      value={this.state.selectedSubLineToEdit.OnsiteStatus === 'Onsite' ? this.onSiteStatusAll[0] : this.state.selectedSubLineToEdit.OnsiteStatus === 'Offshore' ? this.onSiteStatusAll[1] : null}
                      textField="Name"
                      dataItemKey="id"
                      onChange={this.handleComboBoxChangeOnSubLineEdit}
                      required={true}
                    />
                    <span className={this.validateProperty(this.state.selectedSubLineToEdit.OnsiteStatus)}>
                      Please select a valid Value
                                      </span>
                  </div>
                </div>

              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="d-block"><label>Rate:</label></div>
                  <div className="d-block">
                    <Input
                      value={this.state.selectedSubLineToEdit.RateValue}
                      name="editRateValue"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block"><label>Revenue:</label></div>
                  <div className="d-block">
                    <Input
                      value={this.state.selectedSubLineToEdit.Revenue && this.state.selectedSubLineToEdit.Revenue > 0 ? Number(this.state.selectedSubLineToEdit.Revenue).toFixed(2) : 0.00}
                      name="editRevenueValue"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block"><label>Cost:</label></div>
                  <div className="d-block">
                    <Input
                      value={this.state.selectedSubLineToEdit.Cost}
                      name="editTotalCostValue"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="d-block"><label>Cost to Revenue Ratio:</label></div>
                  <div className="d-block">
                    <Input
                      value={this.state.selectedSubLineToEdit.CostToRevenueRatio}
                      name="editTotalCostValue"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                {/*<div className="col-md-2">
                                    <div className="d-block"><label>Allocation %:</label></div>
                  <div className="d-block">
                                        <DebounceInput
                                          debounceTimeout={2000}
                                          width="100%"
                                          value={this.state.selectedSubLineToEdit.AllocationPercentage}
                                          onChange={this.handleSubLineAllocationPercentageChange}
                                          name="subLineAllocPercentage"
                                          type="number"
                                          pattern="[0-9]*"
                                          className="k-textbox"
                                          disabled={false}
                                        />
                                    </div>
                                </div>*/}

                {/*<div className="col-md-2">
                                    <div className="d-block"><label>Effort:</label></div>
                                    <div className="d-block">
                                        <DebounceInput
                                          debounceTimeout={1000}
                                          width="100%"
                                          value={this.state.selectedSubLineToEdit.EffortDays}
                                          name="subLineEffortDays"
                                          type="number"
                                          pattern="[0-9]*"
                                          className="k-textbox"
                      disabled={true}
                    />
                  </div>
                </div>*/}
              </div>

              <div className="row mt-3 mb-3">
                <div className="col-md-12 btn-align-right">
                  <Button type="button" primary={true} onClick={this.onUpdateSubLineResource}>Update Sub line</Button>
                  <Button type="button" onClick={this.toggleSubLineEditDialog}>Clear</Button>
                </div>
              </div>

            </Dialog>
          )}

          {this.state.subLineUpdatedDialog === true && (
            <Dialog title={this.state.dialogTitle} onClose={this.toggleSubLineUpdatedDialog} width="400px">
              <p style={{ margin: '25px', textAlign: 'center' }}>{this.state.dialogMessage}</p>
              <DialogActionsBar>
                <button className="k-button modal-primary" onClick={this.toggleSubLineUpdatedDialog}>
                  OK
                </button>
              </DialogActionsBar>
            </Dialog>
          )}

        </div>

        {/*<div>*/}
        {/*    {this.state.showCancelConfirmation === true && (*/}
        {/*      <Dialog*/}
        {/*        title="Please Confirm"*/}
        {/*        onClose={this.toggleCancelConfirmDialog}*/}
        {/*        width="300px"*/}
        {/*      >*/}
        {/*          <p style={{ margin: '25px', textAlign: 'center' }}>*/}
        {/*              Do you want to cancel the Resource Request ?*/}
        {/*          </p>*/}
        {/*          <DialogActionsBar>*/}
        {/*              <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>*/}
        {/*                  No*/}
        {/*              </button>*/}
        {/*              <button*/}
        {/*                className="k-button modal-primary"*/}
        {/*                onClick={this.navigateBack}*/}
        {/*              >*/}
        {/*                  Yes*/}
        {/*              </button>*/}
        {/*          </DialogActionsBar>*/}
        {/*      </Dialog>*/}
        {/*    )}*/}
        {/*</div>*/}

        <div>
          {this.state.approveAddSubLine && (
            <Dialog title={'Please Confirm'} onClose={this.addSubLineToggle}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Effort is exceeding the main line effort. Do you want to proceed?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.addSubLineToggle}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.confirmAddSubLineToGrid}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.allocateResourcesDialog && (
            <Dialog title={'Please Confirm'} onClose={this.submitAllocationToggle}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you want to send {this.state.numSelectedLines} selected lines for resource allocation?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.submitAllocationToggle}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.submitResourcesForAllocation}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.allocateSoftBookedResourcesDialog && (
            <Dialog title={'Please Confirm'} onClose={this.submitSoftbookedAllocationToggle} width="450px">
              <p style={{ textAlign: 'left' }}>
                Do you want to send {this.state.numSelectedLines} selected lines for resource allocation?
              </p>
              <p style={{ textAlign: 'left' }}>
                You can change the dates of this Resource Line if needed, before sending it for Resource Allocation.
              </p>
              <div className="d-flex flex-col justify-content-start" style={{margin: '20px'}}>
                <div className="d-block" style={{marginRight: '25px'}}>
                  <label>Start Date:</label>
                </div>

                <div className="d-block">
                  <div>
                    <DatePicker
                      width="100"
                      name="softBookingStartDate"
                      value={this.state.softBookingStartDate}
                      min={this.state.softBookingStartDateMin}
                      max={this.state.softBookingEndDate ? new Date(this.state.softBookingEndDate) : reStructureDate(this.state.userDefinedEndDate)}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                      onChange={this.handleSoftBookingStartDateChange}
                    />
                    {this.state.softBookingDatesError === true ? (
                      <span className={this.validateProperty(this.state.softBookingStartDate)}>
                        Please select a Start Date
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-col justify-content-start" style={{margin: '20px'}}>
                <div className="d-block" style={{marginRight: '30px'}}>
                  <label>End Date:</label>
                </div>

                <div className="d-block">
                  <div>
                    <DatePicker
                      width="100"
                      name="softBookingEndDate"
                      value={this.state.softBookingEndDate}
                      min={this.state.softBookingStartDate ? reStructureDate(this.state.softBookingStartDate) : new Date(this.state.softBookingStartDateMin)}
                      max={reStructureDate(this.state.userDefinedEndDate)}
                      format="MM/dd/yyyy"
                      formatPlaceholder={{year: 'YYYY', month: 'MM', day: 'DD'}}
                      onChange={this.handleSoftBookingEndDateChange}
                    />
                    {this.state.softBookingDatesError === true ? (
                      <span className={this.validateProperty(this.state.softBookingEndDate)}>
                        Please select a End Date
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.submitSoftbookedAllocationToggle}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.submitSoftBookingResources}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.softBookingDialog && (
            <Dialog title={'Please Confirm'} onClose={this.submitSoftBookingToggle}>
              <p style={{ margin: '25px', textAlign: 'center' }}>
                Do you want to send {this.state.numSelectedLines} selected lines for soft booking?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.submitSoftBookingToggle}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.submitResourcesForSoftBooking}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>
        <div>
          {this.state.showCancelConfirmation === true && (
            <Dialog title="Please Confirm" onClose={this.toggleCancelConfirmDialog} width="300px">
              <p style={{ margin: '25px', textAlign: 'center' }}>
                This form contains unsaved changes. Do you want to close it?
              </p>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleCancelConfirmDialog}>
                  No
                </button>
                <button className="k-button modal-primary" onClick={this.navigateBack}>
                  Yes
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        <div>
          {this.state.showRejectConfirmation === true && (
            <Dialog title="Please Confirm" onClose={this.toggleRejectConfirmDialog} width="300px">
              <label htmlFor="" className="mandatory">
                Reject Reason
              </label>
              <textarea className="k-textarea" maxLength="1000" value={this.state.rejectReason} rows="5" cols="50" onChange={this.handleRejectReasonInputChange}></textarea>
              <DialogActionsBar>
                <button className="k-button active" onClick={this.toggleRejectConfirmDialog}>
                  Close
                </button>
                <button className="k-button modal-primary" disabled={!this.state.rejectReason || this.state.rejectReason === ''} onClick={this.rejectConfirm}>
                  Submit
                </button>
              </DialogActionsBar>
            </Dialog>
          )}
        </div>

        {this.state.showWorkflowSelectDialogRR === true && (
          <Dialog title="Select Workflow" onClose={this.toggleWorkflowSelectDialogRR} width="300px">
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <p className="subtitle-workflow" style={{ textAlign: 'center' }}>Please select the applicable workflow to send for approval.</p>
                </div>
                <div className="row">
                  <form className="k-form modified">
                    <div className="k-form-field">
                      {this.state.matchingWorkflowList.map((eachOption, i) => {
                        return (
                          <div className="field-mod">
                            <input
                              type="radio"
                              id={'' + eachOption.id}
                              name={'' + eachOption.id}
                              value={eachOption.id}
                              checked={this.state.selectedWorkFlowId === eachOption.id}
                              className="k-radio"
                              key={eachOption.id}
                              onChange={this.setSelectedWorkflowId.bind(this, eachOption.id)}
                            />
                            <label
                              key={eachOption.id}
                              htmlFor={'' + eachOption.id}
                              className="k-radio-label"
                              title={"Approvers: " + eachOption.approvers}
                            >
                              {eachOption.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <DialogActionsBar>
              <button className="k-button" onClick={this.toggleWorkflowSelectDialogRR}>
                No
              </button>
              <button
                className="k-button modal-primary"
                onClick={this.setWorkflowToResources}
                disabled={!this.state.selectedWorkFlowId || this.state.disableAddWorkflowBtn}
              >
                Yes
              </button>
            </DialogActionsBar>
          </Dialog>
        )}

      </div>
    );
  }
}

export default ProjectResourceRequest;
